import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import jhon from "../../Assets/john-steenhuisen.png";
import Directoryheader from "./Directoryheader";
import cross from "../../Assets/cross.svg";
import DemoCratic from "../../Assets/Democratic-Alliance.png";

import { useHistory, useLocation } from "react-router";
import tiwtter from "../../Assets/Twitter.svg";
import insta from "../../Assets/Instagram.svg";
import linkdin from "../../Assets/Linkedin.svg";
import dumyDp from "../../Assets/DummyDp.png"
import facebookImg from "../../Assets/facebook.png"

import { Link } from "react-router-dom";
import { BASE_URL_IMG } from "../../Utils/services";

const DemocraticAlliance = () => {
  const [ProfileData, setProfileData] = useState({});
  const [partyMember, setpartyMember] = useState([]);
  const [partyName, setpartyName] = useState();
  const [partyDiscription, setpartyDiscription] = useState();
  const [partyAvtar, setpartyAvtar] = useState();
  const [partyweb, setpartyweb] = useState();
  const [liMenber, setLiMember] = useState();

  const history = useHistory();
  const location = useLocation();

  const handleClick = (type) => {
    /*  history.push("/Directory/Party"); */
    const index = partyMember.findIndex((element) => element.name === type);

    console.log(index, "index");
    console.log(partyMember[index], "index value");
    history.push({
      pathname: "/Directory/expertise/member/profile",
      state: {
        id: partyMember[index],
      },
    });
  };

   const handleBack=()=>{
     history.push("/Directory/Party")
   }

  useEffect(() => {
    
    const { id, avtars, name, discriptions, partyweb,LiMember } = location.state;
    console.log(id, avtars, name, discriptions, "region data allience");

    setpartyMember(id);
    setpartyName(name);
    setpartyDiscription(discriptions);
    setpartyAvtar(avtars);
    setpartyweb(partyweb);
    setLiMember(LiMember)
    
  }, []);


  const Data = [
    {
      id: 1,
      title: "John Steenhuisen",
      party: "Democratic Alliance ",
      country: "Afghanistan",
      img: jhon,
      BgColor: "darkyellow",
    },
    {
      id: 1,
      title: "John Steenhuisen",
      party: "Democratic Alliance ",
      country: "Afghanistan",
      img: jhon,
      BgColor: "darkyellow",
    },
    {
      id: 1,
      title: "John Steenhuisen",
      party: "Democratic Alliance ",
      country: "Afghanistan",
      img: jhon,
      BgColor: "darkyellow",
    },
    {
      id: 1,
      title: "John Steenhuisen",
      party: "Democratic Alliance ",
      country: "Afghanistan",
      img: jhon,
      BgColor: "darkyellow",
    },
    {
      id: 1,
      title: "John Steenhuisen",
      party: "Democratic Alliance ",
      country: "Afghanistan",
      img: jhon,
      BgColor: "darkyellow",
    },
    {
      id: 1,
      title: "John Steenhuisen",
      party: "Democratic Alliance ",
      country: "Afghanistan",
      img: jhon,
      BgColor: "darkyellow",
    },
    {
      id: 1,
      title: "John Steenhuisen",
      party: "Democratic Alliance ",
      country: "Afghanistan",
      img: jhon,
      BgColor: "darkyellow",
    },

    {
      id: 1,
      title: "John Steenhuisen",
      party: "Democratic Alliance ",
      country: "Afghanistan",
      img: jhon,
      BgColor: "darkyellow",
    },
    {
      id: 1,
      title: "John Steenhuisen",
      party: "Democratic Alliance ",
      country: "Afghanistan",
      img: jhon,
      BgColor: "darkyellow",
    },
  ];
  const handleTwitter = (link) => {
    window.open(`https://twitter.com/${link}`);
  };
  const handleInstagram = (link) => {
    window.open(`https://www.instagram.com//${link}`);
  };
  const handleFacebook = (link) => {
    window.open(`http://facebook.com/${link}`);
  };
  const handleLinkedIn = (link) => {
    window.open(`https://www.linkedin.com//${link}`);
  };

 



  return (
    <div>
      <div className="content">
        <div>
          <Directoryheader />
        </div>
        <div className="mt-8 flex items-center expertise ">
          <p>{partyName || "Democratic Alliance"}</p>
          <span className="ml-2" onClick={handleBack}>
            <img src={cross} alt="" />
          </span>
        </div>

        <div className="mt-8 ">
          <Grid container>
            <Grid item sm={7}>
              <div className="flex membertitle mb-4">
                <h3>Members</h3>
                <div className="member mt-4">
                  <hr />
                </div>

                <p>{ partyMember && partyMember.length}member</p>
              </div>
              <Grid container spacing={2}>
                {partyMember &&
                  partyMember.map((items) => {
                    return (
                      <Grid item lg={4} md={6} sm={6} xs={12}>
                        <div className="CommunityProfile">
                          <div className="card">
                            <div
                              className={`profileBackground`}
                              style={{
                              backgroundColor: `${items.region === "Africa" ? "#469025" : ""
                                || items.region === "Asia" ? "#f3007b" : ""
                                  || items.region === "Europe" ? "#48A4DA" : ""
                                    || items.region === "Latin America" ? "#9900CC" : ""
                                      || items.region === "Middle East & North Africa" ? "#FFDF1B" : ""
                                        || items.region === "North America" ? "#CC3300" : ""
                                          || items.region === "Worldwide" ? "#184183" : ""
                                            || items.region === "Oceania" ? "#00008B" : ""}`
                            }}
                            ></div>
                            <div className="text">
                              <img
                                src={items.image ? `${BASE_URL_IMG}${items.image}` : dumyDp}
                                alt="avtar"
                                className="rounded-full w-16 h-16 object-cover"
                              />
                              <h3>{items.name}</h3>
                              <p>{items.party}</p>
                              <p>{items.country}</p>
                              <p className="DirectoryLink cursor-pointer" onClick={() => handleClick(items.name)} >
                                View Profile
                              </p>
                            </div>
                          </div>
                        </div>
                      </Grid>
                    );
                  })}
              </Grid>
            </Grid>
            <Grid item sm={1}></Grid>
            <Grid item sm={4}>
              <div className="directoryWorldWide pb-12">
                <div className="Democraticborder"></div>

                <div className="Democracticprofile">
                  <div className="DemoCracticAvtar ml-6">
                    <img src={partyAvtar || DemoCratic} alt="" />
                  </div>
                  <div className="ml-6 DemoCracticparty mt-2">
                    <h3>{partyName || "Democratic Alliance"}</h3>
                  </div>
                  <div className="limember ml-6">
                   {liMenber &&  <p>LI Member</p>}
                  </div>
                  <div className="ml-6 mr-4 linepera">
                    <p>
                      {partyDiscription}
                    </p>
                  </div>
                  <div className="democraticlink ml-6 mt-4">
                    <a href="abc">{partyweb || "https://www.da.org.za"} /</a>
                  </div>
                  <div className="socialmedia ml-6 mt-4">
                  <ul className="flex items-center">
                  <li onClick={() => handleTwitter(ProfileData.twitter)} className="cursor-pointer">
                    <img src={tiwtter} alt="" />
                  </li>
                  <li className="ml-4 cursor-pointer" onClick={() => handleInstagram(ProfileData.instagram)}>
                    <img src={insta} alt="" />
                  </li>
                  <li className="ml-4 cursor-pointer" onClick={() => handleFacebook(ProfileData.facebook)}>
                    <img src={facebookImg} alt="" />
                  </li>
                  <li className="ml-4 cursor-pointer" onClick={() => handleLinkedIn(ProfileData.linkedin)}>
                    <img src={linkdin} alt="" />
                  </li>
                </ul>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default DemocraticAlliance;
