import { Button, Grid, Modal, Typography, Box } from '@mui/material'
import React from 'react'
import closeicon from "../../Assets/closeicon.svg";
import TimeAgo from 'react-timeago'
import dumyDp from "../../Assets/DummyDp.png"
import { BASE_URL_IMG } from '../../Utils/services';

const LikesModal = ({ likesData, showLikesModal, handleClose }) => {

    const style = {
        position: "absolute",
        top: "50%",
        left: "55%",
        transform: "translate(-50%, -50%)",
        width: 500,
        bgcolor: "background.paper",
        boxShadow: 24,
        p: 8,
    };

    return (
        <div>
            <Modal
                open={showLikesModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                onClose={handleClose}
            >
                <Box sx={style} className="boxmodal">
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        className="eventModel border-none"
                    >
                        PEOPLE WHO REACTED
                    </Typography>
                    {likesData && likesData.map((data) => {
                        return (
                            <>
                                <div className="grid grid-cols-12 gap-4 py-4">
                                    <div className="col-span-2">
                                        <img src={`${BASE_URL_IMG}/user/${data.user}/${data.user}.jpeg?${new Date().getTime()}` || dumyDp} alt="" className="rounded-full" />
                                    </div>
                                    <div className="col-span-10">
                                        <h2 className="text-minihubHeading text-lg">{data.name}</h2>
                                        <TimeAgo date={data.date} className="text-muteText text-sm" />
                                    </div>
                                </div>
                                <hr className="m-1 " />
                            </>
                        );
                    })}
                    <Button
                        style={{ position: "absolute", top: 8, right: 0 }}
                        onClick={handleClose}
                    >
                        <img src={closeicon} alt="closeicon" />
                    </Button>
                </Box>
            </Modal>
        </div>
    )
}

export default LikesModal