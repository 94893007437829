import React, { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
/* import img from "../../Assets/Group431.svg"; */
import { forgotPassword } from "../../Utils/services";
import Loader from "../Loader";
import { useHistory } from "react-router";
import img from "../../Assets/LoginImage.png";
import footerimg from "../../Assets/loginfooterlogo.png";

const ForgetPassword = () => {
  const [email, setEmail] = useState("");
  const [loader, setLoader] = useState(false);
  const history = useHistory();

  const handleForgetPassword = async (e) => {
    setLoader(true);
    e.preventDefault();
    try {
      const result = await forgotPassword({
        email: email,
      });
      if (result.res.success) {
        setLoader(false);
        toast.success(result.res.message);
        history.push({
          pathname: "/otp",
          state: {
            verification_key: result.res.data.details,
            email: email,
          },
        });
      }
    } catch (error) {
      setLoader(false);
      toast.error(error.message);
      console.log(error);
    }
  };

  return (
    <div className="login">
      {/*   <div className='container' >
        <div className='Forget flex justify-center items-center flex-col  ' >
          <img src={img} alt="" />

          <h2>FORGOT PASSWORD?</h2>
        </div>
        <form onSubmit={handleForgetPassword}>
          <div className="mt-2">

            <input
              type='email'
              id="username"
              className="block input p-2 "
              placeholder='Email ID'
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}

            />
          </div>
          <div className="mt-6 mx-0 text-center">
            <input
              type="submit"
              value="SEND"
              className="py-3 cursor-pointer btnn hover:bg-green-600  text-white text-center "
            />
            <Loader size={40} loader={loader} />
            <p className="mt-4">
              <Link to="/login">Remember password ?</Link>
            </p>
          </div>
        </form>


      </div> */}
      <div className="loginBanner">
        <div className="main flex justify-centrer items-center flex-col ">
          <img src={img} alt="" />
          <h2>FORGOT PASSWORD?</h2>

          <form onSubmit={handleForgetPassword}>
            <div className="mt-2">
              <input
                type="email"
                id="username"
                className="block input p-2 "
                placeholder="Email ID"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="mt-6 mx-0 text-center">
              <input
                type="submit"
                value="SEND"
                className="py-3 cursor-pointer btnn hover:bg-green-600  text-white text-center "
              />
              <Loader size={40} loader={loader} />
              <p className="mt-4">
                <Link to="/login">Remember password ?</Link>
              </p>

              <p className="pt-12 px-8 text-white text-sm ">By clicking on Sign in, you confirm to have read and agree to <a href="https://liberal-international.org/privacy-policy/" className="underline underline-offset-8 font-medium" target="_blank" rel="noopener noreferrer">our Privacy Policy & our Terms &Condition</a> .</p>
            </div>
          </form>
        </div>
      </div>

      <div className="loginfooter">
        <img src={footerimg} alt="" />
      </div>
    </div>
  );
};

export default ForgetPassword;
