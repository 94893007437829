import React from "react";
import searchicon from "../Assets/searchicon.svg";
import searchbutton from "../Assets/searchbutton.svg";

const Searchbar = () => {
  const Data = ["Climate", "Isabelle Pucher", "Climate", "Climate", "Climate"];
  return (
    <>
      <div className="searchbox">
        <div className="searchbar ">
          <div class="bg-white shadow flex searchbarbox">
            <span class="w-auto flex justify-end items-center text-gray-500 p-2">
              <i class="material-icons text-3xl">
                <img src={searchicon} alt="searchicon" />
              </i>
            </span>
            <input
              class="w-full rounded p-2"
              type="text"
              placeholder="Search Posts"
            />
            <button class="bg-white-400 hover:bg-white-300 rounded text-black p-2 pl-4 pr-4">
              <span class="w-auto flex justify-end items-center text-gray-500 p-2">
                <i class="material-icons text-3xl">
                  <img src={searchbutton} alt="searchicon" />
                </i>
              </span>
            </button>
          </div>
        </div>
      </div>
      <div className="FrequentSearch">
        <div className="FrequentHeading">
          <h3>Frequent Searches</h3>
        </div>
        <div className="pera">
          {Data.map((search) => {
            return <p>{search}</p>;
          })}
        </div>
      </div>
    </>
  );
};

export default Searchbar;
