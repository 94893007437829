import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Directoryheader from "../Directory/Directoryheader";
import Loader from "../../Component/Loader";


import { Link, useRouteMatch, useHistory } from "react-router-dom";
import { BASE_URL_IMG, DirectoryService } from "../../Utils/services";
import searchicon from "../../Assets/searchicon.svg";
import searchbutton from "../../Assets/searchbutton.svg";


const DirectoryThinkTank = () => {

  const [directoryParty, setDirectoryParty] = useState([]);
  const [loader, setLoader] = useState(true);
  const [searchPostValue, setSearchPostValue] = useState("");
  const [User, setUser] = useState([]);
  const { path } = useRouteMatch();
  const history = useHistory();

  const handlepage = (type) => {
    const data = User.filter((item) => item.party == type.party);
    history.push({
      pathname: `${path}/${type.name}`,
      state: {
        id: data,
        avtars: type.avtar,
        name: type.name,
        discriptions: type.discripctions,
        partyweb: type.websites,
      },
    });

    console.log(data, "partymember");
  };



  const getDirectoryServiceApiData = async () => {
    console.log("result");
    try {
      const result = await DirectoryService();
      console.log(result.res, "result data");
      const _data = result.res.thinktank;
      setUser(result.res.users);
      setDirectoryParty(_data);
      setLoader(false)

      if (result.res.success) {
        setLoader(false);
      }
      console.log(result.res.users, "Usr Data");
    } catch (error) {
      console.log(error);
    }
  };


  useEffect(() => {
    getDirectoryServiceApiData();
  }, []);

  const handleSearchPost = (e) => {
    setSearchPostValue(e.target.value);
  };

  return (
    <>
      <div className="content">
        <div className="SearchBox">
          <div className="bg-white shadow flex searchbarbox">
            <span className="w-auto flex justify-end items-center text-gray-500 p-2">
              <i className="material-icons text-3xl">
                <img src={searchicon} alt="searchicon" />
              </i>
            </span>
            <input
              className="w-full rounded p-2"
              type="text"
              placeholder="Search Thinktank"
              onChange={handleSearchPost}
            />
            <button className="bg-white-400 hover:bg-white-300 rounded text-black p-2 pl-4 pr-4">
              <span className="w-auto flex justify-end items-center text-gray-500 p-2">
                <i className="material-icons text-3xl">
                  <img src={searchbutton} alt="searchicon" />
                </i>
              </span>
            </button>
          </div>
        </div>
        <Directoryheader />
        <div className="mt-8">
          <Grid container>
            <Grid item sm={12}>
              <Grid container spacing={2}>
                <span className="loader">
                  <Loader size={75} loader={loader} />

                </span>
                {directoryParty.filter((val) => {
                  if (searchPostValue == "") {
                    return val;
                  }
                  else if (val && val.name && val.name.toLowerCase().includes(searchPostValue.toLowerCase())) {
                    return val
                  }
                }).map((items) => {
                  return (
                    <Grid item lg={3} md={6} sm={6} xs={12}>
                      <div className="CommunityProfile">
                        <div className="card">
                          <div
                            className={`profileBackground ${items.BgColor} blue`}
                          ></div>
                          <div className="text party">
                            <img src={`${BASE_URL_IMG}${items.logo}`} alt="" />
                            <h3>{items.name}</h3>
                            <p>{items.membersCount} Members </p>

                            {/* <Link to={`${path}/${items.id}`}>View Profile</Link> */}
                            <p
                              onClick={() =>
                                handlepage({
                                  party: items.name,
                                })
                              }
                              className="DirectoryLink cursor-pointer"
                            >
                              View
                            </p>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>

          </Grid>
        </div>
      </div>
    </>
  )
}

export default DirectoryThinkTank;