import React, { useEffect, useState } from 'react';
import Grid from "@mui/material/Grid";
import Socialpost from '../../Component/Socialpost';
import Polls from "../../Component/Polls";
import Socialvideo from "../../Component/Socialvideo";
import { GetFeedService } from '../../Utils/services';
import Socialtext from '../../Component/Socialtext';



export const DIrectoryMemberPost = ({id}) => {
  const [FeedData, setFeedsData] = useState([]);
  const [userDetails, setUserDetails] = useState([]);

  const getDirectoryServiceApiData = async () => {
    const userId = localStorage.getItem("loginUserId")
    try {
      const result = await GetFeedService({
        "userId": id
      });
      console.log(result, "result postpage");
      setFeedsData(result.res.posts);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getDirectoryServiceApiData();

  }, [])

  const handleTextpost = ({ data, isImg }) => {
    return (
      <>
        {isImg === "jpeg" || isImg === "png" || isImg === "pdf" ? (
          <Socialpost
            title={data.postedBy.name}
            userId={data.postedBy.id}
            heading={data.title}
            pera={data.caption}
            userImg={data.postedBy.image}
            PostImage={
              isImg !== "pdf" && data.media.length > 0 && data.media[0].link
            }
            postPdfFile={
              isImg === "pdf" && data.media.length > 0 && data.media[0].link
            }
            location={data.location}
            _id={data._id}
            comments={data.comments}
            likes={data.likes.length & data.likes.length}
            userDetails={userDetails}
            getDirectoryServiceApiData={getDirectoryServiceApiData}
          />
        ) : (
          <Socialtext
            title={data.postedBy.name}
            userId={data.postedBy.id}
            heading={data.title}
            pera={data.caption}
            userImg={data.postedBy.image}
            PostImage={
              isImg !== "pdf" && data.media.length > 0 && data.media[0].link
            }
            postPdfFile={
              isImg === "pdf" && data.media.length > 0 && data.media[0].link
            }
            location={data.location}
            _id={data._id}
            comments={data.comments}
            likes={data.likes.length & data.likes.length}
            userDetails={userDetails}
            getDirectoryServiceApiData={getDirectoryServiceApiData}
          />
        )}
      </>
    );
  };

  const renderPost = (data) => {
    let isImg =
      data.media.length > 0 &&
      data.media[0].link.split(".")[data.media[0].link.split(".").length - 1];
    return (
      <>
        {isImg === "jpeg" ||
        isImg === "png" ||
        isImg === "pdf" ||
        isImg === false ? (
          /*  <Socialpost
              title={data.postedBy.name}
              userId={data.postedBy.id}
              heading={data.title}
              pera={data.caption}
              userImg={data.postedBy.image}
              PostImage={isImg !== "pdf" && data.media.length > 0 && data.media[0].link}
              postPdfFile={isImg === "pdf" && data.media.length > 0 && data.media[0].link}
              location={data.location}
              _id={data._id}
              comments={data.comments}
              likes={data.likes.length & data.likes.length}
              userDetails={userDetails}
              getDirectoryServiceApiData={getDirectoryServiceApiData}
            /> */
          handleTextpost({ data, isImg })
        ) : (
          <Socialvideo
            name={data.postedBy.name}
            userId={data.postedBy.id}
            heading={data.title}
            para={data.caption}
            userImg={data.postedBy.image}
            postVideo={data.media.length > 0 && data.media[0].link}
            location={data.location}
            _id={data._id}
            comments={data.comments}
            likes={data.likes.length & data.likes.length}
            userDetails={userDetails}
            getDirectoryServiceApiData={getDirectoryServiceApiData}
          />
        )}
      </>
    );
  };

  return (
    <div>
      <div className="Socialpost">
        <Grid container>
          
          <Grid item xs={12}>
            {FeedData.length === 0 && <div className='mt-32 flex justify-center items-center'> <h2 className='text-3xl text-paraText'>Oops! No Data For This Profile</h2> </div>}
            {FeedData && FeedData.map((item) => {
              return (
                <>
                  {item.isPoll ? (
                    <Polls
                      question={item.question}
                      User={item.postedBy.name}
                      location={item.location}
                      choices={item.choices}
                      length = {item.length}
                      img={item.postedBy.image}
                    />
                  ) : (
                    renderPost(item)
                  )}
                </>
              );
            }) 
          }
          </Grid>
        </Grid>
      </div>
    </div>
  )
}
