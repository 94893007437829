import React, { Component } from "react";
import Slider from "react-slick";
import { useHistory } from "react-router-dom";

const MultipleItems = ({ minihubs }) => {
  const history = useHistory();

  
  const handleMinihubShow = (item) => {
    history.push({
      pathname : "/about/minhub",
      state : {
        minihubId : item._id,
        groupDetails : item
      }
    })
  }
  
  const settings = {
    infinite: true,
    speed: 500,
    arrows: false,
    slidesToShow: minihubs && minihubs.length >= 3 ? 3 : 1,
    slidesToScroll: minihubs && minihubs.length >= 3 ? 3 : 1,
    autoplay: true,
  };
  return (
    <>
      <div>
        <Slider {...settings}>
          {
            minihubs && minihubs.map((item) => {
              return (
                <>
                  <div className="flex cursor-pointer justify-center mx-1 items-center px-4 py-4 discussionslider" >
                    <a onClick={() => handleMinihubShow(item)}> <h1>{item.name}</h1></a>
                  </div>
                </>
              )
            })
          }
        </Slider>
      </div>
    </>
  );
}

export default MultipleItems