import React from 'react'
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";

const Loader = ({ loader, size }) => {
    const override = css`
        position : absolute;
        top : 56%;
        left : 49%;
    `;
    return (
        <>
            {
                loader && <ClipLoader size={size} loading={loader} color="#75ea9c" css={override} />
            }
        </>
    )
}

export default Loader
