import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import jhon from "../../Assets/john-steenhuisen.png";
import Directoryheader from "./Directoryheader";
import cross from "../../Assets/cross.svg";
import DemoCratic from "../../Assets/Democratic-Alliance.png";

import { useHistory, useLocation } from "react-router";
import tiwtter from "../../Assets/Twitter.svg";
import insta from "../../Assets/Instagram.svg";
import linkdin from "../../Assets/Linkedin.svg";
import { Link } from "react-router-dom";
import { BASE_URL_IMG, DirectoryService } from "../../Utils/services";
import dumyDp from "../../Assets/DummyDp.png"


const DirectoryOrganisationMember = () => {
  const [partyMember, setpartyMember] = useState();
  const [partyName, setpartyName] = useState();
  const [partyDiscription, setpartyDiscription] = useState();
  const [partyAvtar, setpartyAvtar] = useState();
  const [partyweb, setpartyweb] = useState();

  const history = useHistory();
  const location = useLocation();

  const handleClick = (type) => {

    const index = partyMember.findIndex((element) => element.name === type);

    console.log(index, "index");
    console.log(partyMember[index], "index value");
    history.push({
      pathname: "/Directory/expertise/member/profile",
      state: {
        id: partyMember[index],
      },
    });
  };

  const getDirectoryServiceApiData = async () => {
    console.log("result");
    try {
      const result = await DirectoryService();
      console.log(result.res, "result data");
      const _data = result.res.organization;
    

      const organizationdetail=_data.filter((item)=>item.name===partyName)
      console.log(organizationdetail,"data")
      
      
      console.log(result.res.users, "Usr Data");
  
    
  
    } catch (error) {
      console.log(error);
    }
  };


  useEffect(() => {
    getDirectoryServiceApiData();
  }, []);




   const handleBack=()=>{
     history.push("/Directory/organization")
   }

  useEffect(() => {
    const { id, avtars, name, discriptions, partyweb } = location.state;
    console.log( name, "region data allience");
    setpartyMember(id);
    setpartyName(name);
    setpartyDiscription(discriptions);
    setpartyAvtar(avtars);
    setpartyweb(partyweb);
  }, []);

  console.log(partyDiscription,"discription")

  return (
    <div>
      <div className="content">
        <div>
          <Directoryheader />
        </div>
        <div className="mt-8 flex items-center expertise ">
          <p>{partyName || "Democratic Alliance"}</p>
          <span className="ml-2" onClick={handleBack}>
            <img src={cross} alt="" />
          </span>
        </div>

        <div className="mt-8 ">
          <Grid container>
            <Grid item sm={7}>
              <div className="flex membertitle mb-4">
                <h3>Members</h3>
                <div className="member mt-4">
                  <hr />
                </div>

                <p>{ partyMember && partyMember.length}member</p>
              </div>
              <Grid container spacing={2}>
                {partyMember &&
                  partyMember.map((items) => {
                    return (
                      <Grid item lg={4} md={6} sm={6} xs={12}>
                        <div className="CommunityProfile">
                          <div className="card">
                            <div
                              className={`profileBackground ${items.BgColor} dark-blue`}
                            ></div>
                            <div className="text">
                              <img
                                src={items.image ? `${BASE_URL_IMG}${items.image}` : dumyDp}
                                alt="avtar"
                                className="rounded-full w-16 h-16 object-cover"
                              />
                              <h3>{items.name}</h3>
                              <p>{items.party}</p>
                              <p>{items.country}</p>
                              <p className="DirectoryLink cursor-pointer" onClick={() => handleClick(items.name)} >
                                View Profile
                              </p>
                            </div>
                          </div>
                        </div>
                      </Grid>
                    );
                  })}
              </Grid>
            </Grid>
            <Grid item sm={1}></Grid>
            <Grid item sm={4}>
              <div className="directoryWorldWide pb-12">
                <div className="Democraticborder"></div>

                <div className="Democracticprofile">
                  <div className="DemoCracticAvtar ml-6">
                    <img src={partyAvtar || DemoCratic} alt="" />
                  </div>
                  <div className="ml-6 DemoCracticparty mt-2">
                    <h3>{partyName || "Democratic Alliance"}</h3>
                  </div>
                  <div className="limember ml-6">
                    <p>LI Member</p>
                  </div>
                  <div className="ml-6 mr-4 linepera">
                    <p>
                      {partyDiscription}
                    </p>
                  </div>
                  <div className="democraticlink ml-6 mt-4">
                    <a href="abc">{partyweb || "https://www.da.org.za"} /</a>
                  </div>
                  <div className="socialmedia ml-6 mt-4">
                    <ul className="flex items-center">
                      <li>
                        <img src={tiwtter} alt="" />
                      </li>
                      <li className="ml-4">
                        <img src={insta} alt="" />
                      </li>
                      <li className="ml-4">
                        <img src={linkdin} alt="" />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default DirectoryOrganisationMember;
