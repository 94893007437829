import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import Modal from "@mui/material/Modal";
import closeicon from "../Assets/closeicon.svg";
/* import Calender from "../Pages/Home/Calender"; */
import CalenderIcon from "../Assets/calendericon.svg";
import pluseIcon from "../Assets/pluseicon.svg";
import toggleone from "../Assets/modalcalendertoggleone.svg";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { getEvent } from "../Utils/services";
import { useEffect, useState } from "react";

const style = {
  position: "absolute",
  top: "40%",
  left: "55%",
  transform: "translate(-50%, -50%)",
  width: 645,

  bgcolor: "background.paper",

  boxShadow: 24,
  px: 8,
};

export default function CalenderModel({ handlevalue, handleClose }) {
  const [toggle, settoggle] = useState(false);

  const [currentDate, setCurrentDate] = useState(new Date());
  const [allEvents, setAllEvents] = useState([]);
  const [showEvent, setShowEvent] = useState([]);
  const [year, setYear] = useState(new Date().getUTCFullYear());
  const [month, setMonth] = useState(new Date().getMonth() + 1);

  const handleToggleButton = (e) => {
    e.preventDefault();
    settoggle(!toggle)
  }
  const formateTime = (time) => {
    const _Time = new Date(time);
    const hours = _Time.getHours();
    const minutes = _Time.getMinutes();
    const FullTime = `${hours}:${minutes}`
    return FullTime
  }
  const formatDate = date => {
    const _date = new Date(date)
    const month = _date.getMonth() + 1
    const year = _date.getFullYear()
    const newdate = _date.getDate()
    return `${newdate}/${month}/${year}`
  }


  const handleActiveStartDate = (value) => {
    console.log(value.activeStartDate, "Change Month")
    const _yearMonth = value.activeStartDate;
    const year = _yearMonth.getFullYear();
    const month = _yearMonth.getMonth() + 1;
    setCurrentDate(value.activeStartDate)
    setYear(year)
    setMonth(month)
  }

  const getEventApiData = async (year, month) => {
    try {
      const result = await getEvent({
        year,
        month,
      });
      if (result.res.data.length > 0) {
        setAllEvents(result.res.data)
        let arry = []
        const _allData = result.res.data;
        _allData && _allData.map((item) => {
          const _date = new Date(item.current)
          const _d1 = formatDate(_date)
          const _d2 = formatDate(currentDate)
          if (_d1 === _d2) {
            arry.push(item)
          }
          else {
            console.log(false);
          }
          setShowEvent(arry)
        })
      }
    } catch (error) {
      console.log(error);
    }
  };

  const dateHandler = (date) => {
    setCurrentDate(date)
    setShowEvent([])
    let arry = []
    allEvents.map((item) => {
      const _date = new Date(item.current)
      const _d1 = formatDate(_date)
      const _d2 = formatDate(date)
      if (_d1 === _d2) {
        arry.push(item)
      }
      else {
        console.log(false);
      }
      setShowEvent(arry)
    })
  }


  useEffect(() => {
    getEventApiData(year, month);
  }, [year, month]);


  return (
    <div>
      <Modal
        open={handlevalue}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="boxmodal">
          <div className="pt-14 pl-14 pr-14  flex justify-between">
            <div className="flex items-center">
              <img src={CalenderIcon} alt="icon" />
              <h3 className="ml-4 calendevent underline">Event</h3>
            </div>

            <div className="flex items-center">
              <img src={pluseIcon} alt="icon" />
              <img src={toggleone} alt="icon" className="ml-4" />
            </div>
          </div>
          <div className="ml-14 mb-4 mt-8 mr-14 pb-4 modalborder">
            <Calendar
              onChange={dateHandler}
              value={currentDate}
              onActiveStartDateChange={handleActiveStartDate}
              next2Label={null}
              prev2Label={null}
            />
          </div>
          <div className="mt-8 ml-14 mr-14 pb-14 ">
            <h3 className=" calendevents mb-2">Details</h3>
          </div>
          <div className='calender-auto-overflow'>
            {
              showEvent.length > 0 ? (
                showEvent.map(item => (
                  <div
                    className={`p-5 mb-5 ${item.postTo == "li" ? "bg-sidenav" : "bg-blue"}`
                    }
                    key={item._id}
                  >
                    <h3
                      className={`text-lg ${item.postTo == "li" ? "text-white" : "text-white"} `}
                    >
                      {item.name}
                    </h3>
                    <p
                      className={`text-sm ${item.postTo == "li" ? "text-white" : "text-white"}`}>
                      {item.description}
                    </p>
                    <p
                      className={`text-sm ${item.postTo == "li" ? "text-white" : "text-white"}`}
                    >
                      {`${formatDate(item.start)} to ${formatDate(item.end)}`}
                    </p>
                    <p className={`text-sm ${item.postTo == "li" ? "text-white" : "text-white"}`}>{`${formateTime(item.startTime)} to ${formateTime(item.endTime)}`}</p>
                    <p className={`text-sm ${item.postTo == "li" ? "text-white" : "text-white"}`}>{item.timezone}</p>
                    <p
                      className={`text_tosmall_10 ${item.postTo == "li" ? "text-white" : "text-white"}`}
                    >
                      {item.links.map((item2, index) => {
                        return (
                          <><div key={index}>
                            <p>{item2}</p>
                          </div>
                          </>
                        )
                      })}
                    </p>
                  </div>
                ))
              ) : (

                <div className="ml-5">
                  <p>No Events for today</p>
                </div>
              )
            }
          </div>
          <Button
            onClick={handleClose}
            style={{ position: "absolute", top: 8, right: 0 }}
          >
            <img src={closeicon} alt="closeicon" />
          </Button>
        </Box>
      </Modal>
    </div>
  );
}
