import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { Autocomplete, Box, Button, Modal, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { addPartyMemberApi, DirectoryService, HubServices } from '../../Utils/services';
import { toast } from 'react-toastify';
import closeicon from "../../Assets/closeicon.svg";

const style = {
    position: "absolute",
    top: "50%",
    left: "55%",
    transform: "translate(-50%, -50%)",
    width: 1000,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 8,
};


const AddNewMembersModal = ({
    handleShow,
    handleModalClose,
    getDirectoryServiceApiData,
    partyname,
}) => {
    const [loader, setLoader] = useState(false)
    const [allHubsData, setAllHubsData] = useState([]);
    const [formData, setFormData] = useState({});
    const [fileName, setFileName] = useState('');
    const [hubsDataValue, setHubsDataValue] = useState([]);
    const [allDirecoryDataValue, setAllDirectoryDataValue] = useState({
        party: [],
        expertise: []
    })
    const [allDirecoryData, setAllDirectoryData] = useState({
        party: [],
        expertise: [],
    });
    const [region, setRegion] = useState('');
    const [countries, setCountries] = useState([]);
    const [regions, setRegions] = useState([]);
    const [country, setCountry] = useState('');


    const onChangeHandler = e => {
        e.preventDefault()
        const name = e.target.name
        const value = e.target.value

        setFormData({
            ...formData,
            [name]: value
        })
    }

    const getAllHubsData = async () => {
        try {
            const result = await HubServices();
            setAllHubsData(result.res.allHubs)

        } catch (error) {
            toast.error(error.message)
        }
    }

    const getDirectoryAllData = async () => {
        try {
            const result = await DirectoryService();
            console.log(result)
            const _validParty = result && result.res.party && result.res.party.filter((data) =>{
                return data.name === partyname
            })
            setAllDirectoryData({
                party: _validParty,
                expertise: result.res.expertise,

            })
            setRegions(result.res.regions)
        } catch (error) {
            toast.error(error.message)
        }
    }

    const handleRegionData = async (val) => {
        setRegion(val);
        try {
            const result = await DirectoryService();
            const countriesData = result.res && result.res.country && result.res.country.filter(obj => {
                return obj.region.toLowerCase() == val.toLowerCase()
            }
            )
            setCountries(countriesData)
        }
        catch (error) {
            console.log(error);
            toast.warning("Something went wrong!")
        }
    }

    useEffect(() => {
        getAllHubsData();
        getDirectoryAllData();
    }, []);

    const onInputChange = (event, newInputValue, name) => {
        setHubsDataValue({
            ...hubsDataValue,
            [name]: newInputValue
        })
    }


    function dynamicSort(property) {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }
    const handleAllDirectory = (event, newInputValue, name) => {
        setAllDirectoryDataValue({
            ...allDirecoryDataValue,
            [name]: newInputValue
        })
    }
    const handleFile = (val) => {
        if (!!val) {
            let img = new Image();
            img.src = window.URL.createObjectURL(val);

            img.onload = () => {
                if (img.width === 400 && img.height === 400) {
                    setFileName(val);
                }
                else {
                    setFileName("");
                }
            }
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoader(true);
        const addFormData = new FormData();
        if (hubsDataValue) {
            const hubsValueSend = hubsDataValue && hubsDataValue.hubs.map(data => {
                return data._id
            })
            addFormData.append("hubs", hubsValueSend);
        }
        if (allDirecoryDataValue.expertise) {
            const ExpertiseValueSend = allDirecoryDataValue.expertise && allDirecoryDataValue.expertise.map(data => {
                return data.area
            })
            addFormData.append("expertise", ExpertiseValueSend);
        }
        addFormData.append("name", formData.name);
        addFormData.append("email", formData.email && formData.email.toLowerCase());
        addFormData.append("designation", formData.designation);
        addFormData.append("media", fileName);
        addFormData.append("region", region);
        addFormData.append("country", country);
        if (allDirecoryDataValue.party !== undefined) {
            addFormData.append("party", allDirecoryDataValue.party._id);
        }
        try {
            const results = await addPartyMemberApi(addFormData);
            if (results.res.success) {
                toast.success(results.res.message);
                setLoader(false);
                getDirectoryServiceApiData();
                handleModalClose();
            }
        }
        catch (e) {
            setLoader(false);
            toast.error(e.messages);
        }
    }
    return (
        <>
            <Modal
                open={handleShow}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                onClose={handleModalClose}
            >
                <Box sx={style} className="boxmodal">
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        className="eventModel"
                    >
                        ADD NEW MEMBER
                    </Typography>
                    <form noValidate autoComplete="off" className="w-full">
                        <div className="grid grid-cols-2 gap-x-24 gap-y-4">
                            <TextField
                                id="name"
                                label="Name"
                                name="name"
                                value={formData.name || ''}
                                onChange={e => onChangeHandler(e)}
                            />
                            <TextField
                                id="email"
                                label="Email"
                                name="email"
                                value={formData.email || ''}
                                onChange={e => onChangeHandler(e)}
                            />
                            <TextField
                                id="designation"
                                label="Title"
                                name="designation"
                                value={formData.designation || ''}
                                onChange={e => onChangeHandler(e)}
                            />
                            <div>
                                <div className="relative w-auto overflow-hidden">
                                    <TextField
                                        id="profilephoto"
                                        label="Add a Profile Photo"
                                        value={fileName.name || ""}
                                        className="w-full text-xs"
                                    />

                                    <input
                                        type="file"
                                        accept="image/*"
                                        className="z-50 opacity-0 absolute bottom-0 right-0 w-50"
                                        name="profile-photo"
                                        onChange={(e) => handleFile(e.target.files[0])}
                                    />
                                    <div className="floating-box" >+</div>
                                </div>
                                <p className="text-sm text-red-800 mt-2">Note: Image dimensions should be 400x400px </p>
                            </div>
                            <Autocomplete
                                id="region"
                                options={regions && regions.sort(dynamicSort("name"))}
                                getOptionLabel={(option) => option.name}
                                inputValue={region}
                                onInputChange={(event, newInputValue) => handleRegionData(newInputValue)}
                                renderInput={(params) => <TextField {...params} label="Region" />}
                            />
                            <Autocomplete
                                id="country"
                                options={countries && countries.sort(dynamicSort("name"))}
                                getOptionLabel={(option) => option.name}
                                inputValue={country}
                                onInputChange={(event, newInputValue) => setCountry(newInputValue)}
                                renderInput={(params) => <TextField {...params} label="Country" />}
                            />

                            <Autocomplete
                                name="party"
                                options={allDirecoryData.party && allDirecoryData.party.sort(dynamicSort("name"))}
                                getOptionLabel={(option) => option.name}
                                value={allDirecoryDataValue.party.name}
                                onChange={(e, newVal) => handleAllDirectory(e, newVal, 'party')}
                                renderInput={(params) => <TextField {...params} id="party" label="Name of the Party/Organisation/Thinktank" />}
                            />
                            <Autocomplete
                                multiple
                                name="expertise"
                                limitTags={2}
                                options={allDirecoryData.expertise && allDirecoryData.expertise.sort(dynamicSort("area"))}
                                getOptionLabel={(option) => option.area}
                                value={allDirecoryDataValue.expertise.area}
                                onChange={(e, newVal) => handleAllDirectory(e, newVal, 'expertise')}
                                renderInput={(params) => <TextField {...params} id="expertise" label="Add Expertise" />}
                            />
                            <Autocomplete
                                multiple
                                name="hubs"
                                limitTags={2}
                                options={allHubsData && allHubsData.sort(dynamicSort("name"))}
                                getOptionLabel={(option) => option.name}
                                value={hubsDataValue.name}
                                onChange={(e, newVal) => onInputChange(e, newVal, 'hubs')}
                                renderInput={(params) => <TextField {...params} id="hubs" label="Add Hubs" />}
                            />
                        </div>
                        <div component="div" className="addbutton flex justify-center pt-8 relative">
                            <button disabled={loader ? true : false} className={`addbtn ${loader && "bgButtonLoader"}`} onClick={handleSubmit}>SEND REQUEST</button>
                        </div>
                    </form>
                    <Button
                        style={{ position: "absolute", top: 8, right: 0 }}
                        onClick={handleModalClose}
                    >
                        <img src={closeicon} alt="closeicon" />
                    </Button>
                </Box>
            </Modal>
        </>
    )
}

export default AddNewMembersModal;