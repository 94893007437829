import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";

import astrothor from "../../Assets/AstridThors.png";
import WorldWide from "../../Assets/Worldwide.svg";
import WorldMap from "../../Assets/WorldMap.svg"

//import CountryData from "./CountryData";
import Directoryheader from "../Directory/Directoryheader";
import { BASE_URL_IMG, DirectoryService } from "../../Utils/services";
import { useHistory, useLocation } from "react-router";
import Map from "../../Component/Map";
import { Image } from "@material-ui/icons";
import { ReactComponent as MyIcon } from "../../Assets/Map.svg"
import SearchBox from "../../Component/SearchBox";
import searchicon from "../../Assets/searchicon.svg";
import searchbutton from "../../Assets/searchbutton.svg";
import NewMap from "../../Component/NewMaps";
import { ReactComponent as Mapnew } from "../../Assets/NewWorldMap.svg";


const Directory = () => {
  const [searchPostValue, setSearchPostValue] = useState("");
  const [regionData, setRegionData] = useState([]);
  const [directory, SetDirectory] = useState([])
  const [party, setParty] = useState([])
  const [User, setUser] = useState([]);
  
  const [wordEntered, setWordEntered] = useState(false);

  const history = useHistory();
  const loction = useLocation();
  const handlepage = (type) => {
    const data = User.filter(item => item.region == type)
    console.log(type, "typedata")
    history.push({
      pathname: "/Directory/region",
      state: { id: data, country: type },
    });
  };

  const handleProfile = (type) => {
    console.log(type, "type id")
    history.push({
      pathname: "/Directory/expertise/member/profile",
      state: { id: type },
    });

  }

  const getDirectoryServiceApiData = async () => {
    console.log("result");
    try {
      const result = await DirectoryService();
      console.log(result.res, "result data");
      console.log(result.res, "All Data");
      const _data = result.res.regions;
      const _User = result.res.users;
      console.log(_User, "user Data");
      SetDirectory(result.res.country)
      setParty(result.res.party)

      setRegionData(_data);
      setUser(_User);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getDirectoryServiceApiData();

  }, []);



  const handleSearchPost = (e) => {
    setSearchPostValue(e.target.value);
    // setWordEntered(true) 
  


  };

  return (
    <>
      <div className="content">
        <div className="SearchBox">
          <div className="bg-white shadow flex searchbarbox">
            <span className="w-auto flex justify-end items-center text-gray-500 p-2">
              <i className="material-icons text-3xl">
                <img src={searchicon} alt="searchicon" />
              </i>
            </span>
            <input
              className="w-full rounded p-2"
              type="text"
              placeholder="Who are you looking for ?"
              onChange={handleSearchPost}
            />
            <button className="bg-white-400 hover:bg-white-300 rounded text-black p-2 pl-4 pr-4">
              <span className="w-auto flex justify-end items-center text-gray-500 p-2">
                <i className="material-icons text-3xl">
                  <img src={searchbutton} alt="searchicon" />
                </i>
              </span>
            </button>
          </div>
        </div>
     

        <Directoryheader />

        <div className="mt-8">
          <Grid container   >
            <Grid item sm={12}  >
              <div className="Directory-list mt-6" >
                <Grid container spacing={2}>
                  <Grid item xs={12} >
                    <div className="map" >
                      {
                        searchPostValue === "" && <NewMap wordEntered={wordEntered}  />
                      }   
                    </div>
                    <div className="flex justify-between worlddata pt-4" >
                      <div><span> {regionData.length} Regions </span></div>
                      <div><span> {directory.length} Countries </span></div>
                      <div><span>{User.length} People</span></div>
                      <div><span>{party.length} Parties </span></div>
                    </div>
                  </Grid>
                </Grid>
              </div>
              {/* {<div className="people">
                <h3>People</h3>
              </div>} */}

              {/*   <Grid container spacing={2}>
                {User &&
                  User.map((items) => {
                    return (
                      <Grid item   lg={4} md={6} sm={6} xs={12} >
                        <div className="CommunityProfile" key={items.id}>
                          <div className="card">
                            <div
                              className={`profileBackground` }
                              style={{background : ` #184183`}}
                            ></div>
                            <div className="text">
                              <img
                                src={items.image || astrothor }
                                alt="profile photo"
                                className="rounded-full w-16 h-16 object-cover "
                              />

                              <h3>{items.name}</h3>
                              <p>{items.party}</p>
                              <p>{items.country}</p>

                           
                              <p className="DirectoryLink cursor-pointer" onClick={()=>handleProfile(items._id)} >View Profile</p>
                            </div>
                          </div>
                        </div>
                      </Grid>
                    );
                  })}
              </Grid> */}
            </Grid>
            <Grid item sm={12}>
              <div className="directoryWorldWide mt-8">
                <div className="topborder"></div>
                <div className="Regions flex items-center flex-col justify-center">
                  <div className="resgions">
                    <h3>Regions</h3>
                  </div>
                  <div
                    className="RegionsCountry mt-4 pb-8 cursor-pointer"

                  >
                    <Grid container>
                      {/* <Grid item xs={3}>
                        <div className="my-4 flex items-center flex-col justify-center">
                          <Grid item xs={6}>
                            <div className="worldwidemap">
                              <img src={WorldWide} alt="" />
                            </div>
                          </Grid>
                          <Grid item xs={6}>
                            <div className="worldwide lg:mt-6 ml-6 md:mt-2 sm:mt-2">
                              <h3>Worldwide</h3>
                            </div>
                          </Grid>
                        </div>
                      </Grid> */}
                      {regionData && regionData.filter((val) => {
                        if (searchPostValue == "") {
                          return val;
                        }
                        else if(val && val.name && val.name.toLowerCase().includes(searchPostValue.toLowerCase()) ){
                          return val
                        }
                      }).map((item) => {
                        return (
                          <>
                            <Grid item lg={3} md={3} sm={4} xs={6} >
                              <div className="my-4 flex items-center flex-col justify-center" key={item._id} onClick={() => handlepage(item.name)}>
                                <Grid item xs={6}>
                                  <div className="flex items-center ">
                                    <div className="country">
                                      <img src={`${BASE_URL_IMG}${item.image}`} alt="" className="w-screen" />
                                    </div>
                                  </div>
                                </Grid>
                                <Grid item xs={6}>
                                  <div className="flex items-center cursor-pointer ">
                                    <div className="countryName ml-4">
                                      <h4>{item.name}</h4>
                                    </div>
                                  </div>
                                </Grid>
                              </div>
                            </Grid>
                          </>
                        );
                      })}
                    </Grid>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default Directory;
