import React, { useEffect, useState } from "react";
import hakima from "../Assets/haki.png";
import climate from "../Assets/climate.png";
import profile from "../Assets/DummyDp.png";
import socialbtn from "../Assets/socialpost-btn.svg";
import path from "../Assets/Path.svg";
import interfac from "../Assets/interface.svg";
import heart from "../Assets/heart.svg";
import inetrfaceColor from "../Assets/interfaceColor.svg";
import Blueheart from "../Assets/BlueHeart.svg";
import Artical from "../Pages/Home/Artical";
import Comments from "../Pages/Home/Comments";
import Grid from "@mui/material/Grid";
import {
  addComment,
  BASE_URL_IMG,
  getComments,
  LikeDislikePostApi,
} from "../Utils/services";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../Component/Loader";
import PostReportModal from "../Modals/PostReportModal";
import DeleteReportForm from "../Modals/DeleteReportForm";
import DummyDp from "../Assets/DummyDp.png";

const Socialtext = ({
  postDate,
  title,
  userImg,
  heading,
  pera,
  PostImage,
  location,
  _id,
  comments,
  likes,
  likesData,
  userId,
  userDetails,
  getDirectoryServiceApiData,
  postPdfFile,
}) => {
  console.log(postPdfFile, "postPdfFile");
  const [toggleinterface, settoggleinterface] = useState(false);
  const [postData, setPostData] = useState("");
  const [commentData, setCommentData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);
  const [postId, setPostId] = useState(null);
  const [userReportId, setUserReportId] = useState(null);
  const [liked, setLiked] = useState(false);
  const [showDeleteReport, setShowDeleteReport] = useState(false);
  const [hoverPdf, setHoverPdf] = useState(false);
  const [showmMoreCaptionData, setShowmMoreCaptionData] = useState(false);
  const [moreCaption, setMoreCaption] = useState(false);

  const getCommentsAllData = async (id) => {
    const result = await getComments(id);
    setCommentData(result.res);
  };

  // useEffect(() => {
  //   getCommentsAllData();
  // }, []);
  useEffect(() => {
    if (pera && pera.length > 300) {
      setMoreCaption(true);
    }
  }, []);

  const handlePostComment = async (e) => {
    setLoader(true);
    e.preventDefault();
    try {
      const result = await addComment({
        comment: postData,
        postId: _id,
      });
      if (result.res.success) {
        setLoader(false);
        toast.success(result.res.message);
        getCommentsAllData(_id);
        getDirectoryServiceApiData();
      }
    } catch (error) {
      setLoader(false);
      console.log(error);
      toast.warning(error.message);
    }
    setPostData("");
  };

  const handleReportModal = (_id, userId) => {
    console.log(userId);
    setPostId(_id);
    setUserReportId(userId);
    setShowDeleteReport(true);
  };
  const handleClose = () => {
    setShowReportModal(false);
    setShowDeleteReport(false);
  };

  useEffect(() => {
    userDetails &&
      userDetails.filter((id) => {
        if (id === _id) {
          setLiked(true);
        }
      });
  }, [userDetails]);

  console.log(liked, "Liked");

  const handleLikeDislike = async () => {
    try {
      const result = await LikeDislikePostApi({
        postId: _id,
        userId: userId,
      });
      if (result.res.success) {
        console.log(result.res.message);
        setLiked(!liked);
        getDirectoryServiceApiData();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleTextpost = (data) => {
    return (
      <>
        {data ? (
          <img src={`${BASE_URL_IMG}${PostImage}`} alt="This is post Image" />
        ) : (
          <div className="w-full h-auto bg-pdfBg flex flex-col  pt-4 pb-16 pl-4 emptyimage">
            <h3>{heading}</h3>
            <p className="pl-3 peratext new-line">
              {pera !== "undefined"
                ? `${showmMoreCaptionData ? pera : pera && pera.slice(0, 300)}`
                : ""}
            </p>
            {moreCaption && (
              <p
                className="pl-3 cursor-pointer text-sidenav new-line"
                onClick={() => setShowmMoreCaptionData(!showmMoreCaptionData)}
              >{`${showmMoreCaptionData ? "Show Less" : "Read More..."}`}</p>
            )}
          </div>
        )}
      </>
    );
  };

  return (
    <div>
      <div className="py-0 px-0 bg-white  events">
        <div className="DrHakima flex justify-between">
          <div className="image flex ml-3">
            <img
              className=" Avtar_socialpost lg:h-16 object-cover rounded-full border-2 border-indigo-50  md:h-16 lg:w-16 md:w-16"
              src={userImg ? `${BASE_URL_IMG}${userImg}?${new Date().getTime()}` : DummyDp}
              alt="drhakima"
            />
            <h2 className="">{title}</h2>
          </div>
          <div
            className="titlebtn mr-2"
            onClick={() => handleReportModal(_id, userId)}
          >
            <button className="btn">
              <img src={socialbtn} alt="btn" />
            </button>
            <div className="reportpost">
              <button className="btn">{userId !== localStorage.getItem('loginUserId')?"REPORT POST":"DELETE POST"}</button>
            </div>
          </div>
        </div>
        <div className="climate">
          {postPdfFile ? (
            <div
              className="w-full h-96 bg-pdfBg flex flex-col justify-center items-center"
              onClick={() => window.open(postPdfFile)}
            >
              <i
                className="fa fa-file-pdf-o text-red-600 pdfIcon"
                aria-hidden="true"
              ></i>
              <button className="pt-2 text-2xl text-paraText">
                Click To Download
              </button>
            </div>
          ) : (
            handleTextpost(PostImage)
          )}

          <div className="path flex">
            <div className="flex">
              <div className="image">
                <img src={path} alt="path" />
              </div>
              <div className="location">
                <p>{location}</p>
              </div>
            </div>
            <div className="postbutton flex items-center ">
              <div className="count-comment">
                <p>{comments}</p>
              </div>
              <div className="comment mx-2 mt-1">
                <button onClick={() => settoggleinterface(!toggleinterface)}>
                  {" "}
                  {toggleinterface ? (
                    <img src={interfac} alt="" />
                  ) : (
                    <img src={inetrfaceColor} alt="" />
                  )}
                </button>
              </div>
              <div className="count-heart">
                <p>{likes}</p>
              </div>
              <div className="heart">
                <button onClick={() => handleLikeDislike(_id)}>
                  {liked ? (
                    <img src={Blueheart} alt="" />
                  ) : (
                    <img src={heart} alt="" />
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
        {toggleinterface ? (
          <Artical heading={heading} pera={pera} postDate={postDate} />
        ) : (
          <Comments
            heading={heading}
            pera={pera}
            postDate={postDate}
            likesData={likesData}
            userImg={userImg}
            userId={userId}
            getCommentsAllData={getCommentsAllData}
            getDirectoryServiceApiData={getDirectoryServiceApiData}
            commentData={commentData}
            postId={_id}
            comments={comments}
          />
        )}
        <div className="AddComments">
          <Grid container>
            <Grid item xs={1}>
              <div className="Comment-Avtar flex items-center sm:mr-2">
                <img
                  src={userImg ? `${BASE_URL_IMG}${userImg}?${new Date().getTime()}` : profile}
                  alt=""
                  className="rounded-full"
                />
              </div>
            </Grid>
            <Grid item xs={11}>
              <Loader size={80} loader={loader} />
              <form>
                <div className="Comments">
                  <div className="Comment-search-box flex ">
                    <input
                      type="text"
                      className="search-input "
                      placeholder="Add a comment"
                      value={postData}
                      onChange={(e) => setPostData(e.target.value)}
                    />
                    <button
                      type="submit"
                      disabled={loader ? true : false}
                      className="search-button"
                      onClick={handlePostComment}
                    >
                      POST
                    </button>
                  </div>
                </div>
              </form>
            </Grid>
          </Grid>
        </div>
      </div>
      <DeleteReportForm
        getDirectoryServiceApiData={getDirectoryServiceApiData}
        postId={postId}
        userId={userId}
        reportable={userId !== localStorage.getItem("loginUserId")}
        showDeleteReport={showDeleteReport}
        handleClose={handleClose}
      />
    </div>
  );
};

export default Socialtext;
