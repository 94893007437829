import React, { useState } from 'react'
import searchicon from '../../Assets/searchicon.svg'
import searchbutton from '../../Assets/searchbutton.svg'
import Allhub from './Allhub';


const SearchSlider = ({ AllHubs }) => {
    const [searchValue, setSearchValue] = useState("");
    return (
        <div>
            <div className="bg-white shadow flex searchbarbox">
                <span className="w-auto flex justify-end items-center text-gray-500 p-2">
                    <i className="material-icons text-3xl"><img src={searchicon} alt="searchicon" /></i>
                </span>
                <input
                    className="w-full rounded p-2"
                    type="text"
                    placeholder="Search Hubs"
                    onChange={(e) => setSearchValue(e.target.value)}
                />
                <button className="bg-white-400 hover:bg-white-300 rounded text-black p-2 pl-4 pr-4">
                    <span className="w-auto flex justify-end items-center text-gray-500 p-2">
                        <i className="material-icons text-3xl"><img src={searchbutton} alt="searchicon" /></i>
                    </span>
                </button>
            </div>
            <Allhub AllHubs={AllHubs} searchValue ={searchValue} />

        </div>
    )
}

export default SearchSlider
