import { Box, Button, Modal } from '@mui/material';
import React, { useState } from 'react'
import Loader from "../Component/Loader"
import closeicon from '../Assets/closeicon.svg';


const style = {
    position: 'absolute',
    top: '50%',
    left: '55%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 8,
};


const ConfimationModal = ({ handleBlockUser, showBlockModal, closeBlockModal, isUserBlocked, isDeleteProfileModal }) => {
    const [loader, setLoader] = useState(false);
    return (
        <div>
            <Modal
                open={showBlockModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className="boxmodal">
                    <Button onClick={closeBlockModal} style={{ position: "absolute", top: 8, right: 0 }}  ><img src={closeicon} alt="closeicon" /></Button>

                    {
                        isDeleteProfileModal ? (
                            <div className='pb-6'>
                                <p className='text-xl text-paraText'>Are you sure, you want to delete your profile ? </p>
                            </div>
                        )
                            : (
                                <div className='pb-6'>
                                    <p className='text-xl text-paraText'>Are you sure, you want to {!isUserBlocked ? "block" : "unblock"} this user ? </p>
                                </div>
                            )
                    }

                    <div component="div" className="mt-8 flex justify-end items-center">
                        <div className="mr-8">
                            <Loader size={20} loader={loader} />
                            <button disabled={loader ? true : false} onClick={() => closeBlockModal()} className={`addbtn ${loader && "bgButtonLoader"}`}>Cancel</button>
                        </div>
                        <div>
                            <Loader size={20} loader={loader} />
                            {
                                isDeleteProfileModal ?
                                    <button disabled={loader ? true : false} className={`deletebtn`} onClick={() => handleBlockUser()}>Delete</button>
                                    :
                                    <button disabled={loader ? true : false} className={`deletebtn`} onClick={() => handleBlockUser()}>{!isUserBlocked ? "Block" : "Unblock"}</button>
                            }
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    )
}

export default ConfimationModal