import { Box, Button, Grid, Modal, TextField, Typography } from '@mui/material';
import React, { useState } from 'react'
import Loader from "../Component/Loader"
import closeicon from '../Assets/closeicon.svg';
import { deleteCommentApi, editCommentApi } from '../Utils/services';
import { toast } from 'react-toastify';
import { makeStyles } from '@material-ui/core/styles';



const style = {
    position: 'absolute',
    top: '50%',
    left: '55%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 8,
};

const useStyles = makeStyles((theme) => ({
    textField: {
        marginBottom: theme.spacing(2),
        width: '100%',
    },
}));


const EditCommentModal = ({
    showEditOpenModal,
    closeEditShowModal,
    editValue,
    handleEditChange,
    handleEditCommentSubmit
}) => {
    const [loader, setLoader] = useState(false);
    const classes = useStyles();

    return (
        <div>
            <Modal
                open={showEditOpenModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className="boxmodal">
                    <Button onClick={closeEditShowModal} style={{ position: "absolute", top: 8, right: 0 }}  ><img src={closeicon} alt="closeicon" /></Button>
                    <Grid container>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <TextField
                                name="comment"
                                label="Edit Comment"
                                className={classes.textField}
                                variant="outlined"
                                value={editValue}
                                onChange={handleEditChange}
                            />
                            {!editValue && <Typography className='text-red-500'> Type something ! </Typography>}
                        </Grid>
                    </Grid>
                    <div component="div" className="mt-8 flex justify-end items-center">
                        <div className="mr-8">
                            <Loader size={20} loader={loader} />
                            <button disabled={loader ? true : false} onClick={() => closeEditShowModal()} className={`addbtn ${loader && "bgButtonLoader"}`}>Cancel</button>
                        </div>
                        <div>
                            <Loader size={20} loader={loader} />
                            <button disabled={loader ? true : false} className={`addbtn`} onClick={handleEditCommentSubmit} >Edit</button>
                        </div>
                    </div>
                </Box>
                
            </Modal>
            
        </div>
    )
}

export default EditCommentModal