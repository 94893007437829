import React from "react";
import { Switch, Route } from "react-router-dom";
import Home from "../Pages/Home";
import Index from "../Pages/Mygroup/index";
import YourGroup from "../Pages/Mygroup/YourGroup";
import DescussionBord from "../Pages/Mygroup/DescussionBord";
import Linda from "../Pages/Linda/Linda";
import Directory from "../Pages/Directory/Directory";

import Protected from "./Protected";
import ModalmediaVideo from "../Modals/WritePostVideo";
import DirectoryCountry from "../Pages/Directory/DirectoryCountry";
import DirectoryParty from "../Pages/Directory/DirectoryParty";
import DirectoryExpertise from "../Pages/Directory/DirectoryExpertise";
import AreaExpertise from "../Pages/Directory/AreaExpertise";
import DemocraticAlliance from "../Pages/Directory/DemocraticAlliance";
import Countrymember from "../Pages/Directory/Countrymember";
import Partymeberportal from "../Pages/Directory/Partymeberportal";

import Profile from "../Pages/Profile/profile";
import ProfileEdit from "../Pages/Profile/ProfileEdit";

/* import Gallery from "../Pages/Mygroup/AllhubSlider"; */

import RedirectModel from "../Modals/RedirectPage";
import Modalmedia from "../Modals/WritePostModal-Media";
import DirectoryRegion from "../Pages/Directory/DirectoryRegion";
import { ExpertiseMemberProfile } from "../Pages/Directory/ExpertiseMemberProfile";
import Minhub from "../Pages/Mygroup/Minhub";
import PartyDetails from "../Pages/Profile/PartyDetails";
import { DirectoryProfilepPost } from "../Pages/Directory/DirectoryProfilePost";
import { DirectoryProdileDetail } from "../Pages/Directory/DirectoryProfileDetail";
import DirectoryOrganisation from "../Pages/Directory/DirectoryOrganisation";
import DirectoryThinkTank from "../Pages/Directory/DirectoryThinkTank";
import DirectoryOrganisationMember from "../Pages/Directory/DirectoryOrganisationMember";
import DirectoryMemberProfile from "../Pages/Directory/DirectoryMemberProfile";
import ResourcesPage from "../Pages/Mygroup/ResourcesPage";
import NotificationPage from "../Pages/Notification/NotificationPage";
import EventCalender from "../Component/Events";




const MainContent = () => {
  return (
    <div>
      <Switch>
        <Route path="/" exact>
          <Protected Cmp={Home} />
        </Route>
        <Route path="/notification" exact>
          <Protected Cmp={NotificationPage} />
        </Route>
        <Route path="/event" exact>
          <Protected Cmp={EventCalender} />
        </Route>
        <Route path="/slider" exact>
          <Protected Cmp={Modalmedia} />
        </Route>

        <Route path="/MyGroups" exact>
          <Protected Cmp={YourGroup} />
        </Route>
        <Route path="/Directory" exact>
          <Protected Cmp={Directory} />
        </Route>
        <Route path="/index" exact>
          <Protected Cmp={Index} />
        </Route>
        <Route path="/about" exact>
          <div className="content">
            <Protected Cmp={DescussionBord} />
          </div>
        </Route>
        <Route path="/about/minhub" exact>
          <div className="content">
            <Protected Cmp={Minhub} />
          </div>
        </Route>
        <Route path="/about/minihub/resources" exact>
          <div className="content">
            <Protected Cmp={ResourcesPage} />
          </div>
        </Route>
        <Route path="/Linda" exact>
          <Protected Cmp={Linda} />
        </Route>
        <Route path="/modal" exact>
          <Protected Cmp={ModalmediaVideo} />
        </Route>
        <Route path="/Directory/counrty" exact>
          <Protected Cmp={DirectoryCountry} />
        </Route>
        <Route path="/Directory/counrty/:id" exact>
          <Protected Cmp={Countrymember} />
        </Route>
        <Route path="/Directory/party" exact>
          <Protected Cmp={DirectoryParty} />
        </Route>
        <Route path="/Directory/party/:id" exact>
          <Protected Cmp={DemocraticAlliance} />
        </Route>
        <Route path="/Directory/expertise" exact>
          <Protected Cmp={DirectoryExpertise} />
        </Route>
        <Route path="/Directory/organization" exact>
          <Protected Cmp={DirectoryOrganisation} />
        </Route>
        <Route path="/Directory/organization/:id" exact>
        <Protected Cmp={DirectoryOrganisationMember} />
        </Route>
     
        <Route path="/Directory/thinktank" exact>
          <Protected Cmp={DirectoryThinkTank} />
        </Route>

        <Route path="/Directory/expertise/:id" exact>
          <Protected Cmp={AreaExpertise} />
        </Route>
       {/*  <Route path="/Directory/expertise/member/profile" exact>
          <Protected Cmp={DirectoryProfilepPost} />
        </Route> */}
        <Route path="/Directory/expertise/member/profile" exact>
          <Protected Cmp={DirectoryMemberProfile} />
        </Route>
       {/*  <Route path="/Directory/expertise/member/profile/detail" exact>
          <Protected Cmp={DirectoryProdileDetail} />
        </Route> */}
        
        <Route path="/Directory/region" exact>
          <Protected Cmp={DirectoryRegion} />
        </Route>
        <Route path="/meberportal" exact>
          <Protected Cmp={Partymeberportal} />
        </Route>
        <Route path="/profile" exact>
          <Protected Cmp={Profile} />
        </Route>
        <Route path="/partydetails" exact>
          <Protected Cmp={PartyDetails} />
        </Route>
        <Route path="/Profiles" exact>
          <Protected Cmp={Profile} />
        </Route>
        <Route path="/ProfileEdit" exact>
          <Protected Cmp={ProfileEdit} />
        </Route>
      </Switch>
    </div>
  );
};

export default MainContent;
