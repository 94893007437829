import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Grid from "@mui/material/Grid";

import memberAvtar from "../../Assets/countrymeberprofile.png";

import postsactive from "../../Assets/Posts - Inactive.svg";
import postsactives from "../../Assets/Posts - Active.svg";
import Details from "../../Assets/Details - Inactive.svg";
import Detailactive from "../../Assets/Details - Active.svg";
import ActivePost from "../Directory/ActivePost";
import Lilogocolor from "../../Assets/LI-Logo-Colour.png";

import logoutIcon from "../../Assets/Logout-Default.svg";
import { Link } from "react-router-dom";
import Edit from "../../Assets/Edit.svg";
import Cross from "../../Assets/cross.svg";

import TextField from "@mui/material/TextField";

import ProfileSocialmedia from "./profileSocialmedia";
import ProfileSocialSecand from "./profileSocialSecand";
import AccountDetailsEdits from "./AccountDetailsEdits";
import { useLocation } from "react-router-dom";

import {
  BASE_URL_IMG,
  getExpertise,
  partyList,
  userEditProfileService,
} from "../../Utils/services";
import { ProfilePost } from "./profilePost";
import { toast } from "react-toastify";
import instaImg from "../../Assets/Instagram.svg";
import linkdinImg from "../../Assets/Linkedin.svg";
import tiwtterImg from "../../Assets/Twitter.svg";
import facebookImg from "../../Assets/facebook.png";
import ActivePostEdit from "../Directory/ActivePostEdit";
import { Autocomplete } from "@mui/material";
import { Camera, CameraAltRounded } from "@material-ui/icons";

const ProfileEdit = () => {
  const history = useHistory();
  const location = useLocation();

  let { profileSendData, showEditPartyExpertise } = location.state;
  console.log(profileSendData, "profileSendData");
  const [isActive, setActive] = useState(false);
  const [isActives, setActives] = useState(true);
  const [isEdit, setEdit] = useState(false);
  const [FirstName, setFirstName] = useState(profileSendData.name);
  const [Country, setCountry] = useState(profileSendData.country);
  const [Desigination, setDesigination] = useState(profileSendData.designation);
  const [bioData, setBioData] = useState(profileSendData.bio);
  const [showPostPage, setShowPostPage] = useState(false);
  const [showDetailsPage, setShowDetailsPage] = useState(true);
  const [loader, setLoader] = useState(false);
  const [instagram, setInstagram] = useState(profileSendData.instagram);
  const [facebook, setFacebook] = useState(profileSendData.facebook);
  const [tiwtter, setTiwtter] = useState(profileSendData.twitter);
  const [linkedin, setLinkedin] = useState(profileSendData.linkedin);
  const [nameEdit, setNameEdit] = useState(false);
  const [DesiginationEdit, setDesiginationEdit] = useState(false);
  const [CountryEdit, setCountryEdit] = useState(false);
  const [editParty, setEditParty] = useState(false);
  const [partyListData, setPartyListData] = useState([]);
  const [party, setParty] = useState([]);
  const [expertiseList, setExpertiseList] = useState([]);
  const [expertise, setExpertise] = useState([]);
  const [editExpertise, setEditExpertise] = useState(false);
  const [profilepictureEdit, setProfilepictureEdit] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageProfile, setImageProfile] = useState(null);

  const loginUserId = localStorage.getItem('loginUserId');

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setImageProfile(selectedFile);
    setSelectedImage(URL.createObjectURL(selectedFile));
  };

  useEffect(() => {
    if (profilepictureEdit) {
      setSelectedImage(null);
      setImageProfile(null);
    }
  }, [profilepictureEdit]);

  console.log(profileSendData, "profileSendData");

  const handletoggle = () => {
    setEdit(!isEdit);
  };

  const handleActivePost = () => {
    setShowPostPage(true);
    setShowDetailsPage(false);
    setActive(true);
    setActives(false);
  };
  const handleActiveDetails = () => {
    setShowDetailsPage(true);
    setShowPostPage(false);
    setActive(false);
    setActives(true);
  };

  const getParty = async () => {
    try {
      const response = await partyList();
      const _partyList = response.res.data;
      setPartyListData(_partyList);
    } catch (error) {
      toast.warning("Something went wrong!");
    }
  };

  const getExpertiseData = async () => {
    try {
      const result2 = await getExpertise();
      const _ExpertiseList = result2.res;
      setExpertiseList(_ExpertiseList);
    } catch (error) {
      toast.warning("Something went wrong!");
    }
  };

  useEffect(() => {
    getParty();
    getExpertiseData();
  }, []);

  const onInputPartyChange = (event, newInputValue, name) => {
    const _name = event.target.name;
    const _value = event.target.value;

    if (name == "party") {
      setParty({
        ...party,
        [name]: newInputValue,
      });
    } else {
      setParty({
        ...party,
        [_name]: _value,
      });
    }
  };

  useEffect(() => {
    setExpertise([...profileSendData.expertise]);
  }, []);

  console.log(expertise);
  // console.log({expertise:profileSendData.expertise})

  const onInputExpertiseChange = (event, newInputValue, name) => {
    // const _name = event.target.name;
    // const _value = event.target.value;

    // if (name === "expertise") {
    //   setExpertise({
    //     ...expertise,
    //     [name]: newInputValue,
    //   });
    // } else {
    //   setExpertise({
    //     ...expertise,
    //     [_name]: _value,
    //   });
    // }

    setExpertise(newInputValue);

    console.log(newInputValue);
  };

  const handlePartyEdit = () => {
    setEditParty(!editParty);
  };

  const handleExpertiseEdit = () => {
    setEditExpertise(!editExpertise);
  };

  const handleSumbit = async (e) => {
    e.preventDefault();
    // let expertiseData = expertise.expertise && expertise.expertise.map((data) => {
    //   return data.area;
    // })
    setLoader(true);
    const formData = new FormData();
    formData.append("name", FirstName);
    formData.append("designation", Desigination);
    formData.append("country", Country);
    formData.append("bio", bioData);
    formData.append("instagram", instagram);
    formData.append("twitter", tiwtter);
    formData.append("linkedin", linkedin);
    formData.append("facebook", facebook);
    if(imageProfile){
      formData.append("media", imageProfile);
    }
    
    if (party.party) {
      formData.append("party", party.party && party.party.name);
    }
    if (party.party) {
      formData.append("partyId", party.party && party.party._id);
    }
    if (expertise) {
      const arrayOfExperties = expertise.map((item) => item.area);
      formData.append("expertise", arrayOfExperties);
      console.log("this is running");
    }

    console.log(expertise, " this is experties");

    try {
      console.log(formData);
      const result = await userEditProfileService(formData);
      console.log(result);

      if (result.res.success) {
        toast.success(result.res.message);
        history.push("/Profile");
      }
    } catch (e) {
      setLoader(false);
      console.log(e);
      toast.error(e.message);
    }
  };

 

  return (
    <div>
      <form onSubmit={handleSumbit}>
        <div className="memberProfileborder flex justify-end items-center">
          <div className="memberlogut mr-20 flex items-center">
            <div className="logouticon">
              <img src={logoutIcon} alt="" />
            </div>
            <div className="meber_logoutDetail ">
              <h3>Logout</h3>
            </div>
          </div>
        </div>
        <div className="membercontent px-20">
          <Grid container>
            <Grid item md={6} sm={12}>
              <div className="flex">
                <div className="memberAvtar">
                  {!profilepictureEdit && (
                    <img
                      src={`${BASE_URL_IMG}/user/${loginUserId}/${loginUserId}.jpeg?${new Date().getTime()}`}
                      alt=""
                    />
                  )}
                  {profilepictureEdit && (
                    <>
                      <div className="w-32 h-32 rounded-full bg-gray-300 flex items-center justify-center">
                        <div className="flex justify-center flex-col items-center">
                          {!selectedImage && (
                            <CameraAltRounded
                              fontSize="large"
                              className="text-gray-500"
                            />
                          )}
                          {selectedImage && (
                            <img src={selectedImage} alt="Selected" />
                          )}
                        </div>
                      </div>
                      <input
                        className="ml-2 mt-2 cursor-pointer"
                        type="file"
                        onChange={handleFileChange}
                        accept=".jpg, .jpeg, .jpg"
                      />
                      
                    </>
                  )}
                </div>
                <div
                  className="ml-2 cursor-pointer"
                  onClick={() => setProfilepictureEdit(!profilepictureEdit)}
                >
                  {profilepictureEdit ? (
                    <img src={Cross} alt="" />
                  ) : (
                    <img src={Edit} alt="" className="" />
                  )}
                </div>
              </div>

              <div className="membername ">
                <div className="flex items-center">
                  <div className="ProfileInput">
                    <TextField
                      variant="standard"
                      name="FirstName"
                      value={FirstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      disabled={!nameEdit}
                    />
                  </div>
                  <div
                    className="ml-2 cursor-pointer"
                    onClick={() => setNameEdit(!nameEdit)}
                  >
                    {nameEdit ? (
                      <img src={Cross} alt="" />
                    ) : (
                      <img src={Edit} alt="" className="" />
                    )}
                  </div>
                </div>
                {/*  <hr className="meberhorizontaline" /> */}
              </div>
              <div className="memberposition">
                <div className="flex items-center">
                  <div className=" pt-4">
                    <TextField
                      variant="standard"
                      name="Desigination"
                      value={Desigination}
                      onChange={(e) => setDesigination(e.target.value)}
                      disabled={!DesiginationEdit}
                    />
                  </div>
                  <div
                    className="ml-2 cursor-pointer"
                    onClick={() => setDesiginationEdit(!DesiginationEdit)}
                  >
                    {DesiginationEdit ? (
                      <img src={Cross} alt="" />
                    ) : (
                      <img src={Edit} alt="" />
                    )}
                  </div>
                </div>
                <div className="memberLoction mt-4">
                  <div className="flex items-center">
                    <div className="">
                      <TextField
                        variant="standard"
                        name="Country"
                        value={Country}
                        onChange={(e) => setCountry(e.target.value)}
                        disabled={!CountryEdit}
                      />
                    </div>
                    <div
                      className="ml-2 cursor-pointer"
                      onClick={() => setCountryEdit(!CountryEdit)}
                    >
                      {CountryEdit ? (
                        <img src={Cross} alt="" />
                      ) : (
                        <img src={Edit} alt="" />
                      )}
                    </div>
                  </div>
                </div>
                <div className="memeberSocialmedia mt-10  mb-8 flex">
                  <div
                    className={`memberdetail flex items-center w-1/2 h-18 justify-center py-2 ${
                      isActives && "Detailsactive"
                    } `}
                    onClick={handleActiveDetails}
                  >
                    <div className="postavtar w-6">
                      <img src={isActives ? Detailactive : Details} alt="" />
                    </div>
                    <div className="posts ml-4">
                      <p>Details</p>
                    </div>
                  </div>
                  <div
                    className={`memberpost flex items-center w-1/2 h-18 justify-center py-2 ${
                      isActive && "Postactive"
                    }`}
                    onClick={handleActivePost}
                  >
                    <div className="postavtar w-6">
                      <img
                        src={isActives ? postsactive : postsactives}
                        alt=""
                      />
                    </div>
                    <div className="posts ml-4">
                      <p>Posts</p>
                    </div>
                  </div>
                </div>
                <div className="mt-4">
                  {showDetailsPage && (
                    <>
                      {!showEditPartyExpertise ? (
                        <ActivePost
                          img={Lilogocolor}
                          heading={profileSendData.party}
                          badgeicon={profileSendData.partyImage}
                          ExpertiseData={profileSendData.expertise}
                          badgeData={profileSendData.badge}
                          ProfileDetailsData={profileSendData}
                          isInternational={profileSendData.isInternational}
                        />
                      ) : (
                        <div className="mb-5">
                          <div className="Partymemberpost flex items-center justify-between my-2">
                            <h3>Party</h3>
                            <div
                              className="cursor-pointer"
                              onClick={handlePartyEdit}
                            >
                              {editParty ? (
                                <img src={Cross} alt="" />
                              ) : (
                                <img src={Edit} alt="" />
                              )}
                            </div>
                          </div>
                          {editParty ? (
                            <Autocomplete
                              name="party"
                              options={partyListData}
                              getOptionLabel={(option) => option.name}
                              value={party.name}
                              onChange={(e, newVal) =>
                                onInputPartyChange(e, newVal, "party")
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  id="party"
                                  label="Name of the Party"
                                />
                              )}
                            />
                          ) : (
                            <div className="Partybanner flex items-center  my-4">
                              <div className="partyLogo flex items-center ml-5">
                              {console.log(profileSendData , "+===+++=+")}
                                <img
                                  src={`${BASE_URL_IMG}${profileSendData.partyImage}`}
                                  alt=""
                                />
                              </div>
                              <div className="partyname">
                                <h3>{profileSendData.party}</h3>
                              </div>
                            </div>
                          )}
                          <div className="Partymemberpost  mt-12">
                            <h3>Badge</h3>
                          </div>
                          {profileSendData.badge &&
                            profileSendData.badge.map((item) => {
                              return (
                                <div className="Badge flex items-center my-4 ">
                                  <div className="partyLogo flex items-center pl-5">
                                    <img
                                      src={`${BASE_URL_IMG}${item.logo}`}
                                      alt=""
                                    />
                                  </div>
                                  <div className="partyname">
                                    <h3>{item.name}</h3>
                                  </div>
                                </div>
                              );
                            })}

                          <div className="Partymemberpost flex items-center justify-between mt-12">
                            <h3 className="mb-4">Expertise</h3>
                            <div
                              className="cursor-pointer"
                              onClick={handleExpertiseEdit}
                            >
                              {editExpertise ? (
                                <img src={Cross} alt="" />
                              ) : (
                                <img src={Edit} alt="" />
                              )}
                            </div>
                          </div>

                          {editExpertise ? (
                            <div className=" mt-4">
                              <Autocomplete
                                multiple
                                name="expertise"
                                limitTags={2}
                                options={expertiseList}
                                getOptionLabel={(option) => option.area}
                                value={expertise}
                                onChange={(e, newVal) =>
                                  onInputExpertiseChange(e, newVal, "expertise")
                                }
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    id="expertise"
                                    label="Add Expertise"
                                  />
                                )}
                              />
                            </div>
                          ) : (
                            <div className="expertise_area flex pb-12 ">
                              {profileSendData.expertise &&
                                profileSendData.expertise.map((item) => {
                                  return (
                                    <div className="gvernace bg-white p-2 justify-center items-center mr-2  ">
                                      <p className="text-paraText">
                                        {item.area}
                                      </p>
                                    </div>
                                  );
                                })}
                            </div>
                          )}
                        </div>
                      )}
                    </>
                  )}
                  {showPostPage && <ProfilePost />}
                </div>
              </div>
            </Grid>
            <Grid item md={1} sm={12}></Grid>
            <Grid item md={5} sm={12}>
              <div className="BioCard lg:mt-32 md:mt-4 sm:mt-4">
                <div className="Bioboder flex  items-center">
                  <div className="Bioboder flex  items-center">
                    <div className="profilesave flex  items-center">
                      <div className="Bioboder flex  items-center">
                        <button
                          className="BioEditer ml-6  cursor-pointer"
                          type="submit"
                        >
                          <p>SAVE</p>
                        </button>
                      </div>

                      <div className="ProfileConcel ml-8">
                        <Link to="/Profile">CANCEL</Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bio ml-6 mt-12 mb-5">
                  <div className="flex items-center ">
                    <div className="">
                      <h3>Bio</h3>
                    </div>
                    <div
                      className=" w-5 cursor-pointer ml-1"
                      onClick={handletoggle}
                    >
                      {isEdit ? (
                        <img src={Cross} alt="" />
                      ) : (
                        <img src={Edit} alt="" />
                      )}
                    </div>
                  </div>
                </div>
                <div className="mx-6 BioDetails">
                  <TextField
                    id="standard-multiline-flexible"
                    multiline
                    name="bioData"
                    value={bioData}
                    onChange={(e) => setBioData(e.target.value)}
                    variant="standard"
                    style={{ width: "100%" }}
                    /*  className="BioDataText" */
                    disabled={!isEdit}
                  />
                </div>
                <div>
                  {isEdit ? (
                    <>
                      {/*  <div className="profileSocial_border mr-6 ml-6 "></div> */}
                      <div className="pb-10">
                        <div className="ml-6 pt-8">
                          <div className="grid grid-cols-12 mb-4">
                            <div className="col-span-1">
                              <div className="flex items-center">
                                <img src={facebookImg} alt="" className="h-5" />
                              </div>
                            </div>
                            <div className="col-span-4">
                              <div className="flex items-center">
                                <input
                                  type="text"
                                  name="instagram"
                                  value={facebook}
                                  onChange={(e) => setFacebook(e.target.value)}
                                  className="outline-none border-b border-muteText"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="grid grid-cols-12 mb-4">
                            <div className="col-span-1">
                              <div className="flex items-center">
                                <img src={instaImg} alt="" className="h-5" />
                              </div>
                            </div>
                            <div className="col-span-4">
                              <div className="flex items-center">
                                <input
                                  type="text"
                                  name="instagram"
                                  value={instagram}
                                  onChange={(e) => setInstagram(e.target.value)}
                                  className="outline-none border-b border-muteText"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="grid grid-cols-12 my-4">
                            <div className="col-span-1">
                              <div className="flex items-center">
                                <img src={tiwtterImg} alt="" className="h-5" />
                              </div>
                            </div>
                            <div className="col-span-4">
                              <div className="flex items-center">
                                <input
                                  type="text"
                                  name="tiwtter"
                                  value={tiwtter}
                                  onChange={(e) => setTiwtter(e.target.value)}
                                  className="outline-none border-b border-muteText"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="grid grid-cols-12 my-4">
                            <div className="col-span-1">
                              <div className="flex items-center">
                                <img src={linkdinImg} alt="" className="h-5" />
                              </div>
                            </div>
                            <div className="col-span-4">
                              <div className="flex items-center">
                                <input
                                  type="text"
                                  name="linkedin"
                                  value={linkedin}
                                  onChange={(e) => setLinkedin(e.target.value)}
                                  className="outline-none border-b border-muteText"
                                />
                              </div>
                            </div>
                          </div>
                          <hr className="mr-6 mt-3" />
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="socialmedia ml-6 mt-4 mb-12">
                      <ul className="flex items-center">
                        <li>
                          <img src={tiwtterImg} alt="" />
                        </li>
                        <li className="ml-4">
                          <img src={instaImg} alt="" />
                        </li>
                        <li className="ml-4">
                          <img src={linkdinImg} alt="" />
                        </li>
                        <li className="ml-4">
                          <img src={facebookImg} alt="" />
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
                <div className="AccountDetails">
                  <AccountDetailsEdits emailData={profileSendData.email} />
                </div>
                <div className="BioCardfooter ">
                  <p>
                    Contact: <span> jason@liberal-international.org </span>
                  </p>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </form>
    </div>
  );
};

export default ProfileEdit;
