import React, { useEffect, useState } from 'react'

import ReactPlayer from 'react-player'
import profile from '../Assets/profile.png'
import isabella from '../Assets/IsabellePucher.png';

import { Grid } from '@mui/material';
import path from "../Assets/Path.svg";
import socialbtn from "../Assets/socialpost-btn.svg";

import interfac from "../Assets/interface.svg";
import heart from "../Assets/heart.svg";
import inetrfaceColor from "../Assets/interfaceColor.svg";
import Blueheart from "../Assets/BlueHeart.svg";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from "../Component/Loader"
import { addComment, BASE_URL_IMG, getComments, LikeDislikePostApi } from '../Utils/services';
import Comments from '../Pages/Home/Comments';
import Artical from '../Pages/Home/Artical';
import DeleteReportForm from '../Modals/DeleteReportForm';


const Socialvideo = ({
  postDate,
  name,
  heading,
  para,
  userImg,
  postVideo,
  location,
  _id,
  comments,
  likes,
  likesData,
  userId,
  userDetails,
  getDirectoryServiceApiData
}) => {

  const [toggleinterface, settoggleinterface] = useState(false);
  const [postData, setPostData] = useState('');
  const [commentData, setCommentData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [liked, setLiked] = useState(false);
  const [postId, setPostId] = useState(null);
  const [showDeleteReport, setShowDeleteReport] = useState(false)
  const [userReportId, setUserReportId] = useState(null);

  const getCommentsAllData = async (id) => {
    const result = await getComments(id);
    if (result.res.length > 0) {
      setCommentData(result.res)
    }
  }

  const handlePostComment = async (e) => {
    setLoader(true)
    e.preventDefault();
    try {
      const result = await addComment({
        "comment": postData,
        "postId": _id
      })
      setLoader(false)
      if (result.res.success) {
        toast.success(result.res.message)
        getCommentsAllData(_id);
      }
    } catch (error) {
      setLoader(false)
      console.log(error)
      toast.warning(error.message)
    }
    setPostData("")
  }

  useEffect(() => {
    userDetails && userDetails.filter((id) => {
      if (id === _id) {
        setLiked(true)
      }
    })
  }, [userDetails])


  const handleLikeDislike = async () => {
    try {
      const result = await LikeDislikePostApi({
        "postId": _id,
        "userId": userId
      })
      if (result.res.success) {
        console.log(result.res.message)
        setLiked(!liked);
        getDirectoryServiceApiData();
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleReportModal = (_id, userId) => {
    setPostId(_id);
    setUserReportId(userId)
    setShowDeleteReport(true)
  }
  const handleClose = () => {
    setShowDeleteReport(false)
  };

  return (
    <div className="event">
      <div className="py-0 px-0 bg-white  my-20">
        <div className=" Isabella flex justify-between">
          <div className="image flex">
            <img
              className="Avtar_socialpost h-20 object-cover rounded-full border-2 border-indigo-50w-20 0 md:h-16 lg:w-16 md:w-16"
              src ={userImg ? `${BASE_URL_IMG}${userImg}?${new Date().getTime()}` : isabella}
              alt='Astrid'
            />
            <h2>{name}</h2>
          </div>
          <div className="titlebtn videobtnset" onClick={() => handleReportModal(_id, userId)}>
            <button className="btn">
              <img src={socialbtn} alt="btn" />
            </button>
            <div className="reportpost">
              <button className="btn" >{userId !== localStorage.getItem('loginUserId')?"REPORT POST":"DELETE POST"}</button>
            </div>
          </div>
        </div>
        <div className="emptyimage" >
          <div className="react-media">
            <ReactPlayer
              url={`${BASE_URL_IMG}${postVideo}`}
              controls={true}
              width="100%"
              loop={true}
            />
          </div>
          <div className="video-loction">
            <div className="flex items-center pb-4">
              <div className="image">
                <img src={path} alt="path" />
              </div>
              <div className="loc">
                <p>{location}</p>
              </div>
            </div>
            <div className="postbutton flex items-center ">
              <div className="count-comment">
                <p>{comments}</p>
              </div>
              <div className="comment mx-2">
                <button onClick={() => settoggleinterface(!toggleinterface)}>
                  {" "}
                  {toggleinterface ? (
                    <img src={interfac} alt="" />
                  ) : (
                    <img src={inetrfaceColor} alt="" />
                  )}
                </button>
              </div>
              <div className="count-heart">
                <p>{likes}</p>
              </div>
              <div className="heart">
                <button onClick={() => handleLikeDislike(_id)}>
                  {
                    liked ? <img src={Blueheart} alt="" /> : <img src={heart} alt="" />
                  }
                </button>
              </div>
            </div>
          </div>
        </div>
        {toggleinterface ? (
          <Artical heading={heading} pera={para} postDate={postDate} />
        ) : (
          <Comments postDate={postDate} likesData={likesData} heading={heading} pera={para} getCommentsAllData={getCommentsAllData} getDirectoryServiceApiData={getDirectoryServiceApiData} commentData={commentData} postId={_id} comments={comments} />
        )}
        <div className="AddComments">
          <Grid container>
            <Grid item xs={1}>
              <div className="Comment-Avtar sm:mr-2">
                <img src={userImg ? `${BASE_URL_IMG}${userImg}?${new Date().getTime()}` : profile} alt="" className="rounded-full"  />
              </div>
            </Grid>
            <Grid item xs={11}>
              <div className="Comments">
                <div className="Comment-search-box flex">
                  <input
                    type="text"
                    className="search-input"
                    placeholder="Add a comment..."
                    value={postData}
                    onChange={(e) => setPostData(e.target.value)}
                  />
                  <button
                    type="submit"
                    disabled={loader ? true : false}
                    onClick={handlePostComment}
                    className="search-button"
                  >
                    POST
                  </button>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <DeleteReportForm getDirectoryServiceApiData={getDirectoryServiceApiData} postId={postId} userId={userId} reportable={userId !== localStorage.getItem('loginUserId')} showDeleteReport={showDeleteReport} handleClose={handleClose} />
    </div>
  )
}

export default Socialvideo;
