import React, { useState } from 'react'
import Grid from "@mui/material/Grid";

import SearchBox from "../../Component/SearchBox";
import { Link, NavLink } from "react-router-dom";



const Directoryheader = () => {
  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <header className="Directory">
            <h2>Directory</h2>
          </header>
        </Grid>
        <Grid item xs={12}>
          <div className="DirectorySearch mt-8">
            {/* <SearchBox /> */}
          </div>
          <div className="Directory-sort mt-4">
            <Grid container>
              <Grid item xs={0} sm={1} >
                <div className="sort mt-2 ">
                  <h3>Search By</h3>
                </div>
              </Grid>
              <Grid item xs={10}>
                <div className="Directory-sort-button">
                  <ul>
                    <li>
                      <NavLink exact to="/Directory" activeClassName="ActiveRegion" >Region</NavLink>
                    </li>
                    <li>

                      <NavLink exact to="/Directory/counrty" activeClassName="ActiveRegion" >Country </NavLink>
                    </li>
                    <li>
                      <NavLink exact to="/Directory/Party" activeClassName="ActiveRegion">Party</NavLink>
                    </li>
                    <li>
                      <NavLink exact to="/Directory/organization" activeClassName="ActiveRegion" >Organization</NavLink>
                    </li>
                    <li>
                      <NavLink exact to="/Directory/thinktank" activeClassName="ActiveRegion" >Thinktank</NavLink>
                    </li>
                    <li>
                      <NavLink exact to="/Directory/expertise" activeClassName="ActiveRegion" >Area of Expertise</NavLink>
                    </li>

                  </ul>
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>

    </div>
  )
}

export default Directoryheader
