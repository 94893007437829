import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import closeicon from "../Assets/closeicon.svg";
import Compose from "../Assets/Compose.svg";
import CreatPoll from "../Assets/graphicon.svg";
import Profile from "../Assets/profile.png";
import Places from "../Assets/Places.svg";
import searchButton from "../Assets/searchbutton.svg";

import mediacross from "../Assets/media-cross.svg";
import climate from "../Assets/climate.png";

import Grid from "@mui/material/Grid";

const style = {
  position: "absolute",
  top: "50%",
  left: "55%",
  transform: "translate(-50%, -50%)",
  width: 1000,

  bgcolor: "background.paper",

  boxShadow: 24,
  p: 8,
};

export default function ModalmediaVideo({ handlevalue }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button onClick={handleOpen}>Open modal</Button>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="boxmodal">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="eventModel"
          >
            WRITE POST
          </Typography>
          <Typography
            variant="subtitle2"
            gutterBottom
            component="div"
            className="devicer"
          ></Typography>

          <Grid container spacing={8}>
            <Grid item xs={6}>
              <div className="CreatePolButtons">
                <div className="CreatePolButton">
                  <button className="buttonmodal">
                    <img src={Compose} alt="Compose" />
                    Write Post
                  </button>
                </div>
                <div className="CreatePolButton ">
                  <button className="buttonmodal createpol">
                    <img src={CreatPoll} alt="createpol" /> Create Poll
                  </button>
                </div>
              </div>
              <div className="Createpolpost">
                <div component="div" className="profilepost mb-2">
                  <div component="div" className="image">
                    <img src={Profile} alt="profile" />
                  </div>
                  <div gutterBottom component="div" className="post">
                    <form action="">
                      <input type="text" placeholder="Post Title..." />
                    </form>
                  </div>
                </div>
                <div component="div" className="profilepost">
                  <form action="">
                    <textarea
                      name=""
                      id=""
                      cols="35"
                      rows="7"
                      placeholder="Share your views..."
                    ></textarea>
                  </form>
                </div>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="Address">
                <div className="AddLoction mt-16 mb-2">
                  <h3>Add Location</h3>
                </div>
                <div className="loction">
                  <div className="image">
                    <img src={Places} alt="profile" />
                  </div>
                  <div component="div" className="post mb-2">
                    <form action="">
                      <input type="text" placeholder="Search Location" />
                    </form>
                  </div>
                  <div component="div" className="image mb-2">
                    <img src={searchButton} alt="searchbutton" />
                  </div>
                </div>
                <div className="Addmedia mb-2">
                  <h3>Add Media</h3>
                </div>
                <Grid container>
                  <Grid item xs={10}>
                    <button className=" flex items-center mediapdfvideo-btn justify-center flex-col">
                      <img src={climate} alt="" />
                      <button className="crossbtn">
                        <img src={mediacross} alt="" />
                      </button>
                    </button>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
          <div component="div" className="addbutton flex justify-center pt-8">
            <button className="addbtn">ADD POST</button>
          </div>
          <Button
            onClick={handleClose}
            style={{ position: "absolute", top: 8, right: 0 }}
          >
            <img src={closeicon} alt="closeicon" />
          </Button>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}></Typography>
        </Box>
      </Modal>
    </div>
  );
}
