import React, { useState, useEffect } from "react";

import tiwtter from "../../Assets/Twitter.svg";
import insta from "../../Assets/Instagram.svg";
import linkdin from "../../Assets/Linkedin.svg";
import facebookImg from "../../Assets/facebook.png"

import { Grid } from "@mui/material";
import jhon from "../../Assets/john-steenhuisen.png";

import cross from "../../Assets/cross.svg";
import DemoCratic from "../../Assets/Democratic-Alliance.png";
import DummyDp from "../../Assets/DummyDp.png"

import { useHistory, useLocation } from "react-router";
import { Link } from "react-router-dom";
import { BASE_URL_IMG, DirectoryService } from "../../Utils/services";
import AddNewMembersModal from "./AddNewMembersModal";


const PartyDetails = () => {
  const [partyMember, setpartyMember] = useState();
  const [partyName, setpartyName] = useState();
  const [partyDiscription, setpartyDiscription] = useState();
  const [interNational, setIneterNational] = useState([]);
  const [partywebsite, setpartywebsite] = useState();
  const [userdata, setuserdata] = useState();
  const [party, setparty] = useState();
  const [partyImages, setpartyImage] = useState();
  const [userName, setuserName] = useState();
  const [Country, setCountry] = useState();
  const [User, setUser] = useState();
  const [partydetail, setPartydetail] = useState();
  const [InterNationaLperson, setInterNationalperson] = useState()
  const [ProfileData, setProfileData] = useState({});
  const [isAddMemberModalShow, setIsAddMemberModalShow] = useState(false);
  const history = useHistory();

  const handleModalClose = () => {
    setIsAddMemberModalShow(false);
  };
  const handleAddMember = () => {
    setIsAddMemberModalShow(true);
  }

  const loction = useLocation()
  const { partyname, users, partyImage, username, country, ProfileDetailsData, isInternational } = loction.state;

  const getDirectoryServiceApiData = async () => {

    try {
      const result = await DirectoryService();
      const data = result.res.users.filter((item) => item.party === partyname)
      const party = result.res.party.filter((item) => item.name === partyname)
      setPartydetail(party[0].description)
      setProfileData(party[0])
      setpartywebsite(party[0].website)
      setUser(data)
      setIneterNational(party[0].internationalOfficer[0])
      const internation = result.res.users.filter((item) => item._id == interNational)
      setInterNationalperson(internation[0].name)
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDirectoryServiceApiData();
  }, []);


  useEffect(() => {
    const { partyname, users, partyImage, username, country } = loction.state;
    setuserdata(users)
    setpartyName(partyname)
    setpartyImage(partyImage)
    setuserName(username)
    setCountry(country)
    console.log(users, "data")
  }, []);

  const handleBackState = () => {
    history.goBack();
  }

  const handleTwitter = (link) => {
    window.open(`https://twitter.com/${link}`);
  };
  const handleInstagram = (link) => {
    window.open(`https://www.instagram.com//${link}`);
  };
  const handleFacebook = (link) => {
    window.open(`http://facebook.com/${link}`);
  };
  const handleLinkedIn = (link) => {
    window.open(`https://www.linkedin.com//${link}`);
  };

  return (
    <>
      <div className="content">
        <span className="text-sidenav text-xl cursor-pointer" onClick={handleBackState}>Back</span>
        <div className="mt-8 ">
          <Grid container>
            <Grid item sm={7}>
              <div className="partydetail mb-6">
                <h2>Party Details</h2>
              </div>
              <div className="flex membertitle mb-4">
                <h3>Members</h3>
                <div className="member mt-4">
                  <hr />
                </div>

                <p>{User && User.length}member</p>
              </div>
              {
                isInternational &&
                <button className="addnew mb-8" onClick={handleAddMember}> + ADD NEW</button>
              }
              <Grid container spacing={2}>
                {User &&
                  User.map((items, i) => {
                    return (
                      <Grid item lg={4} md={6} sm={6} xs={12} key={i}>
                        <div className="CommunityProfile">
                          <div className="card">
                            <div
                              className={`profileBackground   ${items.BgColor} dark-blue`}
                              style={{
                                backgroundColor: `${items.region === "Africa" ? "#469025" : ""
                                  || items.region === "Asia" ? "#f3007b" : ""
                                    || items.region === "Europe" ? "f3007b" : ""
                                      || items.region === "Latin America" ? "#9900CC" : ""
                                        || items.region === "Middle East & North Africa" ? "#FFDF1B" : ""
                                          || items.region === "North America" ? "#CC3300" : ""
                                            || items.region === "Worldwide" ? "#184183" : ""
                                              || items.region === "Oceania" ? "#00008B" : ""}`
                              }}
                            ></div>
                            <div className="text">
                              <img
                                src={items.image ? `${BASE_URL_IMG}${items.image}` : DummyDp}
                                alt="avtar"
                                className="rounded-full w-16 h-16 object-cover"
                              />
                              <h3>{items.name}</h3>
                              <p>{`${items.party && items.party.slice(0, 40)}${items.party && items.party.length > 40 ? '...' : ''}`}</p>
                              <p>{items.country}</p>
                              {/* <Link to="/meberportal">View Profile </Link>  */}
                              <p
                                className="DirectoryLink cursor-pointer"
                              /*  onClick={() => handleClick(items.name)} */
                              >
                                View Profile
                              </p>
                            </div>
                          </div>
                        </div>
                      </Grid>
                    );
                  })}
              </Grid>
            </Grid>
            <Grid item sm={1}></Grid>
            <Grid item sm={4}>
              <div className="directoryWorldWide pb-12">
                <div className="Democraticborder"></div>

                <div className="Democracticprofile">
                  <div className="DemoCracticAvtar ml-6">
                    <img src={partyImages ? `${BASE_URL_IMG}${partyImages}` : DummyDp} alt="" className="object-cover" />
                  </div>
                  <div className="ml-6 DemoCracticparty mt-4 mb-4 ">
                    <h3 className="pl-2 ">{partyName || "Democratic Alliance"}</h3>
                  </div>
                  <div className="limember ml-6">
                    <p className="pl-2 pr-2">LI Member</p>
                  </div>
                  <div className="ml-6 mr-4 linepera ">
                    <p className="pl-2 pr-2">
                      {partydetail && partydetail}
                    </p>
                  </div>
                  <div className="democraticlink ml-6 mt-4 pl-2 pr-2">
                    <a href="abc">{partywebsite || "https://www.da.org.za"} /</a>
                  </div>
                  <div className="socialmedia ml-6 mt-4 mb-12">
                    <ul className="flex items-center">
                      <li onClick={() => handleTwitter(ProfileData.twitter)} className="cursor-pointer">
                        <img src={tiwtter} alt="" />
                      </li>
                      <li className="ml-4 cursor-pointer" onClick={() => handleInstagram(ProfileData.instagram)}>
                        <img src={insta} alt="" />
                      </li>
                      <li className="ml-4 cursor-pointer" onClick={() => handleFacebook(ProfileData.facebook)}>
                        <img src={facebookImg} alt="" />
                      </li>
                      <li className="ml-4 cursor-pointer" onClick={() => handleLinkedIn(ProfileData.linkedin)}>
                        <img src={linkdin} alt="" />
                      </li>
                    </ul>
                  </div>
                  {
                    isInternational && (
                      <div className="pl-2 pr-2 mt-4 mb-4">
                        <h3 className="int-officer mt-8 mb-4 ml-4 ">
                          International Officer
                        </h3>
                        <div className="container">
                          <div className="grid grid-cols-2">
                            <div className="card">
                              <div
                                className={`profileBackground  dark-blue`}
                              ></div>
                              <div className="text">
                                <img
                                  src={ProfileDetailsData &&  ProfileDetailsData.image ? `${BASE_URL_IMG}${ProfileDetailsData.image}` : DummyDp}
                                  alt="avtar"
                                  className="rounded-full w-16 h-16 object-cover"
                                />
                                <h3>{userName}</h3>
                                <p> {partyName} </p>
                                <p>{Country} </p>
                                <Link to="/profile" className="DirectoryLink ">
                                  View Profile
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  }

                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        <AddNewMembersModal
          handleShow={isAddMemberModalShow}
          handleModalClose={handleModalClose}
          getDirectoryServiceApiData={getDirectoryServiceApiData}
          partyname={partyname}
        />
      </div>
    </>
  );
};

export default PartyDetails;
