import React, { useContext, useEffect, useState } from "react";
import logo from "../Assets/Logo.svg";
import { NavLink } from "react-router-dom";

import homeicon from "../Assets/Homeicon.svg";
import homeicontwo from "../Assets/homeIcontwo.svg";
import bellicon from "../Assets/bellicon.svg";
import bellicontwo from "../Assets/bellicontwo.svg";

import diricon from "../Assets/dir.svg";
import diricontwo from "../Assets/dirtwo.svg";








import manicon from "../Assets/manicon.svg";
import manicontwo from "../Assets/manicontwo.svg";




import profile from "../Assets/profile.png";
import AuthenContext from "../Utils/AuthContext";
import Eyeicon from "../Assets/Eye-Default.svg";
import LogoutICon from "../Assets/Logout-Default.svg";
import { Notifaction } from "../Layout/Notifaction";
import navlogo from "../Assets/Navlogo.png"
import navfooterimg from "../Assets/navinnerfooter.png"
import { BASE_URL_IMG, getNotifications } from "../Utils/services";
const Navbar = () => {
  const [home,setHome]=useState(true)
  const [directory,setdirectory]=useState(true)
  const [bellicons,setbellicon]=useState(false)
  const [hubs,setHubs]=useState(true)

  const [notificationData, setNotificationData] = useState([]);
 


  const getNotificationApiData = async () => {
    try {
      const result = await getNotifications();
      console.log(result.res)
      setNotificationData(result.res)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getNotificationApiData();
  }, []);


  const authCtx = useContext(AuthenContext);
  const { logout } = authCtx;
  
  const loginUserId=localStorage.getItem('loginUserId')


 const rederImg=()=>{
    return(
      <>
      <img src={`${BASE_URL_IMG}/user/${loginUserId}/${loginUserId}.jpeg?${new Date().getTime()}`} alt=""  className="naveprofile" />
      </>
    )
  }

 const handleHomeIcon=()=>{
   setHome(true)
   setdirectory(true)
   setHubs(true)

 }



 const handledirectoryIcon=()=>{
  setdirectory(false)
  setHome(false)
  setHubs(true)

 }
 const handlehubs=()=>{
  setHubs(false)
  setHome(false)
  setdirectory(true)

 }

 const handbellicon=()=>{
  setbellicon(true)
 }

 const handbellicontwo=()=>{
  setbellicon(false)
 }





  return (
    <div className="navbar">
      <nav className="nav">
       {/*  <div className="logo">
          <img src={logo} alt="Libral" />
        </div> */}
        <ul className="nav-items">
          <li className="nav-item" onClick={handleHomeIcon} >
            <NavLink to="/" exact activeClassName="active"  >
              <img src={home ? homeicon :homeicontwo} alt="" className="mr-1 " /> <span>Home</span>       
            </NavLink>
          </li>
          <li className="nav-item Notifaction " onClick={handbellicon}  onMouseOver={handbellicon}  onMouseOut={handbellicontwo}  >
            <NavLink to={"/notification"} className="flex  Notifaction-color cursor-pointer" >
              <img src={bellicons ? bellicontwo :bellicon} alt="" className="notifactionImg" />
              <span className="flex items-center ">Notifications <div className="pl-1.5"> <div className=" Notifaction-number"><p className="" >{notificationData.length}</p> </div> </div></span>
            </NavLink>
          </li>
          <li className="nav-item" onClick={handledirectoryIcon}>
            <NavLink to="/MyGroups" exact activeClassName="active">
              <img src={directory? diricon : diricontwo} alt="" className="mr-2" />
              <span>My Hubs </span>
            </NavLink>
          </li>
          <li className="nav-item" onClick={handlehubs}>
            <NavLink to="/Directory" exact activeClassName="active">
              <img src={hubs ? manicon :manicontwo } alt="" className="mr-2" />
              <span> Directory</span>
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink to="/profile" activeClassName="active">
              {/* <img src={profile} alt="" className="nav_item_profile mr-1" /> */}
                {rederImg()}  

              <span>Profile</span>
            </NavLink>
            <ul className="dropdown">
              <li className="SubProfile_Nav">
                <NavLink to="/profiles">
                  <img src={Eyeicon} alt="" className="" />
                  View Profile
                </NavLink>
              </li>
              <hr className="mr-16" />
              <li className="SubProfile_Nav">
                <NavLink to="/login" onClick={logout}>
                  <img src={LogoutICon} alt="" className="" />
                  logout
                </NavLink>
              </li>
            </ul>
          </li>
        </ul>
        <main className="navmain">

        </main>
        <footer className="footer">
         <img src={navlogo} alt="" /> 
         <div className="navfooterimg">
           <img src={navfooterimg} alt="" />
         </div>
          
        </footer>
      </nav>
    </div>
  );
};

export default Navbar;
