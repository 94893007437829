import React from 'react'
import ContactFrom from '../../Component/Contact';
import Socialpost from '../../Component/Socialpost';
import Socialvideo from '../../Component/Socialvideo';
import Polls from '../../Component/Polls';
import Searchbar from '../../Component/Searchbar';


const Index = () => {
    return (
        <> 
         <div className="content">
        <div>
        <ContactFrom />
        </div> 
      <div className='events flex justify-between'>
      <div className="event">
           
      <Socialpost title={"Dr Hakima el Haité"}  heading={"Article Heading/ Post Caption"} />
     <Polls />
     <Socialvideo />
    </div>
    <div className="searchbar">
    <Searchbar />
    </div>

   </div>
   </div>

          
          
        </>
    )
}

export default Index;
