import React, { useEffect } from "react";
import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { BASE_URL_IMG, DirectoryService } from "../../Utils/services";

const ActivePost = ({
  heading,
  badgeicon,
  badgeData,
  ExpertiseData,
  handlepage,
  country,
  party,
  partyImage,
  username,
  profileDetailsData,
  isInternational
}) => {
  const [userdata, setuserdata] = useState()
  const getDirectoryServiceApiData = async () => {
    try {
      const result = await DirectoryService();
      console.log(result.res, "result data");


      /*   const _data = result.res.party;
       const  data=result.res.users.filter((item) => item.party == party); */
      /*  setuserdata(data) */

      const data = result.res.users.filter((item) => item.party === party)
      console.log(party)
      console.log(data, "data")
      setuserdata(data)

      /*   console.log(data, "Usr Data ");
        console.log(_data,country,"user dat")
   */

    } catch (error) {
      console.log(error);
    }
  };


  useEffect(() => {
    getDirectoryServiceApiData();
  }, []);

  return (
    <div>
      <div className="Partymemberpost my-2">
        <h3>Party</h3>
      </div>
      <div className="Partybanner flex items-center my-4 ">
        <div className="partyLogo flex items-center pl-5 ">
          <img src={`${BASE_URL_IMG}${badgeicon}`} alt="" />
        </div>
        <div className="partyname cursor-pointer">
          <Link
            to={{
              pathname: "/partydetails",
              state: {
                users: userdata,
                partyname: party,
                partyImage: partyImage,
                username: username,
                country: country,
                ProfileDetailsData: profileDetailsData,
                isInternational : isInternational
              },
            }}
          >
            <h3>{heading}</h3>
          </Link>
        </div>
      </div>
      <div className="Partymemberpost  mt-12">
        <h3>Badge</h3>
      </div>
      {badgeData &&
        badgeData.map((item) => {
          return (
            <div className="Badge flex items-center my-4 ">
              <div className="partyLogo flex items-center pl-5">
                <img src={`${BASE_URL_IMG}${item.logo}`} alt="" />
              </div>
              <div className="partyname ">
                <h3>{item.name}</h3>
              </div>
            </div>
          );
        })}

      <div className="Partymemberpost mt-12">
        <h3>Expertise</h3>
      </div>

      <div className="expertise_area flex pb-12 ">
        {ExpertiseData &&
          ExpertiseData.map((item) => {
            return (
              <div className="gvernace bg-white p-2 justify-center items-center mr-2  ">
                <p className="text-paraText">{item.area}</p>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default ActivePost;
