import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { BASE_URL_IMG, getResources } from '../../Utils/services';
import TimeAgo from 'react-timeago'


const ResourcesPage = () => {
    const [resourcesData, setResourcesData] = useState([]);
    const location = useLocation();
    const [data] = useState("How are you doing today")


    const getAllResourcesData = async () => {
        const { sectionId } = location.state !== undefined && location.state;
        try {
            const _result = await getResources(sectionId);
            if (_result && _result.res && _result.res.length > 0) {
                setResourcesData(_result.res)
            }
        } catch (error) {
            toast.error(error.message)
        }
    }

    useEffect(() => {
        getAllResourcesData();
    }, []);
    return (
        <>
            {
                resourcesData && resourcesData.map((items) => {
                    return (
                        <div className='py-4'>
                            <div className="grid grid-cols-12 bg-white p-10">
                                <div className="col-span-12">
                                    <h3 className="text-xl text-minihubHeading mb-4">{items.heading} </h3>
                                    <div className="text-wrapper text-sm">
                                        <div dangerouslySetInnerHTML={{ __html: items.article }}></div>
                                    </div>
                                </div>
                                <div className="col-span-3 mt-6">
                                    <ul>
                                        <li>
                                            {
                                                items.media && items.media.length > 0 &&
                                                <div className="w-full h-48 cursor-pointer bg-pdfBg flex flex-col justify-center items-center" onClick={() => window.open(items.media.map((pdf) => pdf.link))}>
                                                    <i className="fa fa-file-pdf-o text-red-600 pdfIconResources" aria-hidden="true"></i>
                                                    <button className="pt-2 text-2xl text-paraText">Click To Download</button>
                                                </div>
                                            }
                                        </li>

                                        <li className="text-sm mt-4 grid grid-cols-3">
                                            <p className="primary-color font-semibold mb-0.5 col-span-1"> Location </p>
                                            <span className='col-span-2'> {items.location} </span>
                                        </li>

                                        <li className="text-sm mt-4 grid grid-cols-3">
                                            <p className="primary-color font-semibold mb-0.5 col-span-1"> Posted On</p>
                                            <TimeAgo date={items.date} className="col-span-2" />
                                        </li>

                                        <li className="text-sm mt-4">
                                            <p className="primary-color font-semibold mb-0.5"> Posted by </p>
                                            <span className="flex items-center">
                                                <img src={`${BASE_URL_IMG}${items.postedBy.image}`} alt="" className="w-12 h-12 rounded-full mr-2" />
                                                {items.postedBy.name}
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                    )
                })
            }
            {
                resourcesData.length === 0 && (
                    <h3 className='text-3xl text-sidenav'>Sorry no data found...</h3>
                )
            }
        </>
    )
}

export default ResourcesPage