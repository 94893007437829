import React, { useEffect, useState } from "react";
import CalenderIcon from "../../Assets/calendericon.svg";
import calenderbtn from '../../Assets/btntoggle.svg'
import calenderbtn2 from '../../Assets/btntoggle2.svg'
import 'font-awesome/css/font-awesome.min.css';
import DateDetails from "./DateDetails";

import Calender from "../Home/Calender";

const Events = ({ getEventApiData, eventAllData }) => {
  // console.log(eventAllData, "eventAllData")
  const [toggle, settoggle] = useState(false);

  const [currentDate, setCurrentDate] = useState(new Date());
  const [showEvent, setShowEvent] = useState([]);
  const [year, setYear] = useState(new Date().getUTCFullYear());
  const [month, setMonth] = useState(new Date().getMonth() + 1);

  const handleToggleButton = (e) => {
    e.preventDefault();
    settoggle(!toggle)
  }

  const formatDate = date => {
    const _date = new Date(date)
    const month = _date.getMonth() + 1
    const year = _date.getFullYear()
    const newdate = _date.getDate()
    return `${newdate}/${month}/${year}`
  }

  const getAllApiDataOnLoad = () => {
    setShowEvent([])
    let arry = []
    eventAllData && eventAllData.map((item) => {
      const _date = new Date(item.current)
      const _d1 = formatDate(_date)
      const _d2 = formatDate(currentDate)
      if (_d1 === _d2) {
        arry.push(item)
      }
      else {
        console.log(false);
      }
    })
    setShowEvent(arry)
  }

  useEffect(() => {
    getAllApiDataOnLoad();
  }, [eventAllData])

  const dateHandler = (date) => {
    setCurrentDate(date)
    setShowEvent([])
    let arry = []
    eventAllData.map((item) => {
      const _date = new Date(item.current)
      const _d1 = formatDate(_date)
      const _d2 = formatDate(date)
      if (_d1 === _d2) {
        arry.push(item)
      }
      else {
        console.log(false);
      }
      setShowEvent(arry)
    })
  }

  const handleActiveStartDate = (value) => {
    console.log(value.activeStartDate, "Change Month")
    const _yearMonth = value.activeStartDate;
    const year = _yearMonth.getFullYear();
    const month = _yearMonth.getMonth() + 1;

    setCurrentDate(value.activeStartDate)
    setYear(year)
    setMonth(month)
  }
  useEffect(() => {
    getEventApiData(year, month);
  }, [year, month]);

  return (
    <div className="events-box">
      <div className="flex justify-between items-center pl-8 pr-14" >
        <div className="calender-ican-detail flex items-center ">
          <div className="calender-icon">
            <img src={CalenderIcon} alt="" />
          </div>
          <div className="Calender-name">
            <h3>Events</h3>
          </div>
        </div>
        <div className="events-btn">
          <button onClick={handleToggleButton}>{toggle ? <img src={calenderbtn2} alt="" /> : <img src={calenderbtn} alt="btn" />}</button>
        </div>
      </div>
      <div >
        {/* { /// I have to use this but for now I am using bellow code
          toggle
            ?
            <DateDetails
              showEvent={eventAllData}
              getEventApiData={getEventApiData}
              year={year}
              month={month}
            />
            :
            <Calender
              showEvent={showEvent}
              value={currentDate}
              onChange={dateHandler}
              activeStartDateChange={handleActiveStartDate}
            />} */}

        {
          toggle
            ?
            <DateDetails
              showEvent={eventAllData}
              getEventApiData={getEventApiData}
              year={year}
              month={month}
            />
            : <Calender
              showEvent={showEvent}
              eventAllData={eventAllData}
              value={currentDate}
              onChange={dateHandler}
              activeStartDateChange={handleActiveStartDate}
            />
        }
      </div>
    </div>
  );
};

export default Events;
