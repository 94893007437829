import React, { usestate } from "react";
import Locked from "../../Assets/UnLockedOne.svg";
import Unlocked from "../../Assets/Unlocked.svg";

import { useHistory } from "react-router-dom";
import { BASE_URL_IMG } from "../../Utils/services";


export const ListHub = ({ minhubs, groupDetails, data_id }) => {
  const history = useHistory();

  const handleRoute = (data, items) => {
    if (data === false) {
      // history.push({
      //   pathname: "/about",
      //   state: {
      //     data_id: data_id,
      //     groupDetails: groupDetails,
      //   }
      // });
      history.push({
        pathname: "/about/minhub",
        state: {
          minihubId: items._id,
          groupDetails: items
        }
      })
    }
    else {
      history.push({
        pathname: "/linda",
        state: {
          itmesData: items,
          data_id: data_id,
          groupDetails: groupDetails
        }
      })
    }
  };
  console.log(minhubs, "minhubs")

  return (
    <div>
      <div className="border-b-2 border-grey-100" ></div>
      <div className="grid grid-cols-3">
        <div className="col-span-1"></div>
        <div className="col-span-2">
          {minhubs && minhubs.map((itmes) => {
            return (
              <>
                <div className="Groups" >
                  <div className="groupbtn cursor-pointer " onClick={() => handleRoute(itmes.locked, itmes)} >
                    <div className="flex items-center  border-b-2 border-grey-100 ">
                      <div className="mingroupimg w-28 ">
                        <img src={`${BASE_URL_IMG}${itmes.logo}`} alt="" />
                      </div>
                      <div className="Tittles flex justify-between items-center relative ">
                        <div className="heading">
                          <h4>{itmes.name}</h4>
                          <p>{itmes.members}</p>
                        </div>
                        <div className="time" >
                          <img src={itmes.locked ? Locked : Unlocked} alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </div>
  );
};
