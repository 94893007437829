import React, { useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import closeicon from "../Assets/closeicon.svg";
import Compose from "../Assets/Compose.svg";
import CreatPoll from "../Assets/graphicon.svg";
import Profile from "../Assets/profile.png";
import Places from "../Assets/Places.svg";
import searchButton from "../Assets/searchbutton.svg";
import { useState } from "react";
import { Autocomplete } from "@mui/material";
import LocationSearchInput from "../Component/LocationSearchInput";
import Grid from "@mui/material/Grid";
import mediaPhoto from "../Assets/media-photo.svg";
import mediavideo from "../Assets/media-video.svg";
import mediatube from "../Assets/media-tube.svg";
import TextField from "@material-ui/core/TextField";
import {
  BASE_URL_IMG,
  BASE_URL,
  PostHubServices,
  PostMinihubServices,
  PostServices,
} from "../Utils/services";
import { toast } from "react-toastify";
import Loader from "../Component/Loader";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import GoogleMap from "../Component/googlemap/GoogleMap";

// GoogleMap
import "../Component/googlemap/GoogleMap.css";
import { Search, GpsFixed, Cancel } from "@material-ui/icons";
const apiKey = "AIzaSyAJvFGHeSThBWR0obYtHSWARBP2vVo4mh4";
const mapApiJs = "https://maps.googleapis.com/maps/api/js";
const geocodeJson = "https://maps.googleapis.com/maps/api/geocode/json";

// load google map api js

function loadAsyncScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    Object.assign(script, {
      type: "text/javascript",
      async: true,
      src,
    });
    script.addEventListener("load", () => resolve(script));
    document.head.appendChild(script);
  });
}

const extractAddress = (place) => {
  const address = {
    city: "",
    state: "",
    zip: "",
    country: "",
    plain() {
      const city = this.city ? this.city + ", " : "";
      const zip = this.zip ? this.zip + ", " : "";
      const state = this.state ? this.state + ", " : "";
      return city + zip + state + this.country;
    },
  };

  if (!Array.isArray(place?.address_components)) {
    return address;
  }

  place.address_components.forEach((component) => {
    const types = component.types;
    const value = component.long_name;

    if (types.includes("locality")) {
      address.city = value;
    }

    if (types.includes("administrative_area_level_2")) {
      address.state = value;
    }

    if (types.includes("postal_code")) {
      address.zip = value;
    }

    if (types.includes("country")) {
      address.country = value;
    }
  });

  return address;
};

// GoogleMap

const style = {
  position: "absolute",
  top: "50%",
  left: "55%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 8,
};

export default function Modalmedia({
  handlevalue,
  handleClose,
  showPhoto,
  showVideo,
  showFiles,
  showLinks,
  getDirectoryServiceApiData,
  HandlePoll,
  handlePollClose,
  hubForm,
  minihubForm,
  hubId,
  minihubId,
}) {
  const [formData, setFormData] = useState({});
  const [imgFile, setImgFile] = useState("");
  const [VideoFile, setVideoFile] = useState("");
  const [pdfFile, setPdfFile] = useState("");
  const [linkFile, setLinkFile] = useState("");
  const [disableButton, setDisableButton] = useState(false);
  const [loader, setLoader] = useState(false);
  const [sendImageFile, setSendImageFile] = useState("");
  const [youtubelink, setYoutubeLink] = useState([]);
  // Image Crop Is Here
  const [crop, setCrop] = useState({ aspect: 4 / 3 });
  const [src, setSrc] = useState(null);
  const [image, setImage] = useState(null);
  const [result, setResult] = useState(null);
  const [videoValidation, setVideoValidation] = useState("");
  const [mediaType, setMediaType] = useState("visual");
  const [contentType, setContentType] = useState("application/json");
  const loginUserId = localStorage.getItem("loginUserId");
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [searchLocationInput, setSearchLocationInput] = useState("");
  const [locationTextVisibility , setLocationTextVisibility] = useState(false);
 

  // GoogleMap Start
  const searchInput = useRef(null);
  console.log(searchInput, "searchInput");
  const [address, setAddress] = useState({});

  // console.log(address, "address");

  // init gmap script
  const initMapScript = () => {
    // if script already loaded
    if (window.google) {
      return Promise.resolve();
    }
    const src = `${mapApiJs}?key=${apiKey}&libraries=places&v=weekly`;
    return loadAsyncScript(src);
  };

  // do something on address change
  const onChangeAddress = (autocomplete) => {
    const place = autocomplete.getPlace();
    setAddress(extractAddress(place));
  };

  // init autocomplete
  const initAutocomplete = () => {
    if (!searchInput.current) return;

    const autocomplete = new window.google.maps.places.Autocomplete(
      searchInput.current
    );
    autocomplete.setFields(["address_component", "geometry"]);
    autocomplete.addListener("place_changed", () =>
      onChangeAddress(autocomplete)
    );
  };

  const reverseGeocode = ({ latitude: lat, longitude: lng }) => {
    const url = `${BASE_URL}/location-find`;
    // searchInput.current.value = "Getting your location...";
    setSearchLocationInput('Getting your location...')
    const token = localStorage.getItem("token");
    console.log({ lat: lat, lng: lng });
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ lat: lat, lng: lng }),
    })
      .then((response) => response.json())
      .then((res) => {
        console.log(res.location);
        setAddress(res.location);
        // searchInput.current.value = res.location;
        setSearchLocationInput(res.location)
      });
  };

  const findMyLocation = () => {
    setLocationTextVisibility(true) ;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        console.log(position.coords);
        reverseGeocode(position.coords);
      });
    }
  };

  // load map script after mounted
  useEffect(() => {
    initMapScript().then(() => initAutocomplete());
  }, []);

  // GoogleMap End

  const handleImgChangeFile = (e) => {
    setContentType("application/json");
    setMediaType("visual");
    setImgFile(e.target.files[0]);
    setSrc(URL.createObjectURL(e.target.files[0]));
  };

  function getCroppedImg() {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    // canvas.width = crop.width;
    // canvas.height = crop.height;

    var originWidth = crop.width * scaleX;
    var originHeight = crop.height * scaleY;

    // maximum width/height
    var maxWidth = 1200,
      maxHeight = 1200 / (4 / 3);
    var targetWidth = originWidth,
      targetHeight = originHeight;
    if (originWidth > maxWidth || originHeight > maxHeight) {
      if (originWidth / originHeight > maxWidth / maxHeight) {
        targetWidth = maxWidth;
        targetHeight = Math.round(maxWidth * (originHeight / originWidth));
      } else {
        targetHeight = maxHeight;
        targetWidth = Math.round(maxHeight * (originWidth / originHeight));
      }
    }

    // set canvas size
    canvas.width = targetWidth;
    canvas.height = targetHeight;

    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      targetWidth,
      targetHeight
    );
    console.log(canvas, "canvas");
    const base64Image = canvas.toDataURL("image/jpeg");
    var profile = new Image();
    profile.src = base64Image;
    setResult(profile.src);

    fetch(profile.src)
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File([blob], profile.src, { type: "image/jpeg" });
        setSendImageFile(file);
      });
  }

  // Image Crop Is Here

  const handleImgFile = (val) => {
    setImgFile(val);
    if (val.name !== "") {
      setDisableButton(true);
    }
  };

  const getVideoDuration = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const media = new Audio(reader.result);
        media.onloadedmetadata = () => resolve(media.duration);
      };
      reader.readAsDataURL(file);
      reader.onerror = (error) => reject(error);
    });

  const handleVideoFile = async (val) => {
    setContentType("application/json");
    setMediaType("video");
    if (val && val.size > 0) {
      console.log(val, "val");
      if (val.size && val.size > 0) {
        if (Math.round(val.size / 1048576) > 300) {
          setVideoValidation("Video size should be less than 300 MB");
          setVideoFile("");
        }
        const duration = await getVideoDuration(val);
        let durationSecond = Math.round(duration);

        if (durationSecond && durationSecond > 120) {
          setVideoValidation("Video duration should be less than 2 minutes");
          setVideoFile("");
        } else {
          setVideoFile(val);
          setVideoValidation("");
          if (duration && duration > 120) {
            setVideoValidation("Video duration should be less than 2 minutes");
            setVideoFile("");
          } else {
            setVideoFile(val);
            setVideoValidation("");
          }
        }
        console.log(Math.round(val.size / 1048576), "Val");
      }
    }
  };

  const handlePdfFile = (val) => {
    setContentType("application/pdf");
    setMediaType("file");
    setPdfFile(val);
  };
  const handleLinkFile = (val) => {
    console.log(val, "linkFile");
    setLinkFile(val);
    if (val.name !== "") {
      setDisableButton(true);
    }
  };

  useEffect(() => {
    setShowErrorMsg(false);
  }, [handlevalue]);

  const onChangeHandler = (e) => {
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.value;
    if (name === "title") {
      setShowErrorMsg(false);
    }
    setFormData({ ...formData, [name]: value });
  };
  console.log(formData, "Form Data");

  const HandleYoutubeLink = (e) => {
    setYoutubeLink(e.target.value);
    setMediaType("link");
  };

  const handlePostSubmit = async (e) => {
    
    e.preventDefault();
    if (!formData.title) {
      setShowErrorMsg(true);
      return;
    }
    setLoader(true);
    const postFormData = new FormData();
    postFormData.append("title", formData.title);
    // postFormData.append("location", searchInput.current.value);
    postFormData.append("location", searchLocationInput);
    postFormData.append("caption", formData.caption);
    postFormData.append(
      "media",
      (imgFile && imgFile) ||
        (VideoFile && VideoFile) ||
        (pdfFile && pdfFile) ||
        (youtubelink && youtubelink)
    );
    postFormData.append("mediaType", mediaType);
    console.log(postFormData, "postFormData");
    if (hubId) {
      postFormData.append("id", hubId);
    }
    if (minihubId) {
      postFormData.append("id", minihubId);
    }
    try {
      if (hubForm === "hub") {
        const result = await PostHubServices(postFormData, contentType);
        if (result.res.success) {
          setLoader(false);
          toast.success(result.res.message);
          console.log(result.res, "This is result response");
          console.log(getDirectoryServiceApiData)
          getDirectoryServiceApiData()
          handleClose(); // problem is pass hub id on it
        }
      } else if (minihubForm === "minihub") {
        setContentType("multipart/form-data");
        const result = await PostMinihubServices(postFormData, contentType);
        if (result.res.success) {
          setLoader(false);
          toast.success(result.res.message);
          console.log(result.res, "This is result response");
          handleClose();
          getDirectoryServiceApiData();
        }
      } else {
        const result = await PostServices(postFormData, contentType);
        if (result.res.success) {
          setLoader(false);
          toast.success(result.res.message);
          console.log(result.res, "This is result response");
          handleClose();
          getDirectoryServiceApiData();
        }
      }
    } catch (error) {
      setLoader(false);
      console.log(error.messages, "Error Message");
      toast.error(error.messages);
    }
    setFormData({});
    setImgFile("");
    setSrc(null);
    setResult(null);
    setVideoFile("");
    setPdfFile("");
    setLinkFile("");
    setYoutubeLink("");
  };

  const handleCreatePoll = () => {
    handleClose();
    HandlePoll();
  };
  const handleCreatePost = () => {
    handlevalue();
    handlePollClose();
  };

  useEffect(() => {
    setFormData({});
    setImgFile("");
    setSrc(null);
    setResult(null);
    setVideoFile("");
    setPdfFile("");
    setLinkFile("");
    setYoutubeLink("");
    setSearchLocationInput("");
    setLocationTextVisibility(false);
  }, [handleClose]);

  function searchLocationChangeHandler(val) {
    setSearchLocationInput(val)
    console.log('this is comming from another ' , val)
  }

  // console.log(searchLocationInput)

  return (
    <div>
      <Modal
        open={handlevalue}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        onClose={handleClose}
      >
        <Box sx={style} className="boxmodal">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="eventModel"
          >
            WRITE POST
          </Typography>
          <Typography
            variant="subtitle2"
            gutterBottom
            component="div"
            className="devicer"
          ></Typography>
          <form>
            <Grid container spacing={8}>
              <Grid item md={6} sm={12} xs={12}>
                <div className="CreatePolButtons">
                  <div className="CreatePolButton">
                    <button className="buttonmodal" onClick={handleCreatePost}>
                      <img src={Compose} alt="Compose" />
                      Write Post
                    </button>
                  </div>
                  <div className="CreatePolButton">
                    <button
                      className="buttonmodal createpol"
                      onClick={handleCreatePoll}
                    >
                      <img src={CreatPoll} alt="createpol" /> Create Poll
                    </button>
                  </div>
                </div>
                {/* <img src={result && result} /> */}
                <div className="Createpolpost">
                  <div component="div" className="profilepost mb-2">
                    <div component="div" className="image">
                      <img
                        src={`${BASE_URL_IMG}/user/${loginUserId}/${loginUserId}.jpeg?${new Date().getTime()}`}
                        alt="profile"
                        className="inline object-cover w-8 h-8 rounded-full"
                      />
                    </div>
                    <div gutterBottom component="div" className="post">
                      <input
                        type="text"
                        placeholder="Post Title..."
                        className="outline-none"
                        name="title"
                        value={formData.title || ""}
                        onChange={(e) => onChangeHandler(e)}
                      />
                    </div>
                  </div>
                  <div component="div" className="profilepost">
                    <textarea
                      id=""
                      cols="35"
                      rows="7"
                      className="outline-none"
                      placeholder="Share your views..."
                      name="caption"
                      value={formData.caption || ""}
                      onChange={(e) => onChangeHandler(e)}
                    ></textarea>
                  </div>
                </div>
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <div className="Address">
                  <div className="AddLoction mt-16 mb-2 md:mt-4 sm:mt-4">
                    <h3>Add Location</h3>
                  </div>
                  <div className="loction">
                    <div className="image ">
                      <img src={Places} alt="profile w-1" />
                    </div>
                    <div component="div" className="post ">
                      {/* <input
                        type="text"
                        placeholder="Search Location"
                        className="outline-none"
                        name="location"
                        value={formData.location || ''}
                        onChange={(e) => onChangeHandler(e)}
                      /> */}
                      {/* <input
                        value={searchLocationInput}
                        onChange={searchLocationChangeHandler}
                        placeholder="Search location...."
                        className="outline-none mt-2"
                      /> */}
                      {/* <Autocomplete
                        options={['v1' , 'v2' , 'v3']}
                        
                        renderInput={(params) => (
                          <TextField
                            ref={searchInput}
                            {...params}
                            label="Search location...."
                            variant="standard"
                            value='this is value'
                            onChange={searchLocationChangeHandler}
                          />
                        )}
                      /> */}

                      {!locationTextVisibility&&<LocationSearchInput onInputChange={searchLocationChangeHandler}  ></LocationSearchInput>}
                      {locationTextVisibility&& <div className="flex justify-between">
                      <input value={searchLocationInput} className="border-none outline-none"/>
                      <Cancel onClick={()=>{setSearchLocationInput('') ; setLocationTextVisibility(false)}}></Cancel>
                        </div>}
                    </div>
                    <div component="div" className="image mt-2">
                      {!locationTextVisibility &&<button type="button" onClick={findMyLocation}>
                        <img src={searchButton} alt="searchbutton" className="mx-4" />
                      </button>}
                    </div>
                  </div>
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item md={12}>
                      <div className="Addmedia mt-4">
                        <h3>Add Media</h3>
                      </div>
                    </Grid>
                    {showPhoto && (
                      <Grid item md={12}>
                        <div className="relative w-auto overflow-hidden">
                          <TextField
                            id="profilephoto"
                            label="Add Photo"
                            value={result || ""}
                            className="w-full text-xs"
                          />
                          <input
                            disabled={disableButton}
                            type="file"
                            accept="image/*"
                            className="z-50 opacity-0 absolute bottom-0 right-0 w-50"
                            name="image-file"
                            onChange={handleImgChangeFile}
                          />
                          <div className="floating-box">+</div>
                        </div>
                      </Grid>
                    )}
                    {showVideo && (
                      <Grid item md={12}>
                        <div className="relative w-auto overflow-hidden">
                          <TextField
                            id="profileVideo"
                            label="Add Video"
                            value={(VideoFile.name && VideoFile.name) || ""}
                            className="w-full text-xs"
                          />

                          <input
                            disabled={disableButton}
                            type="file"
                            accept="video/*"
                            className="z-50 opacity-0 absolute bottom-0 right-0 w-50"
                            name="video-file"
                            onChange={(e) => handleVideoFile(e.target.files[0])}
                          />
                          <div className="floating-box">+</div>
                        </div>
                        {videoValidation && videoValidation && (
                          <p className="text-red-600">{videoValidation}</p>
                        )}
                        {/*  <div className="mt-4 flex items-center media-btn justify-center">
                          <img src={mediavideo} alt="" />
                          <span className="ml-2">Video </span>
                        </div> */}
                      </Grid>
                    )}
                    {showFiles && (
                      <Grid item md={12}>
                        <div className="relative w-auto overflow-hidden">
                          <TextField
                            id="profilephoto"
                            label="Add Files"
                            value={(pdfFile.name && pdfFile.name) || ""}
                            className="w-full text-xs"
                          />

                          <input
                            disabled={disableButton}
                            type="file"
                            accept="application/pdf, application/vnd.ms-excel"
                            className="z-50 opacity-0 absolute bottom-0 right-0 w-50"
                            name="pdf-file"
                            onChange={(e) => handlePdfFile(e.target.files[0])}
                          />
                          <div className="floating-box">+</div>
                        </div>
                        {/*   <div className="mt-4 flex items-center media-btn justify-center">
                          <img src={mediatube} alt="" />
                          <span className="ml-2">Files </span>
                        </div> */}
                      </Grid>
                    )}
                    {showLinks && (
                      <Grid item md={12}>
                        <div className="relative w-auto overflow-hidden">
                          {/* <TextField
                            id="profilephoto"
                            label="Add YouTube/Vimeo Link"
                            value={linkFile.name && linkFile.name || ""}
                            className="w-full text-xs"
                          />
                          <input
                            disabled={disableButton}
                            type="file"
                            className="z-50 opacity-0 absolute bottom-0 right-0 w-50"
                            name="link-file"
                            onChange={(e) => handleLinkFile(e.target.files[0])}
                          /> */}
                          <TextField
                            id="youtubelink"
                            type={"url"}
                            name="youtubelink"
                            label="Add YouTube/Vimeo Link"
                            value={youtubelink}
                            onChange={HandleYoutubeLink}
                            className="w-full text-xs"
                          />
                          <div className="floating-box">+</div>
                        </div>
                        {/* <div className="mt-4 flex items-center media-btn justify-center">
                          <img src={mediatube} alt="" />
                          <span className="ml-2">YouTube/Vimeo Link</span>
                        </div> */}
                      </Grid>
                    )}
                  </Grid>
                  <Grid md={12}>
                    {src && (
                      <div className="col-6">
                        <ReactCrop
                          src={src}
                          onImageLoaded={setImage}
                          crop={crop}
                          onChange={setCrop}
                        />
                        <Button variant="contained" onClick={getCroppedImg}>
                          Crop Image
                        </Button>
                      </div>
                    )}
                  </Grid>
                </div>
              </Grid>
            </Grid>
            <div
              component="div"
              className="addbutton flex justify-center pt-8 relative"
            >
              <Loader size={80} loader={loader} />
              <button
                disabled={loader ? true : false}
                onClick={handlePostSubmit}
                className={`addbtn ${loader && "bgButtonLoader"}`}
              >
                ADD POST
              </button>
            </div>
          </form>
          <div className="flex items-center justify-center text-red-500 mt-4">
            {showErrorMsg && <Typography>Please add Post Title !</Typography>}
          </div>

          <Button
            style={{ position: "absolute", top: 8, right: 0 }}
            onClick={handleClose}
          >
            <img src={closeicon} alt="closeicon" />
          </Button>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}></Typography>
        </Box>
      </Modal>
    </div>
  );
}
