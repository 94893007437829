import * as React from "react";
import { useEffect, useState, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Logo from "../../Assets/AllHub.png"
import Arrow_left from "../../Assets/LeftArrow.svg";
import Arrow_right from "../../Assets/RightArrow.svg";
import { useHistory } from "react-router-dom";
import { BASE_URL_IMG } from "../../Utils/services";

const SliderSlick = ({ AllHubs }) => {
  const history = useHistory();

  const NextArrow = ({ onClick }) => {
    return (
      <div className="cursor-pointer right_arrow" onClick={onClick}>
        <img src={Arrow_right} alt="next" />
      </div>
    );
  };
  const PrevArrow = ({ onClick }) => {
    return (
      <div className="cursor-pointer left_Arrow" onClick={onClick}>
        <img src={Arrow_left} alt="prev" />
      </div>
    );
  };
  const [imageIndex, setImageIndex] = useState(0);
  const settings = {
    infinite: true,
    speed: 300,
    slidesToShow: 3,
    centerMode: true,
    centerPadding: "0",
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    beforeChange: (current, next) => setImageIndex(next),
    className: "center custom_style_slider",
  };

  const handleClickHub =(item) =>{
    history.push({
      pathname : "/about",
      state : {
        data_id : item._id,
        groupDetails : item
      }
    })
  }

  return (
    <>
      <div className="flex justify-center mb-8" >
        <img src={Logo} alt="" className="allhublogo" />
      </div>
      <div>
        <Slider
          {...settings}
        >
          {AllHubs && AllHubs.map((items, index) => {
            return (
              <>
                <div className={`cursor-pointer ${index === imageIndex ? "slide activeSlide" : "slide"}`} onClick={() => handleClickHub(items)}>
                  <div className="flex justify-center items-center flex-col ">
                    <img src={`${BASE_URL_IMG}${items.logo}`} alt=""/>
                    <h3 className="text-center sliderfont">{items.name}</h3>
                  </div>
                </div>
              </>
            )
          })}
        </Slider>
      </div>
    </>
  );

};

export default SliderSlick;