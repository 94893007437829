import React, { useEffect, useState, useContext } from "react";
import AuthenContext from "../../Utils/AuthContext";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";

import memberAvtar from "../../Assets/countrymeberprofile.png";
import insta from "../../Assets/Instagram.svg";
import linkdin from "../../Assets/Linkedin.svg";
import tiwtter from "../../Assets/Twitter.svg";
import facebookImg from "../../Assets/facebook.png"
import postsactive from "../../Assets/Posts - Inactive.svg";
import postsactives from "../../Assets/Posts - Active.svg";
import Details from "../../Assets/Details - Inactive.svg";
import Detailactive from "../../Assets/Details - Active.svg";
import ActivePost from "../Directory/ActivePost";
import Directorysocial from "../Directory/Directorysocial";
import Lilogocolor from "../../Assets/LI-Logo-Colour.png";
import badgeicon from "../../Assets/LI-TS_V.png";
import AccountDetails from "./AccountDetails";
import logoutIcon from "../../Assets/Logout-Default.svg";
import { BASE_URL_IMG, deleteUserProfileApi, GetUserProfileService } from "../../Utils/services";
import { ProfilePost, profilePost } from "./profilePost";

import { useHistory } from "react-router";
import ConfimationModal from "../../Modals/ConfimationModal";
import { toast } from "react-toastify";



const Profile = () => {
  const [ProfileData, setProfileData] = useState({});

  const [isActive, setActive] = useState(false);
  const [isActives, setActives] = useState(true);
  const history = useHistory();
  const [showBlockModal, setshowBlockModal] = useState(false);
  const [showEditPartyExpertise, setshowEditPartyExpertise] = useState(false);

  const authCtx = useContext(AuthenContext);
  const { logout } = authCtx;
  const profileImage = localStorage.getItem('userImage')
  const loginUserId = localStorage.getItem('loginUserId')



  const closeBlockModal = () => {
    setshowBlockModal(false)
  }


  const toggleClass = () => {
    setActive(true);
    setActives(false);
  };

  const toggleClassdtails = () => {
    setActives(true);
    setActive(false);
  };

  const getDirectoryServiceApiData = async () => {
    try {
      const result = await GetUserProfileService();
      setProfileData(result.res.profile);
      console.log(result.res.profile, "profile data")

    }
    catch (error) {
      console.log(error.message, "Error");
    }
  };
  useEffect(() => {
    getDirectoryServiceApiData();
  }, []);

  const renderEditPage = (data) => {
    setshowEditPartyExpertise(true)
    history.push(
      {
        pathname: "/ProfileEdit",
        state: {
          profileSendData: data,
          showEditPartyExpertise: true
        }
      }
    )
  }

  const handleBlockUser = async () => {
    if (loginUserId === ProfileData._id) {
      try {
        const result = await deleteUserProfileApi();
        toast.success(result.res.message)
        closeBlockModal();
        localStorage.clear();
        history.push("/login")
      } catch (error) {
        toast.error(error.message)
      }
    }
    else {
      toast.warn("You can only delete your own profile")
    }
  }

  const handleDeleteProfile = (data) => {
    setshowBlockModal(true)
  }



  console.log(ProfileData, "ProfileData NEw")

  const handleTwitter = (link) => {
    window.open(`https://twitter.com/${link}`);
  };
  const handleInstagram = (link) => {
    window.open(`https://www.instagram.com//${link}`);
  };
  const handleFacebook = (link) => {
    window.open(`http://facebook.com/${link}`);
  };
  const handleLinkedIn = (link) => {
    window.open(`https://www.linkedin.com//${link}`);
  };

  const handleMailClick = (link) => {
    window.location = `mailto:${link}`
  }

  return (
    <div>
      <div className="memberProfileborder flex justify-end items-center">
        <div className="memberlogut mr-20 flex items-center">
          <div className="logouticon">
            <img src={logoutIcon} alt="" />
          </div>
          <div className="meber_logoutDetail " onClick={logout} >
            {/* <h3>Logout</h3> */}
            <Link to="/login">Logout</Link>

          </div>
        </div>
      </div>
      <div className="membercontent px-20 ">
        <Grid container>
          <Grid item md={6} sm={12}>
            <div className="memberAvtar ">
            
              <img src={ProfileData.image ? `${BASE_URL_IMG}${ProfileData.image}?${new Date().getTime()}` :  memberAvtar } alt="" />
            </div>
            <div className="membername">
              <div className="">
                <h2>
                  {ProfileData.name}
                </h2>
              </div>
            </div>
            <div className="memberposition">
              <div className=" pr-10 py-4">
                <h3>{ProfileData.designation} </h3>
              </div>
              <div className="my-2">
                <hr />
              </div>
              <div className="memberLoction">
                <h3>{ProfileData.country} </h3>
              </div>
              <div className="memeberSocialmedia mt-10  mb-8 flex">

                <div
                  className={`memberdetail flex items-center w-1/2 h-18 justify-center py-1 ${isActives && "Detailsactive"
                    } `}
                  onClick={toggleClassdtails}
                >
                  <div className="postavtar w-6">
                    <img src={isActives ? Detailactive : Details} alt="" />
                  </div>
                  <div className="posts ml-4">
                    <p>Details</p>
                  </div>
                </div>
                <div
                  className={`memberpost flex items-center w-1/2 h-18 justify-center py-2 ${isActive && "Postactive"
                    }`}
                  onClick={toggleClass}
                >
                  <div className="postavtar w-6">
                    <img src={isActives ? postsactive : postsactives} alt="" />

                  </div>
                  <div className="posts ml-4">
                    <p>Posts</p>
                  </div>
                </div>
              </div>

              <div className="mt-4">
                {/* {isActives ? (
                  <ProfilePost />
                ) : (
                  <ActivePost
                    img={Lilogocolor}
                    heading={ProfileData.party}
                    badgeicon={ProfileData.partyImage}
                    ExpertiseData={ProfileData.expertise}
                    badgeData={ProfileData.badge}
                  />
                )} */}
                {
                  isActive && <ProfilePost />
                }
                {
                  isActives && <ActivePost
                    img={Lilogocolor}
                    heading={ProfileData.party}
                    badgeicon={ProfileData.partyImage}
                    ExpertiseData={ProfileData.expertise}
                    badgeData={ProfileData.badge}
                    country={ProfileData.country}
                    party={ProfileData.party}
                    partyImage={ProfileData.partyImage}
                    username={ProfileData.name}
                    profileID={ProfileData._id}
                    profileDetailsData={ProfileData}
                    isInternational ={ProfileData.isInternational}

                  />
                }
              </div>
            </div>
          </Grid>
          <Grid item md={1} sm={12}>

          </Grid>
          <Grid item md={5} sm={12}>
            <div className="BioCard mt-32">
              <div className="Bioboder flex  items-center">
                <div className="Bioboder flex  items-center">
                  <div className="BioEditer ml-6 ">
                    <div className="cursor-pointer" onClick={() => renderEditPage(ProfileData)}>
                      <p>EDIT PROFILE </p>
                    </div>
                  </div>
                  <div className="BioEditer ml-6 ">
                    <div className="cursor-pointer" onClick={() => handleDeleteProfile(ProfileData)}>
                      <p>DELETE PROFILE </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bio ml-6 mt-12 mb-5">
                <h3>Bio</h3>
              </div>
              <div className="mx-6 BioDetails">
                <p>{ProfileData.bio}</p>
              </div>
              <div className="socialmedia ml-6 mt-4 mb-12">
                <ul className="flex items-center">
                  <li onClick={() => handleTwitter(ProfileData.twitter)} className="cursor-pointer">
                    <img src={tiwtter} alt="" />
                  </li>
                  <li className="ml-4 cursor-pointer" onClick={() => handleInstagram(ProfileData.instagram)}>
                    <img src={insta} alt="" />
                  </li>
                  <li className="ml-4 cursor-pointer" onClick={() => handleFacebook(ProfileData.facebook)}>
                    <img src={facebookImg} alt="" />
                  </li>
                  <li className="ml-4 cursor-pointer" onClick={() => handleLinkedIn(ProfileData.linkedin)}>
                    <img src={linkdin} alt="" />
                  </li>
                </ul>
              </div>
              <div className="AccountDetails">
                {/* {isActive ? null : <AccountDetails email={ProfileData.email} />} */}
                {
                  !isActive && loginUserId === ProfileData._id ? <AccountDetails email={ProfileData.email} /> : ""
                }
              </div>
              <div className="BioCardfooter  py-4"  >
                <p>
                  Contact :
                  <span className="pl-1 cursor-pointer" onClick={() => handleMailClick(ProfileData.email)}>

                    {ProfileData.email}
                  </span>
                </p>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <ConfimationModal isDeleteProfileModal={true} handleBlockUser={handleBlockUser} showBlockModal={showBlockModal} closeBlockModal={closeBlockModal} />
    </div>
  );
};

export default Profile;
