import React from "react";
import ReactTimeago from "react-timeago";
import { useState, useEffect } from "react";

const Artical = ({ heading, pera, postDate }) => {
  const [moreCaption, setMoreCaption] = useState(false);
  const [showmMoreCaptionData, setShowmMoreCaptionData] = useState(false);
  useEffect(() => {
    if (pera && pera.length > 300) {
      setMoreCaption(true);
    }
  }, [pera]);
  return (
    // <div>
    // <div class="Artical">
    //   <h3>{heading}</h3>
    //   <p>
    //      {pera}
    //   </p>
    // </div>
    // </div>
    <div className="ml-7 mr-7 mt-6 mb-14">
      <div className="flex justify-between">
        <h3>{heading}</h3>
        <span className="inline-flex items-center px-3 h-8 bg-gray-200 text-gray-800 rounded-full text-sm">
          <ReactTimeago date={postDate}></ReactTimeago>
        </span>
      </div>
      <p className="text-paraText text-size  mt-2 new-line">
        {pera !== "undefined"
          ? `${showmMoreCaptionData ? pera : pera && pera.slice(0, 300)}`
          : ""}
      </p>
      {moreCaption && (
        <p
          className="cursor-pointer text-sidenav new-line"
          onClick={() => setShowmMoreCaptionData(!showmMoreCaptionData)}
        >{`${showmMoreCaptionData ? "Show Less" : "Read More..."}`}</p>
      )}
    </div>
  );
};

export default Artical;
