import React from "react";
import LindaIcon from "../../Assets/LIDTA.png";
import Grid from "@mui/material/Grid";
import Locked from "../../Assets/Locked.svg";
import { Link, useHistory, useLocation } from "react-router-dom";
import RedirectModal from "../../Modals/RedirectPage";
import { BASE_URL_IMG } from "../../Utils/services";

const Linda = () => {
  const [ON, setON] = React.useState(false);
  const location = useLocation();
  const history = useHistory();
  const { itmesData, data_id, groupDetails } = location.state !== undefined && location.state;
  const handleOpen = () => {
    setON(true);
    console.log("true");
  };
  const handleClose = () => {
    setON(false);
  };

  const handleGoHub =(data) => {
    history.push({
      pathname : "/about",
      state: {
        data_id : data_id,
        groupDetails: groupDetails
      }
    })
  }

  return (
    <div>
      <div className="linda">
        <div className="content"></div>
      </div>
      <div className="Lindacontent">
        <Grid container spacing={8}>
          <Grid item md={6} sm={12} xs={12} >
            <div className="LindaIcon">
              <img src={`${BASE_URL_IMG}${itmesData.logo}`} alt="" className="w-20 h-20 rounded-full object-cover" />
            </div>
            <div className="LindaTitle">
              <h3>{itmesData.name}</h3>
            </div>
            <div className="LindaMember">
              <p>{itmesData.members} Members</p>
            </div>

            <div className="LindaAbout">
              <p>
                {itmesData.description}
              </p>
            </div>
            <div className="LindaKnowAbout">
              <button className="lindabtn" onClick={handleOpen}>KNOW MORE</button>
            </div>
            <div className="LindaLink">
              <button onClick={() => handleGoHub(itmesData)}>Back to Hub</button>
            </div>
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <Grid container xs={12}>
              <div className="LockedCard">
                <div className="locked flex items-center">
                  <div className="LockedIcon">
                    <img src={Locked} alt="" />
                  </div>
                  <div className="lockedTitle">
                    <h3>Locked</h3>
                  </div>
                </div>
                <div className="Detailhub">
                  <p>
                    Details for this Mini Hub is Locked. Join the Mini Hub to
                    read more details.
                  </p>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <RedirectModal handlevalue={ON} handleClose={handleClose}  />
    </div>
  );
};

export default Linda;
