import React, { useEffect, useState } from "react";
import ReadArrow from "../../Assets/read-arrow.svg";
import Grid from "@mui/material/Grid";
import heart from "../../Assets/heart.svg";
import interfac from "../../Assets/interface.svg";
import Artical from "./Artical";
import {
  BASE_URL_IMG,
  deleteCommentApi,
  editCommentApi,
  LikeDislikeCommentApi,
} from "../../Utils/services";
import Blueheart from "../../Assets/BlueHeart.svg";
import dumyDp from "../../Assets/DummyDp.png";
import LikesModal from "./LikesModal";
import CommentConfirmationModal from "../../Modals/CommentConfirmationModal";
import { toast } from "react-toastify";
import DotBTN from "../../Assets/socialpost-btn.svg";
import EditCommentModal from "../../Modals/EditCommentModal";
import CommentDeleteConfirmation from "../../Modals/CommentDeleteConfirmation";
import { addComment } from "../../Utils/services";
import { Typography } from "@mui/material";
import ReactTimeago from "react-timeago";

function likeornot(arr , user){
   let liked = false ;
   arr.forEach(element => {
        if(element.user === user){
          liked = true ;
        }
   });
   return liked ;
}

const Comments = ({
  postDate,
  heading,
  pera,
  commentData,
  postId,
  getCommentsAllData,
  userId,
  likesData,
  getDirectoryServiceApiData,
}) => {

  console.log(postDate , "this is post date");

  const [userImageShow, setUserImageshow] = useState([]);
  const [userNameLength, setUserNameLength] = useState([]);
  const [toggleComments, setToggleComments] = useState({});
  const [liked, setLiked] = useState(false);
  const [toggleLike, setToggleLike] = useState({});
  const [moreCaption, setMoreCaption] = useState(false);
  const [showmMoreCaptionData, setShowmMoreCaptionData] = useState(false);
  const [showLikesModal, setShowLikesModal] = useState(false);
  const [showEditDeleteModal, setEditDeleteModal] = useState(false);
  const [commentIdTOEditDelete, setCommentIdToEditDelete] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditOpenModal, setShowEditOpenModal] = useState(false);
  const [forEditCommentData, setForEditCommentData] = useState("");
  const [editValue, setEditValue] = useState("");
  const [commentInputValue, setCommentInputValue] = useState("");
  const [commentSelection, setCommentSelection] = useState(null);

  let loginUserId = localStorage.getItem("loginUserId");
  useEffect(() => {
    getCommentsAllData(postId);
  }, [postId]);
  useEffect(() => {
    if (pera && pera.length > 300) {
      setMoreCaption(true);
    }
    console.log(loginUserId);
    const data = [];
    console.log(userId);
    commentData.map((item, index) => {
      item.likes &&
        item.likes.filter((data) => {
          if (data.user === loginUserId) {
            setLiked(true);
          }
        });
      if (index < 2) {
        data.push(item);
      }
    });
    const _newLikesData = [];
    likesData &&
      likesData.map((item, index) => {
        if (index < 1) {
          _newLikesData.push(item);
        }
      });
    setUserImageshow(_newLikesData);
    setUserNameLength(likesData && likesData.length);
  }, [commentData, likesData]);

  const formatDate = (date) => {
    const _date = new Date(date);
    const month = _date.getMonth() + 1;
    const year = _date.getFullYear();
    const newdate = _date.getDate();
    return `${newdate}/${month}/${year}`;
  };

  const handleToggleButton = (id) => {
    setToggleComments({
      [id]: !toggleComments[id],
    });
  };

  const handleLikeDislike = async (commentId, postId) => {
    try {
      const result = await LikeDislikeCommentApi({
        postId: postId,
        commentId: commentId,
      });
      if (result.res.success) {
        console.log(result.res.message);
        if (result.res.message === "liked") {
          setToggleLike({
            ...toggleLike,
            [commentId]: !toggleLike[commentId],
          });
        }
        getCommentsAllData(postId);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleViewAllLikes = () => {
    setShowLikesModal(true);
  };
  const handleClose = () => {
    setShowLikesModal(false);
  };

  const handleEditDeleteComment = (content, id) => {
    setEditDeleteModal(true);
    setEditValue(content);
    setCommentIdToEditDelete(id);
  };

  const closeShowEditDeleteModal = () => {
    setEditDeleteModal(false);
  };

  const handleDeleteModal = () => {
    setShowDeleteModal(true);
    setEditDeleteModal(false);
  };
  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleDeleteComment = async () => {
    try {
      const result = await deleteCommentApi({
        commentId: commentIdTOEditDelete,
        postId: postId,
      });
      if (result.res.success) {
        toast.success(result.res.message);
        getCommentsAllData(postId);
        closeDeleteModal();
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleOpenEditCommentModal = () => {
    setShowEditOpenModal(true);
    setEditDeleteModal(false);
  };

  const closeEditShowModal = () => {
    setShowEditOpenModal(false);
    setEditValue("");
  };

  const handleEditChange = (e) => {
    setEditValue(e.target.value);
  };

  const handleEditCommentSubmit = async (e) => {
    e.preventDefault();
    if (!editValue) {
      return;
    }
    try {
      const result = await editCommentApi({
        postId: postId,
        commentId: commentIdTOEditDelete,
        contents: editValue,
      });
      if (result.res.success) {
        toast.success(result.res.message);
        getCommentsAllData(postId);
        closeEditShowModal();
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handlePostComment = async (e, commentId) => {
    e.preventDefault();
    console.log(commentId);
    try {
      const result = await addComment({
        comment: commentInputValue,
        postId: postId,
        commentId: commentId,
      });
      if (result.res.success) {
        getDirectoryServiceApiData();
        getCommentsAllData(postId);
        setCommentInputValue("");
      }
    } catch (error) {
      console.log(error);
      toast.warning(error.message);
    }
  };

  function commentSelectionHandler(id) {
    if (!commentSelection) {
      setCommentSelection(id);
    } else {
      setCommentSelection(null);
    }
  }
  // console.log(commentData)
  return (
    <>
      <>
        <div className="social-comments">
          <div>
            <div className="flex justify-between">
              <h3>{heading}</h3>
              <span className="inline-flex items-center px-3 h-8 bg-gray-200 text-gray-800 rounded-full text-sm">
                <ReactTimeago date={postDate}></ReactTimeago>
              </span>
            </div>
            <p className="text-paraText text-size  mt-2 new-line">
            {pera != 'undefined'?`${showmMoreCaptionData ? pera : pera && pera.slice(0, 300)}`:''}
            </p>
            {moreCaption && (
              <p
                className="cursor-pointer text-sidenav new-line"
                onClick={() => setShowmMoreCaptionData(!showmMoreCaptionData)}
              >{`${showmMoreCaptionData ? "Show Less" : "Read More..."}`}</p>
            )}
          </div>
          <div className="ArticleLikes">
            <Grid
              container
              className="flex items-center cursor-pointer"
              onClick={() => handleViewAllLikes()}
            >
              <div>
                <div className="LikeByPerson">
                  <div className="flex flex-row mx-1">
                    {userImageShow &&
                      userImageShow.map((data, index) => {
                        return (
                          <img
                            src={
                              `${BASE_URL_IMG}/user/${data.user}/${data.user}.jpeg?${new Date().getTime()}` ||
                              dumyDp
                            }
                            alt="user"
                            className={`w-10 h-10 likebyuser rounded-full ${
                              index === 0 ? "z-40 " : "z-0  "
                            }`}
                          />
                        );
                      })}
                  </div>
                </div>
              </div>
              <div className="ml-2">
                <div className="likeByTitle flex items-center videopost_title">
                  {userImageShow &&
                    userImageShow.map((data) => {
                      return <span className="px-1">{data.name}</span>;
                    })}
                  {userNameLength > 1 && (
                    <span className="pl-1">
                      {" "}
                      and {userNameLength - 1}{" "}
                      {`${userNameLength > 2 ? "Others" : "Other"}`}
                    </span>
                  )}
                </div>
              </div>
            </Grid>
          </div>
          {commentData &&
            commentData.map((items, index) => {
              return (
                <div className="Comments-line" key={items._id}>
                  <div className="social-comment-border">
                    <Grid container>
                      <Grid item xs={1}>
                        <div className="comments-avtar">
                          <img
                            src={
                              `${BASE_URL_IMG}/user/${items.user}/${items.user}.jpeg?${new Date().getTime()}` ||
                              dumyDp
                            }
                            alt="avtar"
                            className="rounded-full"
                          />
                        </div>
                      </Grid>
                      <Grid item xs={11}>
                        <div className="Comments-name">
                          <p className="flex items-center">
                            <span>{items.name} </span>{" "}
                            <span className="px-2 ">
                              <p className="text-gray-600">{items.content}</p>
                            </span>
                            {loginUserId === items.user && (
                              <span
                                className="cursor-pointer"
                                onClick={() =>
                                  handleEditDeleteComment(
                                    items.content,
                                    items._id
                                  )
                                }
                              >
                                <img src={DotBTN} />
                              </span>
                            )}
                          </p>
                        </div>
                        <div className="comment-btns flex items-center">
                          <div className="time">
                            <p>{formatDate(items.date)}</p>
                          </div>
                          <div className="Sub-Comments flex items-center">
                            <span
                              className="mx-1 cursor-pointer"
                              onClick={() =>
                                handleLikeDislike(items._id, postId, items.user)
                              }
                            >

                             {likeornot(items.likes ,loginUserId) ? <img src={Blueheart} alt="like" /> :<img src={heart} alt="unlike" />}
                            </span>
                            <span className="mx">
                              <p>{items.likes.length}</p>
                            </span>
                            <span className="ml-3 ">
                              <img src={interfac} alt="" />
                            </span>
                            <span className="ml-1 cursor-pointer">
                              <p>{items.replies.length}</p>
                            </span>
                            <span>
                              <button
                                type="submit"
                                className="search-button mb-2"
                                onClick={() =>
                                  commentSelectionHandler(items["_id"])
                                }
                              >
                                {`${
                                  commentSelection === items["_id"]
                                    ? "Cancel"
                                    : "Reply"
                                }`}
                              </button>
                            </span>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                    <div>
                      {commentSelection === items["_id"] && (
                        <form>
                          <div className="Comments">
                            <div className="Comment-search-box flex ">
                              <input
                                type="text"
                                className="search-input"
                                placeholder="Add a comment"
                                value={commentInputValue}
                                onChange={(e) =>
                                  setCommentInputValue(e.target.value)
                                }
                              />
                              <button
                                type="submit"
                                className="search-button"
                                onClick={(e) =>
                                  handlePostComment(e, items["_id"])
                                }
                              >
                                Reply
                              </button>
                            </div>
                          </div>
                        </form>
                      )}
                      {items.replies.length > 0 && (
                        <div className="pl-16 my-4">
                          <div className="flex items-center">
                            <div className="w-6 h-1 border-t border-solid border-muteText mr-1"></div>

                            <span
                              className="cursor-pointer text-muteText "
                              onClick={() => handleToggleButton(items._id)}
                            >
                              {toggleComments[items._id]
                                ? "Hide Replies"
                                : "Show replies..."}
                            </span>
                          </div>

                          {toggleComments[items._id] &&
                            items.replies.map((data) => {
                              return (
                                <div className="flex items-center my-4 py-2 border-t border-b">
                                  <div className="comments-avtar">
                                    <img
                                      src={`${BASE_URL_IMG}/user/${data.user}/${data.user}.jpeg?${new Date().getTime()}`}
                                      alt="avtar"
                                      className="rounded-full"
                                    />
                                  </div>
                                  <div className="Comments-name ml-4">
                                    <p className="text-gray-600">
                                      <span>{data.name}</span> {data.content}
                                    </p>
                                    <div className="time">
                                      <p>{formatDate(data.date)}</p>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          {/* <div className="More-Comment">
                <Grid container>
                  <Grid item xs={3}>
                    <a href="abc" className="Loadmore">Load More</a>
                  </Grid>
                </Grid>
              </div> */}
          <LikesModal
            likesData={likesData}
            showLikesModal={showLikesModal}
            handleClose={handleClose}
          />
          <CommentConfirmationModal
            forEditCommentData={forEditCommentData}
            showEditOpenModal={showEditOpenModal}
            closeEditShowModal={closeEditShowModal}
            handleOpenEditCommentModal={handleOpenEditCommentModal}
            handleDeleteComment={handleDeleteComment}
            closeDeleteModal={closeDeleteModal}
            showDeleteModal={showDeleteModal}
            handleDeleteModal={handleDeleteModal}
            getCommentsAllData={getCommentsAllData}
            commentIdTOEditDelete={commentIdTOEditDelete}
            postId={postId}
            showEditDeleteModal={showEditDeleteModal}
            closeShowEditDeleteModal={closeShowEditDeleteModal}
          />
          <EditCommentModal
            getCommentsAllData={getCommentsAllData}
            forEditCommentData={forEditCommentData}
            showEditOpenModal={showEditOpenModal}
            closeEditShowModal={closeEditShowModal}
            postId={postId}
            editValue={editValue}
            commentIdTOEditDelete={commentIdTOEditDelete}
            handleEditChange={handleEditChange}
            handleEditCommentSubmit={handleEditCommentSubmit}
          />
          <CommentDeleteConfirmation
            getCommentsAllData={getCommentsAllData}
            postId={postId}
            commentIdTOEditDelete={commentIdTOEditDelete}
            handleDeleteComment={handleDeleteComment}
            showDeleteModal={showDeleteModal}
            closeDeleteModal={closeDeleteModal}
          />
        </div>
      </>
      {/* ) */}
      {/* : <Artical heading={"Article Heading/ Post Caption"} pera="  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
      nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
      erat, sed diam voluptua. At vero eos et accusam et justo duo dolores
      et ea rebum…." />
      } */}
    </>
  );
};

export default Comments;
