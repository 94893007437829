import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import Modal from "@mui/material/Modal";
import closeicon from "../Assets/closeicon.svg";


const style = {
  position: "absolute",
  top: "50%",
  left: "55%",
  transform: "translate(-50%, -50%)",
  width: 500,

  bgcolor: "background.paper",

  boxShadow: 24,
  py:8,
  
};

export default function RedirectModal({handlevalue, handleClose}) {
/*   const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false); */

  return (
    <div>
     {/*  <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={handlevalue}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="boxmodal">
         
         <div className=" flex justify-center items-center riderct_moidal "> 
            <div className="" >
            <h3>You will be redirected to the<br/> Liberal International Website.</h3>
            <div className="flex justify-center">
                <p>Do you wish to continue?</p>
            </div>

            </div>
            
         </div>
         <div className="button_redirect flex justify-between mt-2">
             <div className="ml-32 pt-2">
             <button onClick={handleClose} >No</button>
             </div>
             <div className=" Button_redirect_Yes mr-32">
             <button>yes</button>
             </div>

        </div>

          <Button
            onClick={handleClose}
            style={{ position: "absolute", top: 8, right: 0 }}
          >
            <img src={closeicon} alt="closeicon" />
          </Button>
      
        </Box>
      </Modal>
    </div>
  );
}
