import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import closeicon from '../Assets/closeicon.svg';
import { Autocomplete, Button, Grid, TextField } from '@mui/material';
import { reportPost } from '../Utils/services';
import Loader from "../Component/Loader"


const style = {
    position: 'absolute',
    top: '50%',
    left: '55%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 8,
};

const issuesData = [
    {
        id: 1,
        title: 'Why are you reporting this post?',
    },
    {
        id: 2,
        title: "It's spam"
    },
    {
        id: 3,
        title: 'Nudity or sexual activity',
    },
    {
        id: 4,
        title: 'Hate speech or symbols',
    },
    {
        id: 5,
        title: 'False information',
    },
    {
        id: 6,
        title: 'Bullying or harassment',
    },
    {
        id: 7,
        title: 'Scam or fraud',
    },
    {
        id: 8,
        title: 'Violence or dangerous organizations',
    },
    {
        id: 9,
        title: 'Intellectual property violation',
    },
    {
        id: 10,
        title: 'Sale of illegal or regulated goods',
    },
    {
        id: 11,
        title: 'Suicide or self-injury',
    },
    {
        id: 12,
        title: 'Eating disorders',
    },
    {
        id: 12,
        title: 'Other',
    },
];

const PostReportModal = ({ showReportModal, closeReportModal, userReportId, postId }) => {
    const [issue, setIssue] = useState('');
    const [loader, setLoader] = useState(false);
    const [comment, setComment] = useState("");
    const [showOther, setShowOther] = useState(false);


    const handleReportSubmit = async (e) => {
        setLoader(true);
        e.preventDefault();
        try {
            const result = await reportPost({
                "postId": postId,
                "reportedBy": userReportId,
                "issue": issue,
                "comment": comment,
            });
            if (result.res.success) {
                console.log(result.res.message)
                setLoader(false)
                closeReportModal();
            }
        } catch (error) {
            setLoader(false)
            console.log(error)
        }
    }

    const handleIssueData = (val) => {
        setIssue(val);
        if (val === "Other") {
            setShowOther(true)
        }
        else{
            setShowOther(false)
        }
    }

    return (
        <div>
            <Modal
                open={showReportModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className="boxmodal">
                    <Button onClick={closeReportModal} style={{ position: "absolute", top: 8, right: 0 }}  ><img src={closeicon} alt="closeicon" /></Button>
                    <div>
                        <form>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Autocomplete
                                        id="report"
                                        options={issuesData}
                                        getOptionLabel={(option) => option.title}
                                        inputValue={issue}
                                        onInputChange={(event, newInputValue) => handleIssueData(newInputValue)}
                                        renderInput={(params) => <TextField id="report" {...params} label="Select the Issue" />}
                                    />
                                </Grid>
                                {showOther &&
                                    <Grid item xs={12}>
                                        <div className="Address mt-4" >
                                            <div className="AddLoction mb-2">
                                                <h3>Other</h3>
                                            </div>
                                            <div className="loction">
                                                <div component="div" className="post mb-2">
                                                    <textarea
                                                        id=""
                                                        cols="35"
                                                        rows="7"
                                                        className="outline-none ask_question"
                                                        placeholder="Comment here for other issue"
                                                        name="comment"
                                                        value={comment}
                                                        onChange={(e) => setComment(e.target.value)}
                                                    ></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </Grid>
                                }
                            </Grid>
                            <div component="div" className="mt-8 flex justify-end items-center">
                                <div className="mr-8">
                                    <Loader size={20} loader={loader} />
                                    <button disabled={loader ? true : false} onClick={closeReportModal} className={`addbtn ${loader && "bgButtonLoader"}`}>Cancel</button>
                                </div>
                                <div>
                                    <Loader size={20} loader={loader} />
                                    <button disabled={loader ? true : false} onClick={handleReportSubmit} className={`deletebtn`}>Report</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </Box>
            </Modal>
        </div>
    );
}

export default PostReportModal;