import React, { useEffect } from 'react'
import { useHistory } from 'react-router';



const Protected = (props) => {
  let Cmp = props.Cmp;
  const history = useHistory();
  useEffect(() => {
    if (!localStorage.getItem("token")) {
      // history.push("/Login")
      history.push("/login")
    }

  }, [history]);

  return (
    <div>
      <Cmp />
    </div>
  )
}

export default Protected
