import { Autocomplete, TextField } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { BASE_URL_IMG, getExpertise, partyList } from "../../Utils/services";
import EditImg from "../../Assets/Edit.svg";
import CrossImg from "../../Assets/cross.svg";

const ActivePostEdit = ({
  heading,
  badgeicon,
  badgeData,
  ExpertiseData,
}) => {

  console.log(ExpertiseData, "ExpertiseData,")
  console.log(badgeData, "badgeData,")
  const [editParty, setEditParty] = useState(false);
  const [partyListData, setPartyListData] = useState([]);
  const [party, setParty] = useState([]);
  const [expertiseList, setExpertiseList] = useState([]);
  const [expertise, setExpertise] = useState([]);
  const [editExpertise, setEditExpertise] = useState(false);

  const getParty = async () => {
    try {
      const response = await partyList();
      const _partyList = response.res.data;
      setPartyListData(_partyList)

    }
    catch (error) {
      toast.warning("Something went wrong!")
    }
  }


  const getExpertiseData = async () => {
    try {
      const result2 = await getExpertise();
      const _ExpertiseList = result2.res;
      setExpertiseList(_ExpertiseList)
    }
    catch (error) {
      toast.warning("Something went wrong!")
    }
  }

  useEffect(() => {
    getParty();
    getExpertiseData();
  }, [])


  const onInputPartyChange = (event, newInputValue, name) => {
    const _name = event.target.name
    const _value = event.target.value

    if (name == 'party') {
      setParty({
        ...party,
        [name]: newInputValue
      })
    }
    else {
      setParty({
        ...party,
        [_name]: _value
      })
    }
  }

  const onInputExpertiseChange = (event, newInputValue, name) => {
    const _name = event.target.name
    const _value = event.target.value

    if (name == 'expertise') {
      setExpertise({
        ...expertise,
        [name]: newInputValue
      })
    }
    else {
      setExpertise({
        ...expertise,
        [_name]: _value
      })
    }
  }

  const handlePartyEdit = () => {
    setEditParty(!editParty)
  }

  const handleExpertiseEdit = () => {
    setEditExpertise(!editExpertise)
  }

  return (
    <div className="mb-5">
      <div className="Partymemberpost flex items-center justify-between my-2">
        <h3>Party</h3>
        <div className="cursor-pointer" onClick={handlePartyEdit}>
          {editParty ? (
            <img src={CrossImg} alt="" />
          ) : (
            <img src={EditImg} alt="" />
          )}
        </div>
      </div>
      {
        editParty ? (
          <Autocomplete
            name="party"
            options={partyListData}
            getOptionLabel={(option) => option.name}
            value={party.name}
            onChange={(e, newVal) => onInputPartyChange(e, newVal, 'party')}
            renderInput={(params) => <TextField {...params} id="party" label="Name of the Party" />}
          />
        )
          :
          (

            <div className="Partybanner flex items-center my-4">
              <div className="partyLogo flex items-center ml-5">
                <img src={`${BASE_URL_IMG}${badgeicon}`} alt="" />
              </div>
              <div className="partyname">
                <h3>{heading}</h3>
              </div>
            </div>
          )
      }
      <div className="Partymemberpost  mt-12">
        <h3>Badge</h3>
      </div>
      {badgeData &&
        badgeData.map((item) => {
          return (
            <div className="Badge flex items-center my-4 p-5">
              <div className="partyLogo pl-5">
                <img src={`${BASE_URL_IMG}${item.logo}`} alt="" />
              </div>
              <div className="partyname ml-8">
                <h3>{item.name}</h3>
              </div>
            </div>
          );
        })}

      <div className="Partymemberpost flex items-center justify-between mt-12">
        <h3>Expertise</h3>
        <div className="cursor-pointer" onClick={handleExpertiseEdit}>
          {editExpertise ? (
            <img src={CrossImg} alt="" />
          ) : (
            <img src={EditImg} alt="" />
          )}
        </div>
      </div>

      {editExpertise ?
        <Autocomplete
          multiple
          name="expertise"
          limitTags={2}
          options={expertiseList}
          getOptionLabel={(option) => option.area}
          value={expertise.area}
          onChange={(e, newVal) => onInputExpertiseChange(e, newVal, 'expertise')}
          renderInput={(params) => <TextField {...params} id="expertise" label="Add Expertise" />}
        />
        :
        <div className="expertise_area flex pb-12 ">
          {ExpertiseData &&
            ExpertiseData.map((item) => {
              return (
                <div className="gvernace bg-white p-2 justify-center items-center mr-2  ">
                  <p className="text-paraText">{item.area}</p>
                </div>
              );
            })}
        </div>
      }
    </div>
  );
};

export default ActivePostEdit;
