import React, { useEffect, useState } from "react";
import socialbtn from "../Assets/socialpost-btn.svg";
import path from "../Assets/Path.svg";
import DeleteReportForm from "../Modals/DeleteReportForm";
import DummyDp from "../Assets/DummyDp.png"
import { BASE_URL_IMG, VoteOnPoll } from "../Utils/services";
import { toast } from "react-toastify";
import ReactTimeago from "react-timeago";

const Polls = ({ length, _id, userId, getDirectoryServiceApiData, question, User, location, choices, img , postDate}) => {
  const [getPercen, setPercen] = useState({})
  const [showPercent, setShowPercent] = useState(false);
  const [postId, setPostId] = useState(null);
  const [userReportId, setUserReportId] = useState(null);
  const [showDeleteReport, setShowDeleteReport] = useState(false)
  const [choicesData, setChoicesData] = useState(choices)
  const [totalVotedPoll, setTotalVotedPoll] = useState(0);


  const handleVoteGive = async (choiceId) => {
    try {
      const result = await VoteOnPoll({
        "post": _id,
        "choice": choiceId,
      });
      setChoicesData(result.res.data.choices)
      if (result.res.success) {
        toast.success(result.res.message);
        getDirectoryServiceApiData();
      }
    } catch (error) {
      toast.error(error.message)
    }
  }

  useEffect(() => {
    let totalVoted = 0
    let obj = {}

    choices && choices.map(item => {
      return totalVoted = item.score + totalVoted
    }
    )
    choices && choices.map(item => {
      if (totalVoted > 0) {
        obj = {
          ...obj,
          [item._id]: (item.score / totalVoted) * 100
        }
      }
      else {
        obj = {
          ...obj,
          [item._id]: 0
        }
      }
    })
    setPercen(obj)
  }, [choices])

  const handleShowPercentage = (id) => {
    const _showPer = choices && choices.filter(item => {
      return id === item._id
    })
    const _DataId = _showPer && _showPer.map(val => {
      return val._id
    })
    
    if (_DataId[0] === id) {
      setShowPercent(true)
    }
    else {
      setShowPercent(false)
    }

  }

  const handleReportModal = (_id, userId) => {
    setPostId(_id)
    setUserReportId(userId)
    setShowDeleteReport(true)
  }
  const handleClose = () => {
    setShowDeleteReport(false)
  };
  useEffect(()=>{
    handleShowPercentage()
  },[])

  return (
    <div className="event">
      <div className="py-0 px-0 bg-white  my-20">
        <div className=" Isabella flex justify-between">
          <div className="image flex">
            <img className="h-20 object-cover rounded-full border-2 border-indigo-50w-20 0 md:h-16 Avtar_socialpost w-16" src={img ? `${BASE_URL_IMG}${img}?${new Date().getTime()}` : DummyDp} alt='Astrid' />
            <h2>{User}</h2>
          </div>
          <div className="titlebtn videobtnset" onClick={() => handleReportModal(_id, userId)}>
            <button className="btn">
              <img src={socialbtn} alt="btn" />
            </button>
            <div className="reportpost">
              <button className="btn" >{userId !== localStorage.getItem('loginUserId')?"REPORT POST":"DELETE POST"}</button>
            </div>
          </div>
        </div>
        <div className="emptyimage PollData">
        <div className="flex justify-between">
              <h3 className="ml-2">{question}</h3>
              <span className="inline-flex items-center px-3 h-8 bg-gray-200 text-gray-800 rounded-full text-sm">
                <ReactTimeago date={postDate}></ReactTimeago>
              </span>
            </div>
          <ul className="ml-5 mr-5">
            {
              choices && choices.map((item, index) => (
                <li className="polling" key={index} onClick={() => {handleVoteGive(item._id)}} >
                  {
                    showPercent && (
                      <>
                        <span className="progress-bar" style={{ width: `${getPercen[item._id]}%` }} />
                        <span className="progress-text"> {`${Math.trunc(getPercen[item._id])}%`} </span>
                      </>
                    )
                  }
                  <span>{item.title} </span>
                </li>
              ))
            }
          </ul>
          <div className="paths flex justify-between">
            <div className="flex">
              <div className="image">
                <img src={path} alt="path" />
              </div>
              <div className="loc">
                <p>{location} </p>
              </div>
            </div>
            <div className="postbutton flex items-center">
              <p>Ends in {length} days</p>
            </div>
          </div>
        </div>
      </div>
      <DeleteReportForm getDirectoryServiceApiData={getDirectoryServiceApiData} postId={postId} reportable={userId !== localStorage.getItem('loginUserId')} userId={userId} showDeleteReport={showDeleteReport} handleClose={handleClose} />
    </div>
  );
};

export default Polls;
