import React, { useEffect, useState } from "react";
import Directoryheader from "./Directoryheader";
import Grid from "@mui/material/Grid";
import Afganistan from "../../Assets/afganistan.svg";
import Country from "./CountryData";
import { Link, useRouteMatch, useHistory } from "react-router-dom";
import { BASE_URL_IMG, DirectoryService } from "../../Utils/services";
import searchicon from "../../Assets/searchicon.svg";
import searchbutton from "../../Assets/searchbutton.svg";

const DirectoryCountry = () => {
  const [country, setCountryData] = useState([]);
  const [searchPostValue, setSearchPostValue] = useState("");

  const [User, setUser] = useState([]);
  const [selectCountry, setselectedCountry] = useState([]);
  const [region, setregion] = useState();

  let { path } = useRouteMatch();
  const history = useHistory();

  const handlepage = (type) => {
    const data = User.filter((item) => item.country == type.country);
    const Contidata = region.filter((item) => item.name == type.Continent);
    history.push({
      pathname: `${path}/${type.country}`,
      state: {
        id: data,
        country: type.country,
        flag: type.countryFlag,
        Continent: Contidata,
        LiMember:type.limember
      },
    });
  };

  const GetDirectoryServiceCountry = async () => {
    try {
      const result = await DirectoryService();
      const _data = result.res.country;
      const _User = result.res.users;
      const _Region = result.res.regions;
      setregion(_Region);
      setUser(_User);
      setCountryData(_data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() =>{
    GetDirectoryServiceCountry();
  }, []);

  const handleSearchPost = (e) => {
    setSearchPostValue(e.target.value);
  };

  console.log(searchPostValue, "searchPostValue")


  return (
    <div>
      <div className="content">
        <div className="SearchBox">
          <div className="bg-white shadow flex searchbarbox">
            <span className="w-auto flex justify-end items-center text-gray-500 p-2">
              <i className="material-icons text-3xl">
                <img src={searchicon} alt="searchicon" />
              </i>
            </span>
            <input
              className="w-full rounded p-2"
              type="text"
              placeholder="Search Country"
              onChange={handleSearchPost}
            />
            <button className="bg-white-400 hover:bg-white-300 rounded text-black p-2 pl-4 pr-4">
              <span className="w-auto flex justify-end items-center text-gray-500 p-2">
                <i className="material-icons text-3xl">
                  <img src={searchbutton} alt="searchicon" />
                </i>
              </span>
            </button>
          </div>
        </div>
        <Directoryheader />
        <div className="mt-8">
          <Grid container>
            <Grid item sm={12}>
              <Grid container spacing={2}>
                {country && country.filter((val) => {
                  if (searchPostValue == "") {
                    return val;
                  }
                  else if (val && val.name && val.name.toLowerCase().includes(searchPostValue.toLowerCase())) {
                    return val
                  }
                  else if (val && val.continents && val.continents.toLowerCase().includes(searchPostValue.toLowerCase())) {
                    return val
                  }
                }).map((items) => {
                  return (
                    <Grid item lg={3} md={6} sm={6} xs={12}>
                      <div className="CommunityProfile" key={items._id}>
                        <div className="card">
                          <div
                            className={`profileBackground ${items.BgColor} blue`}
                            style={{
                              backgroundColor: `${items.region === "Africa" ? "#469025" : ""
                                || items.region === "Asia" ? "#f3007b" : ""
                                  || items.region === "Europe" ? "#48A4DA" : ""
                                    || items.region === "Latin America" ? "#9900CC" : ""
                                      || items.region === "Middle East & North Africa" ? "#FFDF1B" : ""
                                        || items.region === "North America" ? "#CC3300" : ""
                                          || items.region === "Worldwide" ? "#184183" : ""
                                            || items.region === "Oceania" ? "#00008B" : ""}`
                            }}
                          ></div>
                          <div className="flex flex-col items-center mt-4 texts Directory_Country">
                            <img src={`${BASE_URL_IMG}${items.flag}`} alt="" />
                            <h3>{items.name}</h3>
                            <p>{items.continents}</p>
                            <p className="gry">{items.users} people </p>

                            {/*  <Link to={ {pathname:`${path}/${items.name}`,state:selectCountry }} onClick={() => handlepage(items.name)} >View Profile</Link> */}
                            <p
                              className="DirectoryLink cursor-pointer"
                              onClick={() =>
                                handlepage({
                                  country: items.name,
                                  countryFlag: items.flag,
                                  Continent: items.region,
                                  li:items.LI
                                })
                              }
                            >
                              View
                            </p>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
            {/* <Grid item sm={1}></Grid> */}
            {/*  <Grid item sm={4}>
              <div className="directoryWorldWide pb-4">
                <div className="topborder"></div>
                <div className="topCountriesheading mt-2 ml-6">
                  <h3>Top Searched Countries</h3>
                </div>
                {country.map((item) =>{
                  return (
                    <>
                      <div
                        className="worldwideCountrySearch ml-6 mr-6 my-2 flex items-center pr-2"
                        key={item._id}
                      >
                        <div className="worldwideboder"></div>
                        <div className="worldwidecountryprofile flex justify-between items-center">
                          <div className="worldcountrydetail flex items-center">
                            <div className="countryAvtar ml-2 ">
                              <img src={item.flag} alt="" />
                            </div>
                            <div className="worldcountryname ml-2">
                              <h3>{item.name}</h3>
                            </div>
                          </div>
                          <div className="view">
                            <a href="abc">View</a>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </Grid> */}
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default DirectoryCountry;
