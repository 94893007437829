import React from 'react'
import Grid from '@mui/material/Grid';
import hubsLogo from '../../Assets/AllHub.png'
import { useHistory } from "react-router-dom";
import { BASE_URL_IMG } from '../../Utils/services';

const Hubs = ({ AllHubs, searchValue }) => {
  const history = useHistory();
  const handleClickHub =(item) =>{
    history.push({
      pathname : "/about",
      state : {
        data_id : item._id,
        groupDetails : item
      }
    })
  }

  return (
    <div className="Hubs">
      <Grid container >
        <div className="logo">
          <img src={hubsLogo} alt="" />
        </div>
      </Grid>
      <Grid container spacing={2} >
        <Grid item xs={12}>
          <Grid container>
            {AllHubs && AllHubs.filter((val)=> {
            if(searchValue == ""){
              return val
            }
            else if(val.name && val.name.toLowerCase().includes(searchValue.toLowerCase())){
              return val
            }
          }).map((items) => {
              return (
                <Grid item xs={6} className="p-2">
                  <div className="flex items-center allhubs cursor-pointer" onClick={() => handleClickHub(items)}>
                    <div className="avtar">
                      <img src={`${BASE_URL_IMG}${items.logo}`} alt="avtar" />
                    </div>
                    <div className="avtartitle">
                      <p>{items.name}</p>
                    </div>
                  </div>
                </Grid>
              )
            })}
          </Grid>
        </Grid>
        <Grid item xs={4}>
        </Grid>
      </Grid>
    </div>
  )
}

export default Hubs
