import React, { useState } from "react";


const AuthenContext = React.createContext({
    isSuperAdmin: false,
    token: "",
    refreshToken: "",
    login: (token, refreshToken) => { },
    logout: () => { },
});

const calculateRemainingTime = (expirationTime) => {
    const currentTime = new Date().getTime();
    const adjExpirationTime = new Date(expirationTime).getTime();

    const remainingDuration = adjExpirationTime - currentTime;

    return remainingDuration;
};

export const AuthContextProvider = (props) => {
    const initialToken = localStorage.getItem("token");
    const initialRefreshToken = localStorage.getItem("refreshToken");
    const [token, setToken] = useState(initialToken);
    const [refreshToken, setRefreshToken] = useState(initialRefreshToken);
    const userIsLoggedIn = !!token;
    const [isSuperAdmin, setIsSuperAdmin] = useState(localStorage.getItem("isSuperAdmin") || false);

    const logoutHandler = () => {
        setToken(null);
        setRefreshToken(null);
        localStorage.removeItem('token');
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("isSuperAdmin");
        setIsSuperAdmin(false);
    }

    const loginHandler = (token, refreshToken, isSuperAdmin, expirationTime) => {
        setToken(token);
        setRefreshToken(refreshToken);
        localStorage.setItem("token", token);
        localStorage.setItem("refreshToken", refreshToken);
        localStorage.setItem("isSuperAdmin", isSuperAdmin);
        setIsSuperAdmin(isSuperAdmin);
        localStorage.setItem('expirationTime', expirationTime);
        const remainingTime = calculateRemainingTime(expirationTime);
        // setTimeout(logoutHandler, remainingTime)  // only uncomment this for logout
    }

    const contextValue = {
        token: token,
        refreshToken: refreshToken,
        isLoggedIn: userIsLoggedIn,
        isSuperAdmin: isSuperAdmin,
        login: loginHandler,
        logout: logoutHandler,
    }

    return (
        <AuthenContext.Provider value={contextValue}>
            {props.children}
        </AuthenContext.Provider>
    )

}


export default AuthenContext;