import React from 'react'
import Grid from '@mui/material/Grid';
import { useEffect } from 'react';
import { BASE_URL_IMG, GetMembersApi } from '../../Utils/services';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import dumyDp from "../../Assets/DummyDp.png"

const MemberProfile = ({ HubId, minihubId, searchMemberValue }) => {
    const [memberListData, setMemberListData] = useState([]);
    const [loader, setLoader] = useState(false)
    const history = useHistory();

    const getAllMembers = async () => {
        setLoader(true)
        try {
            const result = await GetMembersApi(minihubId ? {
                "minihub": minihubId && minihubId
            } :
                {
                    "hub": HubId,
                }
            );
            console.log(result)
            if (result.res.length > 0) {
                setMemberListData(result.res);
                setLoader(false)
            }
        } catch (error) {
            console.log(error)
            setLoader(false)
        }
    }

    useEffect(() => {
        getAllMembers();
    }, [])

    const handleViewProfile = (id) => {
        history.push({
            pathname: "/Directory/expertise/member/profile",
            state: {
                id: id
            }
        })
    }

    return (
        <div className="CommunityMember">
            <div className="cummunityTitle">
                <h3>Community Members</h3>
            </div>
            <Grid container spacing={2}>
                {memberListData && memberListData.filter((val) => {
                    if (searchMemberValue == "") {
                        return val
                    }
                    else if (val.name.toLowerCase().includes(searchMemberValue.toLowerCase())) {
                        return val;
                    }
                }).map((items) => {
                    return (
                        <Grid item md={6} sm={12} xs={12}>
                            <div className="CommunityProfile" >
                                <div className="card">
                                    <div className={'profileBackground'}
                                        style={{
                                            backgroundColor: `${items.region === "Africa" ? "#469025" : ""
                                                || items.region === "Asia" ? "#f3007b" : ""
                                                    || items.region === "Europe" ? "#48A4DA" : ""
                                                        || items.region === "Latin America" ? "#9900CC" : ""
                                                            || items.region === "Middle East & North Africa" ? "#FFDF1B" : ""
                                                                || items.region === "North America" ? "#CC3300" : ""
                                                                    || items.region === "Worldwide" ? "#184183" : ""
                                                                        || items.region === "Oceania" ? "#00008B" : ""}`
                                        }}
                                    >
                                    </div>
                                    <div className="text">
                                        <img src={items.image ? `${BASE_URL_IMG}${items.image}` : dumyDp} alt="" className='w-20 h-20 rounded-full object-cover' />
                                        <h3>{items.name}</h3>
                                        <p>{`${items.party && items.party.slice(0, 40)}${items.party && items.party.length > 40 ? '...' : ''}`}</p>
                                        <p>{items.country}</p>
                                        <a onClick={() => handleViewProfile(items._id)} className='cursor-pointer'>View Profile</a>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    )
                })}
            </Grid>
            <div className="memberbtn flex justify-between"  >
                <div className="perviousbtn">
                    <button className="btn" >PREVIOUS</button>
                </div>
                <div className="nextbtn">
                    <button className="btn" >NEXT</button>
                </div>
            </div>
        </div>
    )
}

export default MemberProfile;
