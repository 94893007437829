import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";

import memberAvtar from "../../Assets/countrymeberprofile.png";
import insta from "../../Assets/Instagram.svg";
import linkdin from "../../Assets/Linkedin.svg";
import tiwtter from "../../Assets/Twitter.svg";
import postsactive from "../../Assets/Posts - Inactive.svg";
import Details from "../../Assets/Details - Inactive.svg";
import ActivePost from "./ActivePost";
import Directorysocial from "./Directorysocial";
import { useLocation } from "react-router-dom";

import Mp from "../../Assets/MP.png";
import Democratic from "../../Assets/Democratic-Alliance.png";
import { GetUserProfileService } from "../../Utils/services";


const Partymeberportal = () => {
  const [profile, setprofileId] = useState({});
  const [isActive, setActive] = useState(false);
  const [isActives, setActives] = useState(false);
  const [profileInfo, setprofileInfo] = useState({});
  const location = useLocation();

  const toggleClass = () => {
    setActive(!isActive);
    setActives(!isActives);
  };
  const toggleClassdtails = () => {
    setActives(!isActives);
    setActive(!isActive);
  };



  useEffect(() => {
    setprofileId(location.state.id);
    getDirectoryServiceApiData(profile._id);
  }, []);
  console.log(profile, "profile");







  const getDirectoryServiceApiData = async (id) => {
    try {
      const result = await GetUserProfileService({
        id: id,
      });
      console.log(result.res.profile, "profileparty");
      setprofileInfo(result.res.profile);
    } catch (error) {
      console.log(error);
    }
  };



  return (
    <div>
      <div className="memberProfileborder"></div>
      <div className="membercontent px-20">
        <Grid container>
          <Grid item xs={6}>
            <div className="memberAvtar ">
              <img src={memberAvtar} alt="" />
            </div>
            <div className="membername">
              <h2>{profileInfo.name}</h2>
            </div>
            <div className="memberposition">
              <div className=" pr-10 py-4">
                <h3> {profileInfo.party}</h3>
              </div>
              <div className="my-4">
                <hr />
              </div>
              <div className="memberLoction">
                <h3>{profileInfo.country}</h3>
              </div>
              <div className="memeberSocialmedia mt-8 flex">
                <div
                  className={`memberpost flex items-center w-1/2 h-18 justify-center py-2 ${
                    isActive ? "Postactive" : null
                  }`}
                  onClick={toggleClass}
                >
                  <div className="postavtar">
                    <img src={postsactive} alt="" />
                  </div>
                  <div className="posts ml-4">
                    <p>Posts</p>
                  </div>
                </div>
                <div
                  className={`memberdetail flex items-center w-1/2 h-18 justify-center py-2 ${
                    isActives ? null : "Detailsactive"
                  } `}
                  onClick={toggleClassdtails}
                >
                  <div className="postavtar">
                    <img src={Details} alt="" />
                  </div>
                  <div className="posts ml-4">
                    <p>Details</p>
                  </div>
                </div>
              </div>

              <div className="mt-4">
                {isActives ? (
                  <ActivePost
                    badgeicon={profileInfo.partyImage}
                    img={Democratic}
                    heading={profileInfo.party || "Democratic Alliance "}
                    badgetitle={profileInfo.badge || "Member of Parliament"}
                    badgeData={profileInfo.badge}
                    Expertise={profileInfo.expertise}
                    ProfileDetailsData ={profileInfo}
                    isInternational ={profileInfo.isInternational}
                    
                  />
                ) : (
                  <Directorysocial  usernewId ={profile._id} />
                )}
              </div>
            </div>
          </Grid>
          <Grid item xs={1}>
            {" "}
          </Grid>
          <Grid item xs={5}>
            <div className="BioCard mt-32">
              <div className="Bioboder"></div>
              <div className="bio ml-6 mt-12 mb-5">
                <h3>Bio</h3>
              </div>
              <div className="mx-6 BioDetails">
                <p>
                 
                  {profileInfo.bio}
                </p>
              </div>
              <div className="socialmedia ml-6 mt-4 mb-12">
                <ul className="flex items-center">
                  <li>
                    <img src={tiwtter} alt="" />
                  </li>
                  <li className="ml-4">
                    <img src={insta} alt="" />
                  </li>
                  <li className="ml-4">
                    <img src={linkdin} alt="" />
                  </li>
                </ul>
              </div>

              <div className="BioCardfooter ">
                <p>
                  {" "}
                  Contact: <span> {profileInfo.email ||"jason@liberal-international.org"} </span>
                </p>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Partymeberportal;
