import React, { useEffect, useState } from "react";
import Directoryheader from "./Directoryheader";
import Grid from "@mui/material/Grid";
import Afganistan from "../../Assets/afganistan.svg";
import Aisa from "../../Assets/worldCountryAisa.svg";
import Country from "./CountryData";

import jhon from "../../Assets/john-steenhuisen.png";
import cross from "../../Assets/cross.svg";
import { useHistory, useLocation } from "react-router";
import worldmap from "../../Assets/WorldMap.svg";
import dumyDp from "../../Assets/DummyDp.png"
import { BASE_URL_IMG } from "../../Utils/services";


const DirectoryRegion = () => {
  const [data, setdata] = useState();
  const [datacountry, setdatacountry] = useState("");
  const location = useLocation();
  const [bgColorByRegion, setBgColorByRegion] = useState("red");

  const RanderProfile = (type) => {
    console.log(type, "typeid");
    history.push({
      pathname: "/Directory/expertise/member/profile",
      state: { id: type },
    });
  };

  useEffect(() => {
    const { id, country } = location.state;
    console.log(id, "region data");
    if(country === "Africa"){
      setBgColorByRegion("#469025")
    }
    else if(country === "Asia"){
      setBgColorByRegion("#f3007b")
    }
    else if(country === "Europe"){
      setBgColorByRegion("#48A4DA")
    }
    else if(country === "Latin America"){
      setBgColorByRegion("#9900CC")
    }
    else if(country === "Middle East & North Africa"){
      setBgColorByRegion("#FFDF1B")
    }
    else if(country === "North America"){
      setBgColorByRegion("#CC3300")

    }
    else if(country === "Worldwide"){
      setBgColorByRegion("#184183")
    }
    else if(country === "Oceania"){
      setBgColorByRegion("#00008B")
    }
    setdata(id);
    setdatacountry(country);
  }, []);

  const history = useHistory();
  const handleClick = () => {
    history.push("/Directory");
  };

  return (
    <>
      <div>
        <div>
          <div className="content">
            <div>
              <Directoryheader />
            </div>
            <div
              className="mt-8 flex items-center expertise "
              onClick={handleClick}
            >
              <p>{datacountry}</p>
              <span className="ml-2">
                <img src={cross} alt="" />
              </span>
            </div>

            <div className="mt-8">
              <Grid container>
                <Grid item sm={12}>
                  <Grid container spacing={2}>
                    {data &&
                      data.map((items) => {
                        return (
                          <Grid item lg={3} md={6} sm={6} xs={12}>
                            <div className="CommunityProfile" key={items.id}>
                              <div className="card">
                                <div
                                  className={`profileBackground`}
                                  style={{
                                    background: `${bgColorByRegion}`,
                                  }}
                                ></div>
                                <div className="text">
                                  <img
                                    src={items.image ? `${BASE_URL_IMG}${items.image}` : dumyDp}
                                    alt="RegionPerson"
                                    className=" rounded-full w-16 h-16 object-cover "
                                  />
                                  <h3>{items.name}</h3>
                                  <p>{items.party}</p>
                                  <p>{items.country}</p>

                                  <p
                                    className="DirectoryLink cursor-pointer"
                                    onClick={() => RanderProfile(items._id)}
                                  >
                                    View
                                  </p>
                                </div>
                              </div>
                            </div>
                          </Grid>
                        );
                      })}
                  </Grid>
                </Grid>
           {/*      <Grid item xs={1}></Grid>
                <Grid item sm={4}>
                  <div className="directoryWorldWide pb-4">
                    <div className="topborder"></div>
                    <div className="Membercountry ml-6 mt-10">
                      <h1>{datacountry}</h1>
                    </div>

                    <div className="pl-6 pr-6 pt-4 pb-8 ">
                      <img src={worldmap} alt="" />
                    </div>
                    <div className="pl-6">
                      <div className="grid grid-cols-3 ">
                        <div>
                          <p>54 Countries</p>
                        </div>
                        <div>
                          <p>68 People</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid> */}
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DirectoryRegion;
