import React, { useState, useEffect } from "react";
import Contact from "../../Component/Contact";
import Grid from "@mui/material/Grid";
import Socialpost from "../../Component/Socialpost";
import Polls from "../../Component/Polls";
import Socialvideo from "../../Component/Socialvideo";
import SearchBox from "../../Component/SearchBox";
import LindaToggle from "./LindaToggle";
import SectionSlider from "./SectionSlider";
import { GetFeedService } from "../../Utils/services";
import { useLocation } from "react-router";
import searchicon from "../../Assets/searchicon.svg";
import searchbutton from "../../Assets/searchbutton.svg";

const Minhub = () => {
  const [FeedData, setFeedsData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [userDetails, setUserDetails] = useState([]);
  const [searchMemberValue, setSearchMemberValue] = useState("");
  console.log("this is feed data" , FeedData)

  const location = useLocation();
  const { minihubId, groupDetails } = location.state;
  const getDirectoryServiceApiData = async () => {
    setLoader(true);
    try {
      const result = await GetFeedService({
        "minihub": minihubId && minihubId
      });
      if (result.res.success) {
        setLoader(false);
      }
      if (result.res.posts.length > 0) {
        setFeedsData(result.res.posts);
      }
      if (result.res.userDetails.likedPosts.length > 0) {
        setUserDetails(result.res.userDetails.likedPosts);
      }
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getDirectoryServiceApiData();
  }, []);

  const renderPost = (data) => {
    let isImg = data.media.length > 0 && data.media[0].link.split(".")[data.media[0].link.split(".").length - 1];
    return (
      <>
        {
          isImg === "jpeg" || isImg === "png" || isImg === "pdf" || isImg === false ?
            <Socialpost
              postDate={data.date}
              title={data.postedBy.name}
              userId={data.postedBy.id}
              heading={data.title}
              pera={data.caption}
              userImg={data.postedBy.image}
              PostImage={data.media.length > 0 && data.media[0].link}
              location={data.location}
              _id={data._id}
              comments={data.comments}
              likes={data.likes.length & data.likes.length}
              userDetails={userDetails}
              getDirectoryServiceApiData={getDirectoryServiceApiData}
            />
            :
            <Socialvideo
              postDate={data.date}
              name={data.postedBy.name}
              userId={data.postedBy.id}
              heading={data.title}
              para={data.caption}
              userImg={data.postedBy.image}
              postVideo={data.media.length > 0 && data.media[0].link}
              location={data.location}
              _id={data._id}
              comments={data.comments}
              likes={data.likes.length & data.likes.length}
              userDetails={userDetails}
              getDirectoryServiceApiData={getDirectoryServiceApiData}
            />
        }
      </>
    );
  };

  return (
    <>
      <Contact minihubForm ={"minihub"} minihubId ={minihubId} getDirectoryServiceApiData={getDirectoryServiceApiData} />
      <Grid container>
        <Grid item xs={6}>
          <SectionSlider minihubId={minihubId} />
          <div className="DiscussionBoard mt-6">
            <h2>Discussion Board</h2>
          </div>
          <div className="disscussionbord">
            {FeedData &&
              FeedData.map((item) => {
                return (
                  <>
                    {item.isPoll ? (
                      <Polls
                        postDate={item.date}
                        question={item.question}
                        User={item.postedBy.name}
                        location={item.location}
                        choices={item.choices}
                        img={item.postedBy.image}
                        userId={item.postedBy.id}
                        length = {item.length}
                        _id={item._id}
                        getDirectoryServiceApiData={getDirectoryServiceApiData}
                      />
                    ) : (
                      renderPost(item)
                    )}
                  </>
                );
              })}
          </div>
        </Grid>
        <Grid item xs={1}>
          {" "}
        </Grid>
        <Grid item xs={5}>
          <div className="searchMarginTop" >
            <div className="SearchBox">
              <div className="bg-white shadow flex searchbarbox">
                <span className="w-auto flex justify-end items-center text-gray-500 p-2">
                  <i className="material-icons text-3xl">
                    <img src={searchicon} alt="searchicon" />
                  </i>
                </span>
                <input
                  className="w-full rounded p-2"
                  type="text"
                  placeholder="Search Members"
                  onChange={(e) => setSearchMemberValue(e.target.value)}
                />
                <button className="bg-white-400 hover:bg-white-300 rounded text-black p-2 pl-4 pr-4">
                  <span className="w-auto flex justify-end items-center text-gray-500 p-2">
                    <i className="material-icons text-3xl">
                      <img src={searchbutton} alt="searchicon" />
                    </i>
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div className="Linda2020">
            <LindaToggle searchMemberValue ={searchMemberValue} groupDetails={groupDetails} minihubId={minihubId} />
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default Minhub;
