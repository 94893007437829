import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import closeicon from '../Assets/closeicon.svg';


const style = {
  position: 'absolute',
  top: '50%',
  left: '55%',
  transform: 'translate(-50%, -50%)',
  width: 1000,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 8,
};

function BasicModal({ sendDate, showEvent, handlevalue, handleClose, showEventDetailsData }) {
  console.log(showEvent, 'showEvent')
  const [showSameDayData, setSameDayData] = React.useState(null);

  const formatDate = date => {
    const _date = new Date(date)
    const month = _date.getMonth() + 1
    const year = _date.getFullYear()
    const newdate = _date.getDate()
    return `${newdate}/${month}/${year}`
  }

  React.useEffect(() => {
    let arr = [];
    showEvent.map((data) => {
      if (formatDate(data.start) == formatDate(sendDate)) {
        console.log(true, "true Datata")
        arr.push(data)
      }
    })
    setSameDayData(arr)
  }, [showEvent, sendDate])

  return (
    <div>
      <Modal
        open={handlevalue}

        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="boxmodal">
          <Typography id="modal-modal-title" variant="h6" component="h2" className="eventModel">
            EVENT DETAILS
          </Typography>
          {
            showEventDetailsData && showEventDetailsData.map((data) => {
              return (
                <>
                  <Button onClick={handleClose} style={{ position: "absolute", top: 8, right: 0 }}  ><img src={closeicon} alt="closeicon" /></Button>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>

                  </Typography>
                  <Typography variant="subtitle2" gutterBottom component="div" className="subtitle">
                    {data.name}
                  </Typography>
                  <Typography variant="body2" gutterBottom className="timing" >
                    {data.time}
                  </Typography>
                  <Typography variant="body2" gutterBottom className="Date" >
                    {formatDate(data.start)}
                  </Typography>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>

                  </Typography>
                  <Typography variant="body2" gutterBottom className="details" >
                    {data.description}
                    <Typography variant="body2" gutterBottom className="otherlink" >
                      {data.links.map((item2, index) => {
                        return (
                          <><div key={index}>
                            <p>{item2}</p>
                          </div>
                          </>
                        )
                      })}
                    </Typography>
                  </Typography>
                </>
              )
            })
          }
          <Typography variant="body2" gutterBottom sx={{ mt: 4 }} className="EventsSameDay" >
            Events on same day
          </Typography>
          <Typography variant="body2" gutterBottom className="otherDate" >
            {formatDate(sendDate)}
          </Typography>
          <div className={`${showSameDayData && showSameDayData.length > 1 && "h-56 overflow-y-scroll bg-scroll"}`}>
            {
              showSameDayData && showSameDayData.map((data, index) => {
                return (
                  <div className="py-2">
                    <Typography variant="body2" gutterBottom component="div" className="EventsSameDays">
                      <Typography variant="body2" gutterBottom component="div" className="EventDetails" >
                        <Typography variant="subtitle2" gutterBottom component="div" className="subtitle" >
                          {data.name}
                        </Typography>
                        <Typography variant="body2" gutterBottom component="div" className="time" >
                          {data.time}
                        </Typography>
                        <Typography variant="body2" gutterBottom component="div" className="otherLink" >
                          {data.links.map((item2, index) => {
                            return (
                              <><div key={index}>
                                <p>{item2}</p>
                              </div>
                              </>
                            )
                          })}
                        </Typography>
                      </Typography>
                    </Typography>
                  </div>

                )
              })
            }
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default BasicModal;