import React, { useEffect, useState } from 'react'
import OtpInput from 'react-otp-input';
import img from "../../Assets/LoginImage.png";
import footerimg from "../../Assets/loginfooterlogo.png"
import Loader from "../Loader"
import { useLocation } from 'react-router';
import { otpVerification } from '../../Utils/services';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';


const OtpVerification = () => {
    const [otp, setOtp] = useState();
    const [loader, setLoader] = useState(false);
    const location = useLocation();
    const [emailValue, setEmailValue] = useState("");
    const [verificationCode, setVerificationCode] = useState("");

    const history = useHistory();

    useEffect(() => {
        if (location.state !== undefined) {
            const { verification_key, email } = location.state;
            setEmailValue(email)
            setVerificationCode(verification_key)
            console.log(verification_key, email, "verification_key, email,")
        }
        if(location.state === undefined){
            history.push("/login")
        }
    }, []);

    const handleOtpChange = (e) => {
        console.log(e)
        setOtp(e);
    }
    const handleOtpSubmit = async (e) => {
        setLoader(true);
        e.preventDefault();
        try {
            const result = await otpVerification({
                "email": emailValue,
                "verification_key": verificationCode,
                "otp": parseInt(otp)
            });
            if (result.res.success) {
                setLoader(false)
                toast.success(result.res.message)
                history.push({
                    pathname: "/changepassword",
                    state: {
                        email: result.res.data.email,
                        token: result.res.data.token
                    }
                })
            }
        } catch (error) {
            setLoader(false);
            toast.error(error.message);
        }
    }

    return (
        <div className='login'>
            {/*     <div className='container' >
                <div className='Forget flex justify-center items-center flex-col  ' >
                    <img src={img} alt="" />
                    <h2>VERIFY OTP</h2>
                </div>
                <form onSubmit={handleOtpSubmit}>
                    <div className="mt-2 flex otpSection justify-center items-center">
                        <OtpInput
                            value={otp}
                            onChange={handleOtpChange}
                            numInputs={6}
                            isInputNum={true}
                        />
                    </div>
                    <div className="mt-6 mx-0 text-center">
                        <input
                            type="submit"
                            value="Verify OTP"
                            className="py-3 cursor-pointer btnn hover:bg-green-600  text-white text-center "
                        />
                        <Loader size={40} loader={loader} />
                    </div>
                </form>


            </div> */}

            <div className="loginBanner">
                <div className='main  flex justify-center items-center flex-col  ' >
                    <img src={img} alt="" />
                    <h2>VERIFY OTP</h2>
                </div>
                <form onSubmit={handleOtpSubmit}>
                    <div className="mt-2 flex otpSection justify-center items-center">
                        <OtpInput
                            value={otp}
                            onChange={handleOtpChange}
                            numInputs={6}
                            isInputNum={true}
                        />
                    </div>
                    <div className="mt-6 mx-0 text-center">
                        <input
                            type="submit"
                            value="Verify OTP"
                            className="py-3 cursor-pointer btnn hover:bg-green-600  text-white text-center "
                        />
                        <Loader size={40} loader={loader} />
                    </div>
                </form>


            </div>

            <div className="loginfooter">
                <img src={footerimg} alt="" />

            </div>
        </div>
    )
}

export default OtpVerification
