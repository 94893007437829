import { Box, Button, Modal } from '@mui/material';
import React, { useState } from 'react'
import Loader from "../Component/Loader"
import closeicon from '../Assets/closeicon.svg';
import CommentDeleteConfirmation from './CommentDeleteConfirmation';
import EditCommentModal from './EditCommentModal';


const style = {
    position: 'absolute',
    top: '50%',
    left: '55%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 8,
};


const CommentConfirmationModal = ({
    handleOpenEditCommentModal,
    handleDeleteModal,
    showEditDeleteModal,
    closeShowEditDeleteModal,
}) => {

    return (
        <div>
            <Modal
                open={showEditDeleteModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className="boxmodal">
                    <Button onClick={closeShowEditDeleteModal} style={{ position: "absolute", top: 8, right: 0 }}  ><img src={closeicon} alt="closeicon" /></Button>
                    <div>
                        <div className="grid grid-cols-4 gap-4">
                            <button className="flex flex-col items-center" onClick={handleOpenEditCommentModal}>
                                <div className="w-8 h-8 flex justify-center items-center rounded-full border border-black">
                                    <i class="fa fa-edit" aria-hidden="true"></i>
                                </div>
                                <span>Edit</span>
                            </button>
                            <button className="flex flex-col items-center" onClick={handleDeleteModal} >
                                <div className="w-8 h-8 flex justify-center items-center rounded-full border border-black">
                                    <i class="fa fa-trash-o" aria-hidden="true"></i>
                                </div>
                                <span>Delete</span>
                            </button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    )
}

export default CommentConfirmationModal