import React from 'react'
import northAmerica from "../Assets/worldNorthAmerica.svg"
import ReactTooltip from "react-tooltip";
import { BASE_URL_IMG } from '../Utils/services';

const MapDetails = ({closeDeleteModal,country,member,regionDetails}) => {
  return (

    <ReactTooltip id="registerTip" place="top" effect="solid" className='tool' data-arrow-color="#fff"  data-border="false" data-event-off="click">
      <div className=' mapDetails' onClick={closeDeleteModal}  >
      <div className='flex  justify-between items-center'>
        <img src={regionDetails && `${BASE_URL_IMG}${regionDetails.image}`} alt="" className='mr-12'/>
        <h1>{regionDetails && regionDetails.name}</h1>
      </div>
      <div className='flex  justify-between items-center '>
        <p>{country} Countries</p>
        <p>{member} People</p>
      </div>
      
    </div>
   
  </ReactTooltip>
  
  )
}

export default MapDetails