import React, { useState } from 'react'
import Events from '../Pages/Home/Events'
import searchicon from "../Assets/searchicon.svg";
import searchbutton from "../Assets/searchbutton.svg";
import { getEvent } from '../Utils/services';


const EventCalender= () => {
  const [loader, setLoader] = useState(false);
  const [searchPostValue, setSearchPostValue] = useState("");
  const [eventAllData, setEventAllData] = useState([]);

  const getEventApiData = async (year, month) => {
    try {
      const result = await getEvent({
        year,
        month,
      });
      if (result.res.data.length > 0) {
        setEventAllData(result.res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };



    const handleSearchPost = (e) => {
        setLoader(false);
        setSearchPostValue(e.target.value);
      };
  return (
    <>
    {/*   <div className="Searchevents">
                <div>
                   <div className="SearchBox">
                      <div className="bg-white shadow flex searchbarbox">
                       <span className="w-auto flex justify-end items-center text-gray-500 p-2">
                         <i className="material-icons text-3xl">
                           <img src={searchicon} alt="searchicon" />
                         </i>
                      </span>
                      <input
                        className="w-full rounded p-2"
                        type="text"
                        placeholder="Search Posts"
                        onChange={handleSearchPost}
                      />
                      <button className="bg-white-400 hover:bg-white-300 rounded text-black p-2 pl-4 pr-4">
                        <span className="w-auto flex justify-end items-center text-gray-500 p-2">
                          <i className="material-icons text-3xl">
                            <img src={searchbutton} alt="searchicon" />
                          </i>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="ToggleEvents">
                  <Events
                    eventAllData={eventAllData}
                    getEventApiData={getEventApiData}
                  />
                </div>
          </div>  */}
          <div className='events-calender-page'>
          <Events
                    eventAllData={eventAllData}
                    getEventApiData={getEventApiData}
            />

          </div>
          
         
    </>
  )
}

export default EventCalender