import React, { useContext, useEffect, useState } from 'react'
import OtpInput from 'react-otp-input';
import img from "../../Assets/LoginImage.png";
import footerimg from "../../Assets/loginfooterlogo.png"
import Loader from "../Loader"
import { useLocation } from 'react-router';
import { otpVerificationLogin } from '../../Utils/services';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import AuthenContext from '../../Utils/AuthContext';


const LoginOtpVerification = () => {
    const [otp, setOtp] = useState();
    const [loader, setLoader] = useState(false);
    const location = useLocation();
    const [emailValue, setEmailValue] = useState("");
    const [verificationCode, setVerificationCode] = useState("");
    const authCtx = useContext(AuthenContext);


    const history = useHistory();

    useEffect(() => {
        if (location.state !== undefined) {
            const { fcmToken, email } = location.state;
            setEmailValue(email)
            setVerificationCode(fcmToken)
            console.log(fcmToken, email, "fcmToken, email,")
        }
        if (location.state === undefined) {
            history.push("/login")
        }
    }, []);

    const handleOtpChange = (e) => {
        console.log(e)
        setOtp(e);
    }
    const handleOtpSubmit = async (e) => {
        setLoader(true);
        e.preventDefault();
        try {
            const result = await otpVerificationLogin({
                "email": emailValue,
                "fcmToken": verificationCode,
                "otp": otp
            });
            const expirationTime = new Date(new Date().getTime() + (parseInt(result.res.data.refreshExpiry) * (3600 * 1000)))
            authCtx.login(result.res.data.accessToken, result.res.data.refreshToken, result.res.data.isSuperAdmin, expirationTime.toISOString());
            localStorage.setItem("loginUserId", result.res.data.user._id)
            localStorage.setItem("loginUserEmail", result.res.data.user.email)
            localStorage.setItem("refreshExpiry", result.res.data.refreshExpiry)
            localStorage.setItem("userImage", result.res.data.user.image)
            if (result.res.success) {
                setLoader(false)
                toast.success(result.res.message)
                history.push("/");
            }
        } catch (error) {
            setLoader(false);
            toast.error(error.message);
        }
    }

    return (
        <div className='login'>
            <div className="loginBanner">
                <div className='main  flex justify-center items-center flex-col  ' >
                    <img src={img} alt="" />
                    <h2>VERIFY OTP</h2>
                </div>
                <form onSubmit={handleOtpSubmit}>
                    <div className="mt-2 flex otpSection justify-center items-center">
                        <OtpInput
                            value={otp}
                            onChange={handleOtpChange}
                            numInputs={4}
                            isInputNum={true}
                        />
                    </div>
                    <div className="mt-6 mx-0 text-center">
                        <input
                            type="submit"
                            value="Verify OTP"
                            className="py-3 cursor-pointer btnn hover:bg-green-600  text-white text-center "
                        />
                        <Loader size={40} loader={loader} />
                    </div>
                </form>


            </div>

            <div className="loginfooter">
                <img src={footerimg} alt="" />

            </div>
        </div>
    )
}

export default LoginOtpVerification
