import React from 'react'
import member from '../../Assets/member.svg'

const Member = ({groupDetails}) => {
    return (
        <div className="flex items=center member">
            <p>{groupDetails && groupDetails.members} Members</p>
            <img src={member} alt="member" />
        </div>
    )
}

export default Member;
