import React,{useState,useEffect} from "react";
import profile from "../Assets/profile.png";
import addPhoto from "../Assets/addphoto.svg";
import addvideo from "../Assets/addvideo.svg";
import attachfile from "../Assets/attacfile.svg";
import youtube from "../Assets/youtube.svg";
import addpoll from "../Assets/addpoll.svg";
import { Grid } from "@mui/material";
import Modalmedia from "../Modals/WritePostModal-Media";
import AddPollForm from "../Modals/AddPollForm";
import SimpleAccordion from "../Pages/Home/AccordianPost";
import { BASE_URL_IMG, GetFeedService } from "../Utils/services";



const Contact = ({hubForm, hubId, minihubForm, minihubId , getDirectoryServiceApiData}) => {

  const [ON, setON] = React.useState(false);
  const [showPhoto, setShowPhoto] = useState(false)
  const [showVideo, setShowVideo] = useState(false)
  const [showFiles, setShowFiles] = useState(false)
  const [showLinks, setShowLinks] = useState(false)
  const [loader, setLoader] = useState(true);
  const [FeedData, setFeedsData] = useState([]);
  const [eventAllData, setEventAllData] = useState([]);
  const [userDetails, setUserDetails] = useState([]);
  const [showPollForm, setShowPollForm] = useState(false);
  const [isPollButton, setIsPollButton] = useState(false)

  const profileImage = localStorage.getItem("userImage");

  const handleOpen = () => {
    setON(true);
  };
  const handleClose = () => {
    setON(false);
  };
  const handlePollClose = () => {
    setShowPollForm(false)
  }

  const handlePhotoshow = () => {
    setIsPollButton(false)
    setON(true);
    setShowPhoto(true)
    setShowVideo(false)
    setShowFiles(false)
    setShowLinks(false)
  }
  const handleVideoshow = () => {
    setIsPollButton(false)
    setON(true);
    setShowVideo(true)
    setShowPhoto(false)
    setShowFiles(false)
    setShowLinks(false)
  }
  const handleFilesshow = () => {
    setIsPollButton(false)
    setON(true);
    setShowFiles(true)
    setShowPhoto(false)
    setShowVideo(false)
    setShowLinks(false)
  }
  const handleLinksshow = () => {
    setIsPollButton(false)
    setON(true);
    setShowLinks(true)
    setShowPhoto(false)
    setShowVideo(false)
    setShowFiles(false)
  }

  


  // const getDirectoryServiceApiData = async () => {
  //   setLoader(true)
  //   try {
  //     const result = await GetFeedService();
  //     if(result.res.success){
  //       setLoader(false)
  //     }
  //     if (result.res.posts.length > 0) {
  //       setFeedsData(result.res.posts);
  //     }
  //     if (result.res.userDetails.likedPosts.length > 0) {
  //       setUserDetails(result.res.userDetails.likedPosts);
  //     }
  //   } catch (error) {
  //     setLoader(false)
  //     console.log(error);
  //   }
  // };




  // useEffect(() => {
  //   getDirectoryServiceApiData();
  // }, []);

  const HandlePoll = () => {
    setIsPollButton(true)
    setShowPollForm(true);
  }

  // const r
  


 


  return (
    <div className="bg-white p-6 contact">
    <div className="forms">
      <Grid container>
        <Grid item xs={1}>
          <div className="fromavtar">
            <div className="image">
              <img src={`${BASE_URL_IMG}${profileImage}?${new Date().getTime()}`} alt="" />
            </div>
          </div>
        </Grid>
        <Grid item xs={10}>
          <form action="">
            <input type="text" placeholder="Write Post" onInput={handleOpen} onClick={handleOpen} />
          </form>
        </Grid>
      </Grid>
    </div>
    <Grid container>
      <Grid item xs={1}></Grid>
      <Grid item xs={10}>
        <div className="Home-Social-Icon">
          <div className="social">
            <ul className="social-links">
              <li className="social-link" onClick={handlePhotoshow}>
                <div className="flex items-center mr-8 ">
                  <img src={addPhoto} alt="" />
                  <span className="ml-2">Add Photos</span>
                </div>
              </li>
              <li className="social-link" onClick={handleVideoshow}>
                <div className="flex items-center mr-8">
                  <img src={addvideo} alt="" />
                  <span className="ml-2">Add Videos</span>
                </div>
              </li>

              <li className="social-link" onClick={handleFilesshow}>
                <div className="flex items-center  mr-8">
                  {" "}
                  <img src={attachfile} alt="" />{" "}
                  <span className="ml-2">Attach Files</span>{" "}
                </div>
              </li>
              <li className="social-link" onClick={handleLinksshow}>
                <div className="flex items-center  mr-8">
                  {" "}
                  <img src={youtube} alt="" />
                  <span className="ml-2">YouTube/Vimeo Link</span>
                </div>
              </li>
              <li className="social-link" onClick={HandlePoll}>
                <div className="flex items-center  mr-8">
                  {" "}
                  <img src={addpoll} alt="" />{" "}
                  <span className="ml-2">Add Poll</span>{" "}
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="Home_Accordian mt-2 ml-3">
          <SimpleAccordion handleOpens={handleOpen} />
        </div>
      </Grid>
    </Grid>

    <Modalmedia
        getDirectoryServiceApiData={getDirectoryServiceApiData}
        handlevalue={ON}
        handleClose={handleClose}
        showPhoto={showPhoto}
        showVideo={showVideo}
        showFiles={showFiles}
        showLinks={showLinks} 
        isPollButton={isPollButton}
        HandlePoll={HandlePoll}
        handlePollClose={handlePollClose}
        hubForm ={hubForm}
        minihubForm ={minihubForm}
        minihubId ={minihubId}
        hubId ={hubId}
      />
      <AddPollForm
        getDirectoryServiceApiData={getDirectoryServiceApiData}
        handleClose={handleClose}
        isPollButton={isPollButton}
        HandlePoll={showPollForm}
        handlePollClose={handlePollClose}
        handleOpen={handleOpen}
      />
  </div>
  );
};

export default Contact;
