import React, { useEffect, useState } from "react";

import Grid from "@mui/material/Grid";
import Socialpost from "../../Component/Socialpost";
import Polls from "../../Component/Polls";
import Socialvideo from "../../Component/Socialvideo";
import { GetFeedService } from "../../Utils/services";

const Directorysocial = ({ usernewId }) => {

  const [FeedData, setFeedsData] = useState([]);
  const [newFeedData, setNewFeedData] = useState([]);
  const [userDetails, setUserDetails] = useState([]);

  const getDirectoryServiceApiData = async () => {
    try {
      const result = await GetFeedService({
        userId: usernewId,
      });

      setFeedsData(result.res.posts);
      if (result.res.userDetails.likedPosts.length > 0) {
        setUserDetails(result.res.userDetails.likedPosts);
      }

    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getDirectoryServiceApiData();
  }, []);

  useEffect(() => {
    let _data = FeedData.filter((data) => {
      return data.postedBy.id === usernewId
    })
    setNewFeedData(_data)
  }, [FeedData])

  console.log(newFeedData, "newFeedData")

  const renderPost = (data) => {
    let isImg = data.media.length > 0 && data.media[0].link.split(".")[data.media[0].link.split(".").length - 1];
    return (
      <>
        {
          isImg === "jpeg" || isImg === "png" || isImg === "pdf" || isImg === false ?
            <Socialpost
              title={data.postedBy.name}
              userId={data.postedBy.id}
              heading={data.title}
              pera={data.caption}
              userImg={data.postedBy.image}
              PostImage={data.media.length > 0 && data.media[0].link}
              location={data.location}
              _id={data._id}
              comments={data.comments}
              likes={data.likes.length & data.likes.length}
              userDetails={userDetails}
              getDirectoryServiceApiData={getDirectoryServiceApiData}
            />
            :
            <Socialvideo
              name={data.postedBy.name}
              userId={data.postedBy.id}
              heading={data.title}
              para={data.caption}
              userImg={data.postedBy.image}
              postVideo={data.media.length > 0 && data.media[0].link}
              location={data.location}
              _id={data._id}
              comments={data.comments}
              likes={data.likes.length & data.likes.length}
              userDetails={userDetails}
              getDirectoryServiceApiData={getDirectoryServiceApiData}
            />
        }
      </>
    );
  };

  return (
    <div>
      <div className="Socialpost">
        <Grid container>
          <Grid item xs={12}>
            {newFeedData && newFeedData.map((item) => {
              return (
                <>
                  {item.isPoll ? (
                    <Polls
                      question={item.question}
                      User={item.postedBy.name}
                      location={item.location}
                      choices={item.choices}
                      length = {item.length}
                      img={item.postedBy.image}
                    />
                  ) : (
                    renderPost(item)
                  )}
                </>
              );
            })}
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Directorysocial;
