import React, { useEffect, useState } from "react";
import profile from "../Assets/profile.png";
import addPhoto from "../Assets/addphoto.svg";
import addvideo from "../Assets/addvideo.svg";
import attachfile from "../Assets/attacfile.svg";
import youtube from "../Assets/youtube.svg";
import addpoll from "../Assets/addpoll.svg";
import Grid from "@mui/material/Grid";
import Socialpost from "../Component/Socialpost";
import Polls from "../Component/Polls";
import Socialvideo from "../Component/Socialvideo";
import SearchBox from "../Component/SearchBox";
import Events from "./Home/Events";
import SimpleAccordion from "../Pages/Home/AccordianPost";
import { BASE_URL_IMG, getEvent, GetFeedService } from "../Utils/services";
import Modalmedia from "../Modals/WritePostModal-Media";
import Loader from "../Component/Loader";
import AddPollForm from "../Modals/AddPollForm";
import searchicon from "../Assets/searchicon.svg";
import searchbutton from "../Assets/searchbutton.svg";
import { Pagination } from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";
import Socialtext from "../Component/Socialtext";

const Home = () => {
  const [ON, setON] = React.useState(false);
  const [showPhoto, setShowPhoto] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const [showFiles, setShowFiles] = useState(false);
  const [showLinks, setShowLinks] = useState(false);
  const [loader, setLoader] = useState(false);
  const [FeedData, setFeedsData] = useState([]);
  const [eventAllData, setEventAllData] = useState([]);
  const [userDetails, setUserDetails] = useState([]);
  const [showPollForm, setShowPollForm] = useState(false);
  const [isPollButton, setIsPollButton] = useState(false);
  const [searchPostValue, setSearchPostValue] = useState("");
  const [page, setPage] = useState(1);
  const [postsPerPage] = useState(10);
  const [totalPageToShow, setTotalPageToShow] = useState(1);
  const [hasMore, sethasMore] = useState(true);
  const loginUserId = localStorage.getItem("loginUserId");


  console.log(FeedData , " this is feed data ######")

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const getEventApiData = async (year, month) => {
    try {
      const result = await getEvent({
        year,
        month,
      });
      if (result.res.data.length > 0) {
        setEventAllData(result.res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleOpen = () => {
    setON(true);
    setShowPhoto(true);
  };
  const handleClose = () => {
    setON(false);
  };
  const handlePollClose = () => {
    setShowPollForm(false);
  };

  const handlePhotoshow = () => {
    setIsPollButton(false);
    setON(true);
    setShowPhoto(true);
    setShowVideo(false);
    setShowFiles(false);
    setShowLinks(false);
  };
  const handleVideoshow = () => {
    setIsPollButton(false);
    setON(true);
    setShowVideo(true);
    setShowPhoto(false);
    setShowFiles(false);
    setShowLinks(false);
  };
  const handleFilesshow = () => {
    setIsPollButton(false);
    setON(true);
    setShowFiles(true);
    setShowPhoto(false);
    setShowVideo(false);
    setShowLinks(false);
  };
  const handleLinksshow = () => {
    setIsPollButton(false);
    setON(true);
    setShowLinks(true);
    setShowPhoto(false);
    setShowVideo(false);
    setShowFiles(false);
  };

  const getDirectoryServiceApiData = async () => {
    setLoader(true);
    try {
      const result = await GetFeedService({
        page: page,
        size: 10,
      });
      setLoader(false);
      if (result.res.success) {
        setLoader(false);
      }
      if (result.res.posts.length > 0) {
        setFeedsData(result.res.posts);
        setTotalPageToShow(Math.ceil(result.res.posts.length / postsPerPage));
      }
      if (result.res.userDetails.likedPosts.length > 0) {
        setUserDetails(result.res.userDetails.likedPosts);
      }
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getDirectoryServiceApiData();
  }, [page]);

  const fetchMoreData = async () => {
    if (window.scrollY > 500) {
      const result = await GetFeedService({
        page: page + 1,
        size: FeedData.length,
      });
      const _data = FeedData.concat(result.res.posts);
      setTimeout(() => {
        setFeedsData(_data);
      }, 500);
      if (_data.length === FeedData.length) {
        sethasMore(false);
      }
    }
  };

  const HandlePoll = () => {
    setIsPollButton(true);
    setShowPollForm(true);
  };

  const handleSearchPost = (e) => {
    setLoader(false);
    setSearchPostValue(e.target.value);
  };

  const handleTextpost = ({ data, isImg }) => {
    return (
      <>
        {isImg === "jpeg" || isImg === "png" || isImg === "pdf" ? (
          <Socialpost
            postDate={data.date}
            title={data.postedBy.name}
            userId={data.postedBy.id}
            heading={data.title}
            pera={data.caption}
            userImg={data.postedBy.image}
            PostImage={
              isImg !== "pdf" && data.media.length > 0 && data.media[0].link
            }
            postPdfFile={
              isImg === "pdf" && data.media.length > 0 && data.media[0].link
            }
            location={data.location}
            _id={data._id}
            comments={data.comments}
            likes={data.likes.length & data.likes.length}
            likesData={data.likes}
            userDetails={userDetails}
            getDirectoryServiceApiData={getDirectoryServiceApiData}
          />
        ) : (
          <Socialtext
            postDate={data.date}
            title={data.postedBy.name}
            userId={data.postedBy.id}
            heading={data.title}
            pera={data.caption}
            userImg={data.postedBy.image}
            PostImage={
              isImg !== "pdf" && data.media.length > 0 && data.media[0].link
            }
            postPdfFile={
              isImg === "pdf" && data.media.length > 0 && data.media[0].link
            }
            location={data.location}
            _id={data._id}
            comments={data.comments}
            likes={data.likes.length & data.likes.length}
            likesData={data.likes}
            userDetails={userDetails}
            getDirectoryServiceApiData={getDirectoryServiceApiData}
          />
        )}
      </>
    );
  };

  const renderPost = (data) => {
    let isImg =
      data.media.length > 0 &&
      data.media[0].link && data.media[0].link.split(".")[data.media[0].link.split(".").length - 1];
    return (
      <>
        {isImg === "jpeg" ||
          isImg === "png" ||
          isImg === "pdf" ||
          isImg === false ? (
          /*  <Socialpost
              title={data.postedBy.name}
              userId={data.postedBy.id}
              heading={data.title}
              pera={data.caption}
              userImg={data.postedBy.image}
              PostImage={isImg !== "pdf" && data.media.length > 0 && data.media[0].link}
              postPdfFile={isImg === "pdf" && data.media.length > 0 && data.media[0].link}
              location={data.location}
              _id={data._id}
              comments={data.comments}
              likes={data.likes.length & data.likes.length}
              userDetails={userDetails}
              getDirectoryServiceApiData={getDirectoryServiceApiData}
            /> */
          handleTextpost({ data, isImg })
        ) : (
          <Socialvideo
            postDate={data.date}
            name={data.postedBy.name}
            userId={data.postedBy.id}
            heading={data.title}
            para={data.caption}
            userImg={data.postedBy.image}
            postVideo={data.media.length > 0 && data.media[0].link}
            location={data.location}
            _id={data._id}
            comments={data.comments}
            likes={data.likes.length & data.likes.length}
            likesData={data.likes}
            userDetails={userDetails}
            getDirectoryServiceApiData={getDirectoryServiceApiData}
          />
        )}
      </>
    );
  };

  return (
    <div className="content">
      <div className="Homepage">
        <Loader size={100} loader={loader} />
        <div className="bg-white p-6 contact">
          <div className="forms">
            <Grid container>
              <Grid item xs={1}>
                <div className="fromavtar">
                  <div className="image">
                    <img src={`${BASE_URL_IMG}/user/${loginUserId}/${loginUserId}.jpeg?${new Date().getTime()}`} alt="" />
                  </div>
                </div>
              </Grid>
              <Grid item xs={10}>
                <form action="">
                  <input
                    type="text"
                    placeholder="Write Post"
                    onInput={handleOpen}
                    onClick={handleOpen}
                  />
                </form>
              </Grid>
            </Grid>
          </div>
          <Grid container>
            <Grid item xs={1}></Grid>
            <Grid item xs={10}>
              <div className="Home-Social-Icon">
                <div className="social">
                  <ul className="social-links">
                    <li className="social-link" onClick={handlePhotoshow}>
                      <div className="flex items-center mr-8 ">
                        <img src={addPhoto} alt="" />
                        <span className="ml-2">Add Photos</span>
                      </div>
                    </li>
                    <li className="social-link" onClick={handleVideoshow}>
                      <div className="flex items-center mr-8">
                        <img src={addvideo} alt="" />
                        <span className="ml-2">Add Videos</span>
                      </div>
                    </li>

                    <li className="social-link" onClick={handleFilesshow}>
                      <div className="flex items-center  mr-8">
                        {" "}
                        <img src={attachfile} alt="" />{" "}
                        <span className="ml-2">Attach Files</span>{" "}
                      </div>
                    </li>
                    <li className="social-link" onClick={handleLinksshow}>
                      <div className="flex items-center  mr-8">
                        {" "}
                        <img src={youtube} alt="" />
                        <span className="ml-2">YouTube/Vimeo Link</span>
                      </div>
                    </li>
                    <li className="social-link" onClick={HandlePoll}>
                      <div className="flex items-center  mr-8">
                        {" "}
                        <img src={addpoll} alt="" />{" "}
                        <span className="ml-2">Add Poll</span>{" "}
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="Home_Accordian mt-2 ml-3">
                <SimpleAccordion handleOpens={handleOpen} handlePhotoshow ={handlePhotoshow} handleVideoshow ={handleVideoshow} handleFilesshow ={handleFilesshow} handleLinksshow ={handleLinksshow} HandlePoll ={HandlePoll} />
              </div>
            </Grid>
          </Grid>
        </div>
        <div className="flex justify-between items-center mt-6">
          {/* <Pagination
            count={10}
            variant="outlined"
            shape="rounded"
            className="flex items-center"
            onChange={handlePageChange}
            page={page}
          /> */}
        </div>
        <div className="Socialpost">
          <Grid container>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <InfiniteScroll
                dataLength={FeedData.length}
                next={fetchMoreData}
                hasMore={hasMore}
                loader={
                  <span className="flex justify-center items-center">
                    Loader...
                  </span>
                }
                endMessage={
                  <span className="flex justify-center items-center">
                    No More Data
                  </span>
                }
              >
                {FeedData &&
                  FeedData.filter((val) => {
                    if (searchPostValue == "") {
                      return val;
                    } else if (
                      val.location &&
                      val.location
                        .toLowerCase()
                        .includes(searchPostValue.toLowerCase())
                    ) {
                      return val;
                    } else if (
                      val.title &&
                      val.title
                        .toLowerCase()
                        .includes(searchPostValue.toLowerCase())
                    ) {
                      return val;
                    } else if (
                      val.caption &&
                      val.caption
                        .toLowerCase()
                        .includes(searchPostValue.toLowerCase())
                    ) {
                      return val;
                    } else if (
                      val.hub &&
                      val.hub
                        .toLowerCase()
                        .includes(searchPostValue.toLowerCase())
                    ) {
                      return val;
                    }
                  }).map((item) => {
                    return (
                      <>
                        {item.isPoll ? (
                          <Polls
                            postDate={item.date}
                            question={item.question}
                            User={item.postedBy.name}
                            location={item.location}
                            choices={item.choices}
                            img={item.postedBy.image}
                            userId={item.postedBy.id}
                            _id={item._id}
                            length={item.length}
                            getDirectoryServiceApiData={
                              getDirectoryServiceApiData
                            }
                          />
                        ) : (
                          renderPost(item)
                        )}
                      </>
                    );
                  })}
              </InfiniteScroll>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={5}>
              <div className="Searchevents">
                <div>
                  <div className="SearchBox">
                    <div className="bg-white shadow flex searchbarbox">
                      <span className="w-auto flex justify-end items-center text-gray-500 p-2">
                        <i className="material-icons text-3xl">
                          <img src={searchicon} alt="searchicon" />
                        </i>
                      </span>
                      <input
                        className="w-full rounded p-2"
                        type="text"
                        placeholder="Search Posts"
                        onChange={handleSearchPost}
                      />
                      <button className="bg-white-400 hover:bg-white-300 rounded text-black p-2 pl-4 pr-4">
                        <span className="w-auto flex justify-end items-center text-gray-500 p-2">
                          <i className="material-icons text-3xl">
                            <img src={searchbutton} alt="searchicon" />
                          </i>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="ToggleEvents">
                  <Events
                    eventAllData={eventAllData}
                    getEventApiData={getEventApiData}
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      <Modalmedia
        getDirectoryServiceApiData={getDirectoryServiceApiData}
        handlevalue={ON}
        handleClose={handleClose}
        showPhoto={showPhoto}
        showVideo={showVideo}
        showFiles={showFiles}
        showLinks={showLinks}
        isPollButton={isPollButton}
        HandlePoll={HandlePoll}
        handlePollClose={handlePollClose}
      />
      <AddPollForm
        getDirectoryServiceApiData={getDirectoryServiceApiData}
        handleClose={handleClose}
        isPollButton={isPollButton}
        HandlePoll={showPollForm}
        handlePollClose={handlePollClose}
        handleOpen={handleOpen}
      />
    </div>
  );
};

export default Home;
