import React, { useState } from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import closeicon from '../Assets/closeicon.svg';
import { Button } from '@mui/material';
import Loader from "../Component/Loader"
import { deletePostHome } from '../Utils/services';
import { toast } from 'react-toastify';


const style = {
    position: 'absolute',
    top: '50%',
    left: '55%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 8,
};

const DeletePost = ({ getDirectoryServiceApiData, postId, showDeletePostModal, closeDeleteModal }) => {
    const [loader, setLoader] = useState(false);

    const handleDeletePost = async () => {
        setLoader(true)
        try {
            const result = await deletePostHome(postId);
            if (result.res.success) {
                setLoader(false)
                toast.success(result.res.message)
                closeDeleteModal();
                getDirectoryServiceApiData();
            }
            console.log(result, "result")
        } catch (error) {
            setLoader(false)
            toast.error(error.message)
            console.log(error, "error")
        }
    }

    return (
        <div>
            <Modal
                open={showDeletePostModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className="boxmodal">
                    <Button onClick={closeDeleteModal} style={{ position: "absolute", top: 8, right: 0 }}  ><img src={closeicon} alt="closeicon" /></Button>
                    <Loader size={80} loader={loader} />
                    <div>
                        <div className="pb-16">
                            <h2 className="text-center font-semibold">Are you sure want to delete this post ?</h2>
                        </div>
                        <div component="div" className="flex justify-end items-center">
                            <div className='mr-8'>
                                <button disabled={loader ? true : false} onClick={closeDeleteModal} className={`addbtn` }>Cancel</button>
                            </div>
                            <div>
                                <button disabled={loader ? true : false} onClick={handleDeletePost} className={`deletebtn`}>Delete</button>
                            </div>
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    );
}

export default DeletePost;