import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

import addPhoto from "../../Assets/addphoto.svg";
import addvideo from "../../Assets/addvideo.svg";
import attachfile from "../../Assets/attacfile.svg";
import youtube from "../../Assets/youtube.svg";
import addpoll from "../../Assets/addpoll.svg";



export default function SimpleAccordion(props) {

  return (
    <div>
      <Accordion>
        <AccordionSummary
        >
          <Typography>Add Media</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="social">
            <ul className="social-links ">
              <li className="social-link" onClick={props.handlePhotoshow}>
                <div className="flex items-center mr-8 ">
                  <img src={addPhoto} alt="" />
                  <span className="ml-2">Add Photos</span>
                </div>
              </li>
              <li className="social-link mt-2" onClick={props.handleVideoshow} >
                <div className="flex items-center mr-8">
                  <img src={addvideo} alt="" />
                  <span className="ml-2">Add Videos</span>
                </div>
              </li>
              <li className="social-link mt-2" onClick={props.handleFilesshow} >
                <div className="flex items-center  mr-8">
                  <img src={attachfile} alt="" />{" "}
                  <span className="ml-2">Attach Files</span>{" "}
                </div>
              </li>
              <li className="social-link mt-2" onClick={props.handleLinksshow} >
                <div className="flex items-center  mr-8">
                  <img src={youtube} alt="" />
                  <span className="ml-2">YouTube/Vimeo Link</span>
                </div>
              </li>
              <li className="social-link mt-2" onClick={props.HandlePoll} >
                <div className="flex items-center  mr-8">
                  <img src={addpoll} alt="" />{" "}
                  <span className="ml-2">Add Poll</span>{" "}
                </div>
              </li>
            </ul>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
