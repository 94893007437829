import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import closeicon from '../Assets/closeicon.svg';
import { Autocomplete, Button, TextField } from '@mui/material';
import { reportPost } from '../Utils/services';
import Loader from "../Component/Loader"
import PostReportModal from './PostReportModal';
import DeletePost from './DeletePost';


const style = {
    position: 'absolute',
    top: '50%',
    left: '55%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 8,
};

const DeleteReportForm = ({getDirectoryServiceApiData, showDeleteReport, handleClose, userId, postId , reportable }) => {
    const [loader, setLoader] = useState(false)
    const [showReportModal, setShowReportModal] = useState(false);
    const [showDeletePostModal, setShowDeletePostModal] = useState(false);

    const handleReportModal =() =>{
        handleClose();
        setShowReportModal(true);
    }
    const closeReportModal = () =>{
        setShowReportModal(false)
    }

    const handleDeletePost = (id) =>{
        handleClose();
        setShowDeletePostModal(true)
        console.log(id, 'deleted')
    }
    const closeDeleteModal = () =>{
        setShowDeletePostModal(false)
    }

    return (
        <div>
            <Modal
                open={showDeleteReport}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className="boxmodal">
                    <Button onClick={handleClose} style={{ position: "absolute", top: 8, right: 0 }}  ><img src={closeicon} alt="closeicon" /></Button>
                    <div>
                        <div className="grid grid-cols-4 gap-4">
                            {/* <button className="flex flex-col items-center">
                                <div className="w-8 h-8 flex justify-center items-center rounded-full border border-muteText">
                                    <i class="fa fa-link text-muteText" aria-hidden="true"></i>
                                </div>
                                <span className="text-muteText">Link</span>
                            </button>
                            <button className="flex flex-col items-center">
                                <div className="w-8 h-8 flex justify-center items-center rounded-full border border-muteText">
                                    <i class="fa fa-share text-muteText" aria-hidden="true"></i>
                                </div>
                                <span className="text-muteText">Share</span>
                            </button> */}
                           {reportable && <button className="flex flex-col items-center" onClick={handleReportModal}>
                                <div className="w-8 h-8 flex justify-center items-center rounded-full border border-red-600">
                                    <i class="fa fa-exclamation-circle text-red-600" aria-hidden="true"></i>
                                </div>
                                <span className="text-red-600">Report</span>
                            </button>}
                            {!reportable&&<button className="flex flex-col items-center" onClick={() => handleDeletePost(postId)}>
                                <div className="w-8 h-8 flex justify-center items-center rounded-full border border-black">
                                    <i class="fa fa-trash-o" aria-hidden="true"></i>
                                </div>
                                <span>Delete</span>
                            </button>}
                        </div>
                    </div>
                </Box>
            </Modal>
            <PostReportModal postId={postId} userId={userId}  showReportModal ={showReportModal} closeReportModal = {closeReportModal} />
            <DeletePost getDirectoryServiceApiData ={getDirectoryServiceApiData} postId={postId} showDeletePostModal ={showDeletePostModal} closeDeleteModal ={closeDeleteModal} />
        </div>
    );
}

export default DeleteReportForm;