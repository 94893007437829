import React, { useEffect, useState } from "react";
import Directoryheader from "./Directoryheader";
import Grid from "@mui/material/Grid";
import Afganistan from "../../Assets/afganistan.svg";
import Aisa from "../../Assets/worldCountryAisa.svg";
import Country from "./CountryData";
import jhon from "../../Assets/john-steenhuisen.png";
import cross from "../../Assets/cross.svg";
import dumyDp from "../../Assets/DummyDp.png";
import { useHistory, useLocation } from "react-router";
import { BASE_URL_IMG } from "../../Utils/services";

const Countrymember = () => {
  const [data, setdata] = useState();
  const [CountryData, setCountryData] = useState();
  const [flagdata, setflagData] = useState();
  const [ContinentData, setContinentData] = useState();
  const [bgColorByRegion, setBgColorByRegion] = useState("red");
  const location = useLocation();
  console.log(ContinentData, "india data");

  const handpage = () => {
    history.push("/Directory/counrty");
  };

  useEffect(() => {
    const { id, country, flag, Continent } = location.state;
    const regionName = Continent[0].name;
    if (regionName === "Africa") {
      setBgColorByRegion("#469025");
    } else if (regionName === "Asia") {
      setBgColorByRegion("#f3007b");
    } else if (regionName === "Europe") {
      setBgColorByRegion("#48A4DA");
    } else if (regionName === "Latin America") {
      setBgColorByRegion("#9900CC");
    } else if (regionName === "Middle East & North Africa") {
      setBgColorByRegion("#FFDF1B");
    } else if (regionName === "North America") {
      setBgColorByRegion("#CC3300");
    } else if (regionName === "Worldwide") {
      setBgColorByRegion("#184183");
    } else if (regionName === "Oceania") {
      setBgColorByRegion("#00008B");
    }
    console.log(id, country, Continent, "region data country ");
    setdata(id);
    setCountryData(country);
    setflagData(flag);
    setContinentData(Continent);
  }, []);

  const handleProfile = (type) => {
    console.log(type, "type id");
    history.push({
      pathname: "/Directory/expertise/member/profile",
      state: { id: type },
    });
  };

  const history = useHistory();
  const handleClick = () => {
    history.push("/Directory/Party");
  };

  return (
    <div>
      <div>
        <div className="content">
          <div>
            <Directoryheader />
          </div>
          <div
            className="mt-8 flex items-center expertise "
            onClick={handleClick}
          >
            <p>{CountryData}</p>
            <span className="ml-2" onClick={() => handpage()}>
              <img src={cross} alt="" />
            </span>
          </div>
          <div className="mt-8">
            <Grid container>
              <Grid item sm={6}>
                <Grid container spacing={2}>
                  {data &&
                    data.map((items) => {
                      return (
                        <Grid item lg={4} md={6} sm={6} xs={12}>
                          <div className="CommunityProfile">
                            <div className="card">
                              <div
                                className={`profileBackground`}
                                style={{
                                  background: `${bgColorByRegion}`,
                                }}
                              ></div>
                              <div className="text">
                                <img
                                  src={items.image ? `${BASE_URL_IMG}${items.image}` : dumyDp}
                                  alt="profile image"
                                  className="rounded-full w-16 h-16 object-cover"
                                />
                                <h3 className="cardname">{items.name}</h3>
                                <p>{items.party}</p>
                                <p>{items.country}</p>

                                <p
                                  className="DirectoryLink cursor-pointer"
                                  onClick={() => handleProfile(items._id)}
                                >
                                  View Profile
                                </p>
                              </div>
                            </div>
                          </div>
                        </Grid>
                      );
                    })}
                </Grid>
              </Grid>
              <Grid item sm={1}></Grid>
              <Grid item sm={5}>
                <div className="directoryWorldWide pb-4">
                  <div className="topborder"></div>
                  <div className="Membercountry ml-6 mt-10">
                    <h1>
                      {/* Afghanistan */} {CountryData}
                    </h1>
                  </div>

                  <div className="py-4">
                    <Grid container>
                      <Grid item xs={4}>
                        <div className="ml-6 memberCountryAvtar">
                          <img
                            src={`${BASE_URL_IMG}${flagdata}`}
                            alt="Country"
                            className=""
                          />
                        </div>
                      </Grid>

                      <Grid item xs={6}>
                        {ContinentData &&
                          ContinentData.map((data) => {
                            return (
                              <div className="flex items-center ml-4 memberContinents">
                                <div>
                                  <img src={data.image} alt="" className="" />
                                </div>
                                <div className="ml-4">
                                  <p>{data.name}</p>
                                </div>
                              </div>
                            );
                          })}
                      </Grid>
                    </Grid>
                    <div className="ml-6 mt-4 memberNumber pb-10 ">
                      <p className="gry">
                        {/* >3 people */} {data && data.length} member
                      </p>
                    </div>
                    <hr className="mx-6" />
                  </div>

                  {/*  <div className="topCountriesheading mt-2 ml-6">
                    <h3>Top Searched Countries</h3>
                  </div>
                  {Country.map((item) => {
                    return (
                      <>
                        <div className="worldwideCountrySearch ml-6 mr-6 my-2 flex items-center pr-2">
                          <div className="worldwideboder"></div>
                          <div className="worldwidecountryprofile flex justify-between items-center">
                            <div className="worldcountrydetail flex items-center">
                              <div className="countryAvtar ml-2 ">
                                <img src={Afganistan} alt="" />
                              </div>
                              <div className="worldcountryname ml-2">
                                <h3>{item.name}</h3>
                              </div>
                            </div>
                            <div className="view">
                              <a href="abc">View</a>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })} */}
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Countrymember;
