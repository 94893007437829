import React,{ useContext } from "react";
import Logo from "../Assets/NavFooterimage.svg"
import AuthenContext from "../Utils/AuthContext";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";






const NavbarResponsive = () => {


  const authCtx = useContext(AuthenContext);
  const { logout } = authCtx;
  return (
    <div>
      <nav className="nav flex flex-wrap items-center justify-between px-4 NavBarResponsive_bg border-none">
        <div className="flex flex-no-shrink items-center mr-6 py-3 text-white ">
          <img src={Logo} alt="logo" className="w-20 mr-3" />
         
        </div>

        <input className="menu-btn hidden" type="checkbox" id="menu-btn" />
        <label
          className="menu-icon block cursor-pointer md:hidden px-2 py-4 relative select-none"
          htmlFor="menu-btn"
        >
          <span className="navicon  flex items-center relative"></span>
        </label>

        <ul className="menu  md:border-none flex justify-end list-reset m-0 w-full md:w-auto">
          <li className="">
            <Link
             to="/"
              className="block md:inline-block px-4 py-3 no-underline text-white hover:text-grey-darker font-bold"
            >
              Home
            </Link>
          </li>

          <li className="">
            <Link
             to="/notification"
              className="block md:inline-block px-4 py-3 no-underline text-white hover:text-grey-darker"
            >
             Notifications
            </Link>
          </li>
          <li className="">
            <Link
             to="/event"
              className="block md:inline-block px-4 py-3 no-underline text-white hover:text-grey-darker"
            >
             Events
            </Link>
          </li>

          <li className="">
            <Link
              to="/MyGroups"
              className="block md:inline-block px-4 py-3 no-underline text-white hover:text-grey-darker"
            >
            My Hubs
            </Link>
          </li>

          <li className="">
            <Link
               to="/Directory"
              className="block md:inline-block px-4 py-3 no-underline text-white hover:text-grey-darker"
            >
            Directory
            </Link>
          </li>
          <li className="">
            <Link
             to="/Profile"
              className="block md:inline-block px-4 py-3 no-underline text-white hover:text-grey-darker"
            >
             Profile
            </Link>
          </li>
          <li className="">
            <p
       
              className="block md:inline-block px-4 py-3 no-underline text-white hover:text-grey-darker"
              onClick={logout}
            >
           Log Out
            </p>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default NavbarResponsive;
