import { Box, Button, Modal } from '@mui/material';
import React, { useState } from 'react'
import Loader from "../Component/Loader"
import closeicon from '../Assets/closeicon.svg';
import { deleteCommentApi } from '../Utils/services';
import { toast } from 'react-toastify';


const style = {
    position: 'absolute',
    top: '50%',
    left: '55%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 8,
};


const CommentDeleteConfirmation = ({showDeleteModal, closeDeleteModal, handleDeleteComment }) => {
    const [loader, setLoader] = useState(false);

    
    return (
        <div>
            <Modal
                open={showDeleteModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className="boxmodal">
                    <Button onClick={closeDeleteModal} style={{ position: "absolute", top: 8, right: 0 }}  ><img src={closeicon} alt="closeicon" /></Button>
                    <div className='pb-6'>
                        <p className='text-xl text-paraText'>Are you sure you want to delete this comment?</p>
                    </div>
                    <div component="div" className="mt-8 flex justify-end items-center">
                        <div className="mr-8">
                            <Loader size={20} loader={loader} />
                            <button disabled={loader ? true : false} onClick={() => closeDeleteModal()} className={`addbtn ${loader && "bgButtonLoader"}`}>Cancel</button>
                        </div>
                        <div>
                            <Loader size={20} loader={loader} />
                            <button disabled={loader ? true : false} className={`deletebtn`} onClick={handleDeleteComment}>Delete</button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    )
}

export default CommentDeleteConfirmation