import React, { useState, useContext, useEffect } from "react";

import img from "../../Assets/LoginImage.png";
import footerimg from "../../Assets/loginfooterlogo.png"
import { useHistory } from "react-router";
import { loginService, signInFirst } from "../../Utils/services";
import AuthenContext from '../../Utils/AuthContext';
import Loader from "../Loader";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { getFCMToken } from "../../Massaging";

const Login = () => {
  const authCtx = useContext(AuthenContext);
  const history = useHistory();
  const [deviceToken, setDeviceToken] = useState("");
  const [userData, setUserData] = useState({
    email: "",
    password: "",
  })
  const [loader, setLoader] = useState(false);

  // useEffect(() => {
  //   if (firebase.messaging.isSupported()) {
  //     const msg = firebase.messaging();
  //     msg.requestPermission().then(() => {
  //       return msg.getToken();
  //     }).then((data) => {
  //       setDeviceToken(data)
  //       console.log(data, "token Data")
  //     })
  //   }
  // })

  useEffect(() => {
    getFCMToken()
      .then((currentToken) => {
        if (currentToken) {
          console.log("FCM Token:", currentToken);
          setDeviceToken(currentToken);
        } else {
          console.log("No FCM token available");
        }
      })
      .catch((error) => {
        console.error("Error getting FCM token:", error);
      });
  }, []);


  useEffect(() => {
    if (localStorage.getItem("token")) {
      history.push("/")
    }
  }, [history]);

  // const handleSubmit = async (evt) => {
  //   setLoader(true)
  //   evt.preventDefault();
  //   console.log(userData.email)
  //   console.log(userData.password)
  //   try {
  //     if (userData.email && userData.password) {
  //       const data = {
  //         email: userData.email,
  //         password: userData.password,
  //         fcmToken: deviceToken
  //       }
  //       const result = await loginService(data);
  //       const expirationTime = new Date(new Date().getTime() + (parseInt(result.res.data.refreshExpiry) * (3600 * 1000)))
  //       authCtx.login(result.res.data.accessToken, result.res.data.refreshToken, result.res.data.isSuperAdmin, expirationTime.toISOString());
  //       localStorage.setItem("loginUserId", result.res.data.user._id)
  //       localStorage.setItem("loginUserEmail", result.res.data.user.email)
  //       localStorage.setItem("refreshExpiry", result.res.data.refreshExpiry)
  //       localStorage.setItem("userImage", result.res.data.user.image)
  //       if (result.res.success) {
  //         setLoader(false)
  //         toast.success(result.res.message)
  //         history.push("/");
  //       }
  //     }
  //   }
  //   catch (error) {
  //     setLoader(false)
  //     toast.error(error.message)
  //   }
  // }



  const handleSubmit = async (evt) => {
    setLoader(true)
    evt.preventDefault();
    console.log(userData.email)
    console.log(userData.password)
    try {
      if (userData.email && userData.password) {
        const data = {
          email: userData.email,
          password: userData.password,
          fcmToken: deviceToken
        }
        const result = await signInFirst(data);
        if (result.res.success) {
          setLoader(false)
          toast.success(result.res.message)
          history.push({
            pathname: "/loginOtp",
            state: {
              fcmToken: deviceToken,
              email: userData.email,
            },
          });
        }
      }
    }
    catch (error) {
      setLoader(false)
      toast.error(error.message)
    }
  }

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setUserData({
      ...userData,
      [name]: value,
    })

  }

  return (
    <div className="login">
      <Loader size={50} loader={loader} />
      <div className="flex  justify-center content-center">
        <div className="loginBanner">
          <div className="liberalhub ">
            <img src={img} alt="" className="mb-4 " />
          </div>
          <div className="liberalhub">
            <h2 className="text-white text-2xl mb-4">SIGN IN</h2>
          </div>
          <form action="#" onSubmit={handleSubmit}  >
            <div className="mt-2">
              <input
                type="email"
                id="username"
                className="block input p-2 "
                placeholder="Email ID"
                name="email"
                value={userData.email}
                onChange={(e) => handleChange(e)}
                required
              />
            </div>
            <div className="mt-2">
              <input
                type="password"
                id="password"
                className="block input p-2 "
                placeholder="Password"
                name="password"
                value={userData.password}
                onChange={(e) => handleChange(e)}
                required
              />
            </div>
            <div className="mt-5 mx-0 text-center">
              <input
                type="submit"
                value="SIGN IN"
                className="py-3 btnn cursor-pointer hover:bg-green-600  text-white text-center "
              />
              <p className="mt-4">
                <Link to="/forgotpassword">Forgot Password?</Link>
              </p>
               <p className="pt-12 px-8 text-white text-sm ">By clicking on Sign in, you confirm to have read and agree to <a href="https://liberal-international.org/privacy-policy/" className="underline underline-offset-8 font-medium" target="_blank" rel="noopener noreferrer">our Privacy Policy & our Terms &Condition</a> .</p>
            </div>
          </form>
        </div>
      </div>
      <div className="loginfooter">
        <img src={footerimg} alt="" className=" " />
      </div>
    </div>
  );
};

export default Login;
