
import React, { useState, useEffect, useCallback } from 'react'
import Contact from '../../Component/Contact'
import Grid from '@mui/material/Grid';
import Socialpost from '../../Component/Socialpost';
import Polls from '../../Component/Polls';
import Socialvideo from '../../Component/Socialvideo';
import SearchBox from '../../Component/SearchBox';
import LindaToggle from './LindaToggle';
import MultipleItems from './YearSlider';
import { GetFeedService } from "../../Utils/services";
import { useLocation } from 'react-router';
import Loader from '../../Component/Loader';
import searchicon from "../../Assets/searchicon.svg";
import searchbutton from "../../Assets/searchbutton.svg";
import Socialtext from '../../Component/Socialtext';
import { toast } from 'react-toastify';

const DescussionBord = () => {
  const [FeedData, setFeedsData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [userDetails, setUserDetails] = useState([]);
  const [noData, setNoData] = useState("")
  const [searchMemberValue, setSearchMemberValue] = useState("");
  const [isKnowMoreDatashow, setIsKnowMoreData] = useState(false);
  const location = useLocation();
  const { data_id, groupDetails } = location.state !== undefined && location.state;
  console.log(groupDetails, "groupDetails")
  const getDirectoryServiceApiData = async (id) => {
    setLoader(true)
    try {
      const result = await GetFeedService({
        "hub": data_id && data_id
      });
      if (result.res.posts.length > 0) {
        setFeedsData(result.res.posts);
      }
      else if (result.res.posts.length === 0) {
        setNoData("No data for this hub")
      }
      if (result.res.userDetails.likedPosts.length > 0) {
        setUserDetails(result.res.userDetails.likedPosts);
      }
      setLoader(false)
    } catch (error) {
      setLoader(false)
      if(error.message === "you are not a member of this hub"){
        setIsKnowMoreData(true)
        setNoData("No data for this hub")
      }
    }
  }
  
  useEffect(() => {
    getDirectoryServiceApiData()
  }, []);


  const handleTextpost = ({ data, isImg }) => {
    return (
      <>
        {isImg === "jpeg" || isImg === "png" || isImg === "pdf" ? (
          <Socialpost
            postDate={data.date}
            title={data.postedBy.name}
            userId={data.postedBy.id}
            heading={data.title}
            pera={data.caption}
            userImg={data.postedBy.image}
            PostImage={
              isImg !== "pdf" && data.media.length > 0 && data.media[0].link
            }
            postPdfFile={
              isImg === "pdf" && data.media.length > 0 && data.media[0].link
            }
            location={data.location}
            _id={data._id}
            comments={data.comments}
            likes={data.likes.length & data.likes.length}
            userDetails={userDetails}
            getDirectoryServiceApiData={getDirectoryServiceApiData}
          />
        ) : (
          <Socialtext
            postDate={data.date}
            title={data.postedBy.name}
            userId={data.postedBy.id}
            heading={data.title}
            pera={data.caption}
            userImg={data.postedBy.image}
            PostImage={
              isImg !== "pdf" && data.media.length > 0 && data.media[0].link
            }
            postPdfFile={
              isImg === "pdf" && data.media.length > 0 && data.media[0].link
            }
            location={data.location}
            _id={data._id}
            comments={data.comments}
            likes={data.likes.length & data.likes.length}
            userDetails={userDetails}
            getDirectoryServiceApiData={getDirectoryServiceApiData}
          />
        )}
      </>
    );
  };


  const renderPost = (data) => {
    let isImg =
      data.media.length > 0 &&
      data.media[0].link.split(".")[data.media[0].link.split(".").length - 1];
    return (
      <>
        {isImg === "jpeg" ||
        isImg === "png" ||
        isImg === "pdf" ||
        isImg === false ? (
          /*  <Socialpost
              title={data.postedBy.name}
              userId={data.postedBy.id}
              heading={data.title}
              pera={data.caption}
              userImg={data.postedBy.image}
              PostImage={isImg !== "pdf" && data.media.length > 0 && data.media[0].link}
              postPdfFile={isImg === "pdf" && data.media.length > 0 && data.media[0].link}
              location={data.location}
              _id={data._id}
              comments={data.comments}
              likes={data.likes.length & data.likes.length}
              userDetails={userDetails}
              getDirectoryServiceApiData={getDirectoryServiceApiData}
            /> */
          handleTextpost({ data, isImg })
        ) : (
          <Socialvideo
            postDate={data.date}
            name={data.postedBy.name}
            userId={data.postedBy.id}
            heading={data.title}
            para={data.caption}
            userImg={data.postedBy.image}
            postVideo={data.media.length > 0 && data.media[0].link}
            location={data.location}
            _id={data._id}
            comments={data.comments}
            likes={data.likes.length & data.likes.length}
            userDetails={userDetails}
            getDirectoryServiceApiData={getDirectoryServiceApiData}
          />
        )}
      </>
    );
  };
  return (
    <>
      <Loader size={100} loader={loader}  />
      <Contact hubForm ={"hub"} hubId ={data_id} getDirectoryServiceApiData={getDirectoryServiceApiData} />
      <Grid container >
        <Grid item md={6} sm={12} xs={12}>
          <MultipleItems minihubs={groupDetails && groupDetails.all} FeedData={FeedData && FeedData} />
          <div className='DiscussionBoard mt-6'>
            <h2>Discussion Board</h2>
          </div>
          {
            noData && !loader && <div className='mt-20 flex items-center justify-center'>
              <p className='text-3xl text-sidenav'>{noData}</p>
            </div>
          }
          <div className="disscussionbord"  >
            {FeedData && FeedData.map((item) => {
              return (
                <>
                  {item.isPoll ? (
                    <Polls
                      postDate={item.date}
                      question={item.question}
                      User={item.postedBy.name}
                      location={item.location}
                      choices={item.choices}
                      img={item.postedBy.image}
                      userId={item.postedBy.id}
                      _id={item._id}
                      getDirectoryServiceApiData={getDirectoryServiceApiData}
                    />
                  ) : (
                    renderPost(item)
                  )}
                </>
              );
            })}
          </div>
        </Grid>
        <Grid item md={1} sm={12} xs={12}> </Grid>
        <Grid item md={5} sm={12} xs={12}>
          <div className="searchMarginTop" >
            <div className="SearchBox">
              <div className="bg-white shadow flex searchbarbox">
                <span className="w-auto flex justify-end items-center text-gray-500 p-2">
                  <i className="material-icons text-3xl">
                    <img src={searchicon} alt="searchicon" />
                  </i>
                </span>
                <input
                  className="w-full rounded p-2 removeBorderRed"
                  type="text"
                  placeholder="Search Members"
                  onChange={(e) => setSearchMemberValue(e.target.value)}
                />
                <button className="bg-white-400 hover:bg-white-300 rounded text-black p-2 pl-4 pr-4">
                  <span className="w-auto flex justify-end items-center text-gray-500 p-2">
                    <i className="material-icons text-3xl">
                      <img src={searchbutton} alt="searchicon" />
                    </i>
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div className="Linda2020" >
            <LindaToggle searchMemberValue ={searchMemberValue} groupDetails={groupDetails} isKnowMoreDatashow ={isKnowMoreDatashow} /> 
          </div>
        </Grid>
      </Grid>
    </>
  )
}

export default DescussionBord
