import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import closeicon from "../Assets/closeicon.svg";
import Compose from "../Assets/Compose.svg";
import CreatPoll from "../Assets/graphicon.svg";
import Profile from "../Assets/profile.png";
import Places from "../Assets/Places.svg";
import searchButton from "../Assets/searchbutton.svg";
import { useState } from "react";
import LocationSearchInput from "../Component/LocationSearchInput";
import { Cancel } from "@material-ui/icons";
import Grid from "@mui/material/Grid";
import { BASE_URL_IMG, PostServices,BASE_URL } from "../Utils/services";
import { toast } from "react-toastify";
import Loader from "../Component/Loader";
import { MenuItem, Select, TextField } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";

const style = {
  position: "absolute",
  top: "50%",
  left: "55%",
  transform: "translate(-50%, -50%)",
  width: 1000,

  bgcolor: "background.paper",

  boxShadow: 24,
  p: 8,
};

const useStyles = makeStyles((theme) => ({
  textField: {
    marginBottom: theme.spacing(2),
    width: "100%",
  },
}));

export default function AddPollForm({
  HandlePoll,
  handlePollClose,
  getDirectoryServiceApiData,
  isPollButton,
  handleOpen,
  handleClose,
}) {
  const classes = useStyles();

  const [formData, setFormData] = useState({});
  const [loader, setLoader] = useState(false);
  const [choices, setChoices] = useState(["", ""]);
  const [choicesData, setChoicesData] = useState({});
  const [length, setLength] = useState(1);
  const [sendChoices, setSendChoicesData] = useState([]);
  const loginUserId = localStorage.getItem("loginUserId");
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [locationTextVisibility ,setLocationTextVisibility] = useState(false);
  const [searchLocationInput ,setSearchLocationInput] = useState('')
  const [errorMsg , setErrorMsg] = useState("")
  // const [address, setAddress] = useState({});

  const handleChoices = (type) => {
    if ((choices.length < 4) && (type === "add")) {
      let newArray = [...choices];
      newArray = [...newArray, ""];
      setChoices(newArray);
    }
    if ((choices.length > 2) && (type === "remove")) {
      let newArray = [...choices];
      newArray.pop()
      setChoices(newArray);
    }
  };

  const handleChoicesData = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setChoicesData({
      ...choicesData,
      [name]: {
        title: value,
      },
    });
  };

  const handleChange = (event) => {
    setLength(event.target.value);
  };

  const onChangeHandler = (e) => {
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.value;
    if (name === "question") {
      setShowErrorMsg(false);
    }
    setFormData({ ...formData, [name]: value });
  };
  console.log(formData, "Form Data");

  useEffect(()=>{
    let _choicesData = [];
    for (const x in choicesData) {
      _choicesData.push(choicesData[x]);
    }
    if(_choicesData.length >= 2){
      setShowErrorMsg(false);
    }
  },[choicesData])

  const handlePollSubmit = async (e) => {
    setLoader(true);
    e.preventDefault();
    if (!formData.question) {
      setShowErrorMsg(true);
      setErrorMsg('Please ask a quetion')
      setLoader(false);
      return;
    }
    let _choicesData = [];
    for (const x in choicesData) {
      _choicesData.push(choicesData[x]);
    }
    if(_choicesData.length < 2){
      setShowErrorMsg(true);
      setErrorMsg('Please add some more choices')
      setLoader(false);
      return;
    }
    const postFormData = new FormData();
    postFormData.append("location", searchLocationInput);
    postFormData.append("question", formData.question);
    postFormData.append("choices", _choicesData);
    postFormData.append("length", length);
    postFormData.append("isPoll", true);

    console.log(postFormData, "This Form Data");
    try {
      const result = await PostServices({
        question: formData.question,
        location: searchLocationInput,
        choices: _choicesData.slice(0 , choices.length),
        length: length,
        isPoll: true,
      });
      if (result.res.success) {
        setLoader(false);
        toast.success(result.res.message);
        handlePollClose();
        getDirectoryServiceApiData();
      }
    } catch (error) {
      setLoader(false);
      toast.error(error.messages);
    }
    setFormData({});
    setChoicesData({});
    setLength(1);
  };
  const handleShowPost = () => {
    handlePollClose();
    handleOpen();
  };

  useEffect(() => {
    setFormData({});
    setChoicesData({});
    setLength(1);
    setShowErrorMsg(false);
    setSearchLocationInput("");
    setLocationTextVisibility(false);
    setChoices(["" , ""])
  }, [handleClose]);

  function searchLocationChangeHandler(val) {
    setSearchLocationInput(val)
    console.log('this is comming from another ' , val)
  }

  const reverseGeocode = ({ latitude: lat, longitude: lng }) => {
    const url = `${BASE_URL}/location-find`;
    // searchInput.current.value = "Getting your location...";
    setSearchLocationInput('Getting your location...')
    const token = localStorage.getItem("token");
    console.log({ lat: lat, lng: lng });
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ lat: lat, lng: lng }),
    })
      .then((response) => response.json())
      .then((res) => {
        setSearchLocationInput(res.location)
      });
  };


  const findMyLocation = () => {
    setLocationTextVisibility(true) ;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        console.log(position.coords);
        reverseGeocode(position.coords);
      });
    }
  };


  return (
    <div>
      <Modal
        open={HandlePoll}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="boxmodal">
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="eventModel"
          >
            Create Poll
          </Typography>
          <Typography
            variant="subtitle2"
            gutterBottom
            component="div"
            className="devicer"
          ></Typography>
          <Grid container spacing={8} className="my-6">
            <Grid item md={6} sm={12} xs={12}>
              <div className="CreatePolButtons">
                <div className="CreatePolButton" onClick={handleShowPost}>
                  <button
                    className={`buttonmodal cursor-pointer  ${
                      isPollButton ? "createpol" : ""
                    }`}
                  >
                    <img src={Compose} alt="Compose" />
                    Write Post
                  </button>
                </div>
                <div className="CreatePolButton ">
                  <button
                    className={`buttonmodal cursor-pointer ${
                      isPollButton ? "" : "createpol"
                    }`}
                  >
                    <img src={CreatPoll} alt="createpol" /> Create Poll
                  </button>
                </div>
              </div>
            </Grid>
          </Grid>
          <form>
            <Grid container>
              <Grid item lg={6} md={12} sm={12} xs={12}>
                <div className="Createpolpost">
                  <div component="div" className="profilePollForm mb-2">
                    <div component="div" className="image mr-2">
                      <img
                        src={`${BASE_URL_IMG}/user/${loginUserId}/${loginUserId}.jpeg?${new Date().getTime()}`}
                        alt="profile"
                        className="inline object-cover w-8 h-8 rounded-full"
                      />
                    </div>
                    <div className="post">
                      <textarea
                        id=""
                        cols="35"
                        rows="7"
                        className="outline-none ask_question"
                        placeholder="Ask a Question"
                        name="question"
                        value={formData.question || ""}
                        onChange={(e) => onChangeHandler(e)}
                      ></textarea>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item lg={6} md={12} sm={12} xs={12}>
                <div>
                  {choices.map((choice, id) => (
                    <TextField
                      name={`choices${id + 1}`}
                      key={id}
                      id={`outlined-basic${id + 1}`}
                      label={`Choice ${id + 1}`}
                      className={classes.textField}
                      variant="outlined"
                      value={
                        choicesData[`choices${id + 1}`]
                          ? choicesData[`choices${id + 1}`].title
                          : ""
                      }
                      onChange={(e) => handleChoicesData(e)}
                    />
                  ))}
                  <div className="cursor-pointer text-sidenav font-bold text-right mb-4">
                  {choices.length > 2 &&<span onClick={() => handleChoices('remove')}>- Remove Poll</span>}
                    <span onClick={() => handleChoices('add')} className="mx-4">+ Add Poll</span> 
                  </div>
                  <div className="flex justify-between items-center w-full">
                    <p className="text-sidenav font-bold">Poll Length</p>
                    <Select
                      name="length"
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={length}
                      onChange={handleChange}
                    >
                      <MenuItem value={1}>1 day</MenuItem>
                      <MenuItem value={2}>2 day</MenuItem>
                      <MenuItem value={3}>3 day</MenuItem>
                      <MenuItem value={4}>4 day</MenuItem>
                      <MenuItem value={5}>5 day</MenuItem>
                      <MenuItem value={6}>6 day</MenuItem>
                      <MenuItem value={7}>7 day</MenuItem>
                    </Select>
                  </div>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="Address">
                  <div className="AddLoction mb-2">
                    <h3>Add Location</h3>
                  </div>
                  <div className="loction">
                    <div className="image">
                      <img src={Places} alt="profile" />
                    </div>
                    <div component="div" className="post mt-1">
                      {/* <input
                        type="text"
                        placeholder="Search Location"
                        className="outline-none"
                        name="location"
                        value={formData.location || ""}
                        onChange={(e) => onChangeHandler(e)}
                      /> */}
                      {!locationTextVisibility && (
                        <LocationSearchInput
                          onInputChange={searchLocationChangeHandler}
                        ></LocationSearchInput>
                      )}
                      {locationTextVisibility && (
                        <div className="flex justify-between w-full">
                          <input
                            value={searchLocationInput}
                            className="border-none outline-none"
                          />
                          <Cancel
                            onClick={() => {
                              setSearchLocationInput("");
                              setLocationTextVisibility(false);
                            }}
                          ></Cancel>
                        </div>
                      )}
                    </div>
                    <div component="div" className="image mt-2">
                      {!locationTextVisibility &&<button type="button" onClick={findMyLocation}>
                        <img src={searchButton} alt="searchbutton" className="mx-4" />
                      </button>}
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
            <div
              component="div"
              className="addbutton flex justify-center pt-8 relative"
            >
              <Loader size={20} loader={loader} />
              <button
                disabled={loader ? true : false}
                onClick={handlePollSubmit}
                className={`addbtn ${loader && "bgButtonLoader"}`}
              >
                ADD POLL
              </button>
            </div>
          </form>
          <div className="flex items-center justify-center text-red-500 mt-4">
            {showErrorMsg && <Typography>{errorMsg}</Typography>}
          </div>
          <Button
            style={{ position: "absolute", top: 8, right: 0 }}
            onClick={handlePollClose}
          >
            <img src={closeicon} alt="closeicon" />
          </Button>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}></Typography>
        </Box>
      </Modal>
    </div>
  );
}
