import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Directoryheader from "../Directory/Directoryheader";

import DemoCratic from "../../Assets/Democratic-Alliance.png";
import DA from "../../Assets/DA.png";
import { Link, useRouteMatch, useHistory } from "react-router-dom";
import { BASE_URL_IMG, DirectoryService } from "../../Utils/services";
import searchicon from "../../Assets/searchicon.svg";
import searchbutton from "../../Assets/searchbutton.svg";

const DirectoryParty = () => {
  const [directoryParty, setDirectoryParty] = useState([]);
  const [searchPostValue, setSearchPostValue] = useState("");

  const [User, setUser] = useState([]);
  const { path } = useRouteMatch();
  const history = useHistory();

  const handlepage = (type) => {
    const data = User.filter((item) => item.party == type.name);
    history.push({
      pathname: `${path}/${type.name}`,
      state: {
        id: data,
        avtars: type.avtar,
        name: type.name,
        discriptions: type.dis,
        partyweb: type.web,
      },
    });

    console.log(data, "partymember");
  };

  const getDirectoryServiceApiData = async () => {
    console.log("result");
    try {
      const result = await DirectoryService();
      console.log(result.res, "result data");
      const _data = result.res.party;
      setUser(result.res.users);
      setDirectoryParty(_data);
      console.log(result.res.users, "Usr Data");
    } catch (error) {
      console.log(error);
    }
  };


  useEffect(() => {
    getDirectoryServiceApiData();
  }, []);

  const handleSearchPost = (e) => {
    setSearchPostValue(e.target.value);
  };

  console.log(searchPostValue, "searchPostValue")

  return (
    <>
      <div className="content">
        <div className="SearchBox">
          <div className="bg-white shadow flex searchbarbox">
            <span className="w-auto flex justify-end items-center text-gray-500 p-2">
              <i className="material-icons text-3xl">
                <img src={searchicon} alt="searchicon" />
              </i>
            </span>
            <input
              className="w-full rounded p-2"
              type="text"
              placeholder="Search Party"
              onChange={handleSearchPost}
            />
            <button className="bg-white-400 hover:bg-white-300 rounded text-black p-2 pl-4 pr-4">
              <span className="w-auto flex justify-end items-center text-gray-500 p-2">
                <i className="material-icons text-3xl">
                  <img src={searchbutton} alt="searchicon" />
                </i>
              </span>
            </button>
          </div>
        </div>
        <Directoryheader />
        {/*    <div className="Directory-list mt-6" >
            <Grid container spacing={2}>          
                    <Grid item xs={6} >
                        <div className="map" >
                          <img src={worldmap} alt="" />
                        </div>
                        <div className="flex justify-between mt-8" >
                            <div><span>6 Regions</span></div>
                            <div><span>232 Countries</span></div>
                            <div><span>1500 People</span></div>
                            <div><span>26 Parties</span></div>
                        </div>
  
                    </Grid>
                                   
            </Grid>
          </div> */}

        <div className="mt-8">
          <Grid container>
            <Grid item sm={12}>
              <Grid container spacing={2}>
                {directoryParty && directoryParty.filter((val) => {
                  if (searchPostValue == "") {
                    return val;
                  }
                  else if (val && val.name && val.name.toLowerCase().includes(searchPostValue.toLowerCase())) {
                    return val
                  }
                }).map((items) => {
                  return (
                    <Grid item lg={3} md={6} sm={6} xs={12}>
                      <div className="CommunityProfile">
                        <div className="card">
                          <div
                            className={`profileBackground ${items.BgColor} blue`}
                          ></div>
                          <div className="text party">
                            <img src={`${BASE_URL_IMG}${items.logo}`} alt="" />
                            <h3>{items.name}</h3>
                            <p>{items.membersCount} Members </p>

                            {/* <Link to={`${path}/${items.id}`}>View Profile</Link> */}
                            <p
                              onClick={() =>
                                handlepage({
                                  name: items.name,
                                  avtar:items.logo,
                                  dis:items.description,
                                  web:items.website,
                                  



                                })
                              }
                              className="DirectoryLink cursor-pointer"
                            >
                              View
                            </p>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
            {/*   <Grid item sm={1}></Grid> */}
            {/*    <Grid item sm={4}>
              <div className="directoryWorldWide pb-4">
                <div className="topborder"></div>
                <div className="topCountriesheading mt-2 ml-6">
                  <h3>Top Searched Countries</h3>
                </div>
                {directoryParty.map((item) => {
                  return (
                    <>
                      <div className="worldwideCountrySearch ml-6 mr-6 my-2 flex items-center pr-2">
                        <div className="partyborder"></div>
                        <div className="worldwidecountryprofile flex justify-between items-center">
                          <div className="worldcountrydetail flex items-center">
                            <div className="countryAvtar ml-2 ">
                              <img src={item.logo} alt="" />
                            </div>
                            <div className="worldcountryname ml-2">
                              <h3>{item.name}</h3>
                            </div>
                          </div>
                          <div className="view">
                            <a href="abc">View</a>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </Grid> */}
          </Grid>
        </div>
      </div>
    </>
  );
};

export default DirectoryParty;
