import React from "react";
import Grid from "@mui/material/Grid";

const AccountDetails = ({email}) => {
  const handleMail = (link) => {
 /*    window.open(`https://mail.google.com/mail/u/0/#inbox?compose=${link}`); */
 window.location = `mailto:${link}`;
  };
  return (
    <>
      <div className="AccountDetails pb-10">
        <div className="AccuntName ml-6 mb-4">
          <h3>Account Details</h3>
        </div>
        <div className="Acc_details  ml-6 ">
         {/*  <Grid container className="mb-4">
            <Grid item xs={2}>
              <div className="email">
                <h4>Email</h4>
              </div>
            </Grid>
            <Grid item xs={8}>
              <div className="email_id ml-4  text-lg cursor-pointer flex items-center"  onClick={()=>handleMail(email)}>
                <p>{email}</p>
              </div>
            </Grid>
          </Grid>
        </div>
        <div className="Acc_details flex ml-6">
          <Grid container>
            <Grid item xs={2}>
              <div className="email">
                <h4>Password</h4>
              </div>
            </Grid>
            <Grid>
              <div className="email_id ml-4">
                <p>***********</p>
              </div>
            </Grid>
          </Grid> */}
          
         
             
             <div className="email">
            <label htmlFor=""> <h4>Email</h4></label>
             </div>
             <div className="profile-email-Id">
              <span>{email}</span>
            
             </div>
          
        </div>

        <div className="Acc_details  ml-6 pt-3">
           <div className="email">
           <label htmlFor=""> <h4>Password</h4></label>          
           </div>
             <div className="email_Id ">
              <span>***********</span>
             </div>


        </div>
      </div>
    </>
  );
};

export default AccountDetails;
