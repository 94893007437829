import React, { useEffect, useState } from "react";
import SearchSlider from "./SearchSlider";
import Grid from "@mui/material/Grid";
import Eye from "../../Assets/View.svg";
import ListItem from "../../Assets/List.svg";

import { useHistory } from "react-router-dom";
import { ListHub } from "./ListHub";
import Cross from "../../Assets/cross.svg";
import { BASE_URL_IMG, HubServices } from "../../Utils/services";
import TimeAgo from 'react-timeago'


const YourGroup = () => {
  const [toggle, settoggle] = useState({});
  const [Hubs, setHubs] = useState();
  const [AllHubs, setAllHubs] = useState();
  const history = useHistory();

  const handleRoute = (data) => {
    history.push({
      pathname: "/about",
      state: {
        data_id: data._id,
        groupDetails: data
      }
    });
  };

  const handleDisplay = (name) => {
    settoggle({
      [name]: !toggle[name],
    });
  };

  const handleDisplayClose = (name) => {
    settoggle({
      [name]: !toggle[name],
    });
  };

  const getDirectoryServiceApiData = async () => {
    try {
      const result = await HubServices();
      setHubs(result.res.validHubs);
      setAllHubs(result.res.allHubs);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDirectoryServiceApiData();
  }, []);

  const formatDate = date => {
    const _date = new Date(date)
    const month = _date.getMonth() + 1
    const year = _date.getFullYear()
    const newdate = _date.getDate()
    return `${newdate}/${month}/${year}`
  }

  return (
    <>
      <div className="content">
        <div className="YoursGropups">
          <div className="searchslider">
            <Grid container spacing={8}>
              <Grid item md={6} sm={12}>
                <div>
                  <div className="YourGroup">
                    <h3>My Hubs </h3>
                  </div>
                  {Hubs &&
                    Hubs.map((itmes) => {
                      return (
                        <>
                          <div className="Groups">
                            <div className="groupbtn ">
                              <div className="flex items-center">
                                <div className="imgicon">
                                  <img src={`${BASE_URL_IMG}${itmes.logo}`} alt="" />
                                </div>
                                <div className="Tittles flex justify-between items-center relative ">
                                  <div className="heading">
                                    <h4>{itmes.name}</h4>
                                    <p>{itmes.members} Member </p>
                                  </div>
                                  {!toggle[itmes.name] ? (
                                    <>
                                      <div className="time">
                                        <TimeAgo date={itmes.lastFeedOn} />
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div
                                        onClick={() =>
                                          handleDisplayClose(itmes.name)
                                        }
                                      >
                                        <img src={Cross} alt="" />
                                      </div>
                                    </>
                                  )}
                                  {!toggle[itmes.name] && (
                                    <div className="HubsHover absolute ">
                                      <div className="AvtarHover flex items-center justify-center">
                                        <div className="p-4 border-r-2 border-black cursor-pointer mr-4">
                                          <img
                                            src={Eye}
                                            alt=""
                                            onClick={() => handleRoute(itmes)}
                                          />
                                        </div>

                                        <div className="p-4 cursor-pointer mr-4">
                                          <img
                                            src={ListItem}
                                            alt=""
                                            onClick={() =>
                                              handleDisplay(itmes.name)
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                              {toggle[itmes.name] && (
                                <ListHub
                                  minhubs={itmes.all}
                                  groupDetails={itmes}
                                  data_id={itmes._id}
                                />
                              )}
                            </div>
                          </div>
                        </>
                      );
                    })}
                </div>
              </Grid>
              <Grid item  md={6} sm={10}  xs={11}>
                <div className="search-slider">
                  <SearchSlider AllHubs={AllHubs} />
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </>
  );
};

export default YourGroup;
