import React, { useEffect, useState } from "react";
import BasicModal from "../../Modals/EventDetailModal";

const DateDetails = ({
  showEvent,
  getEventApiData,
  year,
  month
}) => {
  console.log(showEvent, "showEvent Details Page")
  const [ON, setON] = React.useState(false);
  const [showEventDetailsData, setShowEventDetailsData] = useState([]);
  const [sendDate, setSendDate] = useState("");
  const [startDate, setStartDate] = useState([]);

  const formatDate1 = date => {
    const _date = new Date(date)
    const newdate = _date.getDate()
    return newdate;
  }

  const dayDate = date => {
    let startdate = date.slice(0, 10)
    let dates = new Date(startdate);
    let day = dates.toLocaleString('en-us', { weekday: 'long' });
    return day.slice(0, 3);


  }

  useEffect(() => {
    var resArr = [];
    showEvent.filter(function (item) {
      var i = resArr.findIndex(x => (formatDate1(x.start) == formatDate1(item.start)));
      if (i <= -1) {
        resArr.push(item);
      }
      return null;
    });
    setStartDate(resArr)
  }, [showEvent])

  const handleOpen = (start) => {
    setSendDate(start);
    const _eventData = [];
    showEvent.filter(data => {
      if (data.start == start) {
        _eventData.push(data)
      }
    })
    setShowEventDetailsData(_eventData)
    setON(true);
  };
  const handleClose = () => {
    setON(false);
  };
  console.log(startDate, "unique StartDate")

  // const handleActiveStartDate = (value) => {
  //   console.log(value.activeStartDate, "Change Month")
  //   const _yearMonth = value.activeStartDate;
  //   const year = _yearMonth.getFullYear();
  //   const month = _yearMonth.getMonth() + 1;
  //   setYear(year)
  //   setMonth(month)
  // }
  // useEffect(() => {
  //   getEventApiData(year, month);
  // }, [year, month]);
  console.log(showEventDetailsData, "showEventDetailsData")


  return (
    <>
      <div className="events-detail-date" >
        <div className="py-2">
          {
            startDate && startDate.map((data) => {
              return (
                <div className="my-4 border-t border-gray-300 border-solid">
                  <div className="grid grid-cols-8 gap-4 py-2">
                    <div className="col-span-1  ">
                      <p className="mt-2 text-center mr-4">{dayDate(data.start)}</p>
                      <div className=" date my-1 w-8 h-8 rounded-full grey flex bg-yellow-400 items-center justify-center">
                        <p>{formatDate1(data.start)}</p>
                      </div>
                    </div>
                    <div className="col-span-7">
                      {showEvent.map((item) => {
                        return (
                          <div>
                            {
                              formatDate1(data.start) === formatDate1(item.start) &&
                              (
                                <div className="Date-details" onClick={() => handleOpen(item.start)}>
                                  <div
                                    className={`p-5 mt-4 mb-5 ${item.postTo == "li" ? "bg-sidenav" : "blue"}`
                                    }
                                    key={item._id}
                                  >
                                    <p
                                      className={`text-sm ${item.postTo == "li" ? "text-white" : "text-white"}`}>
                                      {item.name}
                                    </p>
                                    <p
                                      className={`text-sm ${item.postTo == "li" ? "text-white" : "text-white"}`}
                                    >
                                      {item.time}
                                    </p>
                                    <p
                                      className={`text_tosmall_10 ${item.postTo == "li" ? "text-white" : "text-white"}`}
                                    >
                                      {item.links.map((item2, index) => {
                                        return (
                                          <><div key={index}>
                                            <p>{item2}</p>
                                          </div>
                                          </>
                                        )
                                      })}
                                    </p>
                                  </div>
                                </div>
                              )
                            }
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>
        <div className="memberbtn flex justify-between">
          <div className="perviousbtn">
            <button className="btn" z>PREVIOUS</button>
          </div>
          <div className="nextbtn">
            <button className="btn">NEXT</button>
          </div>
        </div>
        <BasicModal sendDate={sendDate} showEvent={showEvent} showEventDetailsData={showEventDetailsData} handlevalue={ON} handleClose={handleClose} />
      </div>
    </>
  );
};

export default DateDetails;
