import React, { useContext } from "react";
import HomePage from "./Layout/HomePage";
//import Home from "./Pages/Home";

import Login from "./Component/Auth/Login";
import { Switch, Route } from "react-router-dom";
import AuthenContext from "./Utils/AuthContext";
import ForgetPassword from "./Component/Auth/ForgetPassword";
import { ToastContainer } from "react-toastify";
import OtpVerification from "./Component/Auth/OtpVerification";
import ChangePassword from "./Component/Auth/ChangePassword";
import { Redirect } from "react-router-dom";
import jwtDecode from "jwt-decode"
import { useHistory } from "react-router-dom";
import LoginOtpVerification from "./Component/Auth/LoginOtpVerification";

function App() {
  const token = localStorage.getItem("token");
  const history = useHistory();
  const authCtx = useContext(AuthenContext);
  // if (token === null) {
  //   history.push("/login")
  // }
  return (
    <div className="App">
      <ToastContainer />
      <Switch>
        <Route path='/login' component={Login} />
        <Route path="/forgotpassword" component={ForgetPassword} />
        <Route path="/changepassword" component={ChangePassword} />
        <Route path="/otp" component={OtpVerification} />
        <Route path="/loginOtp" component={LoginOtpVerification} />
        {
          authCtx.isLoggedIn ?
            <Route path="/" component={HomePage} />
            : <Route path="/login" component={Login} />
        }
        <Route path="*">
          <Redirect to="/login" />
        </Route>
      </Switch>
    </div>
  );
}

export default App;
