import React from "react";
import ReactDOM from "react-dom";
import "./style.css";
import "./imageSlider.css"
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import "font-awesome/css/font-awesome.min.css";
import { AuthContextProvider } from "./Utils/AuthContext";
import "slick-carousel/slick/slick.css";
import NetworkServices from "./Utils/network-services";

import "slick-carousel/slick/slick-theme.css";
NetworkServices();
ReactDOM.render(
  <React.StrictMode>
    <AuthContextProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </AuthContextProvider>
  </React.StrictMode>,

  document.getElementById("root")
);
