import React, { Component } from "react";
import { useState } from "react";
import { useEffect } from "react";
import Slider from "react-slick";
import { getSectionListAPi } from "../../Utils/services";
import { useHistory } from "react-router-dom";


const SectionSlider = ({ minihubId }) => {
  const [sectionData, setSectionData] = useState([]);
  const history = useHistory();


  const getsectionList = async () => {
    try {
      const result = await getSectionListAPi(minihubId);
      if (result.res.length > 0) {
        setSectionData(result.res)
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getsectionList();
  }, []);

  const handleSectionClick = (id) => {
    history.push({
      pathname: "/about/minihub/resources",
      state: {
        sectionId: id,
      }
    })
  }


  const settings = {
    infinite: true,
    speed: 500,
    arrows: false,
    slidesToShow: sectionData && sectionData.length >= 3 ? 3 : 1,
    slidesToScroll: sectionData && sectionData.length >= 3 ? 3 : 1,
    autoplay: true,
  };

  return (
    <>
      <div>
        <Slider {...settings}>
          {
            sectionData && sectionData.map((item) => {
              return (
                <>
                  <div className="flex mx-1 cursor-pointer justify-center items-center px-4 py-4 discussionslider" onClick={() => handleSectionClick(item._id)}>
                    <a><h1>{item.name}</h1></a>
                  </div>
                </>
              )
            })
          }
        </Slider>
      </div>
    </>
  );
}

export default SectionSlider