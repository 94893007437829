import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import Directoryheader from "./Directoryheader";
import cross from "../../Assets/cross.svg";
import jhon from "../../Assets/john-steenhuisen.png";

import { useHistory, useLocation } from "react-router";
import { BASE_URL_IMG } from "../../Utils/services";

const AreaExpertise = () => {
  const [memberdata, sertmemberData] = useState([]);
  const [memberdataRights, sertmemberRight] = useState();
  const history = useHistory();
  const location = useLocation();

  const handleClick = (item) => {
    history.push({
      pathname: "/Directory/expertise/member/profile",
      state: { 
        id: item._id,
        memberDetailsThroughState : item
       },
    });
  };

  useEffect(() => {
    sertmemberData(location.state.id);
    sertmemberRight(location.state.rights);
    console.log(memberdata, "id");
  }, []);

  const data = [
    {
      id: "1",
      expertise: "Human Rights",
      politices: "International Politics",
      expertiseNo: "Area of Expertise Number 3",
    },
    {
      id: "1",
      expertise: "Human Rights",
      politices: "International Politics",
      expertiseNo: "Area of Expertise Number 3",
    },
    {
      id: "1",
      expertise: "Human Rights",
      politices: "International Politics",
      expertiseNo: "Area of Expertise Number 3",
    },
    {
      id: "1",
      expertise: "Human Rights",
      politices: "International Politics",
      expertiseNo: "Area of Expertise Number 3",
    },
  ];

  return (
    <div>
      <div>
        <div className="content">
          <div>
            <Directoryheader />
          </div>
          <div
            className="mt-8 flex items-center expertise"
            onClick={handleClick}
          >
            <p>{memberdataRights} </p>
            <span className="ml-2">
              <img src={cross} alt="" />
            </span>
          </div>
          <div className="mt-8">
            <Grid container>
              <Grid item sm={7}>
                <Grid container spacing={2}>
                  {memberdata.map((items) => {
                    return (
                      <Grid item lg={4} md={6} sm={6} xs={12}>
                        <div className="CommunityProfile">
                          <div className="card">
                            <div
                              className={`profileBackground ${items.BgColor} yellow`}
                            ></div>
                            <div className="text">
                              <img
                                src ={items.image ? `${BASE_URL_IMG}${items.image}` : jhon}
                                alt=""
                                className="rounded-full w-16 h-16 object-cover"
                              />
                              <h3>{items.name}</h3>
                              <p>{items.party}</p>
                              <p>{items.country}</p>
                              <p
                                className="DirectoryLink cursor-pointer"
                                onClick={() => handleClick(items)}
                              >
                                View Profile
                              </p>
                            </div>
                          </div>
                        </div>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
              <Grid item sm={1}></Grid>
              <Grid item sm={4}>
                <div className="directoryWorldWide pb-12">
                  <div className="topborder"></div>
                  <div className="expert mt-2 ml-6 pb-2">
                    <h2>Human Rights</h2>
                  </div>
                  <div className="totalpeople ml-6">
                    <p>{memberdata.length} People</p>
                  </div>
                  <div className="topCountriesheading mt-8 ml-6">
                    <h3>Top Searched Countries</h3>
                  </div>
                  {data.map((items) => {
                    return (
                      <>
                        <div className="topexpertise mx-7 py-2">
                          <h3>{items.expertise}</h3>
                        </div>
                        <hr className="mx-7 pb-2" />
                      </>
                    );
                  })}
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AreaExpertise;
