import React, { useState } from "react";
import LindaAvtar from "../../Assets/LIDTA-ICON-Logo.png";
import Member from "./member";
import Memberconcel from "./memberChange";
import Memberdata from "./memberdata";
import MemberProfile from "./memberProfile";
import membercal from "../../Assets/membercal.svg";
import CalenderModel from "../../Modals/EventCalenderModel";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { BASE_URL_IMG } from "../../Utils/services";

const LindaToggle = ({ groupDetails, minihubId, searchMemberValue, isKnowMoreDatashow }) => {
  const [toggle, settoggle] = useState(true);
  const [ON, setON] = React.useState(false);
  const history = useHistory();
  const handleOpen = () => {
    setON(true);
    console.log("true");
  };
  const handleClose = () => {
    setON(false);
  };
  

  const handleMail = (link) => {
    window.location = `mailto:${link}`
  }

  return (
    <div className="Linda-2020">
      <div className="linda-2"></div>
      <div className="Linda-set">
        <div className="Linda-2020-avtar">
          <img src={groupDetails && `${BASE_URL_IMG}${groupDetails.logo}`} alt="LindaAvtar" className="w-20 h-20 rounded-full object-cover bg-white" />
        </div>
        <div className="Linda-2020-title">
          <h3>{groupDetails && groupDetails.name}</h3>
        </div>
        <div className="member flex justify-between items-center">
          <div className="memberbtn">
            <button onClick={() => settoggle(!toggle)}>
              {toggle ? <Member groupDetails={groupDetails} /> : <Memberconcel groupDetails={groupDetails} />}
            </button>
          </div>
          <div className="membercalender cursor-pointer" onClick={handleOpen}>
            <img src={membercal} alt="calender" />
          </div>
        </div>
        <div className="memberDetail">
          {toggle ? <Memberdata details={groupDetails} /> : <MemberProfile searchMemberValue={searchMemberValue} HubId={groupDetails._id} minihubId={minihubId} />}
        </div>
        <div className="flex justify-center items-center flex-col py-4">
          {
            groupDetails && groupDetails.url && <button type="button" formTarget="_blank" className={`addbtn`} onClick={() => window.open(`//${groupDetails.url}`, '_blank')}>Know More</button>
          }
          {
            isKnowMoreDatashow && (
              <div className="my-5 flex items-center flex-col">
                <p className="text-sidenav">To join this hub, please email</p>
                <span className="text-sidenav cursor-pointer font-semibold" onClick={() => handleMail("office@liberal-international.org")}>office@liberal-international.org</span>
                <p className="text-sidenav">
                  with your request
                </p>
              </div>
            )
          }
        </div>
      </div>
      <CalenderModel handlevalue={ON} handleClose={handleClose} />
    </div>
  );
};

export default LindaToggle;
