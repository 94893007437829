import React, { useEffect, useState } from 'react'
import { BASE_URL_IMG, getNotifications } from '../../Utils/services';
import dumyDp from "../../Assets/DummyDp.png"
import TimeAgo from 'react-timeago'



const NotificationPage = () => {
    const [notificationData, setNotificationData] = useState([]);
    const getNotificationApiData = async () => {
        try {
            const result = await getNotifications();
            console.log(result.res)
            setNotificationData(result.res)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getNotificationApiData();
    }, []);

    return (
        <div className="content">
            <div className="YourGroup mb-5">
                <h3>Notifications</h3>
            </div>
            {notificationData && notificationData.map((data) => {
                return (
                    <>
                        <div className="grid grid-cols-12 gap-4 py-4">
                            <div className="col-span-1">
                                <img src={`${BASE_URL_IMG}/${data.adminToUser ? 'admin' : 'user'}/${data.from}/${data.from}.jpeg?${new Date().getTime()}` || dumyDp} alt="" className="w-14 h-14 rounded-full" />
                            </div>
                            <div className="col-span-11  ">
                                <div className="flex items-center">
                                    <h2 className="text-minihubHeading text-lg">{data.fromName ? data.fromName : ""}</h2>
                                    <h2 className="text-minihubHeading ml-1">{ data.notifType ? `${data.notifType} ${data.notifType && 'on your post'}` : ""}</h2>
                                </div>
                                <h2 className="text-minihubHeading">{data.title ? data.title : ""}</h2>
                                <p className="text-paraText text-sm">{data.description ? data.description : ""}</p>
                                <TimeAgo date={data.notifDate} className="text-muteText text-sm" />
                            </div>
                        </div>
                        <hr className="m-1 " />
                    </>
                );
            })}
        </div>
    )
}

export default NotificationPage