import React, { Component } from "react";
import Navbar from "../Component/Navbar";
import MainContent from "./MainContent";
import NavbarResponsive from "./NavbarResponsive";




class HomePage extends Component {
  render() {
    return (
      <div>
        <div className="Navbar_Responisive">
           <NavbarResponsive />

        </div>
        <div className="Sidebar">
          <Navbar />
        
        </div>
        <div className="main-content">
          <MainContent />
       
        

        </div>
      </div>
    );
  }
}

export default HomePage;
