export const LOGIN = "/signin";
export const SIGNIN_FIRST = "/signin-first";
export const FORGOTPASSWORD = "/forgotPassword"
export const OTPVERIFICATION = "/otpVerification"
export const OTP_VERFICATION_LOGIN = "/verify-second"
export const CHANGEPASSWORD = "/changePassword"
export const DIRCOTRY="/getDirectory"
export const PROFILE="/profile/getProfile/613f3d6de4edc83d43afe9a1"
export const FEEDS ="/feed";
export const POST="/post";
export const HUBS="/myGroups";
export const GET_COMMENTS="/comments";
export const ADD_COMMENTS="/comment";
export const GET_EVENTS="/events";
export const POST_REPORT="/report";
export const LIKE_DISLIKEPOST="/like";
export const DELETE_POST="/post/delete";
export const LIKE_DISLIKECOMMENT="/like-comment";
export const EDIT_PROFILE="/editProfile"
export const USERPROFILE="/getProfile"
export const RESET_PASSWORD="/resetPassword"
export const PARTY_LIST = "/getParty"
export const EXPERTISE_LIST = "/getExpertise"
export const MEMBERS_LIST = "/members"
export const SECTION_LIST = "/section"
export const GET_NOTIFICATIONS = "/notifications"
export const GET_RESOURCES = "/resources"
export const ADD_HUB_POST = "/myGroups/post"
export const ADD_MINIHUB_POST = "/myGroups/minihub/post"
export const ADD_PARTY_MEMBER = "/party/addMember"
export const VOTE_POLL = "/vote"
export const REFRESH_TOKEN = "refresh";
export const BLOCK_USER = "block";
export const BLOCKED_USER_LIST = "getBlockedUser"
export const DELETE_USER_PROFILE = "userDelete"
export const DELETE_COMMENT = "delComment"
export const EDIT_COMMENT = "editComment"


