import React, { useState } from 'react'
import Hubs from './Hubs';

import btntoggle from '../../Assets/btntoggle.svg';
import btntoggle2 from '../../Assets/btntoggle2.svg'

import SliderSlick from './AllhubSlider';



const Allhub = ({ AllHubs, searchValue }) => {
    const [toggle, settoggle] = useState(true);
    return (
        <>
            <div className="Allhub px-10 py-10">
                <div className="flex item-center justify-between  py-4" >
                    <div className="Title" >
                        <h3>All Hubs</h3>
                    </div>
                    <div className="Allhub-btn">
                        <button onClick={() => settoggle(!toggle)} >{toggle ? <img src={btntoggle} alt="" /> : <img src={btntoggle2} alt="" />}</button>
                    </div>
                </div>
                {toggle ? <SliderSlick AllHubs={AllHubs} searchValue ={searchValue} /> : <Hubs AllHubs={AllHubs} searchValue ={searchValue} />}
            </div>
        </>
    )
}

export default Allhub
