import { Grid } from "@mui/material";
import React, { useState } from "react";
import Edit from "../../Assets/Edit.svg";
import Cross from "../../Assets/cross.svg";
import { resetPasswordApi } from "../../Utils/services";
import { toast } from "react-toastify";

const AccountDetailsEdits = ({ emailData }) => {
  const [isAccountEdit, setIsAccountEdit] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPassword2, setNewPassword2] = useState("");

  const handleEditChange = () => {
    setIsAccountEdit(!isAccountEdit);
  };

  const changePasswordSubmit = async (e) => {
    e.preventDefault();
    try {
      const result = await resetPasswordApi({
        "oldPassword": oldPassword,
        "newPassword": newPassword,
        "newPassword2": newPassword2
      });
      if (result.res.success) {
        toast.success(result.res.message);
        setIsAccountEdit(!isAccountEdit)
      }
    }
    catch (error) {
      toast.error(error.message)
    }
  }
  return (
    <div>
      <div className="AccountDetails pb-10">
        <div className="flex items-center">
          <div className="AccuntName ml-6">
            <h3>Account Details</h3>
          </div>
          <div className="cursor-pointer" onClick={handleEditChange}>
            {
              isAccountEdit ? <img src={Cross} alt="" className="ml-1" />
                : <img src={Edit} alt="" className="w-5 ml-1" />
            }
          </div>
        </div>
        <div className="ml-6 mt-3">
          {
            isAccountEdit ? (
              <form>
                <div className="mb-4 mt-2 acounnt_password">
                  <input
                    type="email"
                    value={emailData}
                    className="outline-none text-muteText"
                    disabled
                  />
                  <hr className="mr-32 " />
                </div>
                <div className="mb-4 mt-2 acounnt_password">
                  <input
                    type="password"
                    name="oldPassword"
                    value={oldPassword}
                    placeholder="Enter Current Password"
                    className="outline-none"
                    onChange={(e) => setOldPassword(e.target.value)}
                  />
                  <hr className="mr-32 " />
                </div>
                <div className="mb-4 mt-2 acounnt_password">
                  <input
                    type="password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    placeholder="Enter New Password"
                    className="outline-none"
                  />
                  <hr className="mr-32 " />
                </div>
                <div className="mb-4 mt-2 acounnt_password">
                  <input
                    type="password"
                    name="newPassword2"
                    value={newPassword2}
                    onChange={(e) => setNewPassword2(e.target.value)}
                    placeholder="Re-Enter New Password"
                    className="outline-none"
                  />
                  <hr className="mr-32 " />
                </div>
                <button
                  className="BioEditer cursor-pointer"
                  type="submit"
                  onClick={changePasswordSubmit}
                >
                  <p>Change</p>
                </button>
              </form>
            )
              :
              (
                <>
                  <div className="Acc_details flex">
                    <Grid container>
                      <Grid item xs={2}>
                        <div className="email">
                          <h4>Email</h4>
                        </div>
                      </Grid>
                      <Grid item xs={8}>
                        <div className="email_id ml-4">
                          <p className="text-paraText">{emailData}</p>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                  <div className="Acc_details flex">
                    <Grid container>
                      <Grid item xs={2}>
                        <div className="email">
                          <h4>Password</h4>
                        </div>
                      </Grid>
                      <Grid>
                        <div className="email_id ml-4">
                          <p className="text-paraText">***********</p>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </>
              )
          }
        </div>
      </div>
    </div>
  );
};

export default AccountDetailsEdits;
