import React from 'react'

const Memberdata = ({details}) => {
    return (
        <div className="memberdata">
          <p>{details && details.description}</p>     
        </div>
    )
}

export default Memberdata
