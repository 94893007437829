import React from 'react'
import member from '../../Assets/member-color.svg'
import memberconcel from '../../Assets/memberconcel.svg'

const Memberconcel = ({groupDetails}) => {
    return (
        <div className="flex items=center memberchancel">
            <p>{groupDetails.members} Members</p>
            <img src={member} alt="member" />
            <img src={memberconcel} alt="concel" />
            
        </div>
    )
}

export default Memberconcel;