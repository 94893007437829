import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import Directoryheader from "../Directory/Directoryheader";

import { Link, useRouteMatch, useHistory } from "react-router-dom";
import { DirectoryService } from "../../Utils/services";
import searchicon from "../../Assets/searchicon.svg";
import searchbutton from "../../Assets/searchbutton.svg";

const DirectoryExpertise = () => {
  const [expertise, setExpertise] = useState([]);
  const [searchPostValue, setSearchPostValue] = useState("");


  const [user, setUser] = useState({})

  let { path } = useRouteMatch();
  const history = useHistory();

  const handlePage = (type) => {
    console.log(type, "type exp")


    console.log(user, "expertise member")

    const data = user.filter((item) => item.expertise == type);
    console.log(data, "data")



    history.push(
      {
        pathname: `${path}/${type}`,
        state: { id: data, rights: type }
      }
    )

  }

  const GetDirectoryServiceExpertise = async () => {
    try {
      const result = await DirectoryService();
      console.log(result.res, "resultCountry");
      const _data = result.res.expertise;
      const _userdata = result.res.users;

      setUser(_userdata);


      setExpertise(_data)


    } catch (error) {
      console.log(error);
    }
  };



  useEffect(() => {
    GetDirectoryServiceExpertise();
  }, []);


  const handleSearchPost = (e) => {
    setSearchPostValue(e.target.value);
  };

  console.log(searchPostValue, "searchPostValue")




  return (
    <div>
      <div className="content">
        <div>
          <div className="SearchBox">
            <div className="bg-white shadow flex searchbarbox">
              <span className="w-auto flex justify-end items-center text-gray-500 p-2">
                <i className="material-icons text-3xl">
                  <img src={searchicon} alt="searchicon" />
                </i>
              </span>
              <input
                className="w-full rounded p-2"
                type="text"
                placeholder="Search Expertise"
                onChange={handleSearchPost}
              />
              <button className="bg-white-400 hover:bg-white-300 rounded text-black p-2 pl-4 pr-4">
                <span className="w-auto flex justify-end items-center text-gray-500 p-2">
                  <i className="material-icons text-3xl">
                    <img src={searchbutton} alt="searchicon" />
                  </i>
                </span>
              </button>
            </div>
          </div>
          <Directoryheader />
        </div>
        <div className="mt-8">
          <Grid container>
            <Grid item sm={12}>
              <Grid container spacing={2}>
                {expertise.filter((val) => {
                  if (searchPostValue == "") {
                    return val;
                  }
                  else if (val && val.area && val.area.toLowerCase().includes(searchPostValue.toLowerCase())) {
                    return val
                  }
                }).map((items) => {
                  return (
                    <>
                      <Grid item sm={3}>
                        <div className="areaExpertise cursor-pointer" onClick={() => handlePage(items.area)}>
                          <div className="humanright px-2 py-4 flex justify-center items-center bg-white ">
                            <div   >
                              <h3>{items.area}</h3>
                            </div>
                          </div>
                        </div>
                      </Grid>

                      {/*  */}
                    </>
                  );
                })}    {/*  <Grid item xs={4}>
                        <div className="areaExpertise">
                          <div className="  px-2 py-4 flex justify-center items-center bg-white ">
                            <h3>International Politics</h3>
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={4}>
                        <div className="areaExpertise">
                          <div className="  px-2 py-4 flex justify-center items-center bg-white ">
                            <h3>Area of Expertise Number 3</h3>
                          </div>
                        </div>
                      </Grid> */}
              </Grid>
            </Grid>
            {/*          <Grid item sm={1}></Grid> */}
            {/*    <Grid item sm={4}>
              <div className="directoryWorldWide pb-12">
                <div className="topborder"></div>
                <div className="topCountriesheading mt-2 ml-6 pb-2">
                  <h3>Top Searched Expertise</h3>
                </div>
                {expertise.map((items) => {
                  return (
                    <>
                      <div className="topexpertise mx-7 py-2" key={items._id}>
                        <h3>{items.area}</h3>
                      </div>
                      <hr className="mx-7 pb-2" />
                    </>
                  );
                })}
              </div>
            </Grid> */}
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default DirectoryExpertise;
