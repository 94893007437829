import axios from "axios";

import { ADD_COMMENTS, ADD_HUB_POST, ADD_MINIHUB_POST, ADD_PARTY_MEMBER, BLOCKED_USER_LIST, BLOCK_USER, CHANGEPASSWORD, DELETE_COMMENT, DELETE_POST, DELETE_USER_PROFILE, EDIT_COMMENT, EDIT_PROFILE, EXPERTISE_LIST, FORGOTPASSWORD, GET_COMMENTS, GET_EVENTS, GET_NOTIFICATIONS, GET_RESOURCES, LIKE_DISLIKECOMMENT, LIKE_DISLIKEPOST, LOGIN, MEMBERS_LIST, OTPVERIFICATION, OTP_VERFICATION_LOGIN, PARTY_LIST, POST, POST_REPORT, RESET_PASSWORD, SECTION_LIST, SIGNIN_FIRST, USERPROFILE, VOTE_POLL } from "./routes";
import { DIRCOTRY } from "./routes";
import { PROFILE } from "./routes";
import { FEEDS } from "./routes";
import { HUBS } from "./routes";

// export const BASE_URL = "https://lib.techtonic.asia/user"; // for develop

export const BASE_URL = "https://api.liberal-hub.org/user"; //for production

// export const BASE_URL_IMG = "https://devtechtonic-social.s3.ap-south-1.amazonaws.com" // develop

export const BASE_URL_IMG = "https://d2rjmv8g0aedyu.cloudfront.net" // production

axios.defaults.baseURL = BASE_URL;

export const loginService = async (data) => { 
  try {
    const response = await axios.post(LOGIN, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      console.log(response.data);
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const signInFirst = async (data) => { 
  try {
    const response = await axios.post(SIGNIN_FIRST, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) { 
      console.log(response.data);
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const forgotPassword = async (data) => {
  try {
    const response = await axios.post(FORGOTPASSWORD, data, {
      headers: { "Content-Type": "application/json" },
    })
    if (response.status === 200) {
      console.log(response.data);
      return {
        res: response.data
      };
    }
    else return response.data;
  }
  catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message
  }
}
export const otpVerification = async (data) => {
  try {
    const response = await axios.post(OTPVERIFICATION, data, {
      headers: { "Content-Type": "application/json" },
    })
    if (response.status === 200) {
      console.log(response.data);
      return {
        res: response.data
      };
    }
    else return response.data;
  }
  catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message
  }
}
export const otpVerificationLogin = async (data) => {
  try {
    const response = await axios.post(OTP_VERFICATION_LOGIN, data, {
      headers: { "Content-Type": "application/json" },
    })
    if (response.status === 200) {
      console.log(response.data);
      return {
        res: response.data
      };
    }
    else return response.data;
  }
  catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message
  }
}
export const changePasswordApi = async (data) => {
  try {
    const response = await axios.post(CHANGEPASSWORD, data, {
      headers: { "Content-Type": "application/json" },
    })
    if (response.status === 200) {
      console.log(response.data);
      return {
        res: response.data
      };
    }
    else return response.data;
  }
  catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message
  }
}




export const getEvent = async (data) => {
  try {
    const response = await axios.post(GET_EVENTS, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      console.log(response.data);
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const reportPost = async (data) => {
  try {
    const response = await axios.post(POST_REPORT, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      console.log(response.data);
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const VoteOnPoll = async (data) => {
  try {
    const response = await axios.post(VOTE_POLL, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      console.log(response.data);
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const LikeDislikePostApi = async (data) => {
  try {
    const response = await axios.post(LIKE_DISLIKEPOST, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      console.log(response.data);
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const LikeDislikeCommentApi = async (data) => {
  try {
    const response = await axios.post(LIKE_DISLIKECOMMENT, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      console.log(response.data);
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const DirectoryService = async (data) => {
  try {
    const response = await axios.get(DIRCOTRY,data,{});
    if (response.status === 200) {
      return { res: response.data.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const GetProfileService = async () => {
  try {
    const response = await axios.get(PROFILE, {});
    if (response.status === 200) {
      return { res: response.data.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getNotifications = async () => {
  try {
    const response = await axios.get(GET_NOTIFICATIONS);
    if (response.status === 200) {
      return { res: response.data.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const GetFeedService = async (data) => {
  try {
    const response = await axios.post(FEEDS, data, {});
    if (response.status === 200) {
      return { res: response.data.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const GetMembersApi = async (data) => {
  try {
    const response = await axios.post(MEMBERS_LIST, data, {});
    if (response.status === 200) {
      return { res: response.data.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const PostServices = async (data, contentType) => {
  try {
    const response = await axios.post(POST, data, {
      headers: { 
        "Content-Type": contentType,
     },
    });
    if (response.status === 200) {
      console.log(response.data);
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const PostHubServices = async (data, contentType) => {
  try {
    const response = await axios.post(ADD_HUB_POST, data, {
      headers: { 
        "Content-Type": contentType,
     },
    });
    if (response.status === 200) {
      console.log(response.data);
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const PostMinihubServices = async (data, contentType) => {
  try {
    const response = await axios.post(ADD_MINIHUB_POST, data, {
      headers: { 
        "Content-Type": contentType,
     },
    });
    if (response.status === 200) {
      console.log(response.data);
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};


export const HubServices = async () => {
  try {
    const response = await axios.get(HUBS, {});
    if (response.status === 200) {
      return { res: response.data.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;

  }

}
export const getComments = async (_id) => {
  try {
    const response = await axios.get(`${GET_COMMENTS}/${_id}`, {});
    if (response.status === 200) {
      return { res: response.data.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;

  }
}
export const getResources = async (_id) => {
  try {
    const response = await axios.get(`${GET_RESOURCES}/${_id}`, {});
    if (response.status === 200) {
      return { res: response.data.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;

  }
}
export const getSectionListAPi = async (_id) => {
  try {
    const response = await axios.get(`${SECTION_LIST}/${_id}`, {});
    if (response.status === 200) {
      return { res: response.data.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;

  }
}
export const addComment = async (data) => {
  try {
    const response = await axios.post(ADD_COMMENTS, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      console.log(response.data);
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const deleteCommentApi = async (data) => {
  try {
    const response = await axios.post(DELETE_COMMENT, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      console.log(response.data);
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const editCommentApi = async (data) => {
  try {
    const response = await axios.post(EDIT_COMMENT, data, {
      headers: { "Content-Type": "application/json" },
    });
    if (response.status === 200) {
      console.log(response.data);
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const deletePostHome = async (_id) => {
  try {
    const response = await axios.post(`${DELETE_POST}/${_id}`, {});
    if (response.status === 200) {
      console.log(response.data);
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const blockUserProfileApi = async (_id) => {
  try {
    const response = await axios.post(`${BLOCK_USER}/${_id}`, {});
    if (response.status === 200) {
      console.log(response.data);
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const blockedUserListApi = async (_id) => {
  try {
    const response = await axios.post(`${BLOCKED_USER_LIST}/${_id}`, {});
    if (response.status === 200) {
      console.log(response.data);
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const deleteUserProfileApi = async () => {
  try {
    const response = await axios.post(`${DELETE_USER_PROFILE}`, {});
    if (response.status === 200) {
      console.log(response.data);
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};



// new Here

export const GetUserProfileService = async (data) => {

  try {
    const response = await axios.post(USERPROFILE, data, {});
    if (response.status === 200) {
      return { res: response.data.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const userEditProfileService = async (data) => {
  
  try {
    const response = await axios.post(EDIT_PROFILE,data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const addPartyMemberApi = async (data) => {
  
  try {
    const response = await axios.post(ADD_PARTY_MEMBER,data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};

export const resetPasswordApi = async (data) => {
  try {
    const response = await axios.post(RESET_PASSWORD, data, {
      headers: {
        "Content-Type": "application/json"
      }
    });
    if (response.status === 200) {
      return {
        res: response.data
      }
    }
    else {
      return response.data
    } 

    
  } catch (error) {
    if (error.response) {
      throw error.response.data
    }
    else {
      throw error.message
    }
  }
}

export const partyList = async () => {
  try {
    const response = await axios.get(PARTY_LIST);
    if (response.status === 200) {
      return { res: response.data };
    } else return response.data;
  } catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
};
export const getExpertise = async () => {
  try {
    const response = await axios.get(EXPERTISE_LIST);
    if (response.status === 200) {
      return { res: response.data.data };
    } else return response.data;
  }
  catch (err) {
    if (err.response) throw err.response.data;
    else throw err.message;
  }
}
// new Here