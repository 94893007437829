import React, { useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

function Calender({eventAllData,  showEvent, value, onChange, activeStartDateChange }) {
  const formateTime = (time) => {
    const _Time = new Date(time);
    const hours = _Time.getHours();
    const minutes = _Time.getMinutes();
    const FullTime = `${hours}:${minutes}`
    return FullTime
  }

  const formatDate = date => {
    const _date = new Date(date)
    const month = _date.getMonth() + 1
    const year = _date.getFullYear()
    const newdate = _date.getDate()
    return `${newdate}/${month}/${year}`
}

const handleTileClassName = ({ date }) => {
    const _newData = [];
    eventAllData && eventAllData.map((item) => {
        _newData.push(item.current)
    })
   const _res = _newData.filter((item) => {
        return formatDate(item) === formatDate(date)
    })

    for (let x in _newData) {
        if (formatDate(_res[0]).includes(formatDate(_newData[x]))) {
            return "cutome_calander_class"
        }
    }
}

  return (
    <>
      <div className="ml-3 mr-3" >
        <div>
          <Calendar
            onChange={onChange}
            value={value}
            onActiveStartDateChange={activeStartDateChange}
            next2Label={null}
            prev2Label={null}
            tileClassName={handleTileClassName}
          />
          <div className="calender-boder-bottam">
            <hr />
          </div>

          <div className="calender-dtails" >
            <h3>Details</h3>
          </div>
          <div className='calender-auto-overflow'>

            {
              showEvent.length > 0 ? (
                showEvent.map(item => (
                  <div
                    className={`p-5 mb-5 ${item.postTo == "li" ? "bg-sidenav" : "bg-blue"}`
                    }
                    key={item._id}
                  >
                    <h3
                      className={`text-lg ${item.postTo == "li" ? "text-white" : "text-white"} `}
                    >
                      {item.name}
                    </h3>
                    <p
                      className={`text-sm ${item.postTo == "li" ? "text-white" : "text-white"}`}>
                      {item.description}
                    </p>
                    <p
                      className={`text-sm ${item.postTo == "li" ? "text-white" : "text-white"}`}
                    >
                      {`${formatDate(item.start)} to ${formatDate(item.end)}`}
                    </p>
                    <p className={`text-sm ${item.postTo == "li" ? "text-white" : "text-white"}`}>{`${formateTime(item.startTime)} to ${formateTime(item.endTime)}`}</p>
                    <p className={`text-sm ${item.postTo == "li" ? "text-white" : "text-white"}`}>{item.timezone}</p>
                    <p
                      className={`text_tosmall_10 ${item.postTo == "li" ? "text-white" : "text-white"}`}
                    >
                      {item.links.map((item2, index) => {
                        return (
                          <><div key={index}>
                            <p>{item2}</p>
                          </div>
                          </>
                        )
                      })}
                    </p>
                  </div>
                ))

              ) : (

                <div className="ml-5">
                  <p>No Events for today</p>
                </div>
              )
            }
          </div>
        </div>
      </div>
    </>
  );
}
export default Calender;