import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import img from "../../Assets/LoginImage.png";
import footerimg from "../../Assets/loginfooterlogo.png";
import { changePasswordApi } from "../../Utils/services";
import Loader from "../Loader";
import { useLocation, useHistory } from "react-router";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ChangePassword = () => {
  const [loader, setLoader] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [newPassword2, setNewPassword2] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const [tokenValue, setTokenValue] = useState("");

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (location.state !== undefined) {
      const { email, token } = location.state;
      console.log(email, token, "Email, Location");
      setEmailValue(email);
      setTokenValue(token);
    }
    if(location.state === undefined){
      history.push("/login")
    }
  }, []);

  const handleChangePassword = async (e) => {
    setLoader(true);
    e.preventDefault();
    try {
      const result = await changePasswordApi({
        email: emailValue,
        token: tokenValue,
        newPassword: newPassword,
        newPassword2: newPassword2,
      });
      if (result.res.success) {
        setLoader(false);
        toast.success(result.res.message);
        history.push("/login");
      }
    } catch (error) {
      setLoader(false);
      toast.error(error.message);
      console.log(error);
    }
  };

  return (
    <div className="login">
      <div className="loginBanner">
        <div className="main  flex justify-center items-center flex-col  ">
          <img src={img} alt="" />

          <h2>Change PASSWORD ?</h2>
        </div>
        <form onSubmit={handleChangePassword}>
          <div className="mt-2">
            <input
              type="password"
              id="newPassword"
              className="block input p-2 "
              placeholder="New Password"
              name="email"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </div>
          <div className="mt-2">
            <input
              type="password"
              id="matchPassword"
              className="block input p-2 "
              placeholder="Confirm Password"
              name="newpassword2"
              value={newPassword2}
              onChange={(e) => setNewPassword2(e.target.value)}
            />
          </div>
          <div className="mt-6 mx-0 text-center">
            <input
              type="submit"
              value="SEND"
              className="py-3 cursor-pointer btnn hover:bg-green-600  text-white text-center"
            />
            <Loader size={40} loader={loader} />
            <p className="mt-4">
              <Link to="/login">Remember password ?</Link>
            </p>
          </div>
        </form>
      </div>

      <div className="loginfooter">
        <img src={footerimg} alt="" />
      </div>
    </div>
  );
};

export default ChangePassword;
