import React, { useEffect, useState } from "react";
import DeletePost from "../Modals/DeletePost";
import { DirectoryService } from "../Utils/services";
import MapDetails from "./MapDetails";
import { useHistory, useLocation } from "react-router";


const NewMaps = () => {
    const [showDeletePostModal, setShowDeletePostModal] = useState(false);
    const [usersData, setuserData] = useState("");
    const [usermember, setusermember] = useState("");
    const [region, setRegion] = useState();
    const [regionDetails, setregionDetails] = useState();
  
    const history = useHistory();
  
    const [country, setCountry] = useState([]);
    const [regionData, setRegionData] = useState([]);
    const Handlediv = (data) => {
       setShowDeletePostModal(true);
       const regionFilterData =
        regionData && regionData.filter((item) => item.region === data);
       setCountry(regionFilterData.length);
      const totaluser =
        usersData && usersData.filter((item) => item.region === data);
      setusermember(totaluser.length);
      const regiondetails = region && region.filter((item) => item.name === data);
      console.log(regiondetails, "data");
      if (regiondetails && regiondetails.length > 0) {
        const rgeionDet = regiondetails[0];
        setregionDetails(rgeionDet);
      }
    };
  
    const HandleMouseOut = () => {
      setShowDeletePostModal(false);
    };
  
    const closeDeleteModal = () => {
      setShowDeletePostModal(false);
    };
  
    const HandleLoction = (type) => {
      const data = usersData && usersData.filter((item) => item.region == type);
      console.log(type, "typedata");
      history.push({
        pathname: "/Directory/region",
        state: { id: data, country: type },
      });
    };
  
    const getDirectoryServiceApiData = async () => {
      console.log("result");
      try {
        const result = await DirectoryService();
        console.log(result.res, "result data");
        console.log(result.res, "All Data");
  
        const _data = result.res.country;
        setRegionData(_data);
        setuserData(result.res.users);
        setRegion(result.res.regions);
      } catch (error) {
        console.log(error);
      }
    };
    useEffect(() => {
      getDirectoryServiceApiData();
    }, []);
  return (
   <>
   <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 660 380"  class="st8" space="preserve">

    <g   >
	   <g   id="Group_698_00000147899338814518071460000000864011727740663483_" transform="translate(303.989 6.378)">
		<path id="Path_2791_00000058584684145300178380000000652916248869283491_" class="st0" d="M65.4,141.3l-0.4,1.4l0,0L65.4,141.3
			L65.4,141.3L65.4,141.3z M41.9,121L41.9,121L41.9,121L41.9,121L41.9,121z"/>
		<g id="Component_32_2_00000015313416840467403500000004168104871677705614_" transform="translate(252.364 74.657)">
			<g>
				<path class="st0" d="M-223.9,95.6c0.2-0.2,0.5-0.3,0.8-0.4c0.6-0.2,1.3,0.2,1.9,0.4c1.1,0.2,2.1,0.3,3.2,0.7
					c2.4,0.8,4.2,2.8,6.6,3.7c2.9,1.1,6.3,0.3,8.4-1.9c0.4-0.4,0.7-0.8,0.9-1.2s0.2-1-0.1-1.5c-0.6-1-2-0.9-2.8-1.8
					c-0.5-0.6-0.6-1.5-1.2-2c-0.2-0.2-0.6-0.4-0.9-0.6s-0.5-0.5-0.5-0.9c0.1-0.5,0.6-0.8,0.8-1.2c0.2-0.5-0.3-1.1-0.7-1.4
					c-0.6-0.5-1-0.9-0.9-1.7c0.1-0.4,0.1-0.9,0.2-1.3l1.1-2.3l-0.2-0.1l2.3-1c1.4,0,3.2,0.2,4.4-0.5c0.6-0.3,1.1-0.8,1.7-1.2
					c1.7-1.2,3.9-1.4,6-1.1s4,1,6,1.6c0.7,0.2,1.4,0.2,2.1,0.4c0.6,0.1,1.3,0.3,1.9,0.5c0.8,0.2,1.5,0.4,2.3,0.6l3.2,0.6l1.1,1
					l2.6-0.3l1-0.8l2.7,0.8l-0.9-1.6l0.5-0.6l2.1,1.5l2.1-1.5l-2.9-4.5l-2.1-1.5l1-3.4l1.8-0.6l1.3-1.1l-1.8-1.4l-3.7-3.5l-0.2-4.3
					l1-1.4l2.4,1.8v-2.2l3-3.1h2.6l4.7,2.2l4.6-1l3.3,1l3.8-0.4v-1.8l-2.2-1.8l0.7-3h1.5l-2.2-1.9l2.2-0.7l8.9-3.5l2.2-1.8l3.6,0.5
					l0.6,2.6l6.8,1.3l3.5-2.6l8.6,9.7l2.9,0.2l3-1.1l3.3,3l2,0.7l2-0.2l1,1.5l0.6-0.6l0.4,0.5l1.3-1.3l2.7-3.4l4-1l4.3,2.4l5.2-0.6
					l-1.6-4.1l1.6-2.3l6.4,1.1l2.6,3.2l5.8-0.8l5.2,2.2l7.6-1.1l3-2.7l4.3,1.1v-0.3l2.4,0.2l1.5-1.8l-0.4-3.1l0.5-2.2v-2l-1.6-0.4
					l0.5-1.7l3.5-1.4h3.4l3.5,1.2l3.4,4.9l2.8,4.2l2.7,0.6l4.4,1.8l1,3.3l2.6,0.5l1-2l2.5-1.3l1.4,2.2l-0.4,1.8l0.4,6.8l-2.7-0.4
					l-1.2,1.2l1.2,5.1c-0.9,1.4,0.3,2,1.4,2.9s2.2,1.8,3.6,2.3c2.2,0.8,5,0.5,6.3-1.4s0.8-4.4,1.2-6.6c0.6-3.5,3.7-6.5,7.3-6.9
					c1.5-0.2,3,0.1,4.5,0s3.1-0.6,3.9-1.8c-1.4,0-2.6,1.5-2.4,2.9c0.1,1.4,1.2,2.6,2.4,3.2s2.7,0.7,4.2,0.7c2.9,0,6-0.5,8.2-2.5
					c2.8-2.7,3-7,3.6-10.8c0.7-4.6,2.3-9,3.4-13.5s1.8-9.3,0.7-13.8c-1-4.5-3.7-8.9-2.6-13.3c1-4.2,4.3-7.4,6.9-10.7
					c2.7-3.6,5.3-7.3,7.4-11.3c3.4-6.3,5.6-13.4,5.3-20.6c-0.8-13.8-11-26.1-23.7-31.3S1.1-68.7-11.8-64c-4.1,1.5-8.1,3.4-12,5.4
					c-3.6,1.9-7.2,1.8-10.8-0.5c-3.3-2.2-5.7-5.4-8.6-8c-4.7-4.2-10.8-6.6-17-7.1s-12.6,1.2-17.9,4.6c-4.7,2.9-8.5,7-12.7,10.5
					c-3.3,2.7-6,3.8-10.3,2.8c-8.8-2.1-16.2-7.9-23.8-12.9s-16.2-9.5-25.2-8.5c-2,0.2-4.1,0.8-5.8,1.9c-1.9,1.1-3.9,1.5-6,1.8
					c-4.8,0.8-9.7,0.7-14.6,0.6c-6.4-0.1-13-0.4-19.3,0.9c-5.2,1.1-10.1,3.5-15.3,5.1c-4.7,1.4-9.5,2.6-14.2,4
					c-3.9,1.2-7.5,3.7-11.3,4.5c-9,1.7-20.6-3.3-27.6-8.6c-7.5-5.7-11-7.2-20.3-8.6c-6.6-1-15.9-5-21.9-2.1
					c-10.8,5.4-21.5,11-32.1,16.8c-8.2,4.5-17.2,10.6-17.9,19.8c-0.7,8.9,6.7,16.2,9.6,24.6c3.5,10,0.5,20.9,0.2,31.5
					c-0.2,8.5,1.5,17.2,5.8,24.6s11.2,13.4,19.4,15.7c6.6,1.9,14,1.5,19.5,5.4c6.5,4.6,8.1,13.3,9.3,21.2c-4.3-0.1-9,0-12.4,2.6
					s-4.1,8.8-0.3,10.8c1.1,0.6,2.5,0.7,3.4,1.6c2,1.9,0.2,5.2-0.7,7.8c-1.5,4.2-0.3,9.1,2.9,12.2c3.2,3,8.2,3.9,12.3,2.2
					c7.7-3.2,9.9-13,14.6-19.1c2.2-2.9,4.2-5.2,7.9-5.9c1.4-0.2,2.6,0.1,4,0.2s2.9,0.1,4.3-0.4c2.4-0.9,4.2-3,6.6-3.8
					c1.5-0.5,3.2-0.4,4.6,0.3c1.4,0.7,2.6,1,4.2,0.6c1.1-0.2,2.2-0.6,3.3-0.7s2.4,0.2,3.1,1.1c0.6,0.8,0.7,1.9,0.9,2.8
					c0.3,1.7,1,3.4,2.2,4.5s3.3,1.5,4.8,0.6c0.9-0.6,1.5-1.7,2.2-2.6C-224.5,96.1-224.2,95.9-223.9,95.6z M-210.4,46.4L-210.4,46.4
					L-210.4,46.4L-210.4,46.4L-210.4,46.4L-210.4,46.4z M-67,45.1v2.8l-4.4,1.2v-1.2l2.5-1.1l1.4-3.8l1.8-3.6l-1.8-5h2.8l1.2,7.3
					L-67,45.1z"/>
			</g>
			<g  onMouseOver={() => {
                Handlediv("Europe");
              }}
              onMouseOut={() => HandleMouseOut()}
              data-tip
              data-for="registerTip"
              data-arrow-color="#fff"
              onClick={() => {
                HandleLoction("Europe");
              }}
              className="test123" >
				<path class="st0" d="M-223.9,95.6c0.2-0.2,0.5-0.3,0.8-0.4c0.6-0.2,1.3,0.2,1.9,0.4c1.1,0.2,2.1,0.3,3.2,0.7
					c2.4,0.8,4.2,2.8,6.6,3.7c2.9,1.1,6.3,0.3,8.4-1.9c0.4-0.4,0.7-0.8,0.9-1.2s0.2-1-0.1-1.5c-0.6-1-2-0.9-2.8-1.8
					c-0.5-0.6-0.6-1.5-1.2-2c-0.2-0.2-0.6-0.4-0.9-0.6s-0.5-0.5-0.5-0.9c0.1-0.5,0.6-0.8,0.8-1.2c0.2-0.5-0.3-1.1-0.7-1.4
					c-0.6-0.5-1-0.9-0.9-1.7c0.1-0.4,0.1-0.9,0.2-1.3l1.1-2.3l-0.2-0.1l2.3-1c1.4,0,3.2,0.2,4.4-0.5c0.6-0.3,1.1-0.8,1.7-1.2
					c1.7-1.2,3.9-1.4,6-1.1s4,1,6,1.6c0.7,0.2,1.4,0.2,2.1,0.4c0.6,0.1,1.3,0.3,1.9,0.5c0.8,0.2,1.5,0.4,2.3,0.6l3.2,0.6l1.1,1
					l2.6-0.3l1-0.8l2.7,0.8l-0.9-1.6l0.5-0.6l2.1,1.5l2.1-1.5l-2.9-4.5l-2.1-1.5l1-3.4l1.8-0.6l1.3-1.1l-1.8-1.4l-3.7-3.5l-0.2-4.3
					l1-1.4l2.4,1.8v-2.2l3-3.1h2.6l4.7,2.2l4.6-1l3.3,1l3.8-0.4v-1.8l-2.2-1.8l0.7-3h1.5l-2.2-1.9l2.2-0.7l8.9-3.5l2.2-1.8l3.6,0.5
					l0.6,2.6l6.8,1.3l3.5-2.6l8.6,9.7l2.9,0.2l3-1.1l3.3,3l2,0.7l2-0.2l1,1.5l0.6-0.6l0.4,0.5l1.3-1.3l2.7-3.4l4-1l4.3,2.4l5.2-0.6
					l-1.6-4.1l1.6-2.3l6.4,1.1l2.6,3.2l5.8-0.8l5.2,2.2l7.6-1.1l3-2.7l4.3,1.1v-0.3l2.4,0.2l1.5-1.8l-0.4-3.1l0.5-2.2v-2l-1.6-0.4
					l0.5-1.7l3.5-1.4h3.4l3.5,1.2l3.4,4.9l2.8,4.2l2.7,0.6l4.4,1.8l1,3.3l2.6,0.5l1-2l2.5-1.3l1.4,2.2l-0.4,1.8l0.4,6.8l-2.7-0.4
					l-1.2,1.2l1.2,5.1c-0.9,1.4,0.3,2,1.4,2.9s2.2,1.8,3.6,2.3c2.2,0.8,5,0.5,6.3-1.4s0.8-4.4,1.2-6.6c0.6-3.5,3.7-6.5,7.3-6.9
					c1.5-0.2,3,0.1,4.5,0s3.1-0.6,3.9-1.8c-1.4,0-2.6,1.5-2.4,2.9c0.1,1.4,1.2,2.6,2.4,3.2s2.7,0.7,4.2,0.7c2.9,0,6-0.5,8.2-2.5
					c2.8-2.7,3-7,3.6-10.8c0.7-4.6,2.3-9,3.4-13.5s1.8-9.3,0.7-13.8c-1-4.5-3.7-8.9-2.6-13.3c1-4.2,4.3-7.4,6.9-10.7
					c2.7-3.6,5.3-7.3,7.4-11.3c3.4-6.3,5.6-13.4,5.3-20.6c-0.8-13.8-11-26.1-23.7-31.3S1.1-68.7-11.8-64c-4.1,1.5-8.1,3.4-12,5.4
					c-3.6,1.9-7.2,1.8-10.8-0.5c-3.3-2.2-5.7-5.4-8.6-8c-4.7-4.2-10.8-6.6-17-7.1s-12.6,1.2-17.9,4.6c-4.7,2.9-8.5,7-12.7,10.5
					c-3.3,2.7-6,3.8-10.3,2.8c-8.8-2.1-16.2-7.9-23.8-12.9s-16.2-9.5-25.2-8.5c-2,0.2-4.1,0.8-5.8,1.9c-1.9,1.1-3.9,1.5-6,1.8
					c-4.8,0.8-9.7,0.7-14.6,0.6c-6.4-0.1-13-0.4-19.3,0.9c-5.2,1.1-10.1,3.5-15.3,5.1c-4.7,1.4-9.5,2.6-14.2,4
					c-3.9,1.2-7.5,3.7-11.3,4.5c-9,1.7-20.6-3.3-27.6-8.6c-7.5-5.7-11-7.2-20.3-8.6c-6.6-1-15.9-5-21.9-2.1
					c-10.8,5.4-21.5,11-32.1,16.8c-8.2,4.5-17.2,10.6-17.9,19.8c-0.7,8.9,6.7,16.2,9.6,24.6c3.5,10,0.5,20.9,0.2,31.5
					c-0.2,8.5,1.5,17.2,5.8,24.6s11.2,13.4,19.4,15.7c6.6,1.9,14,1.5,19.5,5.4c6.5,4.6,8.1,13.3,9.3,21.2c-4.3-0.1-9,0-12.4,2.6
					s-4.1,8.8-0.3,10.8c1.1,0.6,2.5,0.7,3.4,1.6c2,1.9,0.2,5.2-0.7,7.8c-1.5,4.2-0.3,9.1,2.9,12.2c3.2,3,8.2,3.9,12.3,2.2
					c7.7-3.2,9.9-13,14.6-19.1c2.2-2.9,4.2-5.2,7.9-5.9c1.4-0.2,2.6,0.1,4,0.2s2.9,0.1,4.3-0.4c2.4-0.9,4.2-3,6.6-3.8
					c1.5-0.5,3.2-0.4,4.6,0.3c1.4,0.7,2.6,1,4.2,0.6c1.1-0.2,2.2-0.6,3.3-0.7s2.4,0.2,3.1,1.1c0.6,0.8,0.7,1.9,0.9,2.8
					c0.3,1.7,1,3.4,2.2,4.5s3.3,1.5,4.8,0.6c0.9-0.6,1.5-1.7,2.2-2.6C-224.5,96.1-224.2,95.9-223.9,95.6z M-210.4,46.4L-210.4,46.4
					L-210.4,46.4L-210.4,46.4L-210.4,46.4L-210.4,46.4z M-67,45.1v2.8l-4.4,1.2v-1.2l2.5-1.1l1.4-3.8l1.8-3.6l-1.8-5h2.8l1.2,7.3
					L-67,45.1z"/>
			</g>
		</g>
	</g>
	<g     onMouseOver={() => {
                Handlediv("Oceania");
              }}
              onMouseOut={() => HandleMouseOut()}
              data-tip
              data-for="registerTip"
              data-arrow-color="#fff"
              onClick={() => {
                HandleLoction("Oceania");
              }}
              className="test123" id="Group_704_00000040553742872826127180000008554227646632115108_" transform="translate(510.17 220.962)">
		<g id="Group_702_00000096052201832893898740000013767302402590253201_" transform="translate(0 45.822)">
			<path id="Path_2807_00000018210781811179838600000016100643691774791586_" class="st1" d="M133.1,91.7c3.3-4.8,6.3-9.9,7.5-15.6
				s0.1-12.1-3.8-16.3c-4.1-4.4-11.4-6.9-11.8-12.9c-0.3-5.5,5.6-8.9,10.3-11.9s9.1-6.4,10.6-12c0.6-2.4,0.8-4.8,0.9-7.2
				c0.2-4.5,0.3-9.1-0.1-13.6c-0.3-3.1-0.8-6.2-1.9-9.1c-1.2-2.9-3.2-5.8-5.7-7.8c-2.5-2.1-7.6-2.1-10.6-1.6
				c-6.9,1.2-13.5,6.3-20.3,4.5c-6.2-1.6-10.2-8.6-16.5-9.7C87-22.3,78-23.4,76.1-19.1s-9,8.4-9,8.4C67-8.3,67-5.8,66.9-3.4
				s-0.1,4.8-0.2,7.3C67.2,4,68,4.5,67.5,5c-0.4,0.4-1.1,0.3-1.6,0.3C62,6.1,59.1,8.1,55.1,7.2C52,6.4,49,4.7,45.8,4.6
				s-6.3,1-9.3,2.1C27,10,17.3,13.5,9.4,19.7c-12.8,10-19.7,26.9-17.6,43c1.1,8.4,5.3,17.2,13.1,20.5c9.6,4,22.2-1,30.1,5.8
				c4.1,3.5,4.7,9.2,8.4,12.9c3.7,3.7,8.7,5.8,13.7,6.8c6,1.2,12.1,1,18.2,1.2c11.8,0.3,23.7,2.2,35.4,1c4.9-0.5,8.6-3.6,12.2-6.6
				C126.9,100.8,130.1,96,133.1,91.7z"/>
		</g>
	</g>
	<g  id="Group_688_00000109015384742796616650000012024905218487134910_" transform="translate(3.001 168.9)">
		<path id="Path_2679_00000081616594593839197260000010232185496058809017_" class="st0" d="M252.3,1.2l-0.8,0.6l0.8,0.6l0.4-0.6
			L252.3,1.2z"/>
		<path id="Path_2680_00000093165414521080036310000003863188084652704141_" class="st0" d="M257.1,3h-1v0.8h1.3L257.1,3z"/>
		<path id="Path_2681_00000169558315955944349590000003896531504929379988_" class="st0" d="M272.4,14.6h-0.9v0.7l0.9,0.3V14.6z"/>
	</g>
	<g    onMouseOver={() => {
                Handlediv("Asia");
              }}
              onMouseOut={() => HandleMouseOut()}
              data-tip
              data-for="registerTip"
              className="test123"
              data-arrow-color="#fff"
              onClick={() => {
                HandleLoction("Asia");
         }}>
		<path class="st2" d="M576.8,270.9l0.3-14.6c0,0-13.9-34.9-15-43.2c-1-7.9-0.7-16,1.3-23.8c1-3.9,2.5-7.6,4.5-11.1
			c1.9-3.3,4.5-6,4.7-10s-1.8-7.9-1.1-11.9c0.5-2.6,2.1-5,1.9-7.6c-0.1-1.7-1-3.2-2.2-4.4s-2.6-2.1-4-3c-1.5-0.9-3-1.9-4.8-1.8
			c-1.7,0.1-3.2,1.4-4.1,2.9c-0.9,1.5-1.2,3.3-1.5,5c-0.8,4-1.5,8.1-2.3,12.1c-2.5-1.2-4.8-2.7-7-4.4c-1.9-1.5-3.5-3.5-5.5-4.8
			l0.8-1.3l-1.2-5.1l1.2-1.2l2.7,0.4l-0.4-6.8l0.4-1.8l-1.3-2.2l-2.5,1.3l-1,2l-2.6-0.5l-1-3.3l-4.3-1.8l-2.7-0.6l-2.8-4.2l-3.4-4.9
			l-3.5-1.2H517l-3.5,1.3l-0.5,1.7l1.6,0.4v2l-0.5,2.2l0.4,3.1l-1.5,1.9l-2.4-0.2v0.3l-4.3-1.1l-3,2.7l-7.6,1.1l-5.2-2.2l-5.8,0.8
			l-2.6-3.2l-6.4-1.1l-1.6,2.3l1.6,4.1l-5.2,0.6l-4.3-2.4l-4,1l-2.7,3.4l-1.3,1.3l-0.4-0.5l-0.6,0.6l-1-1.5l-2,0.2l-2-0.7l-3.3-3
			l-3,1.1l-2.9-0.2l-8.6-9.7l-3.5,2.6l-6.8-1.4l-0.6-2.6l-3.6-0.5l-2.2,1.8l-8.9,3.5l-2.2,0.7l2.2,1.9h-1.5l-0.7,3l2.2,1.9v1.8
			l-3.8,0.4l-3.3-1l-4.6,1l-4.7-2.2h-2.6l-3,3.1v2.2l-2.4-1.9l-1,1.4l0.2,4.3l3.7,3.5l1.8,1.4l2.5-2.1h4c0,0,1,4,1.2,4.3
			s-3.9,0.5-3.9,0.5l-1.3,2.1l1.6,2.4l3,1.4l0.9,2.5l0,0l0.5,1.6l0.1,2.5l2.4,2.3l1.1,4.7l0.2,0.9l-4.8,0.9L391,172l0.2-1.3l0.5-4.2
			l1.5-1.1l-1-0.8l-2-3.1l-2.1,1.5l-2.1-1.5l-0.5,0.6l0.9,1.6l-2.7-0.8l-0.8,0.6l-0.2,0.2l-2.6,0.3l-1.2-1l-3.2-0.6l-0.2,1.2
			l-4.1,0.6l-5-1.3l-2.6-1.1l-4.7,0.9l-1.8,1.6l-2.6-0.3l-1,0.7l0.3,0.9l-3.8,0.6l-1.5,1l0.3,1.3l1,0.3l0.1,1.8l-1,0.5l3.6,3.9
			l3.7,0.9l1.4-1.4l3.6,1.9l2.6-0.8l1.5-1.3l2.6-0.1l-0.4,2l1.2,0.5l-1.2-0.5l-0.1,0.6l-0.5,2.1l-0.8,3.7l-0.2,1l-1.7,2.6l2.3,5.3
			l0.3-1.3v-0.1v3.3l4,5.4l1.4,3l1.7,0.4l1.4,3.6l0.4,2.1l3.6,3.5l2.4,3.8c0.4,1,1.1,2,1.4,3.1c0.6,1.8,0.5,3.7,1.2,5.5
			c0.7,1.7,2.1,3.2,3.9,3.5c3.7,0.6,6.8-4,10.5-3.5c3,6.4,9.3,11.1,16.3,12.2c3.5,0.5,7,0.2,10.5,0.1c3.5-0.1,7.1-0.1,10.4,1.2
			c5.9,2.3,7.5,8.7,11.2,13.2c4.2,5.3,9.7,9.2,15.1,13.1c7.2,5.1,14.9,10.5,23.7,11.1c10.5,0.8,20.1,5.9,30.7,6.5
			c5.8,0.4,9.6-1.8,14.7-4.2c3.2-1.5,6.8-2.1,9.9-3.7c3.2-1.6,5.2-3.5,9-3.7c3.6-0.2,7.1,0.6,10.7,1.4
			C566.5,272.2,571.3,271.6,576.8,270.9z M389.3,191.7L389.3,191.7L389.3,191.7L389.3,191.7z"/>
		<polygon class="st2" points="350.9,164.9 348.6,166.4 348.2,165.5 349.2,163.2 349,163.1 351.3,162.1 351.6,163 353.1,164 
			352.9,164.8 		"/>
		<polygon class="st2" points="365.2,177.6 364.2,179.1 361.6,179.8 360.7,178.4 363,177.6 		"/>
		<rect x="404.5" y="225.3" class="st2" width="2.3" height="1.2"/>
	</g>
	<g  onMouseOver={() => {
                Handlediv("Africa");
              }}
              onMouseOut={() => HandleMouseOut()}
              data-tip
              data-for="registerTip"
              data-arrow-color="#fff"
              onClick={() => {
                HandleLoction("Africa");
              }}
              className="test123" id="Component_34_2_00000023273232119400868300000008457302112969184955_" transform="translate(258.281 172.421)">
		<path class="st3" d="M147.3,73.8c-1.5-1.1-5.9-3.5-5.4-5.7c0.1-0.7,0.6-1.3,1-1.9c2.6-3.7,5.2-7.5,7.8-11.2c0.9-1.3,1.9-3,1.2-4.4
			c-0.5-1.1-1.8-1.6-3-1.5s-2.3,0.6-3.4,1.1c-4.3,2-8.7,3.7-13.2,5.2c-2.1,0.7-4,0.8-5.3-1.3c-0.5-0.8-0.8-1.7-1.2-2.5
			c-1.6-2.6-4.3-2.9-6.8-4l-2-5.1l-0.2-0.4l-2.4-2l-1.2-6V34l-2.4-1.8l-2.6-5.8l-1.8-4.4l-2.9-4.3l0.6-0.3l3.4,4.3l0.6-1l0.4-1.6
			L106,14l-0.2,0.3l-6.1-0.6l-2.5,1.4l-7.5-1.6l-0.6-0.2l-3.9-1.5l-4.4-0.1l-1.5,1.9l0.8,1.6l-1.9,2L74,15l-2.6-0.4L70.6,12
			l-6.9-1.4l0,0l-2.9-2l1.9-1.9L62.1,5l-1-1.1l1.1-2.1l-1.5,0.4l-0.9-1.1l-1.9,0.4l-4.5,0.9l-6.3,0.4l-7.2,2.7l-1.7,0.4L37,6.2
			l-3.7-0.1L32,4.7L31.1,5l-1,3.3l-4.6,3l-1.8,5.8l-3.4,4.1l-2.9,1.5l-1.1,1.5L11.2,32l-1.3,3.3l-0.3,0.8c1.5,1.6,1.4,3.6-1.2,4.1
			C7,40.4,5.6,40,4.2,39.5S1.3,38.7,0,39.1c-1.9,0.5-3.2,2.6-3.2,4.6s1,3.9,2.2,5.5s2.8,2.9,4.1,4.4c3.8,4.2,3.4,10.4,6.9,15
			c4.2,5.5,10.5,9.1,16.8,11.9s13,5.1,18.9,8.6c2.3,1.4,4.4,3.2,6.7,4.7c2,1.4,2.2,3.5,2.7,5.8c1.4,6.2,1.7,12.6,2.3,18.8
			c0.8,8.2,2,16.7,5.9,23.9c4,7.3,11.3,13.2,19.6,13.3c7,0.1,13.5-3.9,17.8-9.4c2.3-3,3.4-6.1,6.9-7.9c3.1-1.7,6.7-2.3,10.2-2.6
			c7.4-0.7,14.9-0.1,22.3-0.9c7.4-0.8,15-3,20.4-8.2c7.4-7.2,8.9-19.1,5.6-28.9C162.9,88,155.5,80.1,147.3,73.8z M21,53.8L21,53.8
			L21,53.8L21,53.8z"/>
	</g>
	<g onMouseOver={() => {
                Handlediv("Middle East & North Africa");
              }}
              onMouseOut={() => HandleMouseOut()}
              data-tip
              data-for="registerTip"
              data-arrow-color="#fff"
              onClick={() => {
                HandleLoction("Middle East & North Africa");
              }}
              className="test123" id="Component_33_2_00000173144864910318512350000012584559309975659199_" transform="translate(267.168 166.46)">
		<g id="Group_965_00000065042888277339976690000004448413972584966544_" transform="translate(88.6)">
			<path class="st4" d="M60.9,23.1L60.9,23.1L60.9,23.1z"/>
			<path class="st4" d="M60.9,23.1L60.9,23.1L60.9,23.1z"/>
		</g>
		<path class="st4" d="M170.5,4.3l-2,2.3h-1.6V3.1l-1.4-0.6L164.2,5l-2.1,0.7L160,5.1v0.6L157.7,5v0.3L154.8,6L154,8.2l-3,2.6
			l-2.9-0.8l-0.3-2.2l-8.2-4l-5.3,2.5l0.2,0.9l-4.8,0.9l-5.9-2.5l0.2-1.3L123.2,4V2.8l-1.1-1.7l-2.2,2l-2,0.1l-2.1-2.1L115,1.4
			l-0.2,0.3l0.7,3.5l1.1,1.6l-0.4,0.6l0,0l0,0h-4.6l-10,1.2v1.9l-1.2-0.5l-0.1,0.6l-0.4,2.1l0,0L99,16.5l-0.2,1L97.2,20l-0.1-0.1
			l-0.2,0.3l-6.1-0.6L88.3,21l-7.5-1.6l-0.6-0.2l0,0l-3.9-1.5l-4.4-0.1l-1.5,1.9L71,21l-1.9,2l-4.2-2.2l-2.6-0.4l-0.8-2.6l-6.9-1.4
			l0,0l0,0l-2.9-2l1.9-1.9l-0.4-1.6l-1-1.1l1.1-2.1l-1.5,0.4L50.9,7L49,7.4l0,0l-4.5,0.9l-6.3,0.4L31,11.4l-1.7,0.4l0,0l-1.2,0.3
			L24.4,12L23,10.5l-0.9,0.4l-1,3.3l-4.6,3L14.7,23l-3.4,4.1l-2.9,1.6v0.1l0,0l-1.1,1.5l-5.1,7.8L1,41.4l0,0l-0.3,0.8l1.5,1.5
			l-1.9,8.4L0.9,52l2.3-1.1h3.4l1.6,1.5L9.7,55l1.6-1v-1.6l1.4,1l1.9-1l2.1,1H23l0.6-1.8L23,50.9l-1.3-16.7l1.5-0.4l17.4,12l4-0.6
			l11.1-8l0,0l4,1.2l4.3-1.6l15.4,8.1l2-0.8l0.2-4.1h22.6v-0.1l-2.4-1.8l-2.6-5.8L97.4,28l-2.9-4.3l0.6-0.3l3.4,4.3l0.6-1l0.4-1.4
			v0.1l0.3-1.3l0,0V24v1l0,0v2.3l4,5.4l1.4,3l1.7,0.4l1.3,3.6l0.4,2.1l3.6,3.5l2.4,3.8l1.2,2.7l0.6,1.3l1.7,6.1l3-0.6l1.2-1.3l2,0.8
			l2.2-1.6l3.7-1.8l4.9-1.4v-1.8l1.4-0.5l2.8-0.9l1.4-0.4l0.6-1.3l1.6-0.4l0.8-1.8l1.7-0.1v-2.6l1.1-1.2l2.2-3.6l-2.5-2.7l-3.3-0.6
			l-0.9-2.5l-0.9-2.7l-3.6,4.6l-4.4,0.3h-0.7l-0.1-1l-0.2-1.7l-0.1-1.1l-1.2-0.6l-0.1,2.7l0,0v0.3l-1.8-4.1l-3.3-4.1h-0.1l-0.9-1.2
			l0.4-1.1h0.1l0.3-0.9h0.1l0,0l3,0.1l2.9,4.2l7.1,3.6l3.2-1.8l1.9,2.9l8.1,1.6h0.2V31l2.9-1.4l-1.5-3.2l-2.2-1l-1.5-2.3l0,0l0,0
			l0,0h10.3v-2.6l4.6-2.3l0.9-4.8l2-0.8l0.4-5l2.8-0.9l2.6-1.3V3.8L170.5,4.3z"/>
	</g>
	<g  onMouseOver={() => {
                Handlediv("Latin America");
              }}
              onMouseOut={() => HandleMouseOut()}
              data-tip
              data-for="registerTip"
              data-arrow-color="#fff"
              onClick={() => {
                HandleLoction("Latin America");
              }}
              className="test123">
		<path class="st5" d="M130.5,202.1c1.4,0.9,3.7,3.3,5.5,1.9c0.8-0.6,1.2-1.6,1.5-2.6s0.8-2,1.6-2.5c2.7-1.7,3.2,2.3,5.4,3
			c1.9,0.6,4-0.8,4.9-2.6c1.7-3.8-0.9-7.3,0.2-11.1c0.5-1.9,2-3.4,3.7-4.3c4.8-2.7,12-2.1,13.8,4.1c0.6,2.1,0.3,4.3,0.5,6.5
			s0.9,4.5,2.8,5.6c2.1,1.2,4.7,0.3,7.2,0c6.2-0.8,11.9,3.2,15.8,7.6c4.6,5.2,8,11.3,12.7,16.3c7.3,7.7,17.1,12.3,26.3,17.6
			s18.3,12,22.3,21.8c5.6,13.6-0.1,29-5.8,42.6c-4.5,12-11.3,23.7-12.2,36.7c-0.5,7.5,1.4,15.5,6.9,20.6c1.9,1.8,4.3,3.2,5.9,5.4
			s2.3,5.3,0.6,7.4c-1.7,2-5.1,1.9-7.3,0.5c-2.2-1.5-3.6-3.9-4.9-6.2s-2.7-4.7-5-6.1c-4.2-2.6-9.7-0.3-12.5,3.4
			c-3,4-6.3,7.2-11.2,8.9c-8.2,2.9-19.3,2-28,2.2c-10.2,0.2-20.4,0-30.6-0.8c-6-0.5-12.1-1.1-17.7-3.4c-7.2-2.9-10.9-7.6-13.3-14.6
			c-7.4-21.7-0.8-45.8-5.1-68.3c-3.5-18-14.2-34.6-29.3-45c-11.1-7.7-25.1-13-31-25.2c-4-8.3-3.1-18.6,1.7-26.6
			c3.9-6.6,13.3-15.6,21.6-15.4c4.6,0.1,9.5,1.6,13.6,1.6l7.9,3.2l5.5,3.7l3.7-0.8l4,8.1c1.5,0.7,2.1,3.2,3.8,3.3
			c1.6,0.2,3.1-0.4,4.7-0.3C124.2,198.4,127.6,200.1,130.5,202.1z"/>
	</g>
	<g  onMouseOver={() => {
                Handlediv("North America");
              }}
              onMouseOut={() => HandleMouseOut()}
              data-tip
              data-for="registerTip"
              data-arrow-color="#fff"
              onClick={() => {
                HandleLoction("North America");
              }}           data-border="false"
       className="test123" >
		    <path class="st6" d="M206.2,146c0.3,0-1.4,3.8-1.4,3.8l-2.2-1.2l0.8-1.7l-2.9,2.9l-1.4-0.9l3.1-2l-1.8-0.5l-3.4,0.5h-2.1l-1-0.8
			l3.6-5.4l2.6-3.9l2.1-0.8l-2.2,4v1.6h4.7l-0.6,3.8C204.7,145.6,205.4,145.8,206.2,146z"/>
		   <path class="st6" d="M224.4,14.6c0.1,0.5-0.1,2.4-0.1,2.4l-8.7,2.5l-5.8,5.3h-6.6l1.7,3.2l-5,5.4l-6,2.5l1,2.4l-5.4,2.9L184,40
			l-5.1-1.7l2.2-3.8l5.7-5.2l-2.8-1.4h-4.3l0.4-6.8h4l-2.9-1.8l1.9-6.2l5.4-5.9h2.1l1.1,7l7.1,4.1l1-1.5l-7.5-3.9l0.8-7l8.7-2.9
			L214,0.9l9.1,2.3l6.2,4l1.6,4.3C230.7,11.9,225.1,14.7,224.4,14.6z"/>
		<polygon class="st6" points="162.9,86.2 169.5,91.3 167.8,95.1 164.9,93.2 160.5,96.3 158.1,94.2 159.4,91.1 		"/>
		<polygon class="st6" points="159.4,33.6 162.9,34.7 162.9,30.8 167.8,32.6 162.5,41.5 159.4,41.5 161.4,36.7 158.1,36.7 
			158.1,30.8 		"/>
		<path class="st6" d="M145.5,32.6l2.5,1l2.5-7h2.7l-1.5,6h4.1l-4.4,6l-2.5-1.3l-9.4,1.3c0,0-3.6-6.3-4.1-5.9c0.5-0.5,7.8-6.2,8.3-6
			L145.5,32.6z"/>
		<polygon class="st6" points="158.7,19.6 153.3,17.1 159.4,14.6 		"/>
		<path class="st6" d="M153.1,60.7c0,0-3.9-9.6-4.2-10c-0.4-0.4,5.4-3.5,5.4-3.5s4.6-1.8,5.2-2v5.5l-1.9,5.9L153.1,60.7z"/>
		<polygon class="st6" points="149.6,23.9 148.8,18.6 155.8,19.6 153.3,23.9 		"/>
		<polygon class="st6" points="150.6,72.4 145.7,68.9 150.6,65.4 152.3,70.7 		"/>
		<path class="st6" d="M145.8,48.9l-2.9,8.6l2.1,5.4v2.9l-3.7-1.4l1.3,3.5h-5.2l-1.7-4.7l-6.6,3.7h-5.6v-4.7l-3.1-2.5l1-2.7l8.7,2.7
			l-2.4-4.1h-6.3V53l6.9-1.3c0,0-5.1-0.4-5.5-1s0-1.8,0-1.8l4.6-3.7h6.6l5.8,2.6l-0.8,7l4.2-9.6L145.8,48.9z"/>
		<polygon class="st6" points="134,28.1 131.8,24.1 141.9,19.6 145.5,19.6 143,25 		"/>
		<polygon class="st6" points="116.8,50.7 113.1,45.2 121.4,38.6 122.6,34.7 132.6,36.5 134,43.3 125.7,44.3 		"/>
		<polygon class="st6" points="146.9,48 145.5,44.1 150.4,44.1 150.4,46 		"/>
		<polygon class="st6" points="64.5,113.8 68.1,114.7 66.3,122.5 64.5,119.7 		"/>
		<path class="st6" d="M72.8,132.8l-3.9-4.4l0.8-1.8l2.2,1.8c0,0,3.8,4.4,3,5.6L72.8,132.8z"/>
		<polygon class="st6" points="188.4,54.4 189.6,58.6 184.1,58.6 184.1,53 		"/>
		<polygon class="st6" points="183.2,42.7 188.4,43.3 188.4,48.9 183.8,49.7 174.7,48 170.3,45.2 172,41.5 173,37.1 169.5,36.2 
			169.5,30.8 173,33.6 176.8,36.2 174.7,40.3 		"/>
		<polygon class="st6" points="184.1,79.6 178.9,83.3 178.2,79.6 181.8,77.8 		"/>
		<polygon class="st6" points="160.6,56.6 159.4,54.2 163.7,47 170.3,48.9 164.9,55.1 163.3,53 		"/>
		<polygon class="st6" points="175.2,32.6 172,30.8 172,28.1 177.4,30.8 		"/>
		<polygon class="st6" points="173,26.6 174.5,21.3 177.4,25 175.2,28.1 		"/>
		<polygon class="st6" points="167.8,21.3 163.7,21.3 167.8,14.6 173,19.6 170.3,28.1 166.8,28.1 		"/>
		<polygon class="st6" points="169.5,40.3 167.8,45.2 164.9,42.7 167.2,38.6 		"/>
		<polygon class="st6" points="198.1,76.1 194.6,79 203.3,86.6 197.3,94.4 193.8,86.8 191.3,88.5 195.2,96.5 193.4,100.8 
			188.4,97.3 190.7,103.9 184.4,99.6 182.2,95.5 178.2,93.2 173,93.2 175.2,87.9 180.9,89.7 182,86.8 186.7,80.4 182.6,74.4 
			180.5,67.9 178.2,71.4 172,69.9 164.9,66.4 165.8,60.7 173,51.8 178.2,51.8 174.7,54.8 172,60.7 173,64.3 175.2,56.6 177.4,54.2 
			182.8,53 181.4,60.7 188.4,60.7 190.3,63.9 195,68.9 		"/>
		<path class="st6" d="M107.8,41c-0.9,1.1-1.8,2.1-2.9,3c-2.9,2.3-6.6,3.8-10.2,3.3c-4.4-0.7-7.8-4.2-11.2-7.2
			C77,34.4,69.1,29.9,60.4,28.9s-18,1.7-23.7,8.3c-1.8,2.1-3.3,4.5-5.4,6.3c-4,3.3-10.1,4.2-12.3,8.9s1.2,10.2,0.3,15.3
			C18,75.5,7.1,79.6,7,87.7c0,1.7,0.5,3.5,0.4,5.2c-0.2,3-2.2,5.6-3.2,8.4c-1.1,3-1.2,6.4,0.1,9.3s4,5.2,7.2,5.7
			c4.8,0.7,10.5-2.8,14.1-5.6c2-1.5,3.5-4,5.3-5.8c2.1-2.1,4.3-4.1,6.7-5.9c1.8-1.4,3.8-2.7,6.1-2.7c2.8-0.1,5.4,1.8,7.2,4.1
			c4.4,5.3,5.9,12.5,6.3,19.4c0.4,6.9,0,13.9,1,20.7c1.2,7.7,2.8,14.4-2.1,21.3c-3.1,4.4-7.7,7.7-12.5,10.2s-9.9,4.5-14.8,6.9
			c-8.9,4.5-17.2,11-21.2,20.1c-1.6,3.7-2.4,8-1.1,11.8c1.5,4.1,5.5,7,9.8,8.1c3,0.8,6.5,0.7,8.8-1.3c3.3-2.8,2.9-8,1.6-12.1
			s-3.3-8.4-2.4-12.6c1.2-5.2,6.2-8.3,10.9-10.8c6.5-3.5,13.1-6.9,20.3-8.2c4.2-0.8,8.7-0.8,12.9,0.3c2.8,0.7,8.7,2.6,9.4,5.6
			l4.6,0.6l7.7,3.6h8.7l5,6.1l3.9-1.4l3.4,7.9l3.4,1.5l1.1-6.5l7.1-2.5l5,1.2c2.1-1.6,4.1,1.8,5.6,3.2c3.1,2.8,6.7,4.8,9.9,7.4
			c1.2,1,2.9,1.7,4.3,0.9c1.2-0.7,1.6-2.4,1.4-3.8c-0.1-1.4-0.6-2.9-0.4-4.3c0.2-1.4,1.4-2.9,2.8-2.6c0.9,0.2,1.5,0.9,2,1.7
			s0.9,1.6,1.7,2.1c2.2,1.4,2.7-1.5,2.9-3c0.4-2.2-0.1-4.6-1.7-6.3c1.4-0.2,2.8-0.3,4.2-0.5c0.9-0.1,2.9-4.4,3.4-5.2
			c1.2-2,2.2-4.1,3.1-6.2c1.6-3.7,2.7-7,6.6-8.8c2.6-1.2,5.5-1.6,8.4-1.8c5.8-0.4,11.6-0.3,17.3,0.4c1.8,0.2,3.6,0.4,5.3-0.3
			c1.9-0.8,3-2.7,4-4.5c3-5.4,6.1-11.1,5.8-17.2c-0.2-4.4-2.2-8.5-4.5-12.3s-4.9-7.3-6.8-11.3s-2.7-8.7-1.3-12.8
			c4.1-12.5,8.1-25.2,2.9-37.3c-2.6-6-6.6-11.5-7.3-17.9c-0.9-9.1,5.3-17.6,12.7-23.1s15.9-9.3,22.9-15.3c4.6-3.9,0.8-7.8-3.9-9.2
			c-2.4-0.8-5.1-0.8-7.6-0.9c-19.9-0.3-39.8,2-59.6,4.3c-5.4,0.6-10.8,1.6-15.9,3.7c-5.6,2.3-11,6.6-15.7,10.3
			c-4.7,3.6-9.1,7.6-13.3,11.9c-1.8,1.9-3.9,3.5-5.8,5.4C110,38.1,109,39.6,107.8,41z"/>
	</g>
</g>
<g>
	<polygon class="st7" points="287.4,132 287.4,132 288,131.3 288,131.3 288,131.3 	"/>
	<polygon class="st7" points="489.4,126.1 492.8,122.6 491.7,115.4 488.9,115.4 490.7,120.4 488.9,123.9 487.4,127.8 484.9,128.9 
		484.9,130.1 489.4,128.9 	"/>
	<polygon class="st7" points="284,195.9 284,195.9 283.6,195.6 	"/>
	<path class="st8" d="M0,0v380h660V0H0z M629.4,290h-1l-0.5-1.8h1L629.4,290z M625.8,281.9l1.2,2.2l-1.2,0.6l-1.2-2.2L625.8,281.9z
		 M619.7,294.5l2.2,2.1l2.8,2.5l-0.7,1.3l-3-2l-1.8-3L619.7,294.5z M615.5,268l2.1,2.8l-1.2,0.8l-1.6-3.6L615.5,268L615.5,268z
		 M618.4,273.2v1.3l-2.2-1.3v-1.1L618.4,273.2z M615.5,271.5l-0.4,1.2l-2.6-1.3l0.7-1.1L615.5,271.5z M611.1,266l2.9,1.9l-0.9,1.1
		l-2-2.4V266z M503.4,15.9h4.9v2.7h-4.6l-2.5-1.8L503.4,15.9z M505.6,28.1v2.8H500l1.3-3.8L505.6,28.1z M487.2,15.9l4.3,3.4v-3.4
		l6.8,1l1.6,3.2l-2.6,1.8l-3.9,3.9l-4.9-2.4l-2.9-4L487.2,15.9z M457.5,38.6l2.6-0.9v2.9h-2.6V38.6z M427,15.9l6.7,3.4l0.8,1.7
		l-2.3,2.2l-2.3,2.9l-1.3-0.5l-1.6-6.3L427,15.9L427,15.9z M422.5,15l3.5,5.1l-1.4,1.8l-5.2-0.4l-3.5-1.5v-2.5l3.5-4.1L422.5,15z
		 M411.4,7.5l6.1,5.5l-2.8,3.8l-4.5-0.9l-1.1-3.6L411.4,7.5z M402.1,56.8l1.3,2.5h-2.5l-1-1.2L402.1,56.8z M380.4,48.9
		c0,0,5.5-2.8,5.6-2.8s2.5-2.8,2.5-2.8l2.3-1.8l2.3,3.7l-7.1,6.2l-3.5,6.7l-2,8l2.5,5.1l3.8,1.8l-1.8,1.6l-5.2-0.9l-4.7-4.2l1.7-2.3
		v-2.6l0.7-7.9l-0.7-2.9l2.9-1.6L380.4,48.9z M373.4,79.3l3.3,0.6V83h-2.6l-0.7-1.6L373.4,79.3L373.4,79.3z M372.1,29l-1.3-0.9
		l1.7-1.9l0.9,0.9L372.1,29z M373.4,16.9h2l1.3,2.5l-1.3,2.5l-2-2.5V16.9z M367.6,15v1.9h-1.2l-1.2-1.9H367.6z M364,20.9l1.3-1.6
		v-1.9h2.3l-1,1.9l2.6,2.5l-1.6,2.5h-1.8l-1.8-1V20.9L364,20.9z M367.2,25.7l2-1.4l2.5,1.4l-0.9-3.7l2.6-1l0.7,1l-1.7,0.7l1.7,1.6
		l-2.5,1.4l-1.5,1.9l-1.5,0.5l-2-1l-2.6,1l-0.6-3.1L367.2,25.7z M361.9,25.7l-1.8,2.4l-1,2.8l-2-1.2l1.1-4l1.4-2.4L361.9,25.7z
		 M363.6,29.9l-1.7,1l-1.3-1.9h1.3L363.6,29.9z M355.3,23.3h1.8L358,25h-1.5l-0.5,3.1l-3.4-1.3L355.3,23.3z M322.8,30.8h2.9v1.5
		l1.4,0.6l1.9-2l3.9,1.4l-0.6,4.8l-3.2,1.5l-2.6-0.7l-2.5-1.5l2.5-1.6l-2.8,0.7l-2.2-1.9L322.8,30.8z M327,48.2l-0.2-4.2l-1.1-2.5
		h2.2l-0.9,1.8l1.9-0.5v1.8l3.2,0.7l-2.6,3.7v-2.5L327,48.2z M315.8,34.6l1.6,0.6l1.7,4.8l-0.6-5.7l1.2-0.7l2.2,1.9l5.1,5.2
		l-2.6,0.9L321.9,53l-3-3.5l-2-4.4l4.9-1.8l-3.9-2.3l0.4,2.3l-1.5,0.8l-1-1.6l-3.3-5.5L315.8,34.6z M297.1,113.5l1-0.6v0.9l-0.3,0.9
		l-0.7-0.3V113.5L297.1,113.5z M290.2,123.1l0.7-1.2l0.6-2.2h2.9v1l-1.4,0.9l-0.1,1.1l2-0.4l1.5,0.4l-1.6,3l0.2,0.7l-1.6,0.6h1.8
		l1.5,1.5l1.3,2.4l0.6,0.3l1.1,2.4l0.5,0.8l-0.3,0.9h2.5l0.5,1.6l-1.9,2.2l1.6,0.5l-1.8,1.6c0,0-4.2,0.3-4.4,0.2
		c-0.1-0.1-1.8-0.1-1.8-0.1l-1.2,1.5l-1.2-0.3l-1.8,1.1l-0.9-0.4l2.1-2.1l0.9-0.8h2.1l1-1.8l-1.5,1.1l-1-0.2l-1-0.5l-1.4,0.2l-0.3-1
		l1.9-0.9l0.1-1.2l-0.9-0.2l0.2-1.8l2.4,0.3l0.4-2.4h-1.1c0,0-0.3-0.7-0.3-0.9c0.1-0.5,0.3-0.9,0.5-1.4l-1.9,0.6h-1l0.6-1.9v-1.8
		l-0.6,2.3h-0.9l0.1-1.9l0.6-2l-1.5,1.6l-0.3-1.3l0.9-1v-1.1L290.2,123.1L290.2,123.1z M289.1,132.3l-0.1,0.1l0.2,0.7l0.2,2.1
		l-0.3,1.9l-0.6,0.2L286,138l-2,1.1l-1.4-0.6l-0.8-1.6l1.1-0.2l1.9-0.7h-1.9l1.5-1.6l-1.5-0.8l0.8-0.8l-0.8-0.5v-1.1h2.6l0.8-0.5
		l-0.8-0.5l1.2-1.4l1.6,0.3l0,0l0.3,0.1l1.3,0.2l0.4,2.3L289.1,132.3l-0.5-0.6L289.1,132.3z M291.5,132.8v-1.1l0.9-0.4v0.8
		L291.5,132.8z M289.8,123.9h-0.5c-0.2-0.4-0.5-0.9-0.8-1.3c-0.2-0.1,0.8-0.5,0.8-0.5l0.5,1.1V123.9z M289,121.6l-0.9,1l-0.2,1.9
		l-0.2,0.2l-0.2-2.4l0.7-0.4v-1.2l1.5-0.3L289,121.6z M288.7,107.8l0.2,1.1v2.1h-0.2l-0.5-1.8l-0.5-1.1L288.7,107.8z M184.1,28.2
		h-4.3l0.4-6.8h4l-2.9-1.8l1.9-6.2l5.4-5.9h2.1l1.1,7l7.1,4.1l1-1.5l-7.5-3.9l0.8-7l8.7-2.9l12.2-2.1l9.1,2.3l6.2,4l1.6,4.3
		c0,0-5.6,2.8-6.3,2.8c0.1,0.5-0.1,2.4-0.1,2.4l-8.7,2.5l-5.8,5.3h-6.6l1.7,3.2l-5,5.4l-6,2.5l1,2.4l-5.4,2.9l-5.5-1.2l-5.1-1.7
		l2.2-3.8l5.7-5.2L184.1,28.2z M206.2,146c0.3,0-1.4,3.8-1.4,3.8l-2.2-1.2l0.8-1.7l-2.9,2.9l-1.4-0.9l3.1-2l-1.8-0.5l-3.4,0.5h-2.1
		l-1-0.8l3.6-5.4l2.6-3.9l2.1-0.8l-2.2,4v1.6h4.7l-0.6,3.8C204.7,145.6,205.4,145.8,206.2,146z M197.3,94.4l-3.5-7.6l-2.5,1.8l3.9,8
		l-1.7,4.3l-5-3.5l2.3,6.6l-6.4-4.3l-2.1-4.1l-4.1-2.3H173l2.2-5.2l5.7,1.8l1.2-2.9l4.6-6.4l-4.1-6l-2.1-6.4l-2.3,3.5l-6.2-1.5
		l-7.1-3.5l1-5.7l7.1-8.9h5.2l-3.5,3L172,61l1,3.6l2.2-7.7l2.2-2.4l5.4-1.2l-1.4,7.7h6.9l1.9,3.2l4.6,5l3.1,7.2l-3.5,2.9l8.7,7.6
		L197.3,94.4z M178.2,79.6l3.7-1.8l2.2,1.8l-5.1,3.7L178.2,79.6z M145,62.9v2.9l-3.7-1.4l1.3,3.5h-5.2l-1.7-4.7l-6.6,3.7h-5.6v-4.7
		l-3.1-2.5l1-2.7l8.7,2.7l-2.4-4.1h-6.3V53l6.9-1.3c0,0-5.1-0.4-5.5-1s0-1.8,0-1.8l4.6-3.7h6.6l5.8,2.6l-0.8,7l4.2-9.6l2.5,3.7
		l-2.9,8.6L145,62.9z M145.5,44.1h4.8V46l-3.5,1.9L145.5,44.1z M150.6,65.4l1.7,5.2l-1.7,1.7l-4.8-3.5L150.6,65.4z M148.8,50.7
		c-0.4-0.4,5.4-3.5,5.4-3.5s4.6-1.8,5.2-2v5.5l-1.9,5.9l-4.4,4.1C153.1,60.7,149.2,51.1,148.8,50.7z M163.7,47l6.6,1.9l-5.4,6.2
		l-1.5-2.2l-2.7,3.6l-1.2-2.4L163.7,47z M164.9,42.7l2.3-4.1l2.3,1.7l-1.7,4.9L164.9,42.7z M173,33.6l3.9,2.5l-2.1,4.1l8.5,2.4
		l5.2,0.6v5.6l-4.6,0.8l-9.1-1.8l-4.4-2.8l1.7-3.7l1-4.4l-3.5-0.9v-5.3L173,33.6z M172,30.8v-2.7l5.4,2.7l-2.2,1.8L172,30.8z
		 M189.6,58.6h-5.5V53l4.3,1.4L189.6,58.6z M177.4,25l-2.2,3.2l-2.2-1.5l1.5-5.3L177.4,25z M167.8,14.6l5.2,5.1l-2.7,8.5h-3.5l1-6.9
		h-4.1L167.8,14.6z M162.9,30.8l4.8,1.7l-5.2,8.9h-3.1l1.9-4.8H158v-5.9l1.4,2.8l3.5,1.1V30.8L162.9,30.8z M159.4,14.6l-0.8,5.1
		l-5.4-2.5L159.4,14.6z M155.8,19.6l-2.5,4.3h-3.7l-0.8-5.2L155.8,19.6z M150.6,26.6h2.7l-1.5,6h4.1l-4.4,6l-2.5-1.3l-9.4,1.3
		c0,0-3.6-6.3-4.1-5.9c0.5-0.5,7.8-6.2,8.3-6l1.9,6l2.5,1L150.6,26.6z M141.9,19.6h3.7L143,25l-9.1,3.2l-2.1-4.1L141.9,19.6z
		 M121.4,38.6l1.2-3.9l10,1.8l1.4,6.8l-8.3,1l-8.9,6.4l-3.7-5.5L121.4,38.6z M12.1,204.6h-0.6v-1h1.1L12.1,204.6z M15,206.5l-1-0.8
		l1-1.2C15,204.6,15.6,206,15,206.5z M16.3,207.1v-1.4l2.2,1.2l-0.4,1.2L16.3,207.1z M18.7,211.9l-0.6-1.9l0.6-1.9l2.5,2.3
		L18.7,211.9z M66.3,122.5l-1.8-2.8v-5.9l3.7,0.9L66.3,122.5z M136.2,253.8v-2.9l0.8,1l0.8,1.9H136.2z M138.9,253.8l-0.4-1.5
		l1.6,1.5H138.9z M206.4,368.3h-3.7l0.8-1.8l4.6-1.9l1.4,1.9L206.4,368.3z M225.9,276.5v9.9l-4.2,10.5l-12.7,6.8v4.5l-6.5,11.6
		l-2.7,4.9l-7.9-2.3l-0.6-0.2l4.2,4.9l-1.2,5.2l-8.7,1.8v5.8l-4.6-1l3.1,3.1l-2.5,7.4l-3.5,3.1l4.6,2.7l-4.6,10.5l9.3,10.3l-1.7,1.9
		l0,0l-1,1h-5.2l-6.8-4.5l-5-7.9l-1.9-8.6l-1.7-3.5l1.9-2.1l0.8-4.3l0.6-6l-2.5-3.1l-0.8-7.4l1.4-16.9l-0.2-25.4l-0.4-0.3l0,0
		l-5.2-4.7l-6.4-4.9l-6-12.4l-4.8-6.4l1.8-1.6l0,0l1.1-1l-1.7-3.3l3.5-6l0.2-0.3l3.9-6.7l-1.9-7l-1.4-1.9l-2.1,2.1l-1.2,1.7
		l-4.4-1.8l-0.8-0.3l-4.2-2.5l-1.3-3.1l-0.4-1l-2.3-2.1l-1.1-1l-3.4-0.6l-2-0.4l-2.5-2l0,0l-3.9-3.2l-3.3,1.7l-6.4-2.7l-12.5-7.4
		l1.3-3.9l-5.2-11.1l-4.1-3.5l-2.1-7.6l-3.3-0.5v4.4l3.3,4.5l1.7,5.5l2.9,4.4l-1,1.9l-3.7-4.3l-2.5-4.9l-3.9-3.5h2.5l-2.9-7.1
		l-1.1-4.7l-0.4-1.7l-5.2-4.4l-2.9-9.6v-9.5l3.7-9.9l1-9.3l4.6-0.4l-0.5-0.9l0,0l-5.7-9.4l-2.3-10.8v-0.1l0,0l-3.1-9.9l-3.5-10.7
		L55,87l-5.4-3.3l-1.9,3.7l-6,2.1l4.4-6.8l-4.2,1.4l-5.4,3.1l2.3,2.7L32,93.2l-15.4,8.5l-6.4,1.2l-1.7-1l14.1-5.6l7.7-7.4h-1.9
		l-5-1.6l1.4-5.8l-3.9-0.8V73l6.4-5.2l8.7-1.4l2.5-5.7L31.8,62l-2.9-1.3l-0.8-7.1l9.1-2.7l2.1,1.6l-2.1,1.9l3.1,1.4l4.4-1.8
		l-3.5-3.9l-1-8l2.5-3.1l3.5,1.2l9.3-5.5l9.7-2.1l4.2,3.5l1.3,4.5H73l7.5,4.7l3.1,3.7l0.2,0.2l0,0l0,0l6.2,5.7l16.6-4.8v7l2.9,1.5
		l3.5-1.5l8.1,5.6l1,4.7L119,68l2.5,2.3l6-0.2l1.9,3.3l1.9-5.6h4.6l1.7,5.1l6.4,1.6l3.7-2.5l1.7,6.2l3.5-3.9l2.5-4.7
		c0,0-2.5-2.7-3.1-3.1s1.9-5.5,1.9-5.5l5.6-3.2l1,7.8l-1.9,4.7l0.8,3.9l3.3-3.9l-0.6,5.2l0.8,4.1l4.8-6l1.9-3.3l5.2,3.1l-1.4,2.7
		h-2.2l0.8,4.3l-2.1,2.7l-6.9,1.9l-2.5,3.3l-6.6,3.5l-5.2,3.7l-10,12.6l2.5,0.2l-1.4,8.2l4.4-1.5l7.7,7.4l5.4,0.9l-2.1,6.9l1.7,4
		l4.1-1.2l-0.2-8.9l5.4-1.8l1.9-3.4L166,113l3.1-4.9l1.9-9.5l7.9,1.7l1.4,3.7l2.9,2.1l-0.8,7.2l4.6,1.8l3.9-6.9l4.2,11.7l-2.3,1.8
		l4.2,5l3.7,2.6l1.2,4.8l-3.1,2.9l-6,2.2l-10-0.7l-3.3,2.4l-5.9,5.7l9.9-5.7l2.3,2.8l-3.5,1.7l1.2,4.1l4.1,0.8l3.5-1.8l1,1.8
		l-7.9,3.4l-4.4,2.7l-0.8-3.9l-0.9,0.4l0,0l-6.7,3.1l-0.6,4.2l-6.8,2l-0.6,0.6l0.4,1.2l-2.6,3.3l-0.3-2.1l-0.4,0.3l0.7,2.9l-1.7,2.8
		l-0.9-3.8l-0.2,0.2l-1.7,9.9l-10.2,7l1.6,9.8l-0.4,4.7l-2.1,1.2l-2.5-5.8l0.2-4.1l-3.1-1.8l-10.2-1l0.4,1.6l-2.3,1.2l-5-1.2
		l-7.1,2.5l-1.1,6.5l0,0l-1.6,9.6l4.8,5.6l6.2,0.4l3.3-5.8l5.8-1.8l1.4,1l-2.7,6.6l-0.2,0.7l-1.6,5.4l0.5-0.1l-1.3,1.3l1.3-1.3
		l4.3-1.3l4.9,1.9l0,0l0.5,0.2l-1.5,8l0.2,0.2l2.5,3l0.6,0.7l5.8-1.2l4.2,2.7l1.9-4.1l8.7-4.1l0.7,0.6l2.9,2.3h13.7l-0.8,1.9
		l5.4,3.8l0.4,0.2l5.2,4.5h6.9l3.4,4.4l3.2,4.1l-0.6,2.7l6,2.6l9.8,2.6l13.3,5.4l2.1,4.7L225.9,276.5z M68.9,128.4l0.8-1.8l2.2,1.8
		c0,0,3.8,4.4,3,5.6l-2.1-1.2L68.9,128.4z M169.5,91.3l-1.7,3.9l-2.9-1.9l-4.3,3.1l-2.4-2.1l1.4-3.1l3.5-4.8L169.5,91.3z
		 M186.8,224.6l-0.6,0.5l-0.9-1.1h0.9L186.8,224.6z M181.4,216l-0.7-0.9l1.2-0.5L181.4,216z M182.4,218.6l-1,0.6V218l0.5-0.4
		L182.4,218.6z M182.4,225.7v1.3h-1v-1.3H182.4z M181.4,228.7h2l-1,2.3h-1V228.7L181.4,228.7z M182.9,224.1l-0.5-0.6l1-0.8
		L182.9,224.1z M182.9,222.2l-1-1.5h1l0.5,1.1L182.9,222.2z M183,218.4l-0.4-0.8l0.8-0.6L183,218.4z M179.5,214.2v1.9l-0.9-0.9
		L179.5,214.2z M174.3,213.2l-0.4,1.9h-3.3v-1L174.3,213.2z M151.6,210.9H151l1.4-1.6l-2.6-0.8l-0.6-1.4l-3.9-1.4l-1.8-0.8l-2.1,0.8
		l-4.1,0.7l0.9-1.6l2.4-0.9l2.5-0.7l1.4,0.8h2.4l1.4,1.6h2.4l4.1,2.2l3.3,1.9l-2,1.1l-1.9-0.5L151.6,210.9z M153.9,214.6l-1.4,0.5
		h-1.6l-1.7-1.2l3.4-0.7L153.9,214.6z M150.7,200.5l0.9-1.4l0.6,1.8l0.6,1.4l-1.2,0.5V201L150.7,200.5z M152.2,195.6l-1.7,1.2
		l-0.5-1.2l2.2-0.9V195.6z M152.8,198.1v-2.3l0.6-0.6l0.6,2L152.8,198.1z M156.6,202.8l0.4,2l-1.4-2H156.6z M155.6,201.9v-1.8
		l1.1,1.8H155.6z M161.5,213.2l-0.8-1.4l-1.4-0.9l0.6-0.5l2.1,0.5l0.9-0.4l0.3-0.1l2.4,0.5l1.2,0.9l2.6,1.4l-0.6,1h-4.2l-1.7,1.9
		l-1-1.4H158l-1.1-1.5H161.5L161.5,213.2z M159.1,205.7h-1V204h1V205.7z M160.6,207.7l-0.4,1h-1.6l0.5-1H160.6z M244.5,374.4l-1-3.5
		l2.7,1.8L244.5,374.4z M245,92.2l-8.7,2.5l-9.6,19.1l-5.6-4.5l-3.9-5.5l-2.5-16.7l3.9-2.1l3.9-6.6H217v-3.9l4.1-3.3l-3.1-3.1
		l1-19.2l-12.5-1.3l-3.1-7.2l8.5-1.8l-6-1.3l-1-5.2l10.4-2.1l3.9-3.7l-2.7-2.9l6.8-4l2.5,1.5L228,15l10.8-2.8l-1.5,5.2l3.7,0.1
		l2.3-5.4l1,7.1c0,0,3.5,0.5,4.1,0s-2.9-7.1-2.9-7.1l5.4-2.9l4.6,0.8l4.4-2.6l11.2,2.6l-10.2,3.3l10.5-1.2l1.9,1.2l4.2,3.5
		l-16.7,4.9l9.2-0.9l-0.8,2.4l5.4-2.4l-5.4,8.1l12.5-8l5.7,4l-15.1,20.2l3.1,2.2l-0.9,3.5l-4.1-0.6l1.9,3.5l-1.5,10.1l-3.1,2.7
		l-5-0.4l4.1,6.8v5l-2.9-0.4l-4.5-5.2l-1.5,5.8l8.9,1.1l-16,6.5L245,92.2z M255.3,171.2l-0.8-0.6l0.8-0.6l0.4,0.6L255.3,171.2z
		 M259.1,217l-0.5-0.6l1-0.2l0.2,0.4L259.1,217z M259.2,172.7v-0.8h1l0.3,0.8H259.2z M261.3,217.7h-0.5V217l0.5,0.4V217.7z
		 M261.2,98.1h3.1l0.4-1.4c0,0-3.5,0.1-3.8,0s1.6-2.3,1.6-2.3l1.6-1.2l1.9,2.4l-0.2,1.6l1.6-0.5l0.1-2.2l2.9,0.3l4.1-1.6l2.3,1.9
		l0.7,3.4l-1.5,2.2l-7,3.1l-2.9-1.2h-2.6l-0.6-1.4l1.6-0.5l-1.1-1.4h-1.9L261.2,98.1z M274.5,184.3v-0.7h0.9v1L274.5,184.3z
		 M275.1,191.9v0.5h-0.9l0.4-0.8L275.1,191.9z M274.2,192.8l-0.6,1.5h-0.8l0.7-1.5H274.2z M271.4,194.4l-1,0.5v-1h0.6L271.4,194.4z
		 M269.8,192.8v1l-1.4,0.4l-0.5-0.8L269.8,192.8z M267.2,192.8l-0.5,0.6v-1.2L267.2,192.8z M261.6,217.3h0.3l0.3,0.4h-0.6
		L261.6,217.3L261.6,217.3z M262.5,221.1h-0.7v-1.3l0.7,0.7V221.1z M263.1,220.5h-0.2l-0.2-0.6h0.4L263.1,220.5L263.1,220.5z
		 M263.4,218.9l-0.3-0.5l0.5-0.2l0.2,0.8h-0.4L263.4,218.9L263.4,218.9z M263.9,217.3h-0.4V217l0.4-0.2V217.3z M266.4,194.4l0.4,0.8
		h-0.7L266.4,194.4z M313.4,250.8h-0.8V250h0.8V250.8z M397.7,282.6l-1-0.3l-6.1,19l-4,1.4l-3.3-4.2V295l2.3-5.1l-1.2-3.6l1.8-2.9
		l4.1-1.3l2.4-2.7l3.1-4.7l1.6,6v1.9L397.7,282.6L397.7,282.6z M384.1,274.7v-1.5h0.8v1.5H384.1z M387.6,277.1v-1.2h1v1.2H387.6z
		 M406.8,226.5h-2.3v-1.2h2.3V226.5z M408.3,293.9h-1.4v-1.5h1.4V293.9z M408.3,261.1V260h0.8l0.4,1.2h-1.2L408.3,261.1L408.3,261.1
		z M411.8,292.4l-0.5-0.4v-1h1.1L411.8,292.4z M460.5,235.6c-0.1,0.8-0.1,1.6,0,2.4l-2.9,1.8l-1.2-3.9l0.7-4.4l1.4,0.7
		C458.5,232.1,460.7,235.3,460.5,235.6z M482.2,225.4l-0.8,2.2v2.5l-0.8-0.7l0.6-4.9l1-1.2L482.2,225.4L482.2,225.4z M481.4,211.5
		l0.9-0.7l-2-1.6l-0.9-1.1l0,0l-1-1.2l-1.1-3.1l-1.7,0.3l-2.2,1.8h-0.2l0,0l-2.2-0.4l-1.7,2.6l-3.1,2.5l-3.5,4l-5.2,4.1v4.9
		l-0.8,6.4l-3.1,3.9l-1.4,0.5l-4.7-7.8l-3.3-7.1l-2.5-7.4l-1-7.6L440,207l-2.7,1.6l-4.1-3.8l1.5,0.3l1.8-1.6l-2.7,0.3l-1.2-1.5
		l-0.4-0.1l-1.7-0.6l-2.2-3h-3.1l-6.1,0.4v0.2h-0.2l-8.1-1.6l-1.9-2.9l-3.2,1.8l-7.1-3.6l-2.9-4.2l-3-0.1l0,0h-0.1l-0.3,0.9l0,0
		l-0.3,0.9l0.9,1.2l0,0l3.4,4.1l1.8,4.1v-0.2v0.4l0,0l0.1-2.7l1.2,0.6l0.1,1.1l0.2,1.7l0.1,1l0.7-0.1l4.4-0.3l3.6-4.6l1,2.7l0.9,2.5
		l3.3,0.6l2.5,2.7l-0.1,0.1l0.1,0.1L414,209l-1.1,1.2v2.6l-1.7,0.1l-0.8,1.8l-1.5,0.4l-0.6,1.3l-1.5,0.4l-2.8,0.9l-1.4,0.4v1.8
		l-4.9,1.4l-3.7,1.8l-2.2,1.6l-2-0.8l-1.2,1.3l-3,0.6l-1.7-6.1l-0.5-1.3l-1.2-2.7l-2.4-3.8l-3.6-3.5l-0.4-2.1l-1.4-3.6l-1.7-0.4
		l-1.4-3l-4-5.4v-0.2v-2.1v-0.9v0.1l0,0l-0.3,1.3V192l-0.4,1.5l-0.6,1l-3.4-4.3l-0.5,0.3l2.9,4.2l1.8,4.4l2.6,5.8l2.4,1.8v0.1l0,0
		v0.1l1.2,6l2.4,2l0.1,0.4l0,0l2,5.1l2.2,0.9l5.1,4.9v0.2l0,0l-0.1,1.5l0.4,0.4l0,0l2.5,2.6l12.9-3.5l-2.1,8.1l-6.3,9.3l-9.9,9.4
		l0,0l-2.1,2L378,260l0,0l-1.6,3l3.1,8.6v0.4l0,0v6.9l-0.7,3.5l-11.3,8.2l1,5.1v4l-5.4,2.9l0.2,1.1l-0.4,1l0,0l0,0l-2.4,5.2
		l-10.8,10.2l-11.7,2.3l-3.7-1.2l0.1-4.1l-3.8-8.8v-0.1l-2.7-8l-1.9-8l-3.4-6.3l0.1-0.4l0,0l2-8.1l2.3-4.4l-2.9-10l0,0l-0.2-0.6l0,0
		l-0.4-1.3l-0.3-1l-1.1-1.4l0,0l-4.5-5.8l0.9-3.6l0,0l0.6-2.4l0.4-1.8l-1.8-3.3h-0.4l0,0l-5.8,0.6l-4-4.4l-0.6,0.2l-2.2,0.8l0,0
		l-1.5,0.6l-5.9,2.2L295,241l0,0l-2.7-0.8l-5.9,1.9l0,0l-0.1,0.1l-7.8-4.9l-1.3-0.8l-1.8-3.9l-0.1-0.2l-3.1-3.1l-0.2-0.2l-1.9-3
		l-3.2-4.9l0.6-2.6l0.7-0.1l2.3-1.1h3.4l1.6,1.5l0,0l-1.6-1.5h-3.4l-2.3,1.1l-0.7,0.1l1.9-8.4l-1.5-1.6l0.3-0.8l0,0l1.3-3.3l5.1-7.8
		l1.1-1.5l0,0l0.1-0.1h-0.1l2.9-1.6l3.4-4.1l1.8-5.8l4.6-3l1-3.3l0.9-0.4l1.4,1.5l3.7,0.1l1.2-0.3l0,0l1.7-0.4l7.2-2.7l6.3-0.4
		l4.5-0.9l0,0l1.9-0.4l0.9,1.1l1.5-0.4v0.1l0,0l-1,2.1l0.9,1.1l0.4,1.6l0,0v0.1L319,181l2.9,2l0,0l0,0l6.9,1.4l0.8,2.6l2.6,0.4
		l4.2,2.2l1.9-2l-0.8-1.5l0,0l0,0l1.5-1.9l4.4,0.1l3.9,1.5l0,0l0.6,0.2l7.5,1.6l2.5-1.5l6.1,0.6l0.2-0.3l0,0l1.7-2.6l0.2-1l0,0
		l0.8-3.7l0.5-2.1l0.1-0.6l1.2,0.5l0,0l-1.2-0.5l0.4-2l-2.6,0.1l-1.5,1.3l-2.6,0.8l-3.6-1.9l-1.4,1.4l-3.7-0.9l-3.6-3.9l1-0.5
		l-0.1-1.8l-1-0.3l-0.3-1.3l1.5-1l3.8-0.6l-0.3-0.9l1-0.7l2.6,0.3l1.8-1.6l4.7-0.9l2.6,1.1l5,1.3l4.1-0.6l0.2-1.2l0,0l0.3-1.8
		l-3-2.3l-3.9-2.8l-2.2-1.3l-0.1-1l1.3,0.1l1.1-3l0.6-1.6l-0.8,0.2l0,0l-2.7,0.8l-2.7,1l-2.2,1.2l1.8,1.6l2.6-0.4v1.4L361,156
		l-0.2-1.5L359,154l1.6-1.4l-1.5-0.6l-2.6-1.4l-2.6,3v0.3l0,0l-0.2,1.3l-1.5,0.4v2.9l-1.4,2.1l0.5,1.5l0,0l0.3,0.9l1.5,1l-0.2,0.8
		L351,165l-2.3,1.5l-0.4-0.9l-0.2-0.5l-4.4,0.4l1.1,1.4l-1.3,0.5l-1.7-1.2l1.4,2.6l-0.7,0.7l2.4,3l-1.1-0.1l0.1,1.3h-1.2l0.6,1.8
		l-2-0.4l-1.9-2l0.3-1.9l-1.6-1.8l-0.5-0.7l0,0l-1.8-2.1v-3.4l-0.4-0.3l0,0l-1.9-1.3l0,0l-1.6-1.2l-0.2-0.1l0,0l-3.6-2.4l-1.4-2.9
		l-1.7,0.9l-0.4-1.2l-0.3-0.9l-0.2,0.1l-1.7,0.3v2.6l2.2,1.9l1.8,2.8l3.4,1.2l-0.6,0.9l5,2.6l-0.5,1.6l-2.2-1.6l-0.9,1.6l1.2,1.3
		l-1.6,3.3l-1-0.1l0.5-2.2l-0.7-2.7l-1.9-1.6l-4.1-2l-3.4-3.4l-1.4-2.8l-2.5-0.7l-1.9,1.4l-2.5,1.9l-2-1l-2.1-0.4l-1.2,1.4l-0.2,1.8
		l-0.2,1.5l-2.6,1.2l-3.1,3.7l0.7,1.8l-1.3,3.6l-2.4,1.1h-3.4l-3.5,1.8l-1.9-2.3h-1l0,0h-3.2l0.1-3.1l-1-1.4l1.7-3.6l-0.1-3.2
		l-0.1-0.4l-0.8-2.6l2.3-1.6l11.4,1.2l0,0l1.4-5.5l-2.1-3.2l-5.4-4.3l4.5,0.1l1.7-0.1l-0.2-2.5l1.4,0.8l1.7,0.1l1-1.3l1.4-0.4
		l1.2-2.4l0.8-0.5l0,0l1.8-1.1l0,0l1-3.7l4.3-1.3l0,0l2.9-0.9l-0.8-3.6l-0.9-4.3l4.5-3.7l-0.4,6.2l-1.6,1.5l0.8,1l1.3,1.6l4.4-0.3
		l3,1l4.5-2h9.1l0,0h0.3l-0.1-1.3l-2.9-2.3v-3l2-2.2l1.5,1.8l1.5-0.2l-0.1-2.1v-1.3l-1.5-0.1l-0.6-2.2l2.4-1.5l4.8,0.3l0.1-0.1l0,0
		l2.3-2l-2.1-1.8l-7.5,2.3l-4.2-2.2l-0.8-7.3l5.5-6.6v-2.3l-1.6,0.2l0,0l-3.7,0.3l-0.3,4.5l-5.9,5.8l0.3,5.1l2.4,2.1l-0.4,3.4
		l-2.5,1.5l-0.1,6.3l-5.5,3.3l-4.1-10.6l-0.6-1.5l-5.5,4.5l-3.4-1.7l-1.1-11l6-3.7l4.4-5.2l6.7-14.5l7.2-6.5l8.6-3l5.5,2.9l-2.6,1.3
		l3.1,1l0,0l1.2,0.4l3.3,1.2l3.8-0.6l9.6,6.5l-0.5,3.1l-4,1.8l-4.1-1.3l-5-2.2l-0.8,0.4l4.7,3l-0.3,2.7l1.8,3.6l3.4,1.7l1.2-1.5
		l-3.1-1.4l0.7-1.8l4.8,2.3l1.2-1.4l-1.9-2.5l3.6-3.7l3.3,1.1l-0.2-3.4l-1.3-0.4l-1.2-5.5l3.3,0.3l2,2.2l-1.9,1.6l1.6,2.3l2.4-0.3
		l0.3-2.6l1-0.9l6.7-4.8l0.2,2.2l2-0.1l0.8-1.3l5.2-1.8l1.2,1.8l1.5-1.7l-1.5-2.5l0.4-1.4l3.4-0.1l5.4,2l5.1,3.2l0.3-2.9l-2.5-2.1
		l-1.5-0.3l-3.2-6.1l1.5-3l-0.2-6l5.4,0.4l0.8,6l1.8,1.6l2.5,7.1l2.7,2.7l-1.4,7.9l1.8,0.7l1.2-1.7l1.1-3.6l-1.5-3.9L417,76l2,1.8
		l1.5,1l0.1-1.8l-4-2.3l-2.8,1.4l-1.3-1.2l-1.2-5.2l-2.6-2l-1-2.7l1.5-1.4l-1.4-3.9l1.3,0.1l2.1,6.7l5.8,0.7l-3.9-1.2l-1.5-3.1
		l2.6,0.9l-0.9-1.8l1.3-1.7l4.7,2.6h3.3l0.8,5l1.6-1.7l-1.4-3.4l-0.8-0.5l-1.6-0.9l-3.8-2l-2.3-4.7l7.6-2.7l1.5-1l-3.2-1.5l-0.2-2.8
		l2-4l5-3.6l-1.7-0.9l3.9-1l1.2,0.9l2.2-2.1l2.6,1.5l-2.6-3.1l4.2-0.5l-3.5-4.3l1.5-4.8l3.2,0.8l-0.6,3l4.1-1.3l1.1,4.3l1.2-2.7
		l3.3-1.6l2.9,1.3l5.8,4.1l-0.9,5.9l-2.4,8l0.4,3.3l3.3-5v-3l2.9,1.3l2.8-1.2l7.5-1.5l-0.1,2.4l3.5,0.2l4.9-1.5l-1.8-3l1.5-0.9
		l4-0.5l5.2,2.2l0.1,2l1.5-0.3l1.5,2.1l-2.3,0.5L492,46l4.8,2.5l-1.4-6.3l1.7,1.5l2.9,0.1l-0.3-1.2l1.7-0.8l3.8,1.5l-0.1-2.6
		l2.1-0.2l-1.6-2.8l-2-0.6l2-1.7l-2.4-2l8.3-0.6l-0.1,1.9l2.4-1l0.7,3.3l0.8-4.3l5-0.6l-0.3,2l3.3,2l1.2-1.2l1.8,0.9l0.3,1.5
		l3.7-1.1l5.9-2.8l4.2,2l1.5,3.9l2.9-0.7l2.3,1.6l-0.3-2.4L554,36l1.1-2.5l1.8,1.9l2.4,0.9l2.1,1.5l0.8-2.3L559,33l-1.1-2.3l9.1-2
		l9,1.5l11,1.5l2.6,3.9h2.2l-2.5-3.4l2.4-2l4.4,0.7l2,3.3l-2.5,2.8l2.1,1.3l2.8,4.8l-5.4,1.5l-1.4-1.7l-2.3,0.1l-0.4,2l-1.9-0.5
		l-1.5-2.2l-1,1.8l3.6,3.4l-0.9,5.7l-2.4-0.6l-1.5,1.2h1.8l9.8,7.1v2.4h-3l-4.3,6.9l-0.9,6.3v1.8l-3-2.2l-2.4,0.6l-1.5,3.9l-1.3-2.3
		l-3.2,2.6l1.3,3.4l-0.1,5.3l1.3,1.6l0.4-1.5l1.7,0.6l-0.1,3.5h1.4l1.6,2.3l-2.2,0.2l2.2,6.9l-1.9,2.1l1.2,3.8l-1.8,1.5l0.7,5.1
		l-2.2,2l-2.6-4.8l-7.1-12.3l0.2-4.9l-0.7-2.7l1.9-1.7l0.4-3.5l0.2-8.1l2.1-2l-1.3-8.3l-3.5,0.7l1.8,3.8l-1.7,5.6l-2.6-1.3l-0.5-3.5
		l-2.9,1l-2.6,1.7l0.2,3.3l-0.6,5.5l1.5,0.2l0.9,1.2l-5.4,3l-0.5-0.9c0,0,0.8-1.5,0.5-1.6s-4.8-0.8-4.8-0.8l-0.4,2.4l-2.9,0.1
		l-7.4,2.3l-1.2,3l-1.3,6.1l-3.5,9.4l3.1,0.1l1.2,3l1.5-0.6l1.5,0.8l0.8-2.1l5.3,2.5l0.8,2.9l0.8,1.7l0.7,5.1l1.7,5.6l-0.9,5.4
		l-2,7.4l-3.3,6.6l-2.1,0.5l-0.7-1.4l-1.3-0.1l-0.9,2.1l-0.4,0.5l0,0l-1.5,1.8l0.7,2.9L539,159l0.2,1.7l1.8,1l0.9,1l0,0l0,0l1.9,2.8
		l1.9,5.1l-2.5,1.6l-2.5,2l-1.2-2.2l0.6-1.8l-1.6-2.5l1-0.7l-1.6-2.4l-1.5,1.1l-1-1.3V160l-1.8-0.5l0,0l-0.3-0.1l-3.2,1.6l-0.5,1
		l-1.3,1.3l-0.7-0.6l0.9-1.2l-0.8-1.5l1.1-2.6l-2.3-0.6l-1.3,2.8l-1.5,0.3l-0.5,2.5l-2-0.2l-0.2,2l1.7,1.6l1.1-0.1l0.5,2h1.2
		l0.9-1.6l1.3-0.9l1.2,0.9h2.9l-0.1,1.8l-2.3,0.5l-2.3,2.3l-0.5,3l2.4,1.1l3.8,5.1l-1.4,1l2.2,1.8l-2,1.8l2.7,1.4l-1.1,4.2l-2.1,4
		l-2.4,4.8l-2.6,3.2l-5.9,2.4l-4.9,1.8v2l-1.2,0.1l-0.7-2.2l-1.7-0.6l-2,0.7l0,0l-2.1,0.7l-1.2,2.3l-0.3,2.9l1.5,1.2l0.4,1.2
		l4.5,3.6l1.5,5.6v3.2l-3.3,2.7l-1.3,0.1l-2.5,3.5l-1.2,0.3l0.7-3.5l-1-0.2h-0.1l0,0h-1.5l-1-1.4l-1.6-2l0,0l-0.1-0.1l-3.4-1.3v-1.8
		h-1.6l-0.1,2l-0.7,2.9l-0.7,2.8l0.9,0.7l1.4,1.6l0.7,1.6l-0.5,0.5l1.2,1.2l2.6,1.4l0,0l0,0l2.4,2.6l0.2,3.9l1.8,2.7l-0.5,1.1
		l-5.2-3.2l-1.9-2.9l-0.8-3.8l-0.5-0.7l-2.8-3.7l-0.6,0.3v-4.5l0.6-2.6l-0.8-3.5l-1.5-4.1L489,215v1.5l-4.2,2.1l-0.9-0.5l0.9-3
		l-1.4-3.8l-1,1.1L481.4,211.5z M316.2,244.6l1.1-1.4v1.9L316.2,244.6z M302.7,169.8l0.7-0.3l0.3,0.6l-0.7,0.6L302.7,169.8z
		 M304.7,168.6l0.7-0.6l0.7,0.2l-0.5,1L304.7,168.6z M306.6,167.4h0.8l0.4,0.5H307L306.6,167.4z M317.5,164.6v4.9l-2.3,0.9v-5.1
		L317.5,164.6z M315.2,161.4l1.5-1.4v4.1H316L315.2,161.4z M322.9,172.7l5.8-0.5l-1,3.7L322.9,172.7z M327.4,177l-0.4,0.8l-0.7-1
		L327.4,177z M336,167.9h0.5l0.5,0.9l-0.3,0.3L336,167.9z M337.8,172.2l0.3-1.6l0.6,1.6H337.8z M338.8,172.6v0.5l-0.2,0.1l-0.2-0.5
		L338.8,172.6z M345.2,170.6l0.2,1.1l0.8,0.5l-0.9,0.3l-0.5-1.1l-1.1-0.6l-0.3-1.3h0.5v0.8L345.2,170.6z M347.3,173.2l-0.6,0.3
		l-0.7-0.6l0.3-0.3L347.3,173.2z M346.3,167.7v-0.6l0.7-0.2v1L346.3,167.7z M348.2,170.1l-0.8-0.6l0.9-0.7h0.7l0.2,1.3h-0.7v-0.9
		L348.2,170.1z M347.9,174.1v0.8h-1v-0.8H347.9z M346.3,175.4l-0.8,0.5l-0.4-0.5l0.6-0.6L346.3,175.4z M346.6,177.6l3,0.9l-0.5,0.9
		l-5.4-0.7v-1.1L346.6,177.6L346.6,177.6z M349,172.9l0.4-0.3l0.8,0.3l-0.4,0.3L349,172.9z M350.5,177.8l0.5-0.7v0.9L350.5,177.8z
		 M351.6,176.5l1.5-0.6l-1.1,1.2L351.6,176.5z M360.7,178.4l2.3-0.9h2.3l-1.1,1.6l-2.5,0.7L360.7,178.4z M318.9,129l-0.3-0.8
		l0.7-0.7l0.7,0.6l-0.3-0.9l1.3-0.2v1.9l-0.5,0.4v1.6h-1.6l-0.5-0.5l0.6-0.2l1,0.2v-1.1L318.9,129z M317.4,130l-0.9-1.1l0.9-0.5
		l0.9,1L317.4,130z M324.8,129.6v-1.3l0.7,0.4v1.2L324.8,129.6z M327.9,126.4l0.5-3.1h0.6l-0.6,3.3L327.9,126.4z M330.3,122.9
		l0.7-1.1c0,0,0.9-0.2,1.1-0.2l-0.2,1.6l-0.8,1.6h-0.4L330.3,122.9z M332.1,114.1l0.5-0.9l0.8,0.4v1.3L332.1,114.1z M337.3,119.2
		h1.1v1.2h-0.8l-0.3,0.8l-0.5,0.3v-1.8L337.3,119.2z M336.7,118.4l1.1-0.6l0.6,0.5l-0.6,0.5L336.7,118.4z M592.2,269.5l3.2,2.2v1.5
		l-6-1.2l-2.5-3.6l-2.8-1.6l-2.5,0.5l-0.8,3.7l-3.8-0.6l-0.3-0.2l0,0l-2.6-2.1l-3.7,0.5l1.9-2.7l-2.2-3.6l-7.2-3.8l-2,1.6l-1.5-2.7
		l3.4-0.6l-0.3-1l-2.9,0.5l-2.3-1.9l-0.4-1.1l3.2-1.3l3.5,1.3l0.6,3.5l2.4,1.4l3.9-4.4l6.7,2.6l0,0l9.5,3.7l-0.5,1.8l4,1.5l0.2,1.8
		l-1.5,0.2L592.2,269.5z M564.6,263.2l-1.5,2.8l-0.5-1.5l1-1.9C564,262.7,564.3,262.9,564.6,263.2z M533,258.6l1.5-4.9l0.9-3.3
		l2.2-1.6l5.7,0.5l2.7-1.1v1.1l-2.5,2l-3.1-1h-3.1l-1.5,1.6l1.8,2.4l4.8-2.1l0.3,1.5l-4,2l3.7,4.2l-3.6,2l-1.2-4l-0.7-0.6l-0.4,5.9
		l-2.1-0.3v-3.7L533,258.6z M515.1,247.5l1.1,0.3l0,0l2,0.5l1.6-2.5l4-3.6l5.3-5.4l5.2,3.7l-2.2,1.1l-0.8,1.4l-0.9,1.7l1.2,1.9
		l-0.4,1l2.5,2.2l-2.7,0.4l-2.2,4.6l-1.1,3.7l-2.5,1.6l-1-1.6l-7.3-0.6c0,0-2.9-7.6-3.5-7.5C512.9,250.5,515.1,247.5,515.1,247.5z
		 M513.9,213.1h-1.7l-0.9-2.4l1.5-1.4h3.1v1l-0.7,1.9L513.9,213.1z M537.9,221.1h1.9l2,1.1l0.8,2.2l3.2,0.5l0.9,3.4l-0.9,0.4
		l-1.8-0.1l1.1,2.3l-1.3,0.6l-0.5-1.3l-1.5,2.4l-1.9-1.2l0.8-2.6l-1.5,0.7v-2.7l1.9,0.6l0.7,2.8l1.5-2.9h1.2l-1.7-1.6l-2.5-2.2
		l-3-0.7l-1.6-1.4l-1.9-2.6l0.6-5.2l1.2-1.8l3.8,4.6l-2,2.4L537.9,221.1z M547.9,232.6l0.9,4.1l-2-0.6v1.9l-0.9,1.8l-2.2-1.3
		l-0.2-2.6h-2.3l-0.9,1.6h-0.9v-1.9l3.1-1.9l0.9,0.9l2.5-3.6L547.9,232.6z M538.3,225.1l-1.3,1.1l-1.8-3.4l2.4,0.6L538.3,225.1z
		 M535.8,202.1l-1.2,1.5l0.7,1.8h-0.7l-0.5-1.4l-1.2-1.4v-2.8l1.8-3l1.5,1L535.8,202.1z M533.8,227.4l0.6,2l-4.2,4.3l-0.9-0.6
		L533.8,227.4z M542.9,270.3l1.4-0.8l1.6-1l3.5-0.6l0.5,1.1l-3.6,1.3l-0.2,0.1l-1.7,1.7l-2.6,0.6L542.9,270.3z M547.7,259.4l-1-1.5
		l1.9-0.6l1.2,2.1H547.7L547.7,259.4z M547.3,177.2l1.7-2v-1.6l3.2-3.2l3.5-0.9l2-0.7l1.1-3.6h3.2l0.9-3.6l-0.9-4.1v-2.8l1.7-1.8
		l2.6,3.4l0.7,5.1l-1.7,1.5l1.2,4.4l1.2,1.8l-1.6,2.8l-0.7-2.6l-1.6,2l-1,1l-2.2-0.9v1.8l-1.9,2.9l-0.9-1.6l-1.4-2.2l-4.6,1.4
		l-1.6,1.2l1.6,1.6v5.1l-1.7,1.5L547.3,177.2z M560.5,133.6l3.2,2.2v2l-2-1.5l-0.6,2.8l-1.3-3.6l-1.9-4.7l-2-8.6l-2.5-2.3l-1.7-5.5
		l1.7-2.9l2.3,5.2l1,2.8l3.5,5.8l2.6,3.9l-3.2-1.3L560.5,133.6z M563.7,151.4l-2,1.4l-1.2-3.1v-3.1h1.5V144l-0.9-2.5v-1.4l9,3.9v2.4
		h-2.5l-0.7,4l-3.2-1.7L562,149L563.7,151.4z M556.7,175.7h-1.3l-1.9,2.2l-1.9-3.1l3.8-1.3L556.7,175.7z M550.3,247.8
		c0.7-0.5,1.4-0.9,2.1-1.3l-0.7,1.9h1.5v2.1h-1.8l1.1,2v1.2l-2.1-2.2L550.3,247.8L550.3,247.8z M554.1,256.7l2.2,0.6l1,2.1l-2.8-1
		l-3.1,1l-0.5-2.1L554.1,256.7z M555.1,273.9l2.9,0.6l-1.1,1.5l-1.8-0.5L555.1,273.9L555.1,273.9z M557.7,268l-0.4-2l1.5-0.7v1.3
		L557.7,268z M570,142.2l1.3-3.1l3-1.9v1.9l-2.9,3.1H570z M575,138.2l1.5-3.6l0.9,0.9l-1.7,2.8L575,138.2L575,138.2z M582.3,223.4
		l1-1.3l0.8,0.7l-0.9,2.1L582.3,223.4z M513.4,256.7l-0.5,1.3h-1.6v-1.3H513.4z M510.3,256.8l-0.7,1.2l-2.8-2.2l1-1.3L510.3,256.8z
		 M484.2,237.9l-1.2-4.3l0.9,0.8l0.9,2.8L484.2,237.9z M491.8,250.5l-1.4-2.1h1.4l1.2,1.1L491.8,250.5z M495,255.7l-1.6-1.4l0.9-1.1
		l1.4,1.3L495,255.7z M499,257.2c0,0-1.5-2.3-1.5-2.6s-3.9-7-3.9-7l-4-4l-2.2-2.5v-1.4l4.4,1.1l3.9,3.4l4.2,3.8l3.3,2.3l1.2,1.2
		l-0.8,1.6l2,0.8l0.7,2.3l2.6,1.8l-1,5.3h-2.6L499,257.2z M506.8,265.2l2.6-2l4,1.9l3.5,0.3l0.4-1.5l3.5,1.5l3.1,1.9l2,0.7h3l4,0.5
		l3.5-0.5l2.5,0.5l2.3-1v1.5l-4.1,0.4l-2.6-0.4l-0.7,0.9l3.3,0.7v2.1c0,0-3.9-1.5-4.1-1.5s0-1.6,0-1.6l-3.8,0.5l-2.1-1.2h-3.7
		l-4.1-0.6l-6.7-1L506.8,265.2z M559.4,333.6l-0.7-0.7l2.7-0.9l0.9,1.5h-2.9V333.6z M576.7,353l-2.5,2.9l-2.5,1.4l-1-1.9v-7.6
		c1.2,0.8,2.3,1.6,3.4,2.5l3.6-1.3L576.7,353z M592.9,323.7l-7.1,9.9l-1.5,4.9l-1.5,2l-2.9-0.1l-4.7,3.2l-1.7-3.2l-2.8,2.2l-5.2-3.4
		l-0.9-2.4V332l-2,0.5l1-2.7l-3.2,0.9l2.7-6.1l-5.1,5.2l-2.1-6.9l-3.3-1.4l-5.9-0.6l-3.1,1.2h-3.4l-0.9,1.5l-2.6,0.2l-2.4,2.2H529
		l-5.1,2.7l-3.7-1c-0.5-0.9-1-1.7-1.6-2.4c-0.3-0.1,2.3-4.4,2.3-4.4l-1-7.5l-0.7-5.6l0.8-1l-0.8-3.1l1.5-6.3l3.2-1.9l11.8-3.1l2-2.9
		v-1.8l1.6-2.2l1.8,2.2l0.7-2.2l2.3-2.8l4.2-2.8l2.6,1.9l2.2,0.7v-2.4l3.3-3.5l3.4-0.4v-1.9l3.8,1.5l4.6,0.6l-2.8,4.7l0.7,2.1
		l5.3,3.3l2.8,1.9l2-1.9l1-7l1.3-5.8h1.4l1.8,7l2.1,0.6l1.3,8.5l3.6,3.2l6.7,11.8l1.5,8.1L592.9,323.7z M599.5,261.7l-2,2.5h-2.8
		l-3.4-1.1v-1.5l2.9,0.8h2.5l1.5-3.2h1.3V261.7L599.5,261.7L599.5,261.7z M600.6,261.2l-1.6-2.9l-2.8-1v-1.1l3.3,1.5l1.7,3.6
		L600.6,261.2L600.6,261.2z M603.8,263.6v-1.7l2.9,2.4l-1.6,1L603.8,263.6z M606.6,374.7l1-2.9l1.7,1.9L606.6,374.7z M625.5,358.8
		l-3.8,3.4v1.9l-3.7,0.8l-2.8,4.4l-3.9,3.4l-2.5-1.3l-2.3-1l3-5l5.1-3.1l4.6-3.4l4.2-6l2.7,3.6L625.5,358.8z M627.1,284.7l0.9,1.6
		h-1.3L627.1,284.7z M636,349.5l-2,1.4l-5.6,6.4l-0.9-1.6l2-3.5l-2-2.1v-1.6l2.4-0.6l0.6-5.2l-0.6-4.2l-0.6-4.3l1.2-0.5l2.4,3.7
		l-1.1,3.8l1.7-0.5l0.2,3.8l1.8,1.6l1.7-1.5l1,0.9L636,349.5z M648.2,291l-2.6-0.5l0.7-1.5l1.9-0.9l0.5,1.5L648.2,291z M651.5,287.8
		h-2.8v-1.1l2.8-1.1V287.8z"/>
</g>
</svg>
    {showDeletePostModal && (
          <div className="detailsMap">
            <MapDetails
              closeDeleteModal={closeDeleteModal}
              country={country}
              member={usermember}
              regionDetails={regionDetails}
            />
          </div>
        )}

   </>
  )
}

export default NewMaps